import { Repositorio } from './repositorio';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Productos } from '../models/Productos';
import { Almacen } from '../models/Almacen';

export class RepositorioProductos extends Repositorio<Productos> {
  constructor(http: HttpClient) {
    super(http, 'Productos');
  }

  generarFolio(): Observable<string> {
    const ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  obtenerPorAlmacen(almacenId: number): Observable<Productos[]> {
    const ruta = `${this.ruta}/ObtenerPorAlmacen/${almacenId}`;
    return this.http.get<Productos[]>(ruta);
  }

  obtenerPorGrupo(almacenId: number): Observable<Productos[]> {
    const ruta = `${this.ruta}/ObtenerPorGrupo/${almacenId}`;
    return this.http.get<Productos[]>(ruta);
  }

  obtenerProductosAlmacenAlternativos(almacenId: number): Observable<Productos[]> {
    const ruta = `${this.ruta}/ObtenerProductosAlmacenAlternativos/${almacenId}`;
    return this.http.get<Productos[]>(ruta);
  }

  obtenerProductosAlmacenPrincipales(almacenId: number): Observable<Productos[]> {
    const ruta = `${this.ruta}/ObtenerProductosAlmacenPrincipales/${almacenId}`;
    return this.http.get<Productos[]>(ruta);
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerProductos(): Observable<Productos[]> {
    const ruta = `${this.ruta}/ObtenerProductos`;
    return this.http.get<Productos[]>(ruta);
  }

  obtenerProductosPorAlmacen(almacenId: number): Observable<Productos[]> {
    const ruta = `${this.ruta}/ObtenerProductosPorAlmacen/${almacenId}`;
    return this.http.get<Productos[]>(ruta);
  }

  obtenerAlmacenes(id: number): Observable<Almacen[]> {
    const ruta = `${this.ruta}/ObtenerAlmacenes/${id}`;
    return this.http.get<Almacen[]>(ruta);
  }

  guardarProductosAlmacenes(
    model: Productos,
    modelAlmacen: Almacen
  ): Observable<Productos[]> {
    const ruta = `${this.ruta}/GuardarDatosAlamacen/`;
    const params = new HttpParams();

    return this.http.post<Productos[]>(ruta, {
      model,
      modelAlmacen,
    });
  }
}

export class Usuario {
  id: number;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  email: string;
  telefono: string;
  contrasena: string;
  confirmacionContrasena: string;
  actualizoContra: boolean;
  activo: boolean;
  tipoUsuarioId: number;
  tipoUsuarioDescripcion: string;
  nombreCompleto: string;
  enviarNotificaciones: string;
}

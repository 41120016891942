import {
  InventarioInicial,
  SalidasDiarias,
  EntradasySalidas,
} from './../../models/modeloTablero';
import { TableroInventario } from './../../models/tableroInventario';
import { KPISModels } from './../../models/kpis';
import { Contexto } from './../../api/contexto.service';
import { Usuario } from 'src/app/shared/models/usuario';
import { MatTableDataSource } from '@angular/material';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnalisisPfep } from '../../models/analisis-pfep';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: 'catalogo.component.html',
  styleUrls: ['catalogo.component.scss'],
})
export class ControlInventarioComponent implements OnInit, OnDestroy {
  constructor(private ctx: Contexto, public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) {}
  // guardo la cantidad de dias necesarios
  cantidad_de_dias: string[] = [];
  semanasEntrada: string[] = [];
  semanasSalida: string[] = [];
  columnasPredefinidas = [
    'numero',
    'codigo',
    'inicial',
    'semanasEntrada', // se sustituye
    'resumen',
    'balance',
    'prom',
    'diasSalida', // se sustituye
    'semanasSalida', // se sustituye
    'tendencia',
    'm6',
  ];
  posicionSemanaEntrada = 3;
  posicionDias = 7;
  posicionSemanaSalida = 8;
  columnas = [];
  sub: Subscription;
  // fuenteDatos = new MatTableDataSource<TableroInventario>();
  fuenteDatos = new MatTableDataSource<InventarioInicial>();

  tableroInventario: TableroInventario;
  ngOnInit() {
    // this.obtenerCantidadDias();
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.obteneDatosTableroInventario();
      }
    );

  }

  obtenerCantidadDias() {
    const fecha = new Date();
    const mes = fecha.getMonth();
    const dias_del_mes = new Date(
      fecha.getFullYear(),
      fecha.getMonth() + 1,
      0
    ).getDate(); // Obten el mes actual y selecciono sus dias
    console.log(dias_del_mes);
    // se crea el arreglo con los dias para mostrar en las columnas de la tabla
    for (let i = 0; i < dias_del_mes; i++) {
      this.cantidad_de_dias.push(`${i + 1}`);
    }
  }
  salidas: EntradasySalidas[] = [];
  // obtenemos los datos del inventario
  obteneDatosTableroInventario() {
    this.ctx.KPIS.tableroInventario(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((e) => {
        this.tableroInventario = e;
        this.fuenteDatos = new MatTableDataSource(e.inventarioInicial);
        //  e.entradaSemanal.forEach(e=>{
        //    this.salidas.push(e)
        //  })
        this.cargarColumnasMostrar();
      });
  }

  obtenerDatoSemanaEntrada(indiceSemana: number, productoId: number): number {
    const entrada = this.tableroInventario.entradaSemanal[indiceSemana].find(
      (e) => e.productoId === productoId
    );

    return entrada ? entrada.cantidadEntrada : 0;
  }

  obtenerDatoSemanaSalidas(indiceSemana: number, productoId: number): number {
    const entrada = this.tableroInventario.salidaSemanal[indiceSemana].find(
      (e) => e.productoId === productoId
    );
    const a = entrada ? entrada.cantidadEntrada : 0;
    if (a > 0) {
      console.log(a);
    }
    return entrada ? entrada.cantidadSalida : 0;
  }

  obtenerDatosDias(indiceDia: number, proudctoId: number): number {
    const salidaDia = this.tableroInventario.salidasDiarias.find(
      (e) => e.productoId === proudctoId
    );
    if (salidaDia.productoId === 757) {
      console.log(salidaDia);
    }
    if (salidaDia) {
      return salidaDia.cantidad[indiceDia];
    } else {
      return 0;
    }
  }

  InventarioInicialSemanas() {
    this.ctx.KPIS.tableroInventario(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((e) => {
        this.tableroInventario = e;
        let semanas = e.entradaSemanal;
        for (const items of semanas) {
        }
      });
  }

  cargarColumnasMostrar() {
    this.obtenerCantidadDias();
    this.columnas = this.columnasPredefinidas;
    this.semanasEntrada = this.tableroInventario.calendario.map(
      (c, i) => `E${i + 1}`
    );
    this.semanasSalida = this.tableroInventario.calendario.map(
      (c, i) => `S${i + 1}`
    );

    this.columnas.splice(this.posicionSemanaEntrada, 1, ...this.semanasEntrada);
    const posicionDias = this.posicionDias + this.semanasEntrada.length - 1;
    this.columnas.splice(
      posicionDias,
      1,
      ...this.cantidad_de_dias.map((d) => d.toString())
    );
    const posicionSalida =
      this.posicionSemanaSalida +
      this.cantidad_de_dias.length +
      this.semanasEntrada.length -
      2;
    this.columnas.splice(posicionSalida, 1, ...this.semanasSalida);
  }

  CrearTableroInventario() {}

  exportarAExcel() {}

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

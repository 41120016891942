import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import * as XLSX from 'xlsx';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { FormBuilder } from '@angular/forms';
import { ExcelAsignacionAlamacenProducto } from 'src/app/shared/models/excel-asignacion-almacen-producto';
import { AsignacionAlmacenProducto } from 'src/app/shared/models/asignacion-almacen-producto';
@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaDocumentoExcelAlmacenProductoComponent implements OnInit {

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }
  columnas = ['almacen','codigoProducto', 'mina'];
  file: File;
  arrayBuffer: any;
  docs: string[] = [];
  guardado: EventEmitter<void> = new EventEmitter<void>();
  asignaciones: AsignacionAlmacenProducto[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dato: any,

    private formBuilder: FormBuilder,
    private alerta: ServicioAlerta,
    private ctx: Contexto,
    private ventana: MatDialogRef<FormaDocumentoExcelAlmacenProductoComponent>,
    private exportAsService: ExportAsService,
  ) {

  }

  ngOnInit() { }

  archivos($event) {
    this.asignaciones.length = 0;
    this.file = $event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.asignaciones = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as AsignacionAlmacenProducto[];
      console.table(this.asignaciones);
      this.docs.push(this.file.name);
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  async guardarAsignaciones() {
    if (this.asignaciones && this.asignaciones.length > 0) {
      const asigs = this.asignaciones.map(a => {return {...a, id: 0, activo:true, productoId: 0, almacenId: 0}}) as AsignacionAlmacenProducto[];
      await this.ctx.asignacionAlmacenProducto.guardarRangoExcel(asigs).toPromise().then(errores => {
        if (errores.length) {
          this.alerta.mostrarExito("Entradas guardadas con éxito");
          this.cerrar();
          this
        } else {
          this.alerta.mostrarError("Error en los codigos " + errores.join(', \n'));

        }
      }).catch(error => {
        console.log(error);
        this.alerta.mostrarExito("Error interno del sistema");
      });
    } else {
      this.alerta.mostrarAdvertencia("Ingrese el archivo excel");
    }
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'arcvhivo_no_encontrado').subscribe();
  }

  cerrar(): void {
    this.guardado.emit();
    this.cerrar();
  }
}

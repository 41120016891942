import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MinaUbicaciones } from '../../models/mina-ubicacion';
import {
  MatTableDataSource,
  MAT_DATE_FORMATS,
  MatPaginator,
  MatSort,
} from '@angular/material';
import { Contexto } from '../../api/contexto.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Almacen } from '../../models/Almacen';
import { Mina } from '../../models/Mina';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { FiltroPfep } from '../../models/filtro-pfep';
import { LoadingService } from 'src/app/loading/loading.service';
import * as XLSX from 'xlsx';
import { AlmacenPfep } from '../../models/almacen-pfep';
import { ReservaPfep, InfoReservaPfep } from '../../models/reservas-pfep';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Inventario } from '../../models/Inventario';
import {
  DetalleRequisicionAlmacenPfep,
  DetalleRequisicionPfep,
  RequisicionPfep,
} from '../../models/requisicion-pfep';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { retry } from 'rxjs/operators';
import { ExcelMaxMinCriticos } from '../../models/excel-max-min-criticos';
import { rmdir } from 'fs';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@AutoUnsubscribe()
@Component({
  selector: 'app-reservas-pfep',
  templateUrl: './reservas-pfep.component.html',
  styleUrls: ['./reservas-pfep.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class ReservasPfepComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtroTabla', { static: false }) filtroTabla: ElementRef;
  @ViewChild('reporte', { static: true }) reporte: ElementRef;

  columnasOriginales = [
    'productoClave',
    'precioMl',
    'precioUsd',
    'status',
    'ideal',
    'maximo',
    'efectivo',
    'idealCritico',
    'maximoCritico',
    'uso',
    'b9',
    'whs',
  ];

  columnas: string[] = [];
  minas: Mina[] = [];
  ubicaciones: MinaUbicaciones[] = [];
  almacenes: Almacen[] = [];
  fuenteDatos = new MatTableDataSource<ReservaPfep>();
  almacenesReabastecimientos: AlmacenPfep[] = [];
  filtro: FormGroup;
  formaRequisicion: FormArray;
  almacenSeleccionado: Almacen;
  subscipcionesCantidad: Subscription;
  subscripcionAlmacen: Subscription;
  arrayBuffer: any;
  file: File;

  // Devualve la lista de columnas por almacenes de reabastecimiento que se deben mostrar
  get codigosReabastecimiento(): string[] {
    return this.almacenesReabastecimientos.map((a) => a.codigo);
  }

  constructor(
    public ctx: Contexto,
    private fb: FormBuilder,
    private alerta: ServicioAlerta,
    private cargando: LoadingService,
    private transalte: TranslateService,
    private router: Router,
    private translate:TranslateService
  ) { }
  ngOnInit() {
    this.cargarMinas();
    const hoy = new Date();
    this.filtro = this.fb.group(
      {
        desde: [moment(), Validators.required],
        hasta: [moment(), Validators.required],
        almacenId: [undefined, Validators.required],
        minaId:[undefined, Validators.required],
      },
      { validators: this.validadorFechas }
    );
    this.subscripcionAlmacen = this.filtro
      .get('almacenId')
      .valueChanges.subscribe((id) => {
        this.almacenSeleccionado = this.almacenes.find((a) => a.id === id);
      });
  }

  validadorFechas(control: AbstractControl): { [key: string]: any } | null {
    const filtro = control.value as FiltroPfep;
    const desde = moment(filtro.desde);
    const hasta = moment(filtro.hasta);
    return desde > hasta ? { invalidDates: true } : null;
  }

  cargarMinas() {
    this.ctx.Minas.obtenerTodos()
      .toPromise()
      .then((minas) => (this.minas = minas));
  }

  buscarUbicaciones(minaId: number) {
    this.ctx.MinaUbicaciones.obtenerPorMina(minaId)
      .toPromise()
      .then((ubicaciones) => (this.ubicaciones = ubicaciones));
  }

  buscarAlmacenes(ubicacionId: Moment) {
    this.ctx.Almacen.obtenerPorUbicacion(ubicacionId)
      .toPromise()
      .then((almacenes) => (this.almacenes = almacenes));
  }

  desdeSeleccionado(desde: Moment) {
    console.log(desde);

    this.filtro.get('desde').setValue(desde);
  }

  hastaSeleccionado(hasta: Date) {
    this.filtro.get('hasta').setValue(hasta);
  }

  generarForma(reporte: InfoReservaPfep) {
    // Limpio las subscripciones en caso de que se vuelva a buscar otro análisis.
    if (this.subscipcionesCantidad) {
      this.subscipcionesCantidad.unsubscribe();
    }
    this.subscipcionesCantidad = new Subscription();
    // En esta forma se agregará el reparto de inventario
    this.formaRequisicion = this.fb.array(
      reporte.analisisReservas.map((r, i) => {
        const grupo = this.fb.group({
          productoId: [r.productoId, Validators.required],
          cantidadRequerida: [null],
          almacenes: this.fb.array(
            reporte.almacenes.map((a) =>
              this.fb.group({
                almacenId: [a.id, Validators.required],
                cantidad: [0, Validators.min(0)], // Cantidad que se le solicitará al almacen
              })
            )
          ),
        });

        // Agrego la subscripción a una sola lista, así solo tengo que declarar solo una variable
        // Espero que modifiquen la cantidad requerida. Cuando se modifica el campo se procede a calcular el reparto
        this.subscipcionesCantidad.add(
          grupo
            .get('cantidadRequerida')
            .valueChanges.subscribe((cantidadRequerida) => {
              this.repartirRequisicion(i, cantidadRequerida);
            })
        );

        return grupo;
      })
    );

    this.formaRequisicion.valueChanges.subscribe((a) => { });
  }

  // Devuelve la existencia del producto, dependiendo del almacén. Esto se consulta al momento de generar la tabla.
  obtenerExistencia(productoId: number, inventario: Inventario[]): number {
    const inv = inventario.find((i) => i.productoId === productoId);
    return inv ? inv.cantidad : 0;
  }

  // Reparte la cantidad requerida entre los almacenes con existencia disponible. Se ejecuta al modificar un campo b9 en la tabla.
  repartirRequisicion(indiceProducto: number, cantidad: number) {
    cantidad = !cantidad || isNaN(cantidad) ? 0 : cantidad;
    const ctrlProducto = this.formaRequisicion.controls[indiceProducto];
    const productoId = ctrlProducto.get('productoId').value as number;
    let cantidadRestante = cantidad;
    const formaAlmacenes = ctrlProducto.get('almacenes') as FormArray;
    formaAlmacenes.patchValue([
      ...formaAlmacenes.controls.map((f) => {
        return { ...(f.value as DetalleRequisicionAlmacenPfep), cantidad: 0 };
      }),
    ]);
    // Recorro los almacenes para repartir la cantidad de requisitada
    for (const a of this.almacenesReabastecimientos) {
      const inventario = a.inventarios.find((i) => i.productoId === productoId);
      if (inventario) {
        const indiceAlmacen = this.almacenesReabastecimientos.findIndex(
          (al) => a.id === al.id
        );
        // Si el primer almacen tiene el suficiente inventario, se le asigna todo lo necesario a ese mismo
        if (inventario.cantidad >= cantidadRestante) {
          formaAlmacenes.controls[indiceAlmacen]
            .get('cantidad')
            .setValue(cantidadRestante);
          cantidadRestante = 0;
        } else {
          // En caso de que que no tenga el suficiente se le asigna todo el disponible
          formaAlmacenes.controls[indiceAlmacen]
            .get('cantidad')
            .setValue(inventario.cantidad);
          cantidadRestante -= inventario.cantidad;
        }
        // Si ya no se necesita repartir mas, salimos de la iteración
        if (cantidadRestante === 0) {
          break;
        }
      }
    }
  }

  solicitarAnalisis() {
    if (this.filtro.invalid) {
      this.alerta.mostrarAdvertencia('Datos inválidos');
      return;
    }
    this.cargando.show('');
    const filtro = this.filtro.value as FiltroPfep;
    this.ctx.analisisPfep
      .buscarAnalisisReservas(filtro)
      .toPromise()
      .then((reporte) => {
        // Preparo las columnas a mostrar
        // columnasAlmacenInfo muestran el inventario actual dependiendo del producto
        const columnasAlmacenInfo = reporte.almacenes.map((a) => a.codigo);
        // columnasAlmacenInfo muestran un campo de texto, donde se puede capturar la cantidad que sera requisitada a ese almacen
        const columnasAlmacenEditar = columnasAlmacenInfo.map((a) => 'b9' + a);
        // Agrego las columnas de almacenes a las columnas predeterminadas
        this.columnas = [
          ...this.columnasOriginales,
          ...columnasAlmacenInfo, // Mostrarán el inventario del producto del registro
          ...columnasAlmacenEditar, // Mostrarán las columnas para editar la cantidad que se le pedirá al almacen del respectivo producto
        ];
        // Se guardan los almacenes para obtener los valores de inventario al momento de crear la tabla
        this.almacenesReabastecimientos = reporte.almacenes;
        this.generarForma(reporte);
        this.fuenteDatos = new MatTableDataSource(reporte.analisisReservas);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
      })
      .finally(() => this.cargando.hide());
  }

  inventario(codigoAlmacen: string, productoId: number): number {
    const almacen = this.almacenesReabastecimientos.find(
      (a) => a.codigo === codigoAlmacen
    );
    const inventario = almacen ? almacen.inventarios : undefined;
    const dato = inventario
      ? inventario.find((i) => i.productoId === productoId)
      : undefined;
    return dato ? dato.cantidad : 0;
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  limpiarFiltro(): void {
    this.filtroTabla.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  limpiarFuneteDatos() {
    this.fuenteDatos = new MatTableDataSource([]);
  }

  limpiarTodo() {
    this.limpiarFiltro();
    this.limpiarFuneteDatos();
  }

  guardarRequisiciones() {
    const detalles = this.formaRequisicion.value as DetalleRequisicionPfep[];

    if (this.formaRequisicion.invalid) {
      this.alerta.mostrarAdvertencia('Datos inválidos');
      return;
    }

    const requisicion = {
      almacenId: this.almacenSeleccionado.id,
      detalles: detalles,
    } as RequisicionPfep;

    this.ctx.analisisPfep
      .crearRequisiciones(requisicion)
      .toPromise()
      .then(() =>
        this.transalte
          .get('messages.guardar', { value: 'Documento' })
          .toPromise()
          .then((res: string) => {
            this.alerta.mostrarExito(res);
            this.limpiarTodo();
          })
      )
      .catch((error) => {
        console.log(error);
        this.transalte
          .get('guardarError')
          .toPromise()
          .then((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  exportarAExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.reporte.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Movimientos');

    /* save to file */
    const filtro = this.filtro.value as FiltroPfep;
    const desde = moment(filtro.desde);
    const hasta = moment(filtro.hasta);
    XLSX.writeFile(
      wb,
      `PFEP_${desde.month()}-${desde.year()}_${hasta.month()}-${hasta.year()}.xlsx`
    );
    // XLSX.writeFile(wb, `reporte_${filtro.desde}_${filtro.hasta}.xlsx`);
  }

  async requisicionSinDetalle() {


    let almacen = this.filtro.get('almacenId').value;
    console.log(almacen);
    if (almacen != null || almacen != undefined) {
      this.cargando.show("Espera un momento... Generando una nueva requisición.")
      let inventario = await this.ctx.Productos.obtenerPorAlmacen(almacen).toPromise();
      if(inventario.length <= 0)
      {
        this.cargando.hide();
        this.transalte.get('messages.noExisteInventario').toPromise()
        .then((res: string) => {
          this.alerta.mostrarExito(res);
        })
        return;
      }
      let pedido = await this.ctx.analisisPfep.crearRequisicionSinDetalle(almacen).toPromise();
      if (pedido) {
        this.cargando.hide();
        // this.router.navigate(['/Registro-Pedidos/', pedido.id]);
        this.router.navigate(['/RegistroPedidos/', pedido.id]);
      }
    } else {
      this.cargando.hide();
      this.translate.get("messages.seleccioneAlmacen").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  descargarLayOutMaxMinCriticos() {
    return window.open("assets/guias-excel/layout_max_min_criticos.xlsx", '_blank');
  }

  subirMaxMinCriticos(event){
    if (this.filtro.invalid) {
      this.alerta.mostrarAdvertencia('Seleccione un almacén');
      return;
    }
    this.cargando.show("Espera un momento... Cargando items con máximos y minimos críticos");
    const filtro = this.filtro.value as FiltroPfep;
    try {

      if (event.target.files && event.target.files[0]) {

        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          this.file = event.target.files[i];
          let fileReader = new FileReader();
          fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            let Json = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as ExcelMaxMinCriticos[];
            if (Json) {
              this.ctx.analisisPfep.cargarMaxMinCriticos(Json, filtro.minaId).toPromise().then(resultado => {
                this.alerta.mostrarExito("Articulos actualizados con máximos y minimos críticos");
                this.cargando.hide();

              }).catch(e => {
              this.cargando.hide();
                this.alerta.mostrarError("Error al cargar el archivo!");
              });
            } else {
              this.cargando.hide();
              this.translate.get("estructuraincorrecta").toPromise().then(e=>{
                this.alerta.mostrarError(e);
              })
            }
          }
          fileReader.readAsArrayBuffer(this.file);
        }
      }
    } catch (error) {
      this.cargando.hide();
      this.translate.get("errorleerdocumento").toPromise().then(e=>{
        this.alerta.mostrarError(e);
      })
      this.alerta.mostrarError('');
    }
  }
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
 
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
 
import { AutenticacionService } from 'src/app/services/autentication/autenticacion.service';
import { Schemas } from 'src/app/shared/models/esquema-desktop';

@Component({
  selector: 'esquema-dekstop',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoEsquemaDesktop implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<Schemas> = new MatTableDataSource([]);
  columnasMostradas = ['version', 'fecha'];

  constructor(public ventana: MatDialog, private ctx: Contexto, private spinner: LoadingService, private alerta: ServicioAlerta, private translate: TranslateService) { }

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  ngOnInit() {
    this.tabla();
  }


  tabla(): void {

    this.ctx.esquemaDesktop.obtenerTodos().toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;

    })
      .catch(e => {
        this.translate.get("messages.errorInformacion").subscribe((res: string) => {
          this.alerta.mostrarError(res);
        })
      })
  }

  async generarNuevoEsquema() {
    try {
      let esquema = await this.ctx.esquemaDesktop.guardar(null).toPromise();
      if (esquema) {
        this.alerta.mostrarExito("Esquema generado con éxito");
        this.tabla();
      } else {
        this.alerta.mostrarExito("Error al generar el esquema");
      }
    } catch (error) {
      this.alerta.mostrarExito("Error interno del sistema");
    }
  }

  limpiarFiltro() {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { ConteosProductos } from '../../models/conteos-productos';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-pedido',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteConteosProductosComponent implements OnInit {
  columnas = [
    'productoCodigo',
    'productoDescripcion',
    'existencia',
    'cantidadConteo',
    'diferencia',
    'entradas',
    'salidas',
    'ajustes'
  ];
  conteo: ConteosProductos = new ConteosProductos();
  fechaLocal: Date = new Date();
  cantidadTotal: number = 0;
  cantidadLineas: number = 0;
  folio: string = "";
  fechaRegistro: string = "";
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ComprobanteConteosProductosComponent>,
    @Inject(MAT_DIALOG_DATA)
    public con: ConteosProductos,
    private exportAsService: ExportAsService,
  ) {


    this.conteo = con;

    this.cantidadTotal = this.conteo.detallesConteo.reduce((suma, detalle) => suma + detalle.existencia, 0);
    this.cantidadLineas = this.conteo.detallesConteo.length;
  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Conteo ' + this.conteo.folio).subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Conteo ' + this.conteo.folio).subscribe();
  }
  ngOnInit() {}

  regresar() {
    this.dialogRef.close();
  }
}

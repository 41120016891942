import {Component,OnInit,ViewChild} from '@angular/core'
import { FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Productos } from 'src/app/shared/models/Productos';
import { MatTableDataSource, MatPaginator,MatSort } from '@angular/material';

@Component({
  selector:"topProductos",
  templateUrl:'./catalogo.component.html',
  styleUrls:['./catalogo.component.scss']
})

export class CatalogoTopProductos implements OnInit{

  @ViewChild(MatPaginator,{static:false}) paginador:MatPaginator;
  @ViewChild(MatSort,{static:false}) ordenador:MatSort;

  tiempo=new FormControl();
  tipoBusqueda=new FormControl();


  displayedColumns: string[] = ['codigo', 'codigoBarra', 'descripcion'];

  NivelBusqueda=[
    {id:1,descripcion:"Mejores"},
    {id:2,descripcion:"Peores"}
  ];
  prd:MatTableDataSource<Productos>=new MatTableDataSource([]);

  /*
  this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
  */
  forma:FormGroup;
  get f(){return this.forma.controls}

  constructor(private ctx:Contexto,private formBuilder:FormBuilder){}

  ngOnInit(){
    this.forma=this.formBuilder.group({
      nivelBusqueda:['',Validators.required],
      nivelBusquedaId:['',Validators.required]
    })
  }

  Buscar(){
    console.log(this.forma.value);
    this.ctx.Inidicadores.obtenerPeriodos(this.forma.get('nivelBusqueda').value).subscribe(resultado=>{
      this.prd=new MatTableDataSource(resultado),
      this.prd.paginator=this.paginador
    })
  }

  limpiarTiempo(){
    this.tiempo;
  }
  tiempoSeleccionado(){
  }

  limpiarPeridoBusqueda() {
  }

  periodoBusquedaSeleccionado(){

  }




}


import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { UnidadesMedida } from 'src/app/shared/models/UnidadesMedida';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ReporteadorFiltro } from 'src/app/shared/models/reporteador-filtro';
import { ReporteAntiguedadStock } from 'src/app/shared/models/reporte-antiguedad-stock';
import { Observable, Subscription } from 'rxjs';
import { Almacen } from 'src/app/shared/models/Almacen';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';

@AutoUnsubscribe()
@Component({
  selector: 'grupo-productos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoReporteAntiguedadStock implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  forma: FormGroup;
  get f() {
    return this.forma.controls;
  }

  almacenFiltrados: Observable<Almacen[]>;
  almacenSinFiltrar: Almacen[];
  almacenfiltro = new FormControl();
  sub: Subscription;


  fuenteDatos: MatTableDataSource<ReporteAntiguedadStock> = new MatTableDataSource([]);
  columnasMostradas = [
    'codigo',
    'nombre',
    'almacen',
    'diasAntiguedad',
    'ultimaFechaMovimiento',
    'existencia'
  ];

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private spinner: LoadingService,
    private alerta: ServicioAlerta,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) { }
  async ngOnInit() {

    this.forma = this.formBuilder.group({
      diasAntiguedad: [0],
      almacen: [''],
      almacenId: [0],
    });
    // this.cargarAlmacenes();
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.f['almacen'].setValue(this.autenticacion.credencial.almacenDescripcion);
        this.f['almacenId'].setValue(this.autenticacion.credencial.almacenId);
        this.busqueda();
      }
    );


  }


  async busqueda() {
    const model = this.forma.value as ReporteadorFiltro;
    let reporte = await this.ctx.reporteAntiguedadStock.obtenerReporteAntiguedadStock(model).toPromise();
    if (reporte) {
      this.fuenteDatos = new MatTableDataSource(reporte);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    }
  }

  descargarExcel(): void {
    let fechaActual = new Date();
    let nombreArchivo = '';
    this.translate
      .get('messages.descargarExcel', { value: fechaActual.toLocaleString() })
      .subscribe((res: string) => {
        nombreArchivo = res;
      });
    this.ctx.reporteAntiguedadStock.obtenerExcel()
      .toPromise()
      .then((respuesta) => {
        this.llamarExcel(respuesta, nombreArchivo);
      })
      .catch((e) => { });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  limpiarFiltro() {

    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }


  cargarAlmacenes() {
    this.ctx.Almacen.obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.almacenSinFiltrar = resultado;
        this.almacenFiltrados = this.almacenfiltro.valueChanges.pipe(
          startWith<string | Almacen>(''),
          map((t) =>
            typeof t === 'string'
              ? t
              : t == null
              ? ''
              : '' + t.id + '' + t.descripcion
          ),
          map((t) => this.filtrarAlmacen(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarAlmacen(modelo: string): Almacen[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.almacenSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  almacenSeleccionado(modelo: Almacen) {
    if (modelo.id === undefined) {
      this.f['almacen'].setValue('Todos');
      this.f['almacenId'].setValue(0);
    } else {
      this.f['almacen'].setValue(modelo.descripcion);
      this.f['almacenId'].setValue(modelo.id);
    }
  }

  limpiarFiltroAlmacen(): void {
    this.f['almacen'].setValue('');
    this.f['almacenId'].setValue(0);
    this.cargarAlmacenes();
  }


  limpiarBusqueda() {
    this.f['diasAntiguedad'].setValue(0);
    // this.f['almacen'].setValue('');
    // this.f['almacenId'].setValue(0);
    // this.cargarAlmacenes();
    this.forma.markAsUntouched();
    this.busqueda();
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }

}

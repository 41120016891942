import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Almacen } from 'src/app/shared/models/Almacen';
import { MinaUbicaciones } from 'src/app/shared/models/mina-ubicacion';
import { startWith, map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { ListasPrecios } from 'src/app/shared/models/listas-precios';



@Component({
  templateUrl:'./forma.component.html',
  styleUrls:['./forma.component.scss']
})
export class FormaListaDePrecios implements OnInit{

  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: ListasPrecios;
  page="";


  mostrarReabastecimiento=false;

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaListaDePrecios>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) {

    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.ListaDePrecio.obtener(id).toPromise().then(resultado => {
        this.modelo=resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new ListasPrecios();
      this.cargarFolio();
    }

  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        codigo: ['', Validators.required],
        descripcion: ['', Validators.required],

      },
    );

    this.translate.get("title.listPrice").subscribe((res:string)=>{
      this.page=res;
    });

  }


  cargarFolio(): void {
    this.ctx.ListaDePrecio.generarFolio().toPromise().then(resultado => {
      this.forma.get('codigo').setValue("List-" + resultado);
    }).catch(e => {
      this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }


  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {
    if (this.forma.valid) {

      const model = this.forma.value as ListasPrecios;
      if (this.idSeleccionado === 0) {
        this.ctx.ListaDePrecio.guardar(model).toPromise().then(() => {
          this.translate.get('messages.guardar',{value: this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          if (e === 'Unprocessable Entity') {
            this.translate.get('messages.guardarExiste',{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          } else {
            this.translate.get('messages.guardarError').subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.ListaDePrecio.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get('messages.actualizar',{value:this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get('messages.guardarExiste',{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else {
            this.translate.get('messages.guardarError').subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get('messages.datosInvalido').subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }
}


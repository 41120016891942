import { Component, OnInit, Output, OnDestroy, EventEmitter, Inject, ɵConsole, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, map, groupBy } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { TranslateService } from '@ngx-translate/core';
import { Salida } from 'src/app/shared/models/Salida';
import { Almacen } from 'src/app/shared/models/Almacen';
import { almacenesPendientes } from 'src/app/shared/models/almacenesPendientes';
import { SelectionModel } from '@angular/cdk/collections';
import { FormaMensajeComponent } from 'src/app/utilerias/mensaje-en-modal/forma/forma.component';
import { ComprobanteComponent } from '../Comprobante/comprobante.component';

@Component({
  templateUrl: 'Selecciones.component.html',
  styleUrls: ['Selecciones.component.scss'],
})
export class SeleccionesComponent implements OnInit {
  checkSelect = new SelectionModel<Salida>(true, []);
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  forma: FormGroup;
  get f() { return this.forma.controls }
  fuenteDatos: MatTableDataSource<Salida> = new MatTableDataSource([]);
  columnasMostradas = ['seleccionar','folio', 'referencia', 'fechaRegistro'];

  DescripcionAlmacenSalidaFiltrados: Observable<almacenesPendientes[]>;
  DescripcionAlmacenSalidaSinFiltrar: almacenesPendientes[];
  DescripcionAlmacenSalidafiltro = new FormControl();

  almacen: almacenesPendientes;
  seleccion: Salida[] = [];

  almacenDescripcion = "";
  almaceId = 0;
  UbicacionDescripcion = "";

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public recibirDato: Salida[], private ventana: MatDialogRef<SeleccionesComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta, private translate: TranslateService, private ventanaDetalle: MatDialog,
    private ventanaConfirm: MatDialog) {
    if (recibirDato.length > 0) {
      this.almacen = new almacenesPendientes;
      this.almacen.almacenDescripcion = recibirDato[0].almacenDescripcion;
      this.almacen.almacenId = recibirDato[0].almacenId;
      this.almacen.minaUbicacion = recibirDato[0].ubicacionDescripcion;
    }

  }

  //seleccion:Salida[]=[];
  @Output() Asignaciones = new EventEmitter();

  ngOnInit() {
    this.forma = this.formBuilder.group({
      almacenDescripcion: ['', Validators.required],
      almacenId: [0],
      ubicacionDescripcion: [''],
    });

    this.cargarDescripcionAlmacenSalida();
    if (this.almacen != null) {
      this.AlamacenSalidaSeleccionado(this.almacen);
    }

  }

  cargarDescripcionAlmacenSalida() {
    this.ctx.Ventas.ObtenerAlmacenesPendientes()
      .toPromise()
      .then((resultado) => {
        this.DescripcionAlmacenSalidaSinFiltrar = resultado;
        this.DescripcionAlmacenSalidaFiltrados = this.DescripcionAlmacenSalidafiltro.valueChanges.pipe(
          startWith<string | Almacen>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcionAlmacen
          ),
          map((t) => this.filtrarAlmacenSalida(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarAlmacenSalida(modelo: string): almacenesPendientes[] {
    const valorFiltro = modelo;
    let filtro = this.DescripcionAlmacenSalidaSinFiltrar.filter(
      (t) => t.almacenDescripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }


  AlamacenSalidaSeleccionado(modelo: almacenesPendientes) {

    let dato = {
      mensaje: 'Si realiza el cambio perdera los datos',
      mostrarCargando: true,
      botonAutorizar: true,
      botonRechazado: true
    }

    if (this.almacenDescripcion != '') {
      this.ventanaConfirm.open(FormaMensajeComponent, { data: dato, panelClass: "form-container" }).afterClosed().toPromise()
        .then(res => {
          if (res == 4) {
            this.seleccion.length = 0;
            this.f['almacenDescripcion'].setValue(modelo.almacenDescripcion);
            this.f['almacenId'].setValue(modelo.almacenId);
            this.f['ubicacionDescripcion'].setValue(modelo.minaUbicacion);
            this.almacenDescripcion = this.f['almacenDescripcion'].value;
            this.almaceId = parseInt(this.f['almacenId'].value);
            this.UbicacionDescripcion = this.f['ubicacionDescripcion'].value;
            this.tabla();
          } else {
            this.f['almacenDescripcion'].setValue(this.almacenDescripcion);
            this.f['almacenId'].setValue(this.almaceId);
            this.f['ubicacionDescripcion'].setValue(this.UbicacionDescripcion);
          }
        })
    } else {
      this.seleccion.length = 0;
      this.f['almacenDescripcion'].setValue(modelo.almacenDescripcion);
      this.f['almacenId'].setValue(modelo.almacenId);
      this.f['ubicacionDescripcion'].setValue(modelo.minaUbicacion);
      this.almacenDescripcion = this.f['almacenDescripcion'].value;
      this.almaceId = parseInt(this.f['almacenId'].value);
      this.UbicacionDescripcion = this.f['ubicacionDescripcion'].value;
      this.tabla();
    }

  }

  limpiarFiltroAlmacenSalidas(): void {
    this.f['almacenDescripcion'].setValue('');
    this.f['almacenId'].setValue(0);
    this.cargarDescripcionAlmacenSalida();
  }

  guardar() {
    this.Asignaciones.emit(this.seleccion);
    this.ventana.close(this.seleccion);
  }

  cerrar(): void {
    this.ventana.close();
  }

  DetallesSalida(seleccion: boolean, row: Salida) {
    if (seleccion) {
      row.almacenDescripcion = this.f['almacenDescripcion'].value;
      row.almacenId = this.f['almacenId'].value;
      row.ubicacionDescripcion = this.f['ubicacionDescripcion'].value;
      this.seleccion.push(row);
    } else {
      let indice = this.seleccion.indexOf(row, 0);
      if (indice > -1) {
        this.seleccion.splice(indice);
      }
    }
  }

  tabla(): void {
    let id = this.f["almacenId"].value;
    this.ctx.Ventas.obtenerSalidasPendientes(id).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;

      if (this.recibirDato.length > 0) {
        this.fuenteDatos.data.forEach(i => {
          this.recibirDato.forEach(j => {
            if (i.folio == j.folio) {
              this.checkSelect.select(i);
              this.seleccion.push(j);
              console.log(this.seleccion)
            }
          })

        })
      }
      //this.fuenteDatos.paginator = this.paginador;
      //this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        console.log(e);
        this.translate
          .get('message.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  MostrarDetalles(id: number) {
    this.ventanaDetalle.open(ComprobanteComponent, {
      data: id,
      panelClass: 'form-container',
    });
  }

  isAllSelected() {
    const numSelected = this.checkSelect.selected.length;
    const numRows = this.fuenteDatos.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.checkSelect.clear()
      : this.fuenteDatos.data.forEach((row) => {

        this.DetallesSalida(true, row);
        this.checkSelect.select(row)

      });
  }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Periodos } from '../models/periodos';
import { AsignacionDiaInhabil } from '../models/asignacion-dia-inhabil';
import { AsignacionPeriodoSemana } from '../models/asignacion-periodo-semana';
import { Respuesta } from '../models/respuesta';
import { PeriodosSemanales } from '../models/periodosSemanales';
import { TurnosConteos } from '../models/turnos-conteos';


export class RepositorioControlPeriodos extends Repositorio<
  Periodos
> {
  constructor(http: HttpClient) {
    super(http, 'ControlPeriodos');
  }


  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  
  obtenerPeriodosAlmacen(almacenId: number): Observable<Periodos[]> {
    const ruta = `${this.ruta}/ObtenerPeriodosAlmacen/${almacenId}`;
    return this.http.get<Periodos[]>(ruta);
  }

  ObtenerPeriodosSemanalesAlmacen(almacenId: number): Observable<PeriodosSemanales[]> {
    const ruta = `${this.ruta}/ObtenerPeriodosSemanalesAlmacen/${almacenId}`;
    return this.http.get<PeriodosSemanales[]>(ruta);
  }

  ObtenerTurnosConteosAlmacen(almacenId: number): Observable<TurnosConteos[]> {
    const ruta = `${this.ruta}/ObtenerTurnosConteosAlmacen/${almacenId}`;
    return this.http.get<TurnosConteos[]>(ruta);
  }

  // ObtenerDiasInhabilesAlmacen(almacenId: number): Observable<TurnosConteos[]> {
  //   const ruta = `${this.ruta}/ObtenerDiasInhabilesAlmacen/${almacenId}`;
  //   return this.http.get<TurnosConteos[]>(ruta);
  // }

  ObtenerComprobantePeriodoDiaInhabil(id: number): Observable<Periodos> {
    const ruta = `${this.ruta}/ObtenerComprobantePeriodoDiaInhabil/${id}`;
    return this.http.get<Periodos>(ruta);
  }

  ObtenerComprobantePeriodoSemanal(id: number): Observable<Periodos> {
    const ruta = `${this.ruta}/ObtenerComprobantePeriodoSemanal/${id}`;
    return this.http.get<Periodos>(ruta);
  }

  guardarRangoExcel(almacenId:number, asignaciones: AsignacionDiaInhabil[]): Observable<string[]>{
    const ruta = `${this.ruta}/GuardarPorRango/${almacenId}`;
    return this.http.post<string[]>(ruta, asignaciones);
  }

  eliminarDiasInhabiles(almacenId:number): Observable<Respuesta<boolean>>{
    const ruta = `${this.ruta}/EliminarDiasInhabiles/${almacenId}`;
    return this.http.get<Respuesta<boolean>>(ruta);

  }

  guardarRangoExcelPeriodoSemanal(almacenId:number, asignaciones: AsignacionPeriodoSemana[]): Observable<string[]>{
    const ruta = `${this.ruta}/GuardarPorRangoSemanal/${almacenId}`;
    return this.http.post<string[]>(ruta, asignaciones);
  }
} 

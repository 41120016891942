import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pantallas } from '../models/pantallas';
import { AsignacionRolPantalla } from '../models/asignacion-rol-pantalla';
import { map } from 'rxjs/operators';

export class RepositorioPantallas extends Repositorio<Pantallas> {
  constructor(http: HttpClient) {
    super(http, 'Pantallas');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerPantallasRoles(rolId: number): Observable<Pantallas[]> {
    const ruta = `${this.ruta}/ObtenerPantallasRoles/${rolId}`;
    return this.http.get<Pantallas[]>(ruta);
  }

  asignar(
    asignacion: AsignacionRolPantalla
  ): Observable<AsignacionRolPantalla> {
    const ruta = `${this.ruta}/Asignar`;
    return this.http
      .post(ruta, asignacion)
      .pipe(map((asig: AsignacionRolPantalla) => asig));
  }

  desasignar(asignacion: AsignacionRolPantalla): Observable<void> {
    const ruta = `${this.ruta}/Desasignar/${asignacion.tipoUsuarioId}/${asignacion.pantallaId}`;
    return this.http.delete(ruta).pipe(map(() => null));
  }

  obtenerPantallasActivos(): Observable<Pantallas[]> {
    const ruta = `${this.ruta}/ObtenerPantallasActivos`;
    return this.http.get<Pantallas[]>(ruta);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {  Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TipoMenu } from 'src/app/shared/models/tipo-menu';
import {TranslateService} from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaTipoMenuComponent implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: TipoMenu;
  actualizar: boolean;
  chkActualiza: boolean;


  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaTipoMenuComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.tiposMenu.obtener(id).toPromise().then(resultado => {
        this.modelo = resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get("message.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
      this.actualizar = false;
      this.chkActualiza = true;
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new TipoMenu();
    }
  }
  page="";
  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [null, Validators.nullValidator],
        clave: ['', Validators.required],
        descripcion: ['', Validators.required],
        activo: [true, Validators.nullValidator],
      },
    );
    this.translate.get("title.typeMenu").subscribe((res:string)=>{
      this.page=res;
    })
  }

  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {

    if (this.forma.valid) {

      const model = this.forma.value as TipoMenu;

      if (this.idSeleccionado === 0) {
        this.ctx.tiposMenu.guardar(model).toPromise().then(() => {
          this.translate.get("messages.guardar",{velue:this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.translate.get("messages.guardarExiste",{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          } else if (e.error === 'ErrorGuardar') {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.tiposMenu.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get("messages.actualizar",{value:this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.translate.get("messages.guardarExiste",{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }
}

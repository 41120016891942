import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ArchivosContactoSoporte } from 'src/app/shared/models/archivos-contacto-soporte';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ContactoSoporte } from 'src/app/shared/models/contacto-soporte';
import {TranslateService} from '@ngx-translate/core';



@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormContactoSoporteComponent implements OnInit {
  forma: FormGroup;
  get f() { return this.forma.controls; }

  modelArchivos: ArchivosContactoSoporte[] = [];
  model: ContactoSoporte;

  fechaActual: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private seguridad: AutenticacionService,
    private translate:TranslateService
  ) {
    let fecha = new Date();
    this.fechaActual =  fecha.toISOString();
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        usuarioId: [this.seguridad.credencial.usuarioId, Validators.required],
        usuario: [this.seguridad.credencial.nombreCompleto, Validators.required],
        tema: ["", Validators.required],
        problema: ["", Validators.required],
      },
    );
  }

  archivoSeleccionado(event) {
    if (event.target.files && event.target.files[0]) {
      const archivo = new ArchivosContactoSoporte;
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          archivo.archivoBase64 = event.target.result;
        }
        reader.readAsDataURL(event.target.files[i]);
        archivo.nombre = event.target.files[i].name;
        this.modelArchivos.push(archivo);
        console.dir(archivo);
      }
    }
  }

  archivoEliminar(indice: number) {
    this.modelArchivos.splice(indice, 1);
  }

  public enviar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as ContactoSoporte;
      model.archivosContacto = this.modelArchivos;
      console.log(model);
      this.ctx.contactoSoporte.guardar(model).toPromise().then((e) => {
        this.translate.get("exitosubir").toPromise().then(e=>{
          this.alerta.mostrarExito(e);

        })

      }).catch(e => {
        console.log(e);
        this.translate.get("errorsubir").toPromise().then(e=>{
        this.alerta.mostrarError(e);

        })
      });
    }
    else {
      this.translate.get("camposfaltantes").toPromise().then(e=>{
      this.alerta.mostrarAdvertencia(e);

      })
    }
  }
}


import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { DetalleSalida } from 'src/app/shared/models/detalleSalidas';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'selector-name',
  templateUrl: './comprobante.component.html',
  styleUrls: ['./comprobante.component.scss'],
})
export class ComprobanteComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private translate: TranslateService
  ) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.Ventas.ObtenerDetalles(id)
        .toPromise()
        .then((resultado) => {
          this.fuenteDatos = new MatTableDataSource(resultado);
          this.folio = resultado[0].folio;
          this.fechaRegistro = resultado[0].registro;
          this.refercia = resultado[0].referencia;
          resultado.forEach((e) => {
            this.totalDetalle += e.totalMxn;
            this.totalDetalleUSD += e.totalUSD;
          });
        })
        .catch((e) => {
          console.log(e);
          this.translate
            .get('message.errorInformacion')
            .subscribe((res: string) => {
              this.alerta.mostrarExito(res);
            });
        });
    }
  }

  fuenteDatos: MatTableDataSource<DetalleSalida> = new MatTableDataSource([]);
  columnasMostradas = [
    'descipcionProductoPrincipal',
    'descripcion',
    'cantidad',
    'precio',
    'precioUSD',
    'TotalMxn',
    'TotalUSD',
  ];
  idSeleccionado: number;
  detalleSalida: DetalleSalida[] = [];

  folio: string;
  fechaRegistro: Date;
  refercia: string;
  totalDetalle = 0;
  totalDetalleUSD = 0;

  ngOnInit() { }
}


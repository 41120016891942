import  {  FormGroup,  ValidationErrors,  ValidatorFn, Validator  }  from  '@angular/forms';

export const ValidarReorden: ValidatorFn=(control:FormGroup): ValidationErrors | null=>{
    const maximo=control.get("stockMaximo");
    const minimo =control.get("stockMinimo");
    const reorden =control.get("reorden");
    return (reorden.value > maximo.value || reorden.value < minimo.value) ? {'reordenInvalid':true} : null;
}


export const minimoMaximo:ValidatorFn=(control: FormGroup):ValidationErrors|null=> {

  const maximo = control.get("stockMaximo");
  const minimo = control.get("stockMinimo");

  return (minimo > maximo) ? {'InvalidoMaximo':true}:null;
}

export const maximoMinimo:ValidatorFn=(control:FormGroup):ValidationErrors | null=>{
  const maximo = control.get("stockMaximo");
  const minimo = control.get("stockMinimo");
  return (maximo<minimo)?{'InvalidoMinimo':true}:null;
}

import { State, Action, StateContext } from '@ngxs/store';
import { SetSelectedTheme } from './theme.actions';
import { Theme } from './type';
import { OverlayContainer } from '@angular/cdk/overlay';

const defaultTheme: Theme = 'light-theme';

@State<Theme>({
  name: 'theme',
  defaults: defaultTheme
})
export class ThemeState {
  constructor(private overlayContainer: OverlayContainer) {
    this.assignOverlayClassList(defaultTheme);
  }

  assignOverlayClassList(newTheme: Theme, actualTheme?: Theme) {
    this.overlayContainer.getContainerElement().classList.remove(actualTheme);
    this.overlayContainer.getContainerElement().classList.add(newTheme);
  }

  @Action(SetSelectedTheme)
  public setSelected(
    { setState, getState }: StateContext<Theme>,
    { theme }: SetSelectedTheme
  ) {
    const actualTheme: Theme = getState();
    this.assignOverlayClassList(theme, actualTheme);
    setState(theme);
  }
}

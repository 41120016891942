import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DetallePaleta } from '../models/detalle-paleta';
import { DocumentosExternosExcel } from '../models/documentos-externos';

export class RepositorioDocumentosExternos extends Repositorio<any> {
  constructor(http: HttpClient) {
    super(http, 'DocumentosExternos');
  }

  leerXML(datos: FormData): Observable<DetallePaleta[]> {
    let ruta = `${this.ruta}/LeerXML`;
    return this.http
      .post(ruta, datos)
      .pipe(map((detalle: DetallePaleta[]) => detalle));
  }

  guardarEntradaDetalle(datos: DetallePaleta[]): Observable<boolean> {
    let ruta = `${this.ruta}/GuardarEntradaDetalle`;
    return this.http
      .post(ruta, datos)
      .pipe(map((guardado: boolean) => guardado));
  }

  guardarPedidoDetalle(datos: DetallePaleta[]): Observable<boolean> {
    let ruta = `${this.ruta}/GuardarPedidoDetalle`;
    return this.http
      .post(ruta, datos)
      .pipe(map((guardado: boolean) => guardado));
  }

  verificarPedidoDetalle(
    datos: DetallePaleta[]
  ): Observable<DocumentosExternosExcel> {
    let ruta = `${this.ruta}/VerificarPedidoDetalle`;
    return this.http
      .post(ruta, datos)
      .pipe(map((guardado: DocumentosExternosExcel) => guardado));
  }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../models/usuario';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioUsuario extends Repositorio<Usuario> {
  constructor(http: HttpClient) {
    super(http, 'Usuario');
  }

  obtenerUsuario(id: number): Observable<Usuario> {
    const ruta = `${this.ruta}/ObtenerUsuario/${id}`;
    return this.http.get<Usuario>(ruta);
  }

  actualizarUsuario(usuario: Usuario): Observable<Usuario> {
    const ruta = `${this.ruta}/ActualizarUsuario`;
    return this.http.post<Usuario>(ruta, usuario);
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }



  enviarNotificaciones(id: number): Observable<void> {
    const ruta = `${this.ruta}/EnviarNotificaciones/${id}`;
    return this.http.put<void>(ruta, null);
  }

  noEnviarNotificaciones(id: number): Observable<void> {
    const ruta = `${this.ruta}/NoEnviarNotificaciones/${id}`;
    return this.http.put<void>(ruta, null);
  }

  eliminarUsuario(id: number): Observable<void> {
    const ruta = `${this.ruta}/EliminarUsuario/${id}`;
    return this.http.delete<void>(ruta);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerUsuarioPerfilAlmacen(almacenId: number): Observable<Usuario[]> {
    const ruta = `${this.ruta}/ObtenerUsuariosPerfilAlmacen/${almacenId}`;
    return this.http.get<Usuario[]>(ruta);
  }

  obtenerUsuarioActivos(): Observable<Usuario[]> {
    const ruta = `${this.ruta}/ObtenerUsuarioActivos`;
    return this.http.get<Usuario[]>(ruta);
  }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { GrupoProductos } from 'src/app/shared/models/Grupo-Producto';
import {TranslateService} from '@ngx-translate/core'
import { Almacen } from 'src/app/shared/models/Almacen';
import { Maquinarias } from 'src/app/shared/models/maquinarias';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';


@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss',],
})
export class FormaMaquinariaComponent implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  model: Maquinarias;
  textoBuscar: string = "";
  almacenes: Almacen[] = [];
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaMaquinariaComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta, private translate:TranslateService, public autenticacion: AutenticacionService) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.Maquinarias.obtener(id).toPromise().then(resultado => {
        this.model = resultado;
        Object.assign(this.forma.value, this.model);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get("errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.model = new Maquinarias();
    }
  }
  pages="";
  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        codigo: ['', Validators.required],
        descripcion: ['', Validators.required],
        activo: [true, Validators.nullValidator],
        almacenId: this.autenticacion.credencial.almacenId,
      },
    );
    this.translate.get("Maquinaria").subscribe((res:string)=>{
      this.pages=res;
    })
    this.cargarAlmacenes();
  }


  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as Maquinarias;
      console.log(model);
      if (this.idSeleccionado === 0) {
        this.ctx.Maquinarias.guardar(model).toPromise().then(() => {
          this.translate.get("messages.guardar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          if (e === 'Unprocessable Entity') {
            this.translate.get("messages.guardarExiste",{value:this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else if (e.error === 'ErrorGuardar') {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.Maquinarias.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get("messages.actualizar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get("message.guardarExiste",{value: this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          } else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  almacenSeleccionado(modelo: Almacen){
    this.f["almacenDescripcion"].setValue(modelo.codigo + " " + modelo.descripcion);
    this.f["almacenId"].setValue(modelo.id);
  }

  async cargarAlmacenes() {
    this.almacenes = await this.ctx.Almacen.obtenerTodos().toPromise();
  }


  buscar(event) {
    this.textoBuscar = event.target.value;
  }

  cerrar(): void {
    this.ventana.close();
  }

  comparo(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o2 === o2;
  }
}

import { DetalleCierres } from "./detalle-cierres";

export class Cierre{
    id: number;
    tipoConteoId: number;
    fechaRegistro = new Date();
    folio: string;
    fechaDocumento: Date;
    esBorrador: boolean;
    usuarioId: number;
    usuarioNombreCompleto: string;
    almacenId: number;
    almacenCodigo: string;
    referencia = '';
    comentarios = '';
    estaSincronizado = false;
    fechaSincronizacion?: Date;
    ubicacionDescripcion: string;
    minaDescripcion: string;
    minaId: number;
    almacenDescripcion: string;
    detalles: DetalleCierres[] = [];

}
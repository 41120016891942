import { Injectable, Inject } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Productos } from 'src/app/shared/models/Productos';



@Injectable({
    providedIn: 'root',
})
export class ProductosAlmacen {

    
    // private _productos = new BehaviorSubject<Productos[]>([]);

    // public get productos(): Observable<Productos[]>
    // {
    //     return this._productos.asObservable();
    // }

    // constructor() {
    // }

    // public asignarProductos(productos: Productos[]){
    //     try {
    //         this._productos.next(productos);
    //     } catch (error) {
    //         if(this._productos.isStopped){
    //             this._productos =  new BehaviorSubject<Productos[]>([]);
    //         }
    //         console.warn(error);
    //     }
    // }
    /**
     *
     */

    public almacen = new BehaviorSubject<boolean>(false);
    constructor() {
        
    }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ventas } from '../models/Ventas';
import { Salida } from '../models/Salida';
import { almacenesPendientes } from '../models/almacenesPendientes';
import { DetalleSalida } from '../models/detalleSalidas';
import { VentasProductos } from '../models/VentasProductos';

export class RepositorioVentas extends Repositorio<Ventas> {
  constructor(http: HttpClient) {
    super(http, 'Ventas');
  }

  generarFolio(): Observable<string> {
    const ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  obtenerVentas(id: number): Observable<VentasProductos[]> {
    const ruta = `${this.ruta}/ObtenerDetallesVenta/${id}`;
    return this.http.get<VentasProductos[]>(ruta);
  }

  actualizarVentas(usuario: Ventas): Observable<Ventas> {
    const ruta = `${this.ruta}/ActualizarUsuario`;
    return this.http.post<Ventas>(ruta, usuario);
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  eliminarVenta(id: number): Observable<void> {
    const ruta = `${this.ruta}/EliminarUsuario/${id}`;
    return this.http.delete<void>(ruta);
  }

  obtenerExcel(id: number): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, id, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerSalidasPendientes(id: number): Observable<Salida[]> {
    const ruta = `${this.ruta}/SalidasPendientes/${id}`;
    return this.http.get<Salida[]>(ruta);
  }

  ObtenerAlmacenesPendientes(): Observable<almacenesPendientes[]> {
    const ruta = `${this.ruta}/Almacenes`;
    return this.http.get<almacenesPendientes[]>(ruta);
  }

  ObtenerDetalles(id: number): Observable<DetalleSalida[]> {
    const ruta = `${this.ruta}/ObtenerDetalleSalida/${id}`;
    return this.http.get<DetalleSalida[]>(ruta);
  }

  AceptarVenta(modelo: Ventas): Observable<string> {
    const ruta = `${this.ruta}/Guardar`;
    return this.http.post<string>(ruta, modelo);
  }
}

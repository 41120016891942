export class AsignacionEmpresaProducto {
  asignacionProductoEmpresaId: number;
  productoId: number;
  empresaMineraId: number;
  empresaSku: string;
  empresaMaterial: string;
  maximoCritico: number;
  idealCritico: number;
  otro: string;
}

import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { Cierre } from '../../models/cierres';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-pedido',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteCierreComponent implements OnInit {
  columnas = [
    'productoCodigo',
    'producto',
    'movimientoDescripcion',
    'inventarioAntes',
    'cantidad',
    'inventarioCantidad',
  ];
  cierre: Cierre = new Cierre();
  fechaLocal: Date = new Date();
  cantidadTotal: number = 0;
  cantidadLineas: number = 0;
  folio: string = "";
  fechaRegistro: string = "";
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ComprobanteCierreComponent>,
    @Inject(MAT_DIALOG_DATA)
    public cie: Cierre,
    private exportAsService: ExportAsService,
  ) {


    this.cierre = cie;

    this.cantidadTotal = this.cierre.detalles.reduce((suma, detalle) => suma + detalle.cantidad, 0);
    this.cantidadLineas = this.cierre.detalles.length;
  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Cierre ' + this.cierre.folio).subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Cierre ' + this.cierre.folio).subscribe();
  }
  ngOnInit() {}

  regresar() {
    this.dialogRef.close();
  }
}

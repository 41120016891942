import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Reporteador } from 'src/app/shared/models/reporteador';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Usuario } from 'src/app/shared/models/usuario';
import { Productos } from 'src/app/shared/models/Productos';
import { Almacen } from 'src/app/shared/models/Almacen';
import { Filtro } from 'src/app/shared/models/Filtro';
import { startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ExpandOperator } from 'rxjs/internal/operators/expand';
import { GrupoProductos } from 'src/app/shared/models/Grupo-Producto';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { LoadingService } from 'src/app/loading/loading.service';
import * as _ from 'underscore';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'Minas',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class CatalogoReporteador implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  sub: Subscription;

  fuenteDatos: MatTableDataSource<Reporteador> = new MatTableDataSource([]);
  columnasPorMostrar = [
    ['documento', 'Tipo de Documento', true], // 0
    ['fechaDocumento', 'Fecha documento', true], // 1
    ['folio', 'Folío de documento', true], // 2
    ['mina', 'Mina', true], // 3
    ['almacen', 'Almacén', true], // 4
    ['usuario', 'Usuario de almacén', true], // 5
    ['empleado', 'Empleado de mina', true], // 6
    ['codigoProducto', 'Código producto', true], // 7
    ['codigoProductoReemplazo', 'Producto de reemplazo', true], // 8
    ['producto', 'Producto', true], // 9
    ['cantidadEntradas', 'Entradas (Cant.)', true], // 10
    ['cantidadSalidas', 'Salidas (Cant.)', true], // 11
    ['cantidadExistencias', 'Stock Balance', true], // 12
    ['cantidadPedido', 'Pedidos (Cant.)', true], // 13
    ['cantidadAjustes', 'Ajustes (Cant.)', true], // 14
    ['cantidadRequisiciones', 'DUE', true], // 15
    ['cantidadEffective', 'Effective', true], // 16
    ['stockMinimo', 'Stock Mínimo', true], // 17
    ['stockMaximo', 'Stock Máximo', true], // 18
    ['puntoReorden', 'Punto de reorden', true], // 19
    ['estatusRequisiciones', 'Estatus de requisiciones', true], // 20
    ['cantidadDiferencia', 'Diferencia', true], // 21
    ['cantidad', 'Cantidad', true], // 22
    ['importeMesActualMXN', 'Importe mes actual mxn', true], // 23
    ['importeMesAnteriorMXN', 'Importe mes anterior mxn', true], // 24
    ['importeMesActualUSD', 'Importe mes actual USD', true], // 25
    ['importeMesAnteriorUSD', 'Importe mes anterior usd', true], // 26
    ['desviacion', 'Desviación', true], // 25
    ['desviacionUSD', 'Desviación usd', true], // 28
    ['porcentajeDesviacion', 'Porcentaje Desviación', true], // 29
    ['porcentajeDesviacionUSD', 'Porcentaje Desviación USD', true], // 30
    ['importeMxn', 'Importe mxn', true], // 31
    ['importeUSD', 'Importe USD', true], // 32
    ['folioRequisicion', 'Folío de Requisición', true], // 33
    ['ubicacion', 'Ubicación', true], // 34
    ['material', 'Material', true], // 35
    ['piezaPorEquipo', 'Maquinaria', true], // 36
    ['orden', 'Número de remisión', true], // 37
    ['taller', 'Taller', true], // 38
    ['tecnico', 'Técnico', true], // 39





  ];

  ocultarCamposAjustes: number[] = [
    2,
    8,
    10,
    11,
    13,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,

  ];
  ocultarCamposAjustesGlobal: number[] = [
    2,
    8,
    10,
    11,
    13,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,

  ];
  ocultarCamposSalidas: number[] = [

    10,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,

  ];
  ocultarCamposSalidasGlobal: number[] = [
    1,
    2,
    6,
    8,
    10,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    36,
    37,


  ];
  ocultarCamposEntradas: number[] = [
    6,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    36,
    38,
    39,

  ];
  ocultarCamposEntradasGlobal: number[] = [
    1,
    2,
    6,
    8,
    11,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    36,
    37,
    38,
    39,

  ];
  ocultarCamposExistencia: number[] = [
    0,
    1,
    2,
    5,
    6,
    8,
    10,
    11,
    13,
    14,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    36,
    37,
    38,
    39,

  ];

  ocultarCamposKardex: number[] = [
    3,
    6,
    8,
    12,
    13,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    36,
    37,
    38,
    39,

  ];
  ocultarCamposKardexGlobal: number[] = [
    0,
    1,
    2,
    3,
    5,
    6,
    8,
    13,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    36,
    37,
    38,
    39,

  ];

  ocultarCamposEstatusRequis: number[] = [
    0,
    1,
    2,
    3,
    5,
    6,
    11,
    12,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    34,
    35,
    36,
    37,
    38,
    39,
  ];
  ocultarCamposVentasAlmacen: number[] = [
    0,
    3,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
  ];
  ocultarCamposProductosVenta: number[] = [
    0,
    3,
    5,
    6,
    7,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
  ];
  ocultarCampoMesVsMes: number[] = [
    0,
    1,
    2,
    3,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
  ];
  ocultarCampoMesVsMesProducto: number[] = [
    0,
    1,
    2,
    3,
    5,
    6,
    7,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
  ];

  mostrarUsuarios = true;
  get columnasSeleccionadas() {
    return this.columnasPorMostrar.filter((c) => c[2]).map((c) => c[0]);
  }

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 10;
    }
    return 100;
  }
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService,
    private cargando: LoadingService,

  ) {


  }

  forma: FormGroup;
  get f() {
    return this.forma.controls;
  }
  modelo: Filtro;
  global = false;
  productoFiltrados: Observable<Productos[]>;
  productoSinFiltrar: Productos[];
  productos: Productos[];
  productofiltro = new FormControl();

  almacenFiltrados: Observable<Almacen[]>;
  almacenSinFiltrar: Almacen[];
  almacenfiltro = new FormControl();

  gruposProductoFiltrados: Observable<GrupoProductos[]>;
  gruposProductoSinFiltrar: GrupoProductos[];
  grupoProductoFiltro = new FormControl();

  usuarioFiltrados: Observable<Usuario[]>;
  usuarioSinFiltrar: Usuario[];
  usuariofiltro = new FormControl();
  nombreArchivo = '';
  ocultarOrdenReferencia: boolean;
  opcionAlmacen = [];

  TipoDeFiltro = [
    [1, 'Ajustes'],
    [2, 'Entradas'],
    [3, 'Salidas'],
    [4, 'Existencias'],
    [5, 'Kardex'],
    [6, 'Estatus de requisiciones'],
    [7, 'Ventas'],
    [8, 'Ventas Mes contra Mes'],
  ];

  tipoDeFiltroIngle = [
    [1, 'Setting'],
    [2, 'In'],
    [3, 'Out'],
    [4, 'Stock'],
    [5, 'Kardex'],
    [6, 'Requisitions status'],
    [7, 'Sales'],
    [8, 'Month-to-Month Sales']
  ];

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  resultadodefiltro = this.TipoDeFiltro;
  mostrarGlobal = true;
  ocultarfecha = true;
  mostrarAfecha = true;
  dateAfecha = new FormControl(new Date());

  ngOnInit() {
    if (window.localStorage.getItem('language') === 'en') {
      this.resultadodefiltro = this.tipoDeFiltroIngle;
    }
    this.forma = this.formBuilder.group({
      producto: [''],
      almacen: [''],
      usuario: [''],
      grupoProducto: [''],
      productoId: [0],
      almacenId: [0],
      usuarioId: [0],
      grupoProductoId: [0],
      fechaInicial: [''],
      fechaFinal: [''],
      tipoBusqueda: ['', Validators.required],
      tipoBusquedaId: [],
      afecha: [new Date()],
      ordenReferencia: ['']
    });
    this.cargarGrupoProducto();
    this.cargarProductos();

    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.f['almacen'].setValue(this.autenticacion.credencial.almacenDescripcion);
        this.f['almacenId'].setValue(this.autenticacion.credencial.almacenId);
        this.cargarUsuarios();
        this.cargarGrupoProducto();
        this.cargarProductos();
      }
    );
  }

  descargarExcel(): void {
    if (this.forma.valid) {
      const model = this.forma.value as Filtro;
      const fechaActual = new Date();
      this.nombreArchivo =
        'Reporte de general hasta el día: ' + fechaActual.toLocaleString() + '.xls';
      this.cargando.show(this.nombreArchivo);
      this.ctx.reporte
        .obtenerExcel(model)
        .toPromise()
        .then((respuesta) => {
          this.llamarExcel(respuesta, this.nombreArchivo);
          this.cargando.hide();
        })
        .catch((e) => { this.cargando.hide(); });
    }
  }

  llamarExcel(respuesta, nombreArchivo) {
    const blob = new Blob([respuesta], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
      url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  Buscar() {
    if (this.forma.valid) {
      const model = this.forma.value as Filtro;
      model.global = this.global;
      model.afecha = this.dateAfecha.value;
      // console.log(this.dateAfecha.value)
      const d1 = new Date(model.fechaInicial);
      const d2 = new Date(model.fechaFinal);
      console.log(model);
      if (d1 > d2) {
        this.alerta.mostrarError('El rango de fechas es incorrecto');
        return;
      }

      this.ctx.reporte
        .obtenerReporte(model)
        .toPromise()
        .then((resultado) => {
          this.fuenteDatos = new MatTableDataSource(resultado);
          this.fuenteDatos.paginator = this.paginador;
          this.fuenteDatos.sort = this.ordenador;
        })
        .catch((e) => {
          this.translate
            .get('messages.errorInformacion')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
    }
  }

  cargarProductos() {
    this.ctx.Productos.obtenerProductosPorAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.productos = resultado;
        this.productoSinFiltrar = resultado;
        this.productoFiltrados = this.productofiltro.valueChanges.pipe(
          startWith<string | Productos>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarProducto(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarProducto(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.productoSinFiltrar.filter(
      (t) =>
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  productoSeleccionado(modelo: Productos) {
    if (modelo.id === undefined) {
      this.f['producto'].setValue('Todos');
      this.f['productoId'].setValue(0);
    } else {
      this.f['producto'].setValue(modelo.descripcion + ' ' + modelo.descripcion);
      this.f['productoId'].setValue(modelo.id);
    }
  }

  cargarAlmacenes() {
    this.ctx.Almacen.obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.almacenSinFiltrar = resultado;
        this.almacenFiltrados = this.almacenfiltro.valueChanges.pipe(
          startWith<string | Almacen>(''),
          map((t) =>
            typeof t === 'string'
              ? t
              : t == null
                ? ''
                : '' + t.id + '' + t.descripcion
          ),
          map((t) => this.filtrarAlmacen(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarAlmacen(modelo: string): Almacen[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.almacenSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  almacenSeleccionado(modelo: Almacen) {
    if (modelo.id === undefined) {
      this.f['almacen'].setValue('Todos');
      this.f['almacenId'].setValue(0);
    } else {
      this.f['almacen'].setValue(modelo.descripcion);
      this.f['almacenId'].setValue(modelo.id);
    }
  }

  limpiarFiltroProducto(): void {
    this.f['producto'].setValue('');
    this.f['productoId'].setValue(0);
    this.cargarProductos();
  }

  limpiarFiltroUsuario(): void {
    this.f['usuario'].setValue('');
    this.f['usuarioId'].setValue(0);
    this.cargarUsuarios();
  }

  limpiarFiltroGrupoProducto(): void {
    this.f['grupoProducto'].setValue('');
    this.f['grupoProductoId'].setValue(0);
    this.cargarGrupoProducto();
  }

  limpiarFiltroAlmacen(): void {
    this.f['almacen'].setValue('');
    this.f['almacenId'].setValue(0);
    this.cargarAlmacenes();
  }

  cargarUsuarios() {
    this.ctx.usuario
      .obtenerUsuarioPerfilAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.usuarioSinFiltrar = resultado;
        this.usuarioFiltrados = this.usuariofiltro.valueChanges.pipe(
          startWith<string | Usuario>(''),
          map((t) =>
            typeof t === 'string'
              ? t
              : t == null
                ? ''
                : t.nombre + ' ' + t.apellidoPaterno + ' ' + t.apellidoMaterno
          ),
          map((t) => this.filtrarUsuario(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  cargarGrupoProducto() {
    this.ctx.grupoProducto
      .obtenerGruposPorAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.gruposProductoSinFiltrar = resultado;
        this.gruposProductoFiltrados = this.grupoProductoFiltro.valueChanges.pipe(
          startWith<string | GrupoProductos>(''),
          map((t) => (typeof t === 'string' ? t : t == null ? '' : t.codigo)),
          map((t) => this.filtrarGrupoProducto(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarUsuario(modelo: string): Usuario[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.usuarioSinFiltrar.filter(
      (t) => t.nombre.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  private filtrarGrupoProducto(modelo: string): GrupoProductos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.gruposProductoSinFiltrar.filter(
      (t) =>
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  grupoProductoSeleccionado(modelo: GrupoProductos) {
    if (modelo.id === undefined) {
      this.f['grupoProducto'].setValue('Todos');
      this.f['grupoProductoId'].setValue(0);
      this.productoSinFiltrar = this.productos;
    } else {
      this.f['grupoProducto'].setValue(modelo.descripcion);
      this.f['grupoProductoId'].setValue(modelo.id);
      this.productoSinFiltrar = _.filter(this.productos, function (producto: Productos) {
        return producto.grupoProductoId === modelo.id;
      });
    }
    this.productofiltro.setValue('');
  }

  usuarioSeleccionado(modelo: Usuario) {
    if (modelo.id === undefined) {
      this.f['usuario'].setValue('Todos');
      this.f['usuarioId'].setValue(0);
    } else {
      this.f['usuario'].setValue(
        modelo.nombre +
        ' ' +
        modelo.apellidoPaterno +
        ' ' +
        modelo.apellidoMaterno
      );
      this.f['usuarioId'].setValue(modelo.id);
    }
  }

  tipoSeleccionado(event) {
    this.mostrarUsuarios = true;
    // cuando se le da clic al boton buscar toma los valores de esta variable
    this.opcionAlmacen = [event[0], event[1]];
    this.f['tipoBusqueda'].setValue(this.opcionAlmacen[1]);
    this.f['tipoBusquedaId'].setValue(this.opcionAlmacen[0]);
    // oculta y muestra las opciones de acuerdo al tipo de filtro
    this.seleccionarTodasLasColumnas();
    this.mostrarGlobal = (event[0] > 5 || event[0] === 4) ? false : true;
    this.ocultarfecha = (event[0] === 4) ? false : true;
    this.mostrarAfecha = (event[0] === 4) ? true : false;
    this.ocultarOrdenReferencia = (event[0] === 2) || (event[0] === 3) ? true : false;


    if (event[0] === 4) {
      this.ocultarCamposExistencia.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 5 && this.global) {
      this.ocultarCamposKardexGlobal.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 5) {

      this.ocultarCamposKardex.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 1 && this.global) {
      this.ocultarCamposAjustesGlobal.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 1) {
      this.ocultarCamposAjustes.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 3 && this.global === true) {
      this.ocultarCamposSalidasGlobal.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 3) {
      this.ocultarCamposSalidas.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 2 && this.global) {
      this.ocultarCamposEntradasGlobal.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 2) {
      this.ocultarCamposEntradas.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (event[0] === 6) {
      this.ocultarCamposEstatusRequis.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
    } else if (
      event[0] === 7 &&
      (this.f['producto'].value === '' || this.f['producto'].value === 'Todos')
    ) {
      this.ocultarCamposVentasAlmacen.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
      this.mostrarUsuarios = false;
    } else if (event[0] === 7 && this.f['producto'].value !== '') {
      this.ocultarCamposProductosVenta.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
      this.mostrarUsuarios = false;
    } else if (
      event[0] === 8 &&
      this.f['producto'].value === ''
    ) {
      this.ocultarCampoMesVsMes.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
      this.mostrarUsuarios = false;
    } else if (
      event[0] === 8 &&
      this.f['producto'].value !== ''
    ) {
      this.ocultarCampoMesVsMesProducto.forEach((numero) => {
        this.seleccionarColumna(numero);
      });
      this.mostrarUsuarios = false;
    } else {
      this.seleccionarTodasLasColumnas();
    }
    this.Buscar();
  }

  seleccionarColumna(indice: number) {
    const columna = this.columnasPorMostrar[indice];
    columna[2] = !columna[2];
  }

  seleccionarTodasLasColumnas() {
    this.columnasPorMostrar.forEach((c) => (c[2] = true));
  }

  limpiarFiltroTipoReporte() {
    this.f['tipoBusqueda'].setValue('');
    this.f['tipoBusqueda'].setValue('');

    this.f['tipoBusqueda'].setValue('');
    this.seleccionarTodasLasColumnas();
  }

  limpiarFiltro() {
    this.f['producto'].setValue('');
    this.f['almacen'].setValue('');
    this.f['usuario'].setValue('');
    this.f['grupoProducto'].setValue('');
    this.f['grupoProductoId'].setValue(0);
    this.f['productoId'].setValue(0);
    this.f['almacenId'].setValue(0);
    this.f['usuarioId'].setValue(0);
    this.f['fechaInicial'].setValue('');
    this.f['fechaFinal'].setValue('');
    this.f['tipoBusqueda'].setValue('');

    this.cargarProductos();
    this.cargarAlmacenes();
    this.cargarUsuarios();
    this.cargarGrupoProducto();

    this.forma.markAsUntouched();
  }

  cierreDiarioDeAlmacenes() {
    this.cargando.show('Generando cierre diario de almacenes... Modo manual');
    this.ctx.cierreManual.cierreDiarioDeAlmacenes()
      .toPromise()
      .then((resultado) => {
        this.alerta.mostrarExito('Cierre diario generado con exito');
        console.log('Cerrado');
        this.cargando.hide();
      })
      .catch((e) => {
        this.cargando.hide();
        // this.alerta.mostrarError('Error interno de cierre diario');
        console.log(e);
      });
  }

  cierreMasivoDeAlmacenes() {
    this.cargando.show('Generando cierre masivo de todos los almacenes... Este proceso puede tardar varias horas');
    this.ctx.cierreManual.cierreMasivoDeAlmacenes()
      .toPromise()
      .then((resultado) => {
        this.alerta.mostrarExito('Cierre diario generado con exito');
        console.log('Cerrado');
        this.cargando.hide();
      })
      .catch((e) => {
        this.cargando.hide();
        // this.alerta.mostrarError('Error interno de cierre masivo');
        console.log(e);
      });
  }

  generacionKardex() {
    this.cargando.show('Generando Kardex');
    this.ctx.cierreManual.generacionKardex()
      .toPromise()
      .then((resultado) => {
        this.alerta.mostrarExito('Kardex generado con exito');
        this.cargando.hide();
        console.log('Cerrado');
      })
      .catch((e) => {
        this.cargando.hide();
        // this.alerta.mostrarError('Error interno de generación de Kardex');
        console.log(e);
      });
  }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { TurnosConteos } from '../models/turnos-conteos';

export class RepositorioTurnosConteos extends Repositorio<TurnosConteos> {
  constructor(http: HttpClient) {
    super(http, 'TurnosConteos');
  }

  ObtenerTurnosConteosAlmacen(almacenId: number): Observable<TurnosConteos[]> {
    const ruta = `${this.ruta}/ObtenerTurnosConteosAlmacen/${almacenId}`;
    return this.http.get<TurnosConteos[]>(ruta);
  }
}

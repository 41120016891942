import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
//import { FormaGrupoProductoComponent } from '../forma/forma.component';
import { LoadingService } from 'src/app/loading/loading.service';
import { GrupoProductos } from 'src/app/shared/models/Grupo-Producto';
import {TranslateService} from '@ngx-translate/core';
import { FormaMaquinariaComponent } from '../forma/forma.component';
import { Maquinarias } from 'src/app/shared/models/maquinarias';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';


@Component({
  selector: 'grupo-productos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoMaquinariasComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<Maquinarias> = new MatTableDataSource([]);
  columnasMostradas = ['codigo', 'descripcion', 'activo', 'opciones'];

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta, private spinner: LoadingService,private translate:TranslateService, public autenticacion: AutenticacionService) { }
  ngOnInit() {
    this.tabla();
  }

  eliminar(id: number): void {
    this.ctx.grupoProducto.eliminar(id).toPromise().then(() => {
      this.translate.get("eliminar").subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("eliminarError").subscribe((res:string)=>{
          this.alerta.mostrarExito(res);
        })
      });
  }


  tabla(): void {
    this.ctx.Maquinarias.obtenerMaquinariasPorAlmacen(this.autenticacion.credencial.almacenId)
    .toPromise()
    .then((resultado) => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }

  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "Reporte hasta el día: " + fechaActual.toLocaleString();
    this.ctx.tipoUsuario.obtenerExcel().toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {

    this.ctx.Maquinarias.activar(id).toPromise().then(() => {
      this.translate.get("activar").subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("activarError").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });

  }

  desactivar(id: number): void {
    this.ctx.Maquinarias.desactivar(id).toPromise().then(() => {
      this.translate.get("desactivar").subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("desactivarError").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }

  abrirForma(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaMaquinariaComponent, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

}

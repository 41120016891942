import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';

export class FormaDetallePedido extends FormGroup {

  constructor(public detalle: DetallePedido) {
    super(

      new FormBuilder().group({
        id:[0],
        productoId: ['', Validators.required],
        orden: ['', Validators.nullValidator],
        fechaSolicitud: [Hoy(), Validators.nullValidator],
        productoCodigo: ['', Validators.nullValidator],
        productoDescripcion: ['', Validators.nullValidator],
        unidadMedidaCodigo: ['', Validators.nullValidator],
        cantidadFaltante: [0, Validators.nullValidator],
        cantidadEntregada: [0, Validators.nullValidator],
        existencia: [0, Validators.nullValidator],
        cantidad: [1],
      }).controls
    );
    this.get('cantidad').setValidators([Validators.required, validadorCantidad.bind(this)]);
    this.patchValue(detalle);
    console.log(this.detalle);
  }
}

export function validadorCantidad(control: AbstractControl): ValidationErrors | null {
  let error = null;


  const forma = this as FormaDetallePedido;
  const cantidadEntregada = forma.get('cantidadEntregada').value;
  const cantidad = control.value;

  if(cantidad < cantidadEntregada && cantidad !== null){
    error = { cantidadInvalida: 'true' };
  }

  return error;
}


export function Hoy(){
  let fechaSeleccionada: Date = new Date();
  let fechaActual: Date = new Date();
  fechaSeleccionada.setHours(fechaActual.getHours());
  fechaSeleccionada.setMinutes(fechaActual.getMinutes());
  fechaSeleccionada.setSeconds(fechaActual.getSeconds());
  let fechaDoc = fechaSeleccionada;
  console.log(fechaDoc);
  return fechaDoc;
}

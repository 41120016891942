import { Injectable, Inject } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Credencial } from 'src/app/shared/models/credencial';



@Injectable({
    providedIn: 'root',
})
export class ServicioUsuarioAlmacen {

    public actualizarCredencial = new BehaviorSubject<Credencial>(null);
    constructor() {
    }


}

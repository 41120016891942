import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource,
  Sort,
} from '@angular/material';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Almacen } from 'src/app/shared/models/Almacen';
import { first, map, startWith, tap } from 'rxjs/operators';
import { Productos } from 'src/app/shared/models/Productos';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { ExcelDetalleRequisicion } from 'src/app/shared/models/excel-detalle-requisicion.';
import { LoadingService } from 'src/app/loading/loading.service';
import { MinaUbicaciones } from 'src/app/shared/models/mina-ubicacion';
import { Mina } from 'src/app/shared/models/Mina';
import { DetallePedidoSalida } from 'src/app/shared/models/detalle-pedido-salida';
import { PedidoSalida } from 'src/app/shared/models/pedido-salida';
import { timeStamp } from 'console';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';

@AutoUnsubscribe()
@Component({
  templateUrl: './doc-pedido.component.html',
  styleUrls: ['./doc-pedido.component.scss'],
})
export class FormaDocPedidoSalidaComponent implements OnInit {
  forma: FormGroup;
  pedidoId: number = 0;
  pedido: PedidoSalida = new PedidoSalida();
  detallePedidos: DetallePedidoSalida[] = [];
  detallePedidosCompletados: DetallePedidoSalida[] = [];
  arrayBuffer: any;
  file: File;
  detalle: DetallePedidoSalida;
  borrarListaDetalle: DetallePedidoSalida[] = [];
  get f() {
    return this.forma.controls;
  }

  busquedaAlmacenDestino = new FormControl();
  almacenesDestinoFiltrados = new BehaviorSubject<Almacen[]>([]);
  almacenesDestino: Almacen[] = [];
  almacenesReabastecimiento: Almacen[] = [];
  almacenesFiltradosReabastecimiento: Observable<Almacen[]>;
  almacenesSinFiltrarReabastecimiento: Almacen[];
  filtroAlmacenesReabastecimiento = new FormControl();


  ubicacion: MinaUbicaciones[] = [];
  ubicacionFiltrados: Observable<MinaUbicaciones[]>;
  ubicacionSinFiltrar: MinaUbicaciones[];
  filtroUbicacion = new FormControl();

  minas: Mina[] = [];
  ubicaciones: MinaUbicaciones[] = [];
  almacenes: Almacen[] = [];

  subscripcionAlmacen: Subscription;
  productos: Productos[] = [];

  textoBuscarMina: string = '';
  textoBuscarUbicacion: string = '';
  textoBuscarAlmacen: string = '';
  textoBuscar: string = '';


  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<DetallePedidoSalida> = new MatTableDataSource([]);

  columnasMostradas = [
    'linea',
    'cantidad',
    'cantidadEntregada',
    'orden',
    'fecha',
    'producto',
    'codigoProductoReemplazo',
    'unidadMedidaCodigo',
    'opciones',
  ];

  @ViewChild(MatPaginator, { static: false }) paginadorCompletado: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenadorCompletado: MatSort;
  @ViewChild('filtroCompletado', { static: false })
  filtroCompletado: ElementRef;
  sub: Subscription;
  fuenteDatosCompletado: MatTableDataSource<DetallePedidoSalida> = new MatTableDataSource(
    []
  );
  columnasMostradasCompletado = [
    'cantidad',
    'cantidadEntregada',
    'orden',
    'fecha',
    'producto',
    'unidadMedidaCodigo',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private translate: TranslateService,
    private activateRouter: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private cargando: LoadingService,
    public datepipe: DatePipe,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {
    this.activateRouter.params.subscribe((params) => {
      this.pedidoId = +params['id'];
    });
  }

  async ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.nullValidator],
      folio: ['', Validators.required],
      fechaDocumento: [new Date(), Validators.required],
      referencia: ['', Validators.nullValidator],
      comentarios: ['', Validators.nullValidator],
      esBorrador: [false, Validators.required],
      cantidadTotalPedido: [0, Validators.nullValidator],
      totalLineas: [0, Validators.nullValidator],
      almacenId: [undefined, Validators.required],
      almacenDescripcion: ["", Validators.nullValidator],
      almacenReabastecimientoId: [0, Validators.nullValidator],
      almacenReabastecimientoDescripcion: ['', Validators.nullValidator],
      almacenDestinoId: [null, Validators.nullValidator],
      almacenDestinoDescripcion: ['', Validators.nullValidator],
    });

    if (this.pedidoId && this.pedidoId > 0) {
      const resultado = await this.ctx.pedidosSalida.obtenerPedidoDetalle(this.pedidoId).toPromise();
      if (resultado.ok && resultado.objeto != null) {
        this.pedido = resultado.objeto;
        Object.assign(this.forma.value, this.pedido);
        this.forma.reset(this.forma.value);
        this.cargarProductos(this.pedido.almacenId);
        this.cargarDetallePedidos();
      }
    } else {
      this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
        (credencial) => {
          this.forma.get('almacenId').setValue(this.autenticacion.credencial.almacenId);
          this.forma.get('almacenDescripcion').setValue(this.autenticacion.credencial.almacenDescripcion);
          this.generarFolio(this.autenticacion.credencial.almacenId);
          this.cargarProductos(this.autenticacion.credencial.almacenId);
          this.detallePedidos = [];
          this.pedido.detalles = [];
          this.fuenteDatos = new MatTableDataSource(this.detallePedidos);
          this.cargarDetallePedidos();
        }
      );
    }


    // this.cargarAlmacenes();
    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;

    this.cargarAlmacenesDestino();
  }

  async cargarAlmacenesDestino(){
    this.almacenesDestino = await this.ctx.Almacen.obtenerTodos().toPromise();
    this.almacenesDestinoFiltrados.next(this.almacenesDestino);
  }

  async generarFolio(almacenId: number){
    const resultado = await this.ctx.pedidosSalida.generarFolio(almacenId).toPromise();
    this.forma.get("folio").setValue(resultado.objeto);
  }

  cargarDetallePedidos() {
    this.detallePedidos = this.pedido.detalles;
    if (this.detallePedidos.length == 0) {
      this.detalle = new DetallePedidoSalida();
      this.detalle.id = 0;
      this.detalle.linea = 1;
      this.detalle.cantidad = 0;
      this.detalle.cantidadEntregada = 0;
      this.detalle.fechaSolicitud = new Date();
      this.detalle.orden = '';
      this.detalle.pedidoId = this.pedidoId;
      this.detalle.productoCodigo = '';
      this.detalle.productoDescripcion = '';
      this.detalle.productoId = 0;
      this.detalle.unidadMedidaCodigo = '';
      this.detalle.existencia = 0;
      this.detalle.bloqueado = false;
      this.detalle.codigoProductoReemplazo = '';
      this.detallePedidos.push(this.detalle);
      this.fuenteDatos = new MatTableDataSource<DetallePedidoSalida>(this.detallePedidos);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.conteoLineasCantidad();
    } else {
      this.fuenteDatos = new MatTableDataSource<DetallePedidoSalida>(this.detallePedidos);
      this.fuenteDatos.paginator = this.paginador;
      // this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    }

    this.detallePedidosCompletados = this.pedido.detalleCompleto;
    this.fuenteDatosCompletado = new MatTableDataSource(
      this.detallePedidosCompletados
    );
    this.fuenteDatosCompletado.paginator = this.paginadorCompletado;
    this.fuenteDatosCompletado.sort = this.ordenadorCompletado;
  }

  limpiar(): void {}

  guardar(tipo: boolean): void {
    //GUARDO EL PEDIDO COMO DOCUMENTO BORRADOR

    const pedido = this.forma.value as PedidoSalida;
    pedido.esBorrador = tipo;
    var productoEnCero = this.detallePedidos.filter((e) => e.cantidad == 0).map((e) => (e.remarcar = true));
    if (productoEnCero.length > 0) {
      this.alerta.mostrarAdvertencia('Aún hay productos con cantidad en cero, en el detalle');
      return;
    }


    let lista = this.detallePedidos.filter((e) => e.cantidad < e.cantidadEntregada);
    if (lista.length > 0) {
      lista.map((e) => (e.remarcar = true));
      this.alerta.mostrarAdvertencia('Hay producto con cantidad menor a la entregada!');
      return;
    }
    this.cargando.show("Espera un momento... Guardando la requisición " + pedido.folio);

    var helper = {};
    var detallePedidoAgrupado = this.detallePedidos.reduce(function (r, o) {
      var key = o.productoId + '-' + o.orden;
      if (!helper[key]) {
        helper[key] = Object.assign({}, o);
        r.push(helper[key]);
      } else {
        helper[key].cantidadFaltante += o.cantidadFaltante;
        helper[key].cantidad += o.cantidadFaltante;
      }
      return r;
    }, []);

    let detPedido = detallePedidoAgrupado as DetallePedidoSalida[];
    pedido.detalles = [];
    pedido.detalles.length = 0;
    pedido.detalles = detPedido;
    pedido.listaDetalleBorrar = this.borrarListaDetalle;
    const accion =
      this.pedido.id > 0
        ? this.ctx.pedidosSalida.actualizarRequisicion(pedido)
        : this.ctx.pedidosSalida.generarRequisicion(pedido);

    accion
      .toPromise()
      .then(() => {
        this.cargando.hide();
        this.alerta.mostrarExito('Documento guardado con éxito!');
        this.router.navigate(['/RequisicionSalida']);
      })
      .catch(() => {
        this.cargando.hide();
        this.alerta.mostrarError('Error al guardar!');
      });
  }

  cerrar(): void {}



  actualizaLinea(
    linea: number,
    cantidad?: number,
    cantidadEntregada?: number,
    orden?: string,
    fecha?: Date,
    producto?: Productos
  ) {
    const detallePedido = this.fuenteDatos.data.find((e) => e.linea == linea);
    detallePedido.id = detallePedido.id;
    detallePedido.cantidad = +cantidad;
    detallePedido.cantidadEntregada = +cantidadEntregada;
    detallePedido.cantidadFaltante = detallePedido.cantidad - detallePedido.cantidadEntregada;
    detallePedido.fechaSolicitud = fecha;
    detallePedido.orden = orden;
    detallePedido.pedidoId = this.pedidoId;
    if (producto.id > 0) {
      detallePedido.productoCodigo = producto.codigo;
      detallePedido.productoDescripcion = producto.codigo + ' - ' + producto.descripcion;
      detallePedido.productoId = producto.id;
      detallePedido.unidadMedidaCodigo = producto.unidadMedidaClave;
      detallePedido.existencia = +producto.existencia;
      detallePedido.bloqueado = false;
      detallePedido.codigoProductoReemplazo = producto.codigoProductoReemplazo;
    }
    detallePedido.remarcar = false;
    // this.verificaExistenciaProducto(detallePedido.productoCodigo, detallePedido.existencia);
    this.cantidadVsEntrega();
    this.conteoLineasCantidad();
  }

  verificaExistenciaProducto(codigoProducto: string, existencia: number) {
    let cantidadProducto = this.detallePedidos
      .filter((e) => e.productoCodigo == codigoProducto)
      .reduce((suma, detalle) => suma + +detalle.cantidad, 0);
    if (existencia < cantidadProducto) {
      this.alerta.mostrarAdvertencia(
        'La cantidad del producto ' +
          codigoProducto +
          ' es mayor a la existencia!'
      );
      this.detallePedidos
        .filter((e) => e.productoCodigo == codigoProducto)
        .map((e) => (e.remarcar = true));
      return;
    }
  }

  cantidadVsEntrega() {
    let lista = this.detallePedidos.filter(
      (e) => e.cantidad < e.cantidadEntregada
    );
    if (lista.length > 0) {
      lista.map((e) => (e.remarcar = true));
      this.alerta.mostrarAdvertencia(
        'Hay producto con cantidad menor a la entregada!'
      );
      return;
    }
  }

  agregarLinea() {
    const detalle = new DetallePedidoSalida();
    detalle.id = 0;
    detalle.linea = this.detallePedidos.length + 1;
    detalle.cantidad = 0;
    detalle.cantidadEntregada = 0;
    detalle.cantidadFaltante = 0;
    detalle.fechaSolicitud = new Date();
    detalle.orden = '';
    detalle.pedidoId = this.pedidoId;
    detalle.productoCodigo = '';
    detalle.productoDescripcion = '';
    detalle.productoId = 0;
    detalle.unidadMedidaCodigo = '';
    detalle.existencia = 0;
    detalle.bloqueado = false;
    detalle.codigoProductoReemplazo = '';
    this.detallePedidos.push(detalle);
    this.fuenteDatos = new MatTableDataSource(this.detallePedidos);
    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
    // this.ordenadoPorLineaDesc();
    this.conteoLineasCantidad();
    this.textoBuscar = '';
  }

  ordenadoPorLineaDesc() {
    const sortState: Sort = { active: 'linea', direction: 'desc' };
    this.ordenador.active = sortState.active;
    this.ordenador.direction = sortState.direction;
    this.ordenador.sortChange.emit(sortState);
  }

  bloqueaLinea() {
    this.detallePedidos.map((e) => (e.bloqueado = true));
  }

  conteoLineasCantidad() {
    this.f['cantidadTotalPedido'].setValue(0);
    this.f['totalLineas'].setValue(0);
    this.f['cantidadTotalPedido'].setValue(this.fuenteDatos.data.reduce((suma, detalle) => suma + +detalle.cantidad,0));
    this.f['totalLineas'].setValue(this.fuenteDatos.data.length);


    let totalEntrada = this.fuenteDatos.data.reduce((suma, detalle) => suma + +detalle.cantidad,0);
    this.f['cantidadTotalPedido'].setValue(totalEntrada.toFixed(2));
    this.f['totalLineas'].setValue(this.fuenteDatos.data.length.toFixed(2));

  }

  removerLinea(linea: number) {
    if (this.detallePedidos.length > 1) {
      this.detallePedidos.splice(linea - 1, 1);
      let contador: number = 1;
      for (let index = 0; index < this.detallePedidos.length; index++) {
        const element = this.detallePedidos[index];
        element.linea = contador;
        contador++;
      }
      this.fuenteDatos = new MatTableDataSource(this.detallePedidos);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      // this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    }
  }

  //#region  Productos
  async cargarProductos(almacenId) {
    this.ctx.Productos.obtenerPorAlmacen(almacenId)
    .pipe(first(), tap(productos => {
       this.productos = productos;
    })).subscribe();
  }

  async limpiarProductos(linea: number) {
    const detallePedido = this.fuenteDatos.data.find((e) => e.linea == linea);
    if (!detallePedido.bloqueado) {
      detallePedido.cantidad = 0;
      detallePedido.cantidadFaltante = 0;
      detallePedido.cantidadEntregada = 0;
      detallePedido.pedidoId = this.pedidoId;
      detallePedido.productoCodigo = '';
      detallePedido.productoDescripcion = '';
      detallePedido.productoId = 0;
      detallePedido.unidadMedidaCodigo = '';
      detallePedido.existencia = 0;
      detallePedido.bloqueado = false;
      detallePedido.remarcar = false;
      detallePedido.codigoProductoReemplazo = '';
      detallePedido.id = 0;
      this.textoBuscar = '';
    }
  }

  //#endregion

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  filtrar(filtro: string) {
    this.fuenteDatos.filter = filtro;
  }

  limpiarFiltroCompletado(): void {
    this.filtroCompletado.nativeElement.value = '';
    this.fuenteDatosCompletado.filter = '';
  }

  filtrarCompletado(filtro: string) {
    this.fuenteDatosCompletado.filter = filtro;
  }

  cargarExcelDetalle(event) {
    try {
      if (this.forma.get('almacenId').value == null || this.forma.get('almacenId').value <= 0) {
        this.alerta.mostrarAdvertencia('Seleccione un almacén');
        return;
      }
      this.detallePedidos = [];
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          this.file = event.target.files[i];
          let fileReader = new FileReader();
          fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i)
              arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join('');
            var workbook = XLSX.read(bstr, { type: 'binary' });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            let Json = XLSX.utils.sheet_to_json(worksheet, {
              raw: true,
            }) as ExcelDetalleRequisicion[];
            if (Json) {
              this.detallePedidos = Json.map((s) => {
                const pro = this.productos.find(e => e.codigo === s.articulo);

                const r = {} as DetallePedidoSalida;
                r.orden = s.orden;
                r.productoCodigo = s.articulo;
                r.productoDescripcion = pro != null? pro.codigo + ' - ' + pro.descripcion: "";
                r.codigoProductoReemplazo = pro != null? pro.codigoProductoReemplazo: "";
                r.productoId = pro != null? pro.id: undefined;
                r.fechaSolicitud = s.fecha;
                r.cantidadEntregada = 0;
                r.cantidad = +s.cantidad
                r.remarcar = pro != null? false: true;
                r.bloqueado = false;
                r.unidadMedidaCodigo = pro != null? pro.unidadMedidaClave: "";
                r.linea = +s.linea;
                r.fechaSolicitud = new Date(s.fecha);
                r.cantidadFaltante = +s.cantidad
                return r;
              })

              this.fuenteDatos = new MatTableDataSource<DetallePedidoSalida>(this.detallePedidos);
              this.fuenteDatos.paginator = this.paginador;
              // this.ordenadoPorLineaDesc();
              this.conteoLineasCantidad();
            } else {
              this.translate
                .get('estructuraincorrecta')
                .toPromise()
                .then((e) => {
                  this.alerta.mostrarError(e);
                });
            }
          };
          fileReader.readAsArrayBuffer(this.file);
        }
      }
    } catch (error) {
      this.translate
        .get('errorleerdocumento')
        .toPromise()
        .then((e) => {
          this.alerta.mostrarError(e);
        });
      this.alerta.mostrarError('');
    }
  }

  //#region CargaAlmacenes
  cargarAlmacenesReabastecimiento() {
    this.ctx.Almacen.obtenerReabastecimiento()
      .toPromise()
      .then((resultado) => {
        this.almacenesSinFiltrarReabastecimiento = resultado;
        this.almacenesFiltradosReabastecimiento = this.filtroAlmacenesReabastecimiento.valueChanges.pipe(
          startWith<string | Almacen>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarAlmacenesReabastecimiento(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarAlmacenesReabastecimiento(nombre: string): Almacen[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.almacenesSinFiltrarReabastecimiento.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  almacenSeleccionadoReabastecimiento(almacen: Almacen) {
    console.log(almacen.id);
    this.forma
      .get('almacenReabastecimientoDescripcion')
      .setValue(almacen.descripcion);
    this.forma.get('almacenReabastecimientoId').setValue(almacen.id);
  }

  limpiarAlmacenesReabastecimiento(): void {
    this.forma.get('almacenReabastecimientoDescripcion').setValue('');
    this.forma.get('almacenReabastecimientoId').setValue(0);
    this.cargarAlmacenesReabastecimiento();
  }

  buscarAlmacen(event) {
    this.textoBuscarAlmacen = event.target.value;
  }


  limpiarAlmacenSeleccion(): void {
    this.forma.get('almacenId').setValue(0);
    this.forma.get('almacenDescripcion').setValue('');
  }

  //#endregion

  buscar(event) {
    this.textoBuscar = event.target.value;
  }


  cargarAlmacenes() {
    this.ctx.Almacen.obtenerTodos()
      .toPromise()
      .then((almacenes) => (this.almacenes = almacenes));
  }

  seleccionarAlmacen(almacen: Almacen) {
    this.forma.get('almacenId').setValue(almacen.id);
    this.forma.get('almacenDescripcion').setValue(almacen.codigo + " " + almacen.descripcion);
    this.generarFolio(almacen.id);
    this.cargarProductos(almacen.id);
    this.detallePedidos = [];
    this.pedido.detalles = [];
    this.fuenteDatos = new MatTableDataSource(this.detallePedidos);
    this.cargarDetallePedidos();
  }


  descargarGuiaLayout() {
    let archivo = window.open("assets/guias-excel/Layout_DetallePedido.xlsx", '_blank');
    setTimeout(function () {
      archivo.close();
    }, 100);
    return false;
  }

  fitrarAlmacenesDestino() {
    if (!this.almacenesDestino) {
      return;
    }
    // get the search keyword
    let search = this.busquedaAlmacenDestino.value;
    if (!search) {
      this.almacenesDestinoFiltrados.next(this.almacenesDestino.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.almacenesDestinoFiltrados.next(
      this.almacenesDestino.filter(a => a.codigo.toLowerCase().indexOf(search) > -1 || a.descripcion.toLowerCase().indexOf(search) > -1)
    );
  }

}

import { Documento } from './documento';
import { DetalleTraspaso } from './detalle-traspaso';
import { AsignacionEntradaPedido } from './asignacion-entrada-pedido';

export class Traspaso extends Documento {
  detalles: DetalleTraspaso[] = [];
  detalleCompleto: DetalleTraspaso[] = [];
  almacenReabastecimientoCodigo: string;
  almacenReabastecimientoId: number;
  almacenReabastecimientoDescripcion: string;
}
import { HttpClient } from '@angular/common/http';

export abstract class Repo {
  private readonly _ruta: string;
  private readonly _http: HttpClient;
  get ruta() {
    return this._ruta;
  }
  get http() {
    return this._http;
  }

  constructor(http: HttpClient, controlador: string) {
    this._http = http;
    this._ruta = '/api/' + controlador;
  }
}

import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Contexto } from 'src/app/shared/api/contexto.service';
import { LoadingService } from 'src/app/loading/loading.service';
import {
  ChartOptions,
  ChartDataSets,
} from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { Grafica } from 'src/app/shared/models/grafica';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'db-control-recepciones',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class DdControlRecepciones implements OnInit, OnDestroy {
  productos: Grafica[] = [];
  sub: Subscription;

  public lineChartData: ChartDataSets[] = [
    { data: [0], type: 'line', label: 'año 2020', lineTension: 0, borderColor: 'rgba(250,213,56)', fill: false, backgroundColor: 'rgba(0,0,0,0)' },
    { data: [0], type: 'line', label: 'Máximo', lineTension: 0, borderColor: 'gray', fill: false, backgroundColor: 'rgba(0,0,0,0)' },
    { data: [0], type: 'line', label: 'Mínimo', lineTension: 0, borderColor: 'gray', fill: false, backgroundColor: 'rgba(0,0,0,0)' },
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };

  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  public barChartColors: Color[] = [
    { backgroundColor: 'rgba(0,0,0,0)' },
    { backgroundColor: 'gray' },
    { backgroundColor: 'gray' },
  ]
  constructor(private ctx: Contexto, private spinner: LoadingService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) { }
  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarGrafica();
      }
    );
  }

  async cargarGrafica() {

    this.productos = await this.ctx.dashbord.obtenerControlRecepciones(this.autenticacion.credencial.almacenId).toPromise();
    if (this.productos) {
      let productos = this.productos.map(e => e.producto);
      let cantidades = this.productos.map(e => e.cantidad);

      this.lineChartData[0].data = cantidades;
      this.lineChartData[1].data = Array.from({ length: productos.length }, (x, y) => 40);
      this.lineChartData[1].borderDash = [cantidades.length];
      this.lineChartData[2].data = Array.from({ length: productos.length }, (x, y) => -40);
      this.lineChartData[2].borderDash = [cantidades.length];
      this.lineChartLabels = productos;
    }
  }
  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}


import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Roles } from '../models/rol';
import { UsuarioRoles } from '../models/usuario-rol';

export class RepositorioRol extends Repositorio<Roles> {
  constructor(http: HttpClient) {
    super(http, 'Roles');
  }

  obtenerRolesPorUsuario(id: number): Observable<UsuarioRoles[]> {
    const ruta = `${this.ruta}/ObtenerRolesPorUsuario/${id}`;
    return this.http.get<UsuarioRoles[]>(ruta);
  }

  guardarUsuarioRol(usuarioEnRol: UsuarioRoles): Observable<void> {
    const ruta = `${this.ruta}/GuardarUsuarioRol`;
    return this.http.post<void>(ruta, usuarioEnRol);
  }

  eliminarUsuarioRol(usuarioEnRol: UsuarioRoles): Observable<void> {
    const ruta = `${this.ruta}/EliminarUsuarioRol`;
    return this.http.post<void>(ruta, usuarioEnRol);
  }
}

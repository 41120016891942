import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Credencial } from '../models/credencial';
import { Login } from '../models/acceso';

export class RepositorioAcceso extends Repositorio<Login> {
  constructor(http: HttpClient) {
    super(http, 'Login');
  }

  obtenerCredencial(acceso: Login): Observable<Credencial> {
    const ruta = `${this.ruta}/ObtenerCredencial`;
    return this.http.post<Credencial>(ruta, acceso);
  }

  obtenerPantallasPerfil(crede: Credencial): Observable<Credencial> {
    const ruta = `${this.ruta}/ObtenerPantallasPerfil`;
    return this.http.post<Credencial>(ruta, crede);
  }

}

export class Grafica {
    cantidad: number;
    almacen: string;
    mes: string;
    anio: string;
    anioAnterior: string;
    producto: string;
    totalATiempo: number;
    totalRetraso: number;
    totalPartidas: number;
    porcentajeATiempo: number;
    porcentajeRetraso: number;
    diasTranscurridos: number;
    productoDescripcion: string;
}

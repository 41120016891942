import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { TipoMenu } from '../models/tipo-menu';
import { map } from 'rxjs/operators';
import * as signalR from '@aspnet/signalr';
import { HostService } from 'src/app/services/infoHost/host-service.service';

export class RepositorioTipoMenu extends Repositorio<TipoMenu> {
  private _hubConnection: signalR.HubConnection;
  public cambio = new Subject();

  private ref: string;
  constructor(http: HttpClient, url: HostService) {
    super(http, 'TiposMenu');
    this.ref = url.getHostname();
  }
  metodo = (valor: number) => {};

  obtenerTodosTiposMenu(): void {
    this.cambio.next();
  }

  ConneciontHub() {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.ref)
      .build();
    this._hubConnection.on('Guardar', (x) => {
      this.obtenerTodosTiposMenu();
    });

    this._hubConnection.on('Actualizar', (x) => {
      this.obtenerTodosTiposMenu();
    });

    this._hubConnection.on('Eliminar', (x) => {
      this.obtenerTodosTiposMenu();
    });

    this._hubConnection.on('Activar', (x) => {
      this.obtenerTodosTiposMenu();
    });

    this._hubConnection.on('Desactivar', (x) => {
      this.obtenerTodosTiposMenu();
    });

    this._hubConnection
      .start()
      .then((v) => console.log('Hub very very good!!'))
      .catch((v) => {
        console.log('Error hub!' + v);
      });
  }

  activar(id: number): Observable<void> {
    this.ref;
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  guardarExcel(lista: TipoMenu[]): Observable<TipoMenu[]> {
    const ruta = `${this.ruta}/GuardarColeccionExcel`;
    return this.http.post<TipoMenu[]>(ruta, lista);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }
}

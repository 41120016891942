import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsignacionEmpresaProducto } from '../models/asignacion-empresa-producto';
import { Productos } from '../models/Productos';

export class RepositorioAsignacionMinaProducto extends Repositorio<
  AsignacionEmpresaProducto
> {
  constructor(http: HttpClient) {
    super(http, 'AsignacionesEmpresasProductos');
  }

  obtenerProductos(minaId: number): Observable<Productos[]> {
    const ruta = `${this.ruta}/ObtenerProductos/${minaId}`;
    return this.http.get<Productos[]>(ruta);
  }

  obtenerAsignacion(
    productoId: number,
    empresaId: number
  ): Observable<AsignacionEmpresaProducto> {
    const ruta = `${this.ruta}/${productoId}/${empresaId}`;
    return this.http.get<AsignacionEmpresaProducto>(ruta);
  }

  eliminarAsignacion(productoId: number, empresaId: number): Observable<void> {
    const ruta = `${this.ruta}/${productoId}/${empresaId}`;
    return this.http.delete<void>(ruta);
  }

  guardarAsignaciones(
    asignaciones: AsignacionEmpresaProducto[],
    minaId: number
  ): Observable<void> {
    const ruta = `${this.ruta}/Guardar/${minaId}`;
    return this.http.post<void>(ruta, asignaciones);
  }

  obtenerExcel(minaId: number): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, minaId, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }
}

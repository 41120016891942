import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Schemas } from '../models/esquema-desktop';
 


export class RepositorioEsquemaDesktop extends Repositorio<Schemas> {
    constructor(http: HttpClient) {
        super(http, 'Schemas');
    }


}

import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import * as XLSX from 'xlsx';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { FormBuilder } from '@angular/forms';
import { ExcelAsignacionAlamacenProducto } from 'src/app/shared/models/excel-asignacion-almacen-producto';
import { AsignacionAlmacenProducto } from 'src/app/shared/models/asignacion-almacen-producto';
import { AsignacionProductoCritico } from 'src/app/shared/models/asignacion-producto-critico';
import { Router } from '@angular/router';
import { CatalogoControlPeriodosComponent } from '../catalogo/catalogo.component';
import { Session } from 'protractor';
import { Credencial } from 'src/app/shared/models/credencial';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { AsignacionDiaInhabil } from 'src/app/shared/models/asignacion-dia-inhabil';
import { AsignacionPeriodoSemana } from 'src/app/shared/models/asignacion-periodo-semana';
import { Periodos } from 'src/app/shared/models/periodos';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaDocumentoExcelPeriodoSemanalComponent implements OnInit {

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }
  columnas = ['numSemana','fechaInicial','fechaFinal','fechaConteo'];
  file: File;
  arrayBuffer: any;
  docs: string[] = [];
  guardado: EventEmitter<void> = new EventEmitter<void>();
  asignaciones: AsignacionPeriodoSemana[] = [];
  periodo: CatalogoControlPeriodosComponent;
  pnum: number;
  periodoSeleccionado:number;


  constructor(
    @Inject(MAT_DIALOG_DATA) public dato: any,

    private formBuilder: FormBuilder,
    private alerta: ServicioAlerta,
    private ctx: Contexto,
    private ventana: MatDialogRef<FormaDocumentoExcelPeriodoSemanalComponent>,
    private exportAsService: ExportAsService,
    private router: Router,
    private spinner: LoadingService,
    public autenticacion: AutenticacionService,
  ) {

  }

  ngOnInit() { 
    
  }


  onMensajeHijo(mensaje) {
    this.periodoSeleccionado=mensaje;
   }


  archivos($event) {
    this.asignaciones.length = 0;
    this.file = $event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.asignaciones = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as AsignacionPeriodoSemana[];
      console.table(this.asignaciones);
      this.docs.push(this.file.name);
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  showDateto(event) {
    //enseña lo que elegistes en el input
    
  }

  async guardarAsignaciones() {
    if (this.asignaciones && this.asignaciones.length > 0) {
      
    this.spinner.show('Espera un momento, estamos guardando las asignaciones de periodo semanal.');

      const asigs = this.asignaciones.map(a => {return {...a, }}) as AsignacionPeriodoSemana[];

      await this.ctx.ControlPeriodos.guardarRangoExcelPeriodoSemanal(this.autenticacion.credencial.almacenId, asigs).toPromise().then(errores => {
        if (errores.length) {
          this.spinner.hide();
          this.alerta.mostrarExito("Periodo semanal guardado con éxito");
          this.ventana.close();
          this.router.navigate(['/']);
        
        } else {
          this.spinner.hide();
          this.alerta.mostrarError("Error en los codigos " + errores.join(', \n'));

        }
      }).catch(error => {
        console.log(error);
        this.spinner.hide();
        this.alerta.mostrarError("Error interno del sistema");
      });
    } else {
      this.alerta.mostrarAdvertencia("Ingrese el archivo excel");
    }
    this.router.navigate(['PeriodosC']);

  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'arcvhivo_no_encontrado').subscribe();
  }

  cerrar(): void {
    this.guardado.emit();
    this.cerrar();
  }
}

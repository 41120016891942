import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { AutenticacionService } from '../services/autenticacion.service';
import { ThemeService } from 'src/app/services/theming/theme.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Theme } from 'src/app/services/theming/type';
import { TipoMenu } from 'src/app/shared/models/tipo-menu';
import { Pantallas } from 'src/app/shared/models/pantallas';
import { Router } from '@angular/router';
import { Almacen } from 'src/app/shared/models/Almacen';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { Credencial } from 'src/app/shared/models/credencial';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {
  isExpanded = false;
  element: HTMLElement;
  darkThemeSelected: boolean;
  nombreUsuario: string;
  tipoUsuario: string;
  sub: Subscription;

  enlace: boolean;
  enlaceCuestionario: boolean;
  subscribeRecargaMenu: Subscription;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );


  menuSeguridad: Pantallas[] = [];
  menuCatalogos: Pantallas[] = [];
  menuConfiguracion: Pantallas[] = [];
  menuOperacion: Pantallas[] = [];
  menuReportes: Pantallas[] = [];
  usuariosAlmacenes: Almacen[] = [];
  nombre: string = "";
  almacen: string = "";


  constructor(private router: Router,  public servicioUsuarioAlmacen: ServicioUsuarioAlmacen, private breakpointObserver: BreakpointObserver, public autenticacion: AutenticacionService, public ctx: Contexto, public servicioTema: ThemeService) {
    this.nombreUsuario = autenticacion.credencial.nombreCompleto;
    this.tipoUsuario = autenticacion.credencial.tipoUsuarioDescripcion;
    this.enlace = false;
    this.enlaceCuestionario = false;
    servicioTema.selectedTheme$.pipe(first()).subscribe(t => {
      this.darkThemeSelected = t === 'dark-theme';
    });
  }
  async ngOnInit(): Promise<void> {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {

        this.menuSeguridad = [];
        this.menuCatalogos = [];
        this.menuConfiguracion = [];
        this.menuOperacion = [];
        this.menuReportes = [];

        this.menuSeguridad =  this.autenticacion.credencial.pantallasSeguridad;
        this.menuCatalogos =  this.autenticacion.credencial.pantallasCatalogos;
        this.menuConfiguracion =  this.autenticacion.credencial.pantallasConfiguracion;
        this.menuOperacion =  this.autenticacion.credencial.pantallasOperacion;
        this.menuReportes =  this.autenticacion.credencial.pantallasReportes;
        this.usuariosAlmacenes =  this.autenticacion.credencial.usuarioAlmacen.filter(a => a.id != this.autenticacion.credencial.almacenId);
        this.nombreUsuario = this.autenticacion.credencial.nombreCompleto;
        this.almacen = this.autenticacion.credencial.almacenDescripcion;
      }
    );

  }

  toggleActive(event: any) {
    event.preventDefault();
    if (this.element !== undefined) {
      this.element.style.backgroundColor = "";
    }
    var target = event.currentTarget;
    target.style.backgroundColor = "#000000";
    this.element = target;
  }

  toggleTheme(darkThemeSelected: boolean) {
    let theme: Theme;
    theme = darkThemeSelected ? 'dark-theme' : 'light-theme';
    this.servicioTema.setSelectedTheme(theme);
  }

  // cargarMenuSeguridad(): void {
  //   this.ctx.pantallas.obtenerTodos().toPromise().then(resultado => {
  //     this.menuSeguridad = resultado.filter(e => e.tipoMenuDescripcion == "Seguridad");
  //     console.dir(this.menuSeguridad);
  //   }).catch(e => {
  //     console.log(e);

  //   });
  // }

  // cargarMenuCatalogos(): void {
  //   this.ctx.pantallas.obtenerTodos().toPromise().then(resultado => {
  //     this.menuCatalogos = resultado.filter(e => e.tipoMenuDescripcion == "Catalogos");
  //     console.dir(this.menuCatalogos);
  //   }).catch(e => {
  //     console.log(e);
  //   });
  // }

  irA(menu: string): void{
     this.router.navigate(['/'+ menu]);
  }

  async almacenSeleccionado(almacen: Almacen) {
    try {
      let credencial = new Credencial();
      credencial = this.autenticacion.credencial;
      credencial.almacenId = almacen.id;
      credencial.almacenDescripcion = almacen.descripcion;
      this.autenticacion.asignarCredencial(credencial);
      this.servicioUsuarioAlmacen.actualizarCredencial.next(this.autenticacion.credencial);
      this.nombreUsuario = this.autenticacion.credencial.nombreCompleto;
      this.almacen = this.autenticacion.credencial.almacenDescripcion;
      this.usuariosAlmacenes =  this.autenticacion.credencial.usuarioAlmacen.filter(a => a.id != this.autenticacion.credencial.almacenId);

    } catch (e) {
      console.log(e);

    }
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

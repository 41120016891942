import {Component, OnInit,EventEmitter,Inject} from '@angular/core';
import {FormGroup,Validators,FormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ProcesoEstatus} from 'src/app/shared/models/ProcesoEstatus';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';
import {Contexto} from 'src/app/shared/api/contexto.service';
import {ServicioAlerta} from 'src/app/utilerias/alerta.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl:'./forma.component.html',
  styleUrls:['./forma.component.scss']
})

export class FormaProcesoEstatus implements OnInit{

  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: ProcesoEstatus;

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaProcesoEstatus>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.ProcesoEstatus.obtener(id).toPromise().then(resultado => {
        this.modelo = resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get("messages.eliminarError").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new ProcesoEstatus();
      this.cargarFolio();
    }
  }
  pages="";
  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        codigo: ['', Validators.required],
        descripcion: ['', Validators.required],
        activo: [true, Validators.nullValidator]
      },
    );
    this.translate.get("title.procesStatus").subscribe((res:string)=>{
      this.pages=res;
    })
  }

  cargarFolio(): void {
    var r = this.ctx.ProcesoEstatus.generarFolio().toPromise().then(resultado => {
      this.forma.get('codigo').setValue("PROEST-" + resultado);
    }).catch(e => {
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }

  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as ProcesoEstatus;
      if (this.idSeleccionado === 0) {
        this.ctx.ProcesoEstatus.guardar(model).toPromise().then(() => {
          this.translate.get("messages.guardar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get("messages.guardarExiste",{value:this.pages}).subscribe((res:string)=>{
          this.alerta.mostrarAdvertencia(res);
        })
          } else if (e.error === 'ErrorGuardar') {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        console.log(model)
        this.ctx.ProcesoEstatus.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get("messages.actualizar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.translate.get("messages.guardarExiste",{value:this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }
}

import { Documento } from './documento';
import { DetallePedidoSalida } from './detalle-pedido-salida';
import { AsignacionSalidaPedido } from './asignacion-salida-pedido';

export class PedidoSalida extends Documento {
  estatusId: number; /// 1 = Abierto, 2 Cerrado
  detalles: DetallePedidoSalida[] = [];
  detalleCompleto: DetallePedidoSalida[] = [];
  listaDetalleBorrar: DetallePedidoSalida[] = [];
  asignacionPedidos: AsignacionSalidaPedido[];
  almacenReabastecimientoCodigo: string;
  almacenReabastecimientoId: number;
  almacenReabastecimientoDescripcion: string;
  almacenDestinoId: number;
  almacenDestinoCodigo: string;
  almacenDestinoDescripcion: string;
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Productos } from '../models/Productos';
import { ListasPrecios } from '../models/listas-precios';
import { DetalleListaPrecio } from '../models/detalle-lista-precio';

export class RespositorioListaDePrecio extends Repositorio<ListasPrecios> {
  constructor(http: HttpClient) {
    super(http, 'ListaDePrecio');
  }

  generarFolio(): Observable<string> {
    let ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  guardarLista(model: DetalleListaPrecio): Observable<DetalleListaPrecio> {
    const ruta = `${this.ruta}/guardarListaPrecio`;
    return this.http.post<DetalleListaPrecio>(ruta, model);
  }

  actualizarLista(
    id: number,
    model: DetalleListaPrecio
  ): Observable<DetalleListaPrecio[]> {
    const ruta = `${this.ruta}/ActualizarTodo/${id}`;
    return this.http.put<DetalleListaPrecio[]>(ruta, model);
  }

  activarLista(id: number): Observable<string> {
    const ruta = `${this.ruta}/ActualizarActivo`;
    return this.http.post<string>(ruta, id);
  }

  obtenerDatosProducto(id: number): Observable<Productos> {
    const ruta = `${this.ruta}/obtenerDatosPrd/{id}`;
    return this.http.get<Productos>(ruta);
  }

  GetPrd(id: number): Observable<DetalleListaPrecio[]> {
    const ruta = `${this.ruta}/GetProductos/${id}`;
    return this.http.get<DetalleListaPrecio[]>(ruta);
  }

  EditarTodaLista(
    id: number,
    porcentaje: number
  ): Observable<DetalleListaPrecio[]> {
    const ruta = `${this.ruta}/AumentarTodos/${id}`;
    return this.http.put<DetalleListaPrecio[]>(ruta, porcentaje);
  }
}

import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { AsignacionEmpresaProducto } from 'src/app/shared/models/asignacion-empresa-producto';
export class FormaEmpresasProductos extends FormGroup {
  constructor(public asignacion: AsignacionEmpresaProducto) {
    super(
      new FormBuilder().group({
        asignacionProductoEmpresaId: [null, Validators.nullValidator],
        empresaMineraId: [null, Validators.nullValidator],
        empresaSKU: [null, Validators.nullValidator],
        empresaMaterial: ['', Validators.nullValidator],
        otro: ['', Validators.nullValidator],
        maximo: [0, Validators.nullValidator],
        ideal: [0, Validators.nullValidator],
        productoId: [undefined, Validators.nullValidator],
      }).controls
    );

    if (asignacion) {
      this.patchValue(asignacion);
    }

    this.valueChanges.subscribe((valor) => {
      this.asignacion = this.value;
    });
  }
}

export class ListaFormaEmpresasProductos extends FormArray {
  constructor(
    public asignaciones: AsignacionEmpresaProducto[],
    public empresaId: number
  ) {
    super(asignaciones.map((a) => new FormaEmpresasProductos(a)));

    this.valueChanges.subscribe((valor) => {
      this.asignaciones = (this.controls as FormaEmpresasProductos[]).map(
        (c) => c.asignacion
      );
    });
  }

  public nuevaLinea() {
    this.push(
      new FormaEmpresasProductos({
        empresaMineraId: this.empresaId,
      } as AsignacionEmpresaProducto)
    );
  }

  public eliminarLinea(indice: number) {
    this.removeAt(indice);
  }
}

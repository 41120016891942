import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MinaUbicaciones } from 'src/app/shared/models/mina-ubicacion';
import { Mina } from 'src/app/shared/models/Mina';


@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss',],
})
export class FormaMinaUbicaciones implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: MinaUbicaciones;


  Filtrados: Observable<Mina[]>;
  SinFiltrar: Mina[];
  filtro = new FormControl();





  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaMinaUbicaciones>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.MinaUbicaciones.obtener(id).toPromise().then(resultado => {
        this.modelo = resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get("message.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new MinaUbicaciones();
      this.cargarFolio();
    }
  }
  pages="";
  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        codigo: ['', Validators.required],
        descripcion: ['', Validators.nullValidator],
        activo: [true, Validators.nullValidator],
        referencia: ['', [Validators.nullValidator]],
        descripcionMina: ['', Validators.required],
        minaId: [0, Validators.nullValidator],
      },
    );

    this.cargarEmpresasMinas();
    this.translate.get("title.miningLocation").subscribe((res:string)=>{
      this.pages=res;
    })
  }

  cargarFolio(): void {
    var r = this.ctx.MinaUbicaciones.generarFolio().toPromise().then(resultado => {
      this.forma.get('codigo').setValue("UBI-" + resultado);
    }).catch(e => {
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }
  limpiar(): void {
    this.forma.reset();
  }

  cargarEmpresasMinas() {
    this.ctx.Minas.obtenerTodos().toPromise().then(resultado => {
      this.SinFiltrar = resultado;
      this.Filtrados = this.filtro.valueChanges
        .pipe(
          startWith<string | Mina>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarRol(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarRol(mina: string): Mina[] {
    const valorFiltro = mina.toLowerCase();
    let filtro = this.SinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  minaSeleccionado(mina: Mina) {
    this.f['descripcionMina'].setValue(mina.descripcion);
    this.f['minaId'].setValue(mina.id);
  }

  guardar(): void {
    if (this.forma.valid) {


      const model = this.forma.value as MinaUbicaciones;
      if (this.idSeleccionado === 0) {
        this.ctx.MinaUbicaciones.guardar(model).toPromise().then(() => {
          this.translate.get("messages.guardar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get("messages.guardarExiste",{value:this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else if (e.error === 'ErrorGuardar') {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.MinaUbicaciones.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get("messages.actualizar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.translate.get("messages.guardarError",{value:this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  limpiarFiltro(): void {

    this.f['descripcionMina'].setValue('');
    this.f['minaId'].setValue(null);
    this.cargarEmpresasMinas();
  }




}

export class Periodos {
    id: number;
    numPeriodo: number;
    numSemana: number;
    descripcion: string;
    almacenId: number;
    almacen: string;
    usuarioId: number
    usuario: string;
    fecha: Date;
    activo: boolean;
    calculado: boolean;
    mina: string;
    producto:string;
    sDiario:string;
    sMensual:string;
    diaInhabil: number;
    pSemana: number;
    dinhabil: number;
    fechaInicial: Date;
    fechaFinal: Date;
    fechaConteo: Date;
}


import {Component, OnInit,ViewChild} from '@angular/core';
import {FormGroup,FormBuilder,FormControl, Validators, FormArray} from '@angular/forms';

import {MatTableDataSource, MatTable} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

import {Contexto} from 'src/app/shared/api/contexto.service';
import {Almacen} from 'src/app/shared/models/Almacen';
import { Observable, from } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {AsignacionAlmacenReabastecimiento} from 'src/app/shared/models/AsignacionAlmacenReabastecimiento';
import {ServicioAlerta} from 'src/app/utilerias/alerta.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl:"ListaAlmacen.html",
  styleUrls:["ListaAlmacen.scss"]
})

export class ListaAlmacen implements OnInit{

  constructor(private ctx:Contexto,private formBuilder:FormBuilder,private alerta:ServicioAlerta,private translate:TranslateService){}

  forma:FormGroup;
  mostrarColumnas:string[]=["mina","ubicacionMina","almacen","select"];
  seleccion=new SelectionModel<Almacen>(true,[]);
  dataSource: MatTableDataSource<Almacen>=new MatTableDataSource([]);
  almacenes:Almacen[];

  sinFiltarAlmacen:Almacen[];
  FiltradoAlmacen:Observable<Almacen[]>;
  formReabastecimiento=new FormControl();

  @ViewChild(MatTable,null) table: MatTable<any>;

  formFiltrar=new FormControl();

  get f() {return this.forma.controls }
  page="";
  ngOnInit(){
    this.forma = this.formBuilder.group(
      {
        descripcionAlmacen:[''],
        almacenId:[0],
        AlmacenReabastecimientoId:[0,Validators.min(1)],
        EsReabastecimiento:[false]
      },
    );
    this.obtenerReestecimiento();
    this.almacenesLocales();
    this.translate.get("title.storeHouse").subscribe((res:string)=>{
      this.page=res;
    })
  }

  almacenesLocales() {
    this.ctx.Almacen.obtenerLocales().toPromise().then(resultado => {
      this.dataSource=new MatTableDataSource(resultado);
      //this.dataSource.data.forEach(row => this.seleccion.select(row));
    }).catch(e => {
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      } )
      console.log(e);
    });
  }

  minaUbicacionSeleccionado(mina: Almacen) {
    this.f['descripcionMinaUbicaciones'].setValue(mina.descripcion);
    this.f['minaUbicacionesId'].setValue(mina.id);
  }

  obtenerReestecimiento(){
    this.ctx.Almacen.obtenerReabastecimiento().subscribe((resultado)=>{
      this.sinFiltarAlmacen = resultado;
      this.FiltradoAlmacen = this.formReabastecimiento.valueChanges
        .pipe(
          startWith<string | Almacen>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this._filter(t))

        );
    })
  }

  private _filter(almacen: string): Almacen[] {
    const filterValue = almacen.toLowerCase();

    return this.sinFiltarAlmacen.filter(option => option.descripcion.toLowerCase().indexOf(filterValue) === 0);
  }

  almacenSeleccionado(almacen:Almacen){
    this.f["AlmacenReabastecimientoId"].setValue(almacen.id);
    this.f["descripcionAlmacen"].setValue(almacen.descripcion);
    let listado:Almacen[];
    this.ctx.Almacen.reabastecimientoLocal(almacen.id).subscribe((res)=>{
      this.dataSource.data.forEach(element => {
        res.forEach(data=>{
          if(data.almacenId==element.id){
            this.seleccion.select(element);
          }
        })
      });
    },(err:any)=>{
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }

  limpiarFiltroR():void{
    this.f['descripcionAlmacen'].setValue('');
    this.f['almacenId'].setValue(null);
    this.obtenerReestecimiento();
    this.almacenesLocales();
  }



  opcion(selector:boolean,almacen:Almacen){
      if(this.forma.valid){
        let value=this.f["almacenId"].value;
          this.f["almacenId"].setValue(almacen.id);
          const model = this.forma.value as AsignacionAlmacenReabastecimiento
            this.ctx.Almacen.ingresarReabastecimiento(model).subscribe((res)=>{
              this.translate.get("messages.guardar",{value:this.page}).subscribe((res2:string)=>{
                this.alerta.mostrarExito(res2);
              });
            },(err:any)=>{
              this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
                this.alerta.mostrarError(res)
              })
            })

      }
      else{
        this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarError(res)
        })
        this.almacenesLocales();
      }
  }


  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterToggle() {
    this.isAllSelected() ?
        this.seleccion.clear() :
        this.dataSource.data.forEach(row => this.seleccion.select(row));
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Almacen): string {
    if (!row) {
      console.log(row)
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.seleccion.isSelected(row) ? 'deselect' : 'select'} row ${row.descripcion + 1}`;
  }
}

export class Detalle {
  id: number;
  cantidad: number;
  productoId: number;
  productoDescripcion: string;
  productoCodigo: string;
  unidadMedidaCodigo: string;
  orden: string;
  fechaSolicitud: Date | string;
  linea: number;
  existencia: number;
  productoCodigoDescripcion: string;
  codigoProductoReemplazo: string;

}


import { Component, OnInit, Input } from '@angular/core';
import { FormaListaDetallePedido } from './forma-lista-detalle-pedido';
import { Productos } from 'src/app/shared/models/Productos';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Detalle } from 'src/app/shared/models/detalle';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';
import { ProductosAlmacen } from 'src/app/services/productos/productos-almacen.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lista-detalle-pedido',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.scss'],
})
export class ListaDetallesPedidoComponent implements OnInit {
  @Input() formaLista: FormaListaDetallePedido;
  @Input() almacenId: number;
  subNuevoAlmacen = new Subscription();
  @Input() productos: Productos[] = [];
  constructor(private db: Contexto) { }

  ngOnInit() {}
}

import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { LogSincronizacion } from '../../models/LogSincronizacion';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-pedido',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteDetalleSincronizacionComponent implements OnInit {
  columnas = [
    'tipoMovimientoDescripcion',
    'folio'
  ];
  log: LogSincronizacion = new LogSincronizacion();
  fechaLocal: Date = new Date();
  cantidadTotal: number = 0;
  cantidadLineas: number = 0;
  folio: string = "";
  fechaRegistro: string = "";
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ComprobanteDetalleSincronizacionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public log_: LogSincronizacion,
    private exportAsService: ExportAsService,
  ) {


    this.log = log_;

    this.cantidadLineas = this.log.detalle.length;
  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Log ' + this.log.folio).subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Log ' + this.log.folio).subscribe();
  }
  ngOnInit() {}

  regresar() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TranslateService } from '@ngx-translate/core';
import { Productos } from 'src/app/shared/models/Productos';
import { Observable } from 'rxjs';
import { EmpresaMinera } from 'src/app/shared/models/EmpresaMinera';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { FormaAsignacionEmpresaProductoComponent } from '../forma/forma.component';

@Component({
  selector: 'grupo-productos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoEmpresaProductoComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<Productos> = new MatTableDataSource([]);
  empresaFiltrados: Observable<EmpresaMinera[]>;
  empresaSinFiltrar: EmpresaMinera[];
  empresafiltro = new FormControl();
  cargando: boolean;
  columnasMostradas = ['producto', 'opciones'];

  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.cargarEmpresaes();
  }

  cargarEmpresaes() {
    this.ctx.EmpresaMinera.obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.empresaSinFiltrar = resultado;
        this.empresaFiltrados = this.empresafiltro.valueChanges.pipe(
          startWith<string | EmpresaMinera>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarEmpresa(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarEmpresa(modelo: string): EmpresaMinera[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.empresaSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  eliminar(productoId: number): void {
    const empresa = this.empresafiltro.value as EmpresaMinera;
    const empresaId = empresa ? empresa.id : 0;
    this.ctx.asignacionEmpresaProducto
      .eliminarAsignacion(productoId, empresaId)
      .toPromise()
      .then(() => {
        this.translate.get('messages.eliminar').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
        this.tabla(empresa.id);
      })
      .catch((e) => {
        console.log(e);
        this.translate
          .get('messages.eliminarError')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  mostrarEmpresa(empresa: EmpresaMinera) {
    let texto = '';
    if (empresa) {
      texto = empresa.descripcion;
    }
    return texto;
  }

  tabla(empresaId?: number): void {
    const empresa = this.empresafiltro.value as EmpresaMinera;
    if (!empresa) {
      this.alerta.mostrarAdvertencia('forms.selectMine');
    }
    this.cargando = true;

    this.ctx.asignacionEmpresaProducto
      .obtenerProductos(empresa.id)
      .toPromise()
      .then((resultado) => {
        this.fuenteDatos = new MatTableDataSource(resultado);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
      })
      .catch((e) => {
        this.translate
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      })
      .finally(() => (this.cargando = false));
  }

  descargarExcel(): void {
    const empresa = this.empresafiltro.value as EmpresaMinera;
    const fechaActual = new Date();
    let nombreArchivo = '';
    this.translate
      .get('messages.descargarExcel', { value: fechaActual.toLocaleString() })
      .subscribe((res: string) => {
        nombreArchivo = res;
      });
    this.ctx.asignacionEmpresaProducto.obtenerExcel(empresa.id)
      .toPromise()
      .then((respuesta) => {
        this.llamarExcel(respuesta, nombreArchivo);
      })
      .catch((e) => {});
  }

  llamarExcel(respuesta, nombreArchivo) {
    const blob = new Blob([respuesta], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  filtrar() {
    this.fuenteDatos.filter = this.filtro.nativeElement.value
      .trim()
      .toLowerCase();
  }

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  limpiarfiltroEmpresa() {
    this.empresafiltro.setValue(null);
  }

  abrirForma(id?: string): void {
    const dato = id ? id : null;
    const empresa = this.empresafiltro.value as EmpresaMinera;
    const empresaId = empresa ? empresa.id : 0;
    const forma = this.ventana.open(FormaAsignacionEmpresaProductoComponent, {
      data: { productoId: dato, empresaId: empresaId },
      panelClass: 'form-container',
    });
    forma.componentInstance.guardado.subscribe(() => {
      this.tabla(empresaId);
    });
  }
}

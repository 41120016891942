import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { startWith, map } from 'rxjs/operators';
import { Almacen } from 'src/app/shared/models/Almacen';
import { Productos } from 'src/app/shared/models/Productos';
import { AsignacionAlmacenProducto } from 'src/app/shared/models/asignacion-almacen-producto';
import { TranslateService } from '@ngx-translate/core';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss',],
})
export class FormaAsignacionAlmacenProducto implements OnInit, OnDestroy {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: AsignacionAlmacenProducto;

  opcionAlmacen:Almacen[];
  agregarAlmacen:number[]=[];

  productoFiltrados: Observable<Productos[]>;
  productoSinFiltrar: Productos[];
  productofiltro = new FormControl();

  almacenFiltrados: Observable<Almacen[]>;
  almacenSinFiltrar: Almacen[];
  almacenfiltro = new FormControl();
  sub: Subscription;

  file: File;
  arrayBuffer: any;


  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaAsignacionAlmacenProducto>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,public translate:TranslateService,public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) {

  }
  pages="";
  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        codigo: ['', Validators.required],
        productoId: [0, Validators.required],
        producto: ['', Validators.required],
        almacenId: [0, Validators.required],
        almacen: ['', Validators.required],
        activo: [true, Validators.nullValidator],
        sku: [null, Validators.required],
        material: ['', Validators.nullValidator],
        otro: ['', Validators.nullValidator],
        maximo: [0, Validators.nullValidator],
        ideal: [0, Validators.nullValidator],
      },
    );


    if (this.id !== null) {
      this.idSeleccionado = this.id;
      this.ctx.asignacionAlmacenProducto.obtener(this.id).toPromise().then(resultado => {
        this.modelo = resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new AsignacionAlmacenProducto();
      this.cargarFolio();
      this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
        (credencial) => {
          this.cargarProductos();
          this.f['almacen'].setValue(this.autenticacion.credencial.almacenDescripcion);
          this.f['almacenId'].setValue(this.autenticacion.credencial.almacenId);
        }
      );
    }

    // this.cargarAlmacenes();
    this.translate.get("title.productsAndstorehouse").subscribe((res:string)=>{
      this.pages=res;
    })
  }

  cargarFolio(): void {
    var r = this.ctx.asignacionAlmacenProducto.generarFolio().toPromise().then(resultado => {
      this.forma.get('codigo').setValue("AAP-" + resultado);
    }).catch(e => {
      this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }


  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as AsignacionAlmacenProducto;
      if (this.idSeleccionado === 0) {
        this.ctx.asignacionAlmacenProducto.guardar(model).toPromise().then(() => {
          this.translate.get("messages.guardar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get("messages.guardarExiste",{value:this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.asignacionAlmacenProducto.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get('messages.actualizar',{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get('messages.guardarExiste',{value:this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          } else {
            this.translate.get('messages.guardarError').subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get('messages.datosInvalido').subscribe((res:string)=>{
        this.alerta.mostrarAdvertencia(res);
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  cargarProductos() {
    this.ctx.Productos.obtenerPorAlmacen(this.autenticacion.credencial.almacenId).toPromise().then(resultado => {
      this.productoSinFiltrar = resultado;
      this.productoFiltrados = this.productofiltro.valueChanges
        .pipe(
          startWith<string | Productos>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarProducto(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarProducto(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = this.productoSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  productoSeleccionado(modelo: Productos) {
    this.f['producto'].setValue(modelo.descripcion);
    this.f['productoId'].setValue(modelo.id);
  }


  cargarAlmacenes() {
    this.ctx.Almacen.obtenerTodos().toPromise().then(resultado => {
      this.almacenSinFiltrar = resultado;
      this.almacenFiltrados = this.almacenfiltro.valueChanges
        .pipe(
          startWith<string | Almacen>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarAlmacen(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarAlmacen(modelo: string): Almacen[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = this.almacenSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  almacenSeleccionado(modelo: Almacen) {
    this.f['almacen'].setValue(modelo.descripcion);
    this.f['almacenId'].setValue(modelo.id);
  }

  limpiarFiltroProducto(): void {

    this.f['producto'].setValue('');
    this.f['productoId'].setValue(null);
    this.cargarProductos();
  }

  limpiarFiltroAlmacen(): void {
    this.f['almacen'].setValue('');
    this.f['almacenId'].setValue(null);
    this.cargarAlmacenes();
  }


  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }

}

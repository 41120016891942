import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { DashboardVentasGenerales } from 'src/app/shared/models/dashboard-ventas-generales';
import { UnidadesMedida } from 'src/app/shared/models/UnidadesMedida';
import { Grafica } from 'src/app/shared/models/grafica';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'db-ventas-generales',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class DdVentasGenerales implements OnInit, OnDestroy {

  graficaVentaActual: Grafica[] = [];
  graficaVentaAnterior: Grafica[] = [];

  dashboard: DashboardVentasGenerales = new DashboardVentasGenerales();
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
    legend: {
      position: "right",
    }
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  sub: Subscription;

  // barChartData: ChartDataSets[] = [{ data: [0], label: "", hidden: true }];

  public barChartData: ChartDataSets[] = [
    { data: [], type: 'line', label: "Año anterior 2020", borderColor: 'rgb(198,198,198)', backgroundColor: 'rgba(0,0,0,0)' },
    { data: [], type: 'bar', label: 'Año actual 2021', borderColor: 'rgb(198,198,198)', backgroundColor: 'rgba(250,213,56)' },


  ];

  constructor(private ctx: Contexto, public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) { }
  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarVentasGenerales();
      }
    );
  }

  async cargarVentasGenerales() {
    this.dashboard = await this.ctx.dashbord.ventasGenerales(this.autenticacion.credencial.almacenId).toPromise();
    if (this.dashboard) {
      this.graficaVentaActual = this.dashboard.ventasGeneralesActuales;
      this.graficaVentaAnterior = this.dashboard.ventasGeneralesAnterior;
      console.log(this.graficaVentaAnterior);
      console.log(this.graficaVentaActual);

      this.barChartLabels = this.graficaVentaActual.map(p => "Mes: " + p.mes + " - " + p.almacen);
      this.barChartData[0].data = this.graficaVentaAnterior.map(p => p.cantidad);
      this.barChartData[1].data = this.graficaVentaActual.map(p => p.cantidad);



      // this.barChartData.length = 0;


      // for (const ele of this.graficaVentaActual) {
      //   let ventasActuales: ChartDataSets;
      //   ventasActuales = {
      //     data: [ele.cantidad],

      //     type: "line",
      //     lineTension: 0,

      //   }
      //   this.barChartLabels = [ele.almacen + " " + ele.mes];
      //   this.barChartData.push(ventasActuales);
      // }

      // for (const ele of this.graficaVentaActual) {
      //   let ventasAnteriores: ChartDataSets;
      //   ventasAnteriores = {
      //     data: [ele.cantidad],

      //     type: "bar",
      //     yAxisID: 'A'
      //   }
      //   this.barChartLabels = [ele.almacen + " " + ele.mes];
      //   this.barChartData.push(ventasAnteriores);
      // }
      console.log(this.barChartData);
    }
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}



import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { ParametrosPFEP } from '../models/parametros-pfep';

export class RepositorioParametrosPFEP extends Repositorio<ParametrosPFEP>{
  constructor(http: HttpClient) {
    super(http, 'ParametrosPFEP');
  }

}

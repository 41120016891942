import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FormaTipoMenuComponent } from '../forma/forma.component';
import { TipoMenu } from 'src/app/shared/models/tipo-menu';
import * as XLSX from 'xlsx';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ImportacionExcelService } from 'src/app/services/importar-a-excel/importar-excel.service';


@AutoUnsubscribe()
@Component({
  selector: 'catalogo-tipo-menu',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoTipoMenuComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<TipoMenu> = new MatTableDataSource([]);
  columnasMostradas = ['clave', 'descripcion', 'activo', 'opciones'];
  file: File;
  arrayBuffer: any;
  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }
  constructor(public ventana: MatDialog, private router: Router, private spinner: LoadingService, private ctx: Contexto,
    private alerta: ServicioAlerta, private translate: TranslateService, private importacionExcel: ImportacionExcelService) { }

  ngOnInit() {
    this.ctx.tiposMenu.ConneciontHub();
    this.ctx.tiposMenu.cambio.subscribe(() => this.tabla());
    this.tabla();
  }

  tabla(): void {
    this.ctx.tiposMenu.obtenerTodos().toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.spinner.hide();
    })
      .catch(e => {
        this.translate.get("messages.errorInformacion").subscribe((res: string) => {
          this.alerta.mostrarError(res);
        })
      });
  }

  abrirForma(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaTipoMenuComponent, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {

    this.ctx.tiposMenu.activar(id).toPromise().then(() => {
      this.translate.get("messages.activar").subscribe((res: string) => {
        this.alerta.mostrarExito(res);
      })
      this.ctx.tiposMenu.cambio.subscribe(() => this.tabla());
    }).catch(e => {
      console.log(e);
      this.translate.get("messages.activarError").subscribe((res: string) => {
        this.alerta.mostrarError(res);
      })
    });
  }

  desactivar(id: number): void {
    this.ctx.tiposMenu.desactivar(id).toPromise().then(() => {
      this.translate.get("messages.desactivar").subscribe((res: string) => {
        this.alerta.mostrarExito(res);
      })
      this.ctx.tiposMenu.cambio.subscribe(() => this.tabla());
    }).catch(e => {
      console.log(e);
      this.translate.get("messages.desactivarError").subscribe((res: string) => {
        this.alerta.mostrarError(res);
      })
    });
  }


  eliminar(id: number): void {

    this.ctx.tiposMenu.eliminar(id).toPromise().then(() => {
      this.ctx.tiposMenu.cambio.subscribe(() => this.tabla());
      this.translate.get("messages.eliminar").subscribe((res: string) => {
        this.alerta.mostrarExito(res);
      })
    }).catch(e => {
      console.log(e);
      this.translate.get("messages.eliminarError").subscribe((res: string) => {
        this.alerta.mostrarError(res);
      })
    });
  }


  limpiarFiltro(): void {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }



  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "";
    this.translate.get("descargarExcel", { value: fechaActual.toLocaleString() }).subscribe((res: string) => {
      nombreArchivo = res;
    })
    this.ctx.tiposMenu.obtenerExcel().toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  importarExcel($event) {
    this.file = $event.target.files[0];
    let fileReader = new FileReader();

    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      this.importacionExcel.LeerArchivo(this.file, data).then(respuesta => {
        console.log(respuesta);
        if (respuesta) {
          let lista = respuesta as TipoMenu[];
          this.ctx.tiposMenu.guardarExcel(lista).toPromise().then(() => {
            this.translate.get("messages.guardar", { value: "tipo de menu" }).subscribe((res: string) => {
              this.alerta.mostrarExito(res);
            })
            this.tabla();
          }).catch(e => {
            this.translate.get("messages.guardarError").subscribe((res: string) => {
              this.alerta.mostrarError(res);
            })
          });

        } else {

        }
      }).catch(r => {
        console.log(r);
      });
    }

    // fileReader.onload = (e) => {
    //   this.arrayBuffer = fileReader.result;
    //   var data = new Uint8Array(this.arrayBuffer);
    //   var arr = new Array();
    //   for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    //   var bstr = arr.join("");
    //   var workbook = XLSX.read(bstr, { type: "binary" });
    //   var first_sheet_name = workbook.SheetNames[0];
    //   var worksheet = workbook.Sheets[first_sheet_name];
    //   let JsonProductos = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as TipoMenu[];
    //   if (JsonProductos) {
    //     this.ctx.tiposMenu.guardarExcel(JsonProductos).toPromise().then(() => {
    //       this.translate.get("messages.guardar",{value:"tipo de menu"}).subscribe((res:string)=>{
    //         this.alerta.mostrarExito(res);
    //       })
    //       this.tabla();
    //     }).catch(e => {
    //       this.translate.get("messages.guardarError").subscribe((res:string)=>{
    //         this.alerta.mostrarError(res);
    //       })
    //     });
    //   } else {
    //     this.translate.get("messages.guardarError").subscribe((res:string)=>{
    //       this.alerta.mostrarError(res);
    //     })
    //   }
    // }
    fileReader.readAsArrayBuffer(this.file);
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';

import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Usuario } from 'src/app/shared/models/usuario';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FormaRolesUsuarioComponent } from '../../rol-usuario/forma/forma.component';
import { TranslateService } from '@ngx-translate/core';
import { Ventas } from 'src/app/shared/models/Ventas';
import { DetallesVenta } from 'src/app/shared/models/DetallesVenta';
import { DetallesComponent } from '../detalles/detalles.component';

@AutoUnsubscribe()
@Component({
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class VentasCatalogo implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  fuenteDatos: MatTableDataSource<Ventas> = new MatTableDataSource([]);
  columnasMostradas = [
    'folio',
    'fechaRegistro',
    'referencia',
    'almacen',
    'Usuario',
    'opciones',
  ];


  constructor(private ctx: Contexto, private ventanaDetalle: MatDialog) {}
  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  ngOnInit() {
    this.tabla();
  }

  abrirForma(id: number) {
    this.ventanaDetalle.open(DetallesComponent, {
      data: id,
      panelClass: 'form-container',
    });
  }

  tabla() {
    this.ctx.Ventas.obtenerTodos()
      .toPromise()
      .then((res) => {
        this.fuenteDatos = new MatTableDataSource(res);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
  
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Mina } from 'src/app/shared/models/Mina';
import { startWith, map } from 'rxjs/operators';
import { Almacen } from 'src/app/shared/models/Almacen';
import { AsignacionMinaEmpleado } from 'src/app/shared/models/asignacion-mina-empleado';
import { Empleados } from 'src/app/shared/models/Empleados';
import {TranslateService} from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss',],
})
export class FormaAsignacionMinaEmpleado implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: AsignacionMinaEmpleado;



  empleadoFiltrados: Observable<Empleados[]>;
  empleadoSinFiltrar: Empleados[];
  empleadofiltro = new FormControl();

  minaFiltrados: Observable<Mina[]>;
  minaSinFiltrar: Mina[];
  minafiltro = new FormControl();


  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaAsignacionMinaEmpleado>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.asignacionMinaEmpleado.obtener(id).toPromise().then(resultado => {
        this.modelo = resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get("message.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new AsignacionMinaEmpleado();
      this.cargarFolio();
    }
  }
  page="";
  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        codigo: ['', Validators.required],
        empleadoId: [0, Validators.required],
        empleado: ['', Validators.required],
        minaId: [0, Validators.required],
        mina: ['', Validators.required],
        activo: [true, Validators.nullValidator],
      },
    );
    this.cargarEmpleado();
    this.cargarMinas();
    this.translate.get("minesAndEmployee").subscribe((res:string)=>{
      this.page=res;
    })
  }

  cargarFolio(): void {
    var r = this.ctx.asignacionMinaEmpleado.generarFolio().toPromise().then(resultado => {
      this.forma.get('codigo').setValue("AME-" + resultado);
    }).catch(e => {
      this.translate.get("message.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }


  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as AsignacionMinaEmpleado;
      if (this.idSeleccionado === 0) {
        this.ctx.asignacionMinaEmpleado.guardar(model).toPromise().then(() => {
          this.translate.get("messages.guardar",{value: this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get("messages.guardarExiste",{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.asignacionMinaEmpleado.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get("messages.actualizar",{value:this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get("messages.guardarExiste",{value: this.page}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          } else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  cargarEmpleado() {
    this.ctx.Empleados.obtenerTodos().toPromise().then(resultado => {
      this.empleadoSinFiltrar = resultado;
      this.empleadoFiltrados = this.empleadofiltro.valueChanges
        .pipe(
          startWith<string | Empleados>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.nombreCompleto),
          map(t => this.filtrarEmpleado(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarEmpleado(modelo: string): Empleados[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = this.empleadoSinFiltrar.filter(t => t.nombreCompleto.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  empleadoSeleccionado(modelo: Empleados) {
    this.f['empleado'].setValue(modelo.nombreCompleto);
    this.f['empleadoId'].setValue(modelo.id);
  }


  cargarMinas() {
    this.ctx.Minas.obtenerTodos().toPromise().then(resultado => {
      this.minaSinFiltrar = resultado;
      this.minaFiltrados = this.minafiltro.valueChanges
        .pipe(
          startWith<string | Mina>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarMina(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarMina(modelo: string): Mina[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = this.minaSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  almacenSeleccionado(modelo: Almacen) {
    this.f['mina'].setValue(modelo.descripcion);
    this.f['minaId'].setValue(modelo.id);
  }

  limpiarFiltroEmpleado(): void {

    this.f['empleado'].setValue('');
    this.f['empleadoId'].setValue(null);
    this.cargarEmpleado();
  }

  limpiarFiltroMina(): void {
    this.f['mina'].setValue('');
    this.f['minaId'].setValue(null);
    this.cargarMinas();
  }
}

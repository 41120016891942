import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { DetallePaleta } from 'src/app/shared/models/detalle-paleta';

@Component({
  selector: 'documentos-externos-entradas',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class DocumentosExternosEntradas implements OnInit {
  @ViewChild('inputArchivos', { read: ElementRef, static: false }) inputArchivos: ElementRef;
  fuenteDatos: MatTableDataSource<DetallePaleta> = new MatTableDataSource([]);
  columnasMostradas = ['partNumber', 'description', 'quantity', 'etdDate', 'eastTime', 'consignee'];
  detallePaleta: DetallePaleta[] = [];
  constructor(public ventana: MatDialog,
    private ctx: Contexto,
    private spinner: LoadingService,
    private alerta: ServicioAlerta,
    private translate: TranslateService) { }

  ngOnInit() {

  }
  async archivos() {
    try {
      if (this.detallePaleta.length === 0) {
        let datos = new FormData();
        let xml = (<HTMLInputElement>this.inputArchivos.nativeElement).files[0];
        datos.append("xml", xml);

        let detalles = await this.ctx.documentosExternos.leerXML(datos).toPromise();
        if (detalles) {
          this.detallePaleta = detalles;
          this.fuenteDatos = new MatTableDataSource(detalles);
        } else {
          this.alerta.mostrarAdvertencia('No fue posible leer el XML intente nuevamente.');
        }
      } else {
        this.alerta.mostrarAdvertencia('Hay una lista de productos pendiente por guardar.');
      }
    } catch (error) {
      console.log(error);
      this.alerta.mostrarError('Error interno, contacte con el administrador.');
    }
  }

  limpiar() {
    this.detallePaleta.length = 0;
    this.fuenteDatos = new MatTableDataSource(null);
  }

  async guardarEntrada() {
    try {
      if (this.detallePaleta.length > 0) {
        let guardaEntrada = await this.ctx.documentosExternos.guardarEntradaDetalle(this.detallePaleta).toPromise();
        if (guardaEntrada) {
          this.alerta.mostrarExito('Documento entrada guardado con éxito.');
          this.limpiar();
        } else {
          this.alerta.mostrarAdvertencia('Error al guardar el documento.');
        }
      } else {
        this.alerta.mostrarAdvertencia('No hay detalles por guardar.');
      }
    } catch (error) {
      this.alerta.mostrarError('Error interno, contacte con el administrador');
    }
  }
}
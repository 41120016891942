import {Component,OnInit,ViewChild} from '@angular/core'
import { FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { InventarioPeriodo } from 'src/app/shared/models/InventarioPeriodo';
import { MatTableDataSource, MatPaginator,MatSort } from '@angular/material';

@Component({
  selector: "Inventario",
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoInventario implements OnInit{

  @ViewChild(MatPaginator,{static:false}) paginador:MatPaginator;
  @ViewChild(MatSort,{static:false}) ordenador:MatSort;

  tiempo=new FormControl();
  tipoBusqueda=new FormControl();


  displayedColumns: string[] = ['codigo', 'codigoBarra', 'descripcion'];

  tiempoInventario=[
    {id:1,descripcion:"1 mes"},
    {id:2,descripcion:"3 meses"},
    {id:3,descripcion:"6 meses"}
  ];
  inventario:MatTableDataSource<InventarioPeriodo>=new MatTableDataSource([]);

  forma:FormGroup;
  get f(){return this.forma.controls}

  constructor(private ctx:Contexto,private formBuilder:FormBuilder){}

  ngOnInit(){
    this.forma=this.formBuilder.group({
      seleccionInventario:['',Validators.required],
      seleccionInventarioId:['',Validators.required]
    });
  }

  Buscar(){
    console.log(this.forma.value);
    this.ctx.Inidicadores.obtenerPeriodosInventario(this.forma.get('seleccionInventario').value).subscribe(resultado=>{
      this.inventario=new MatTableDataSource(resultado),
      this.inventario.paginator=this.paginador
      console.log(this.inventario)
    })
  }

  limpiarTiempo(){
    this.tiempo;
  }
  tiempoSeleccionado(){
  }

  limpiarPeridoBusqueda() {
  }

  periodoBusquedaSeleccionado(){

  }




}


import {Component,OnInit, Inject} from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { VentasProductos } from 'src/app/shared/models/VentasProductos';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@Component({
  templateUrl:'./detalles.component.html',
  styleUrls:['./detalles.component.scss']
})

export class DetallesComponent implements OnInit{

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<DetallesComponent>,
  private formBuilder: FormBuilder,private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService){
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.Ventas.obtenerVentas(id).toPromise().then(resultado => {
        this.fuenteDatos = new MatTableDataSource(resultado);
      }).catch(e => {
        console.log(e);
        this.translate.get("message.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarExito(res);
        })
      });
    }

  }

  fuenteDatos: MatTableDataSource<VentasProductos> = new MatTableDataSource([]);
  columnasMostradas = ['folio', 'referencia', 'almacen','descripcionProductoPrincipal' ,'producto','cantidad','precio','precioUSD'];
  idSeleccionado:number;

  ngOnInit(){

  }

  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "";
    this.translate.get("messages.descargarExcel",{value: fechaActual.toLocaleString()}).subscribe((res:string)=>{
      nombreArchivo=res;
    })
    this.ctx.Ventas.obtenerExcel(this.idSeleccionado).toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

}

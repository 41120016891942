import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { Almacen } from '../models/Almacen';
import { AsignacionAlmacenReabastecimiento } from '../models/AsignacionAlmacenReabastecimiento';
import { Usuario } from '../models/usuario';
import { AsignacionAlmacenUsuario } from '../models/asignacion-almacen-usuario';

export class RepositorioAlmacen extends Repositorio<Almacen> {
  constructor(http: HttpClient) {
    super(http, 'Almacenes');
  }

  generarFolio(): Observable<string> {
    let ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerPorUbicacion(ubicacionId): Observable<Almacen[]> {
    const ruta = `${this.ruta}/ObtenerPorUbicacion/${ubicacionId}`;
    return this.http.get<Almacen[]>(ruta);
  }

  obtenerPorAlmacen(almacenId): Observable<Almacen[]> {
    const ruta = `${this.ruta}/ObtenerPorAlmacen/${almacenId}`;
    return this.http.get<Almacen[]>(ruta);
  }

  obtenerReabastecimiento(): Observable<Almacen[]> {
    const ruta = `${this.ruta}/almacenReabesticimiento`;
    return this.http.get<Almacen[]>(ruta);
  }

  obtenerLocales(): Observable<Almacen[]> {
    const ruta = `${this.ruta}/almacenesLocales`;
    return this.http.get<Almacen[]>(ruta);
  }

  reabastecimientoLocal(id: number): Observable<Almacen[]> {
    const ruta = `${this.ruta}/ReabasteciminetoDeLocales/${id}`;
    return this.http.get<Almacen[]>(ruta);
  }

  ingresarReabastecimiento(model: AsignacionAlmacenReabastecimiento) {
    const ruta = `${this.ruta}/ingresarReabastecimiento`;
    return this.http.post(ruta, model);
  }

  obtenerAlmacenesUsuarios(almacenId: number): Observable<Usuario[]> {
    const ruta = `${this.ruta}/ObtenerAlmacenesUsuarios/${almacenId}`;
    return this.http.get<Usuario[]>(ruta);
  }

  asignar(
    asignacion: AsignacionAlmacenUsuario
  ): Observable<AsignacionAlmacenUsuario> {
    const ruta = `${this.ruta}/Asignar`;
    return this.http
      .post(ruta, asignacion)
      .pipe(map((asig: AsignacionAlmacenUsuario) => asig));
  }

  desasignar(asignacion: AsignacionAlmacenUsuario): Observable<void> {
    const ruta = `${this.ruta}/Desasignar/${asignacion.almacenId}/${asignacion.usuarioId}`;
    return this.http.delete(ruta).pipe(map(() => null));
  }

  listaAlmacenes(id: number): Observable<Almacen[]> {
    const ruta = `${this.ruta}/AlmacenesListas/${id}`;
    return this.http.get<Almacen[]>(ruta);
  }

  //Metodo para gestionar lista de precios almancenes en la ventana de precios
  agregarLista(id: number, model: Almacen): Observable<void> {
    const ruta = `${this.ruta}/AgregarListaAlmacen/${id}`;
    return this.http.put<void>(ruta, model);
  }

  //Metodo para lista de precios en la ventana de almacenes
  listaAlmacenesPrecios(id: number, listaId: number): Observable<void> {
    const ruta = `${this.ruta}/AgregarAlmacenListas/${id}`;
    return this.http.put<void>(ruta, listaId);
  }
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Usuario } from 'src/app/shared/models/usuario';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FormaPantallaComponent } from '../forma/forma.component';
import { Pantallas } from 'src/app/shared/models/pantallas';


@AutoUnsubscribe()
@Component({
  selector: 'catalogo-pantallas',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoPantallaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<Pantallas> = new MatTableDataSource([]);
  columnasMostradas = ['clave', 'descripcion', 'icono', 'tipoMenuDescripcion', 'activo', 'opciones'];

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.tabla();
  }

  tabla(): void {
    this.ctx.pantallas.obtenerTodos().toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al cargar las pantallas');
      });
  }

  abrirForma(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaPantallaComponent, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  cambiarEstadoOperacion(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {

    this.ctx.pantallas.activar(id).toPromise().then(() => {
      this.alerta.mostrarExito('Registro activado');
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al activar');
      });

  }

  desactivar(id: number): void {
    this.ctx.pantallas.desactivar(id).toPromise().then(() => {
      this.alerta.mostrarExito('Registro inactivo');
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al inactivar');
      });
  }


  eliminar(id: number): void {
    this.ctx.pantallas.eliminar(id).toPromise().then(() => {
      this.alerta.mostrarExito('Registro eliminado');
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al eliminar');
      });
  }
 

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }


  descargarExcel(): void{
   
  }
}
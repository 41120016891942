import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Almacen } from 'src/app/shared/models/Almacen';
import { FormaFechaConteoMensual } from '../forma/forma.component';
import {TranslateService} from '@ngx-translate/core';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { RestriccionFecha } from 'src/app/shared/models/restriccionFecha';


@Component({
  selector: 'FechaConteoMensual',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoFechaConteoMensual implements OnInit {

  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService, public autenticacion: AutenticacionService ) { }
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  

  fuenteDatos: MatTableDataSource<RestriccionFecha> = new MatTableDataSource([]);
  columnasMostradas = ['almacenDescripcion', 'operacionDescripcion', 'dia', 'opciones'];

  ngOnInit() {
    this.tabla();
  }

  tabla(): void {
    this.ctx.FechaOperacion.ObtenerFechaRestriccionAlmacen(this.autenticacion.credencial.almacenId)
    .toPromise()
    .then((resultado) => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }

  abrirForma(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaFechaConteoMensual, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

  // abrirFormaLista(id?: string): void {
  //   let dato = id ? id : null;
  //   let forma = this.ventana.open(agregarListaComponent, { data: dato, panelClass: 'form-container' });
  //   forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  // }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {

    this.ctx.Almacen.activar(id).toPromise().then(() => {
      this.translate.get('messages.activar').subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get('messages.activarError').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });

  }

  desactivar(id: number): void {
    this.ctx.Almacen.desactivar(id).toPromise().then(() => {
      this.translate.get('messages.desactivar').subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get('messages.desactivarError').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }


  eliminar(id: number): void {
    this.ctx.Almacen.eliminar(id).toPromise().then(() => {
      this.translate.get('messages.eliminar').subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get('messages.eliminarError').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }


  limpiarFiltro(): void {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }

  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "";
    this.translate.get("messages.descargarExcel",{value: fechaActual.toLocaleString()}).subscribe((res:string)=>{
      nombreArchivo=res;
    })
    this.ctx.Almacen.obtenerExcel().toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

}

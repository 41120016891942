import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Productos } from 'src/app/shared/models/Productos';
import { FormaEmpresasProductos } from '../forma/empresas-productos.form';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, debounceTime, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'app-forma-empresa-producto',
  templateUrl: './forma-producto.component.html',
  styleUrls: ['./forma-producto.component.scss'],
})
export class FormaProductoComponent implements OnInit {
  @Input() forma: FormaEmpresasProductos;
  @Input() listaProductos: Productos[];
  @Input() productosSinAsignar: Productos[];
  @Output() productoSeleccionado = new EventEmitter<Productos>();
  formaProducto = new FormControl(null, Validators.required);
  productosAuto: Productos[];
  subs: Subscription;
  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.sustituirFuncionTocadoProducto();

    this.subs = this.formaProducto.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        map((producto) => {
          if (producto.id) {
            // Si tiene id, se asume que es de tipo "Producto" y se procede a hacer la seleccion en la forma.
            this.seleccionarProducto(producto);
          }
          return typeof producto === 'string'
            ? producto
            : producto == null
            ? ''
            : producto.codigo;
        })
      )
      .subscribe((dato) => this.filtrar(dato));

    const productoId = this.forma.get('productoId').value;
    if (productoId) {
      const producto = this.listaProductos.find((p) => p.id === productoId);
      this.formaProducto.setValue(producto);
    }
  }

  filtrar(dato: string) {
    const valorFiltro = dato ? dato.toLowerCase() : '';
    const filtro = this.productosSinAsignar.filter(
      (t) =>
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    this.productosAuto = filtro;
  }

  seleccionarProducto(producto: Productos) {
    this.forma.get('productoId').setValue(producto.id);
    this.productoSeleccionado.emit(producto);
  }

  limpiarAutoCompletar() {
    this.productosAuto = [];
  }

  campoMostrar(producto: Productos) {
    let mostrar = '';
    if (producto) {
      mostrar = `${producto.codigo} - ${producto.descripcion}`;
    }
    return mostrar;
  }

  sustituirFuncionTocadoProducto(): void {
    const funcionOriginal = this.forma.get('productoId').markAsTouched;
    this.forma.get('productoId').markAsTouched = (opts: { onlySelf: true }) => {
      const funcionEnlazada = funcionOriginal.bind(
        this.forma.get('productoId')
      );
      funcionEnlazada(opts);
      this.formaProducto.markAsTouched(opts);
    };
  }
}

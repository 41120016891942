import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { RestriccionFecha } from '../models/restriccionFecha';

export class RepositorioFechaOperacion extends Repositorio<RestriccionFecha> {
  constructor(http: HttpClient) {
    super(http, 'RestriccionFecha');
  }

  ObtenerFechaRestriccionAlmacen(almacenId: number): Observable<RestriccionFecha[]> {
    const ruta = `${this.ruta}/ObtenerFechaRestriccionAlmacen/${almacenId}`;
    return this.http.get<RestriccionFecha[]>(ruta);
  }
}

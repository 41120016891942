import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Repo } from './repo';

export abstract class Repositorio<T> extends Repo {
  constructor(http: HttpClient, controlador: string) {
    super(http, controlador);
  }

  obtener(id: number): Observable<T> {
    return this.http.get(`${this.ruta}/${id}`).pipe(
      map((data: T) => {
        return data;
      })
    );
  }

  obtenerTodos(): Observable<T[]> {
    console.log(this.ruta);
    return this.http.get(this.ruta).pipe(
      map((data: T[]) => {
        return data;
      })
    );
  }

  guardar(entidad: T): Observable<T> {
    return this.http.post(this.ruta, entidad).pipe(
      map((data: T) => {
        return data;
      })
    );
  }

  actualizar(id: number, entidad: T): Observable<T> {
    const ruta = this.ruta + '/' + id;
    return this.http.put(ruta, entidad).pipe(
      map((data: T) => {
        return data;
      })
    );
  }

  eliminar(id: number): Observable<boolean> {
    const ruta = this.ruta + '/' + id;
    return this.http.delete(ruta).pipe(
      map((data: T) => {
        return true;
      })
    );
  }
}

import { Injectable } from '@angular/core';
import {CanDeactivate} from '@angular/router';
import { Observable } from 'rxjs';
export interface PuedeDesactivar{
  permitirSalirDeRuta:()=>Observable<boolean>|Promise<boolean>|boolean;
}
//guardian para preguntar si desea salir de un formulario sin guardar los cambios
@Injectable({
  providedIn: 'root'
})



export class CanDeactiveGuard implements CanDeactivate<PuedeDesactivar>{

  canDeactivate(component:PuedeDesactivar){
    return component.permitirSalirDeRuta?component.permitirSalirDeRuta():true;
  }

}

import { Injectable } from '@angular/core';
import { ThemeState } from './theme.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';
import { Theme } from './type';
import { Select } from '@ngxs/store';
import { SetSelectedTheme } from './theme.actions';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  @Select(ThemeState)
  selectedTheme$: Observable<Theme>;

  @Dispatch()
  setSelectedTheme(theme: Theme) {
    return new SetSelectedTheme(theme);
  }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Mina } from 'src/app/shared/models/Mina';
import { startWith, map } from 'rxjs/operators';
import { EmpresaMinera } from 'src/app/shared/models/EmpresaMinera';
import {TranslateService} from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss',],
})
export class FormaMina implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: Mina;


  minaFiltrados: Observable<EmpresaMinera[]>;
  minaSinFiltrar: EmpresaMinera[];
  filtroMina = new FormControl();


  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaMina>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.Minas.obtener(id).toPromise().then(resultado => {
        this.modelo = resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get("message.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new Mina();
      this.cargarFolio();
    }
  }
  pages="";
  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        codigo: ['', Validators.required],
        descripcion: ['', Validators.required],
        activo: [true, Validators.nullValidator],
        descripcionEmpresaMinera: ['', Validators.required],
        empresaMineraId: [0, Validators.nullValidator],
      },
    );
    this.cargarEmpresasMinas();
    this.translate.get("title.mines").subscribe((res:string)=>{
      this.pages=res;
    })
  }

  cargarFolio(): void {
    var r = this.ctx.Minas.generarFolio().toPromise().then(resultado => {
      this.forma.get('codigo').setValue("EMP-" + resultado);
    }).catch(e => {
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }
  limpiar(): void {
    this.forma.reset();
  }

  cargarEmpresasMinas() {
    this.ctx.EmpresaMinera.obtenerTodos().toPromise().then(resultado => {
      this.minaSinFiltrar = resultado;
      this.minaFiltrados = this.filtroMina.valueChanges
        .pipe(
          startWith<string | EmpresaMinera>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarRol(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarRol(mina: string): EmpresaMinera[] {
    const valorFiltro = mina.toLowerCase();
    let filtro = this.minaSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  minaSeleccionado(mina: Mina) {
    this.f['descripcionEmpresaMinera'].setValue(mina.descripcion);
    this.f['empresaMineraId'].setValue(mina.id);
  }

  guardar(): void {
    if (this.forma.valid) {


      const model = this.forma.value as Mina;
      if (this.idSeleccionado === 0) {
        this.ctx.Minas.guardar(model).toPromise().then(() => {
          this.translate.get("messages.guardar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {this.translate.get("message.guardarExiste",{value: "mina"}).subscribe((res:string)=>{
            this.alerta.mostrarAdvertencia(res);
          })
          } else if (e.error === 'ErrorGuardar') {
            this.translate.get("messages.guardarExiste",{value: this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.Minas.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get("messages.actualizar",{value:this.pages}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.translate.get("messages.guardarExiste",{value:this.pages}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          } else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  limpiarFiltro(): void {

    this.f['descripcionEmpresaMinera'].setValue('');
    this.f['empresaMineraId'].setValue(null);
    this.cargarEmpresasMinas();
  }
}

import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { EmpresaMinera } from 'src/app/shared/models/EmpresaMinera';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaEmpresaMinera implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }
  modelo: EmpresaMinera;

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaEmpresaMinera>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private transalte: TranslateService
  ) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.EmpresaMinera.obtener(id)
        .toPromise()
        .then((resultado) => {
          this.modelo = resultado;
          Object.assign(this.forma.value, this.modelo);
          this.forma.reset(this.forma.value);
        })
        .catch((e) => {
          console.log(e);
          this.transalte
            .get('messages.errorInformacion')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
    } else {
      this.idSeleccionado = 0;
      this.modelo = new EmpresaMinera();
      this.cargarFolio();
    }
  }
  pages = '';
  ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.nullValidator],
      codigo: ['', Validators.required],
      descripcion: ['', Validators.required],
      activo: [true, Validators.nullValidator],
    });
    this.transalte.get('title.miningCompanies').subscribe((res: string) => {
      this.pages = res;
    });
  }

  cargarFolio(): void {
    let r = this.ctx.EmpresaMinera.generarFolio()
      .toPromise()
      .then((resultado) => {
        this.forma.get('codigo').setValue('GPO-' + resultado);
      })
      .catch((e) => {
        this.transalte
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as EmpresaMinera;
      if (this.idSeleccionado === 0) {
        this.ctx.EmpresaMinera.guardar(model)
          .toPromise()
          .then(() => {
            this.transalte
              .get('messages.guardar', { value: this.pages })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            console.log(e);
            if (e === 'Unprocessable Entity') {
              this.transalte
                .get('guardarExiste', { valie: this.pages })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.transalte.get('guardarError').subscribe((res: string) => {
                this.alerta.mostrarError(res);
              });
            }
          });
      } else {
        this.ctx.EmpresaMinera.actualizar(this.idSeleccionado, model)
          .toPromise()
          .then(() => {
            this.transalte
              .get('messages.actualizar', { value: this.pages })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            console.log(e);
            if (e === 'Unprocessable Entity') {
              this.transalte
                .get('message.guardarExiste', { value: 'empresa minera' })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.transalte
                .get('message.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      }
    } else {
      this.transalte.get('datosInvalidos').subscribe((res: string) => {
        this.alerta.mostrarAdvertencia(res);
      });
    }
  }

  cerrar(): void {
    this.ventana.close();
  }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cierre } from '../models/cierres';
import { DetalleCierres } from '../models/detalle-cierres';
import { ExcelDetalleRequisicion } from '../models/excel-detalle-requisicion.';
import { Respuesta } from '../models/respuesta';
import { ExcelDetalleCierre } from '../models/excel-detalle-cierre';

export class RepositorioCierre extends Repositorio<Cierre> {
  constructor(http: HttpClient) {
    super(http, 'Cierre');
  }

  obtenerExcel(id: number): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, id, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerCierresAlmacen(almacenId: number): Observable<Cierre[]> {
    const ruta = `${this.ruta}/ObtenerCierresAlmacen/${almacenId}`;
    return this.http.get<Cierre[]>(ruta);
  }


  ObtenerDetalles(id: number): Observable<DetalleCierres[]> {
    const ruta = `${this.ruta}/ObtenerDetalleCierres/${id}`;
    return this.http.get<DetalleCierres[]>(ruta);
  }

  obtenerCierreDetalle(id: number): Observable<Cierre> {
    const ruta = `${this.ruta}/ObtenerCierreDetalle/${id}`;
    return this.http.get<Cierre>(ruta);
  }

  obtenerComprobanteCierre(id: number): Observable<Cierre> {
    const ruta = `${this.ruta}/ObtenerComprobanteCierre/${id}`;
    return this.http.get<Cierre>(ruta);
  }


  cargarDetalleCierresExcel(items: ExcelDetalleCierre[]): Observable<ExcelDetalleCierre[]> {

    let ruta = `${this.ruta}/CargarDetalleCierreExcel`;
    return this.http.post(ruta, items).pipe(
      map((articulos: ExcelDetalleCierre[]) => {
        return articulos;
      })
    );
  }

}

import { Component } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  language="";
  constructor(public transalte:TranslateService){
    this.transalte.addLangs(['es','en']);
    if(window.localStorage.getItem("language")==null){
      this.transalte.setDefaultLang('es');
    }else{
      this.transalte.use(window.localStorage.getItem("language"));
    }
  }

  cambiarIdioma(idioma:string){
    this.language=idioma;
    this.transalte.use(idioma);
  }
}

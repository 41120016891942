import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Usuario } from 'src/app/shared/models/usuario';
import { TipoUsuario } from 'src/app/shared/models/tipo-usuario';
import { startWith, map } from 'rxjs/operators';
import { TranslateService} from '@ngx-translate/core';


@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaUsuarioComponent implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  usuario: Usuario;
  actualizar: boolean;
  chkActualiza: boolean;

  tipoUsuarios: TipoUsuario[] = [];
  tipoUsuariosFiltrados: Observable<TipoUsuario[]>;
  tipoUsuariosSinFiltrar: TipoUsuario[];
  filtroTipoUsuario = new FormControl();



  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaUsuarioComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.usuario.obtenerUsuario(id).toPromise().then(resultado => {
        this.usuario = resultado;
        Object.assign(this.forma.value, this.usuario);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get("message.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarExito(res);
        })
      });
      this.actualizar = false;
      this.chkActualiza = true;
    }
    else {
      this.idSeleccionado = 0;
      this.usuario = new Usuario();
      this.chkActualiza = false;
      this.actualizar = true;
    }

  }
  texto:string;
  page="";
  ngOnInit() {
    this.translate.get("profiles").subscribe((res:string)=>{
      this.page=res;

    })

    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        nombre: ['', Validators.required],
        apellidoPaterno: ['', Validators.required],
        apellidoMaterno: [''],
        email: ['', Validators.required],
        telefono: ['', Validators.required],
        contrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/), Validators.minLength(6)]],
        confirmacionContrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/), Validators.minLength(6)]],
        actualizoContra: [false, Validators.nullValidator],
        tipoUsuarioId: [0, Validators.required],
        tipoUsuarioDescripcion: ['', Validators.required],
        // rolId: ['', Validators.nullValidator],
        // rolDescripcion: ['', Validators.nullValidator],
      },
    );
    this.cargarTipoUsuarios();
    this.texto=this.f.telefono.value;

  }

  validarTelefono(event){
    console.log(  event.keyCode);
    if(this.texto.length==3){
      this.texto="("+this.texto+")";
    }
  }

  cargarTipoUsuarios() {
    this.ctx.tipoUsuario.obtenerTodos().toPromise().then(resultado => {
      this.tipoUsuariosSinFiltrar = resultado;
      this.tipoUsuariosFiltrados = this.filtroTipoUsuario.valueChanges
        .pipe(
          startWith<string | TipoUsuario>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarTipoUsuario(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarTipoUsuario(nombre: string): TipoUsuario[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.tipoUsuariosSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  tipoUsuarioSeleccionado(tipoUsuario: TipoUsuario) {
    console.log(tipoUsuario.id);
    this.f['tipoUsuarioDescripcion'].setValue(tipoUsuario.descripcion);
    this.f['tipoUsuarioId'].setValue(tipoUsuario.id);
  }

  limpiar(): void {
    this.forma.reset();
  }

  actualizarContrasena(valor): void {
    this.actualizar = valor.checked;
    if (this.actualizar) {
      this.f['actualizoContra'].setValue(this.actualizar);
      this.f['contrasena'].setValidators([Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/), Validators.minLength(6)]);
      this.f['confirmacionContrasena'].setValidators([Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/), Validators.minLength(6)]);

    } else {
      this.f['actualizoContra'].setValue(this.actualizar);
      this.f['contrasena'].setValidators([Validators.nullValidator]);
      this.f['confirmacionContrasena'].setValidators([Validators.nullValidator]);
    }
  }

  guardar(): void {
    if (this.actualizar === false) {
      this.f['contrasena'].clearValidators();
      this.f['confirmacionContrasena'].clearValidators();
      for (const key in this.forma.controls) {
        this.forma.get(key).updateValueAndValidity();
      }
    }
    if (this.forma.valid) {

      const usuario = this.forma.value as Usuario;

      if (this.idSeleccionado === 0) {
        this.ctx.usuario.guardar(usuario).toPromise().then(() => {
          this.translate.get("messages.guardar",{value:this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.translate.get("messages.actualizarError",{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
            this.translate.get("messages.emailExiste",{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else if (e.error === 'ErrorGuardar') {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        this.ctx.usuario.actualizarUsuario(usuario).toPromise().then(() => {
          this.translate.get("messages.actualizar",{value:this.page}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.translate.get("messages.guardarExiste",{value:this.page}).subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          } else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }


  verContrasena(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  verContrasenaConfirmacion(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  cerrar(): void {
    this.ventana.close();
  }


}

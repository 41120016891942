import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { startWith, map, tap } from 'rxjs/operators';
import { Productos } from 'src/app/shared/models/Productos';
import { AsignacionEmpresaProducto } from 'src/app/shared/models/asignacion-empresa-producto';
import { TranslateService } from '@ngx-translate/core';
import { EmpresaMinera } from 'src/app/shared/models/EmpresaMinera';
import { FormaEmpresasProductos } from './empresas-productos.form';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})

//nada
export class FormaAsignacionEmpresaProductoComponent implements OnInit {
  forma: FormaEmpresasProductos;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }

  opcionEmpresa: EmpresaMinera[];
  agregarEmpresa: number[] = [];

  productoFiltrados: Observable<Productos[]>;
  productoSinFiltrar: Productos[];
  productofiltro = new FormControl();

  empresaFiltrados: Observable<EmpresaMinera[]>;
  empresaSinFiltrar: EmpresaMinera[];
  empresafiltro = new FormControl();
  pages = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dato: { productoId: number; empresaId: number },
    private ventana: MatDialogRef<FormaAsignacionEmpresaProductoComponent>,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    public translate: TranslateService
  ) {
    if (dato.productoId !== null) {
      this.ctx.asignacionEmpresaProducto
        .obtenerAsignacion(dato.productoId, dato.empresaId)
        .toPromise()
        .then((resultado) => {
          this.forma = new FormaEmpresasProductos(resultado);
        })
        .catch((e) => {
          console.log(e);
          this.translate
            .get('messages.errorInformacion')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
    }
  }

  ngOnInit() {
    this.forma = new FormaEmpresasProductos(undefined);
    this.cargarProductos();
    this.cargarEmpresas();
    this.translate.get('title.mineProduct').subscribe((res: string) => {
      this.pages = res;
    });
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    this.forma.markAllAsTouched();
    if (this.forma.valid) {
      const model = this.forma.value as AsignacionEmpresaProducto;
      if (!model.asignacionProductoEmpresaId) {
        this.ctx.asignacionEmpresaProducto
          .guardar(model)
          .toPromise()
          .then(() => {
            this.translate
              .get('messages.guardar', { value: this.pages })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            console.log(e);
            if (e === 'Unprocessable Entity') {
              this.translate
                .get('messages.guardarExiste', { value: this.pages })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.translate
                .get('messages.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      } else {
        this.ctx.asignacionEmpresaProducto
          .actualizar(model.asignacionProductoEmpresaId, model)
          .toPromise()
          .then(() => {
            this.translate
              .get('messages.actualizar', { value: this.pages })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            console.log(e);
            if (e === 'Unprocessable Entity') {
              this.translate
                .get('messages.guardarExiste', { value: this.pages })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.translate
                .get('messages.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      }
    } else {
      this.translate.get('messages.datosInvalido').subscribe((res: string) => {
        this.alerta.mostrarAdvertencia(res);
      });
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  cargarProductos() {
    this.ctx.Productos.obtenerProductos()
      .toPromise()
      .then((resultado) => {
        this.productoSinFiltrar = resultado;
        this.productoFiltrados = this.productofiltro.valueChanges.pipe(
          startWith<string | Productos>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarProducto(t))
        );

        const id = this.forma.asignacion.productoId;
        if (id) {
          const producto = resultado.find((p) => p.id === id);
          this.productofiltro.setValue(producto);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarProducto(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.productoSinFiltrar.filter(
      (t) =>
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  productoSeleccionado(modelo: Productos) {
    this.f['productoId'].setValue(modelo.id);
  }

  cargarEmpresas() {
    this.ctx.EmpresaMinera.obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.empresaSinFiltrar = resultado;
        this.empresaFiltrados = this.empresafiltro.valueChanges.pipe(
          startWith<string | EmpresaMinera>(),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarEmpresa(t))
        );
        const id = this.forma.asignacion.empresaMineraId;
        if (id) {
          const empresa = resultado.find((p) => p.id === id);
          this.empresafiltro.setValue(empresa);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  mostrarEmpresa(empresa: EmpresaMinera) {
    let texto = '';
    if (empresa) {
      texto = empresa.descripcion;
    }
    return texto;
  }

  mostrarProducto(producto: Productos) {
    let texto = '';
    if (producto) {
      texto = `${producto.codigo} ${producto.descripcion}`;
    }
    return texto;
  }

  private filtrarEmpresa(modelo: string): EmpresaMinera[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.empresaSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  empresaSeleccionado(modelo: EmpresaMinera) {
    this.f['empresaMineraId'].setValue(modelo.id);
  }

  limpiarFiltroProducto(): void {
    this.f['productoId'].setValue(null);
    this.cargarProductos();
  }

  limpiarFiltroEmpresa(): void {
    this.f['empresaId'].setValue(null);
    this.cargarEmpresas();
  }
}

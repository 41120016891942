import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Plataformas } from '../models/plataformas';

export class RepositorioControlPlataformas extends Repositorio<Plataformas> {
  constructor(http: HttpClient) {
    super(http, 'ControlPlataformas');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerPlataformasAlmacen(almacenId: number): Observable<Plataformas[]> {
    const ruta = `${this.ruta}/ObtenerPlataformasAlmacen/${almacenId}`;
    return this.http.get<Plataformas[]>(ruta);
  }

} 

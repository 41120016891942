import { Component, OnInit, EventEmitter } from "@angular/core";
import { MatTable, MatTableDataSource } from "@angular/material";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { ServicioAlerta } from "src/app/utilerias/alerta.service";
import { Contexto } from "src/app/shared/api/contexto.service";
import { AutoUnsubscribe } from "src/app/utilerias/autounsubscribe";
import { Productos } from "src/app/shared/models/Productos";
import { UnidadesMedida } from "src/app/shared/models/UnidadesMedida";
import { GrupoProductos } from "src/app/shared/models/Grupo-Producto";
import { startWith, map } from "rxjs/operators";
import { ImagenesProductos } from "src/app/shared/models/imagenes-productos";
import { Almacen } from "src/app/shared/models/Almacen";
import { AsignacionAlmacenProducto } from "src/app/shared/models/asignacion-almacen-producto";
import { TranslateService } from "@ngx-translate/core";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute,Router } from "@angular/router";
import {ValidarReorden,maximoMinimo,minimoMaximo} from '../validators'
@AutoUnsubscribe()
@Component({
  templateUrl: "./forma.component.html",
  styleUrls: ["./forma.component.scss"],
})
export class FormaProductos implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }
  modelo: Productos;

  modelProductoImagenes: ImagenesProductos[] = [];
  nuevosalmacenes: Almacen[] = [];
  almacenes: Almacen[] = [];
  almacenesProducto: AsignacionAlmacenProducto[] = [];

  unidadMedidaFiltrados: Observable<UnidadesMedida[]>;
  unidadMedidaSinFiltrar: UnidadesMedida[];
  unidadMedidafiltro = new FormControl();

  grupoProductoFiltrados: Observable<GrupoProductos[]>;
  grupoProductoSinFiltrar: GrupoProductos[];
  grupoProductofiltro = new FormControl();

  mostrarColumnas: string[] = ["mina", "ubicacionMina", "almacen", "select"];
  seleccion = new SelectionModel<Almacen>(true, []);
  dataSource: MatTableDataSource<Almacen> = new MatTableDataSource([]);
  id: number;
  almacenSeleccionado: Almacen[] = [];

  constructor(private formBuilder: FormBuilder,private ctx: Contexto,private alerta: ServicioAlerta, private translate: TranslateService,
    public activateRoute: ActivatedRoute,public router:Router){
    this.activateRoute.params.subscribe((params) => {
      this.id = +params["id"];
    });

    if (this.id > 0) {
      this.idSeleccionado = this.id;
      this.ctx.Productos.obtener(this.id)
        .toPromise()
        .then((resultado) => {
          this.modelo = resultado;
          Object.assign(this.forma.value, this.modelo);
          this.forma.reset(this.forma.value);
          this.ctx.Productos.obtenerAlmacenes(this.id).toPromise().then((resultado) => {
              this.dataSource.data.forEach((element) => {resultado.forEach((element2) => {
                  if (element.id == element2.almacenId) {
                    this.seleccion.select(element);
                    this.almacenSeleccionado.push(element);
                  }
                });
              });
            });
        })
        .catch((e) => {
          console.log(e);
          this.translate
            .get("message.eliminarError")
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
    } else {
      this.idSeleccionado = 0;
      this.modelo = new Productos();
      this.cargarFolio();
    }
  }

  page = "";
  crearForma(){
    this.forma = this.formBuilder.group({
      id: [0, Validators.required],
      codigo: ["", Validators.required],
      codigoBarra: ["", Validators.required],
      descripcion: ["", Validators.required],
      grupoProductoId: [0, Validators.required],
      grupoProductoDescripcion: ["", Validators.required],
      unidadMedidaId: [0, Validators.required],
      unidadMedidaDescripcion: ["", Validators.required],
      activo: [true, Validators.required],
      stockMinimo: [0,[Validators.required]],
      stockMaximo: [0,[Validators.required]],
      reorden: [0,[Validators.required]],
      precioUSD: [0,[Validators.required]],
      costo: [0,[Validators.required]],
      precio: [0,[Validators.required]],
      material:[''],
      sku:[''],
      almacenes: []
    },{validators:[ValidarReorden,maximoMinimo,minimoMaximo]});
    console.log("forma creada")
  }

  async ngOnInit() {

    this.crearForma();
    this.almacenesLocales();
    // await this.cargarProductosEnAlmacen();
    await this.cargarAlmacenes();
    this.cargarGruposProductos();
    this.cargarUnidadesMedida();
    this.translate.get("products").subscribe((res: string) => {
      this.page = res;
    });

  }

  ChequearMinimo():boolean {
    return this.forma.hasError('InvalidoMinimo') &&
    this.forma.get("stockMaximo").dirty &&
    this.forma.get("stockMinimo").dirty;
  }

  ChequearMaximo():boolean {
    return this.forma.hasError('InvalidoMaximo') &&
    this.forma.get("stockMaximo").dirty &&
    this.forma.get("stockMinimo").dirty;
  }

  ChequearReorden():boolean {
    return this.forma.hasError('reordenInvalid') &&
    this.forma.get("stockMaximo").dirty &&
    this.forma.get("stockMinimo").dirty &&
    this.forma.get("reorden").dirty;
  }
  almacenesLocales() {
    this.ctx.Almacen.obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.dataSource = new MatTableDataSource(resultado);
      })
      .catch((e) => {
        this.translate
          .get("messages.errorInformacion")
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
        console.log(e);
      });
  }

  cargarFolio(): void {
    const r = this.ctx.Productos.generarFolio()
      .toPromise()
      .then((resultado) => {
        this.forma.get("codigo").setValue("PRO-" + resultado);
      })
      .catch((e) => {
        this.translate
          .get("messages.errorInformacion")
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as Productos;
      model.almacenes = this.almacenSeleccionado;
      if (this.idSeleccionado === 0) {
        this.ctx.Productos.guardar(model)
          .toPromise()
          .then(() => {
            this.translate
              .get("messages.guardar", { value: this.page })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
              this.guardado.emit();
              this.router.navigate(["/Productos/"])
          })
          .catch((e) => {
            if (e === "Unprocessable Entity") {
              this.translate
                .get("messages.guardarExiste", { value: this.page })
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            } else {
              this.translate
                .get("messages.guardarError")
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      } else {
        this.ctx.Productos.actualizar(this.idSeleccionado, model)
          .toPromise()
          .then(() => {
            this.translate
              .get("messages.actualizar", { value: this.page })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.router.navigate(["/Productos/"])

          })
          .catch((e) => {
            if (e === "Unprocessable Entity") {
              this.translate
                .get("messages.guardar", { value: this.page })
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            } else {
              this.translate
                .get("messages.guardarError")
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });

      }
    } else {
      this.translate.get("messages.datosInvalido").subscribe((res: string) => {
        this.alerta.mostrarError(res);
      });
    }
  }

  cargarGruposProductos() {
    this.ctx.grupoProducto
      .obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.grupoProductoSinFiltrar = resultado;
        this.grupoProductoFiltrados = this.grupoProductofiltro.valueChanges.pipe(
          startWith<string | GrupoProductos>(""),
          map((t) =>
            typeof t === "string" ? t : t == null ? "" : t.descripcion
          ),
          map((t) => this.filtrarGrupoProducto(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarGrupoProducto(modelo: string): GrupoProductos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.grupoProductoSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  grupoProductoSeleccionado(modelo: GrupoProductos) {
    this.f["grupoProductoDescripcion"].setValue(modelo.descripcion);
    this.f["grupoProductoId"].setValue(modelo.id);
  }

  cargarUnidadesMedida() {
    this.ctx.UnidadesMedida.obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.unidadMedidaSinFiltrar = resultado;
        this.unidadMedidaFiltrados = this.unidadMedidafiltro.valueChanges.pipe(
          startWith<string | UnidadesMedida>(""),
          map((t) =>
            typeof t === "string" ? t : t == null ? "" : t.descripcion
          ),
          map((t) => this.filtrarUnidadMedida(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarUnidadMedida(modelo: string): UnidadesMedida[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.unidadMedidaSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  unidadMedidaSeleccionado(modelo: UnidadesMedida) {
    this.f["unidadMedidaDescripcion"].setValue(modelo.descripcion);
    this.f["unidadMedidaId"].setValue(modelo.id);
  }

  limpiarFiltroUnidadMedida(): void {
    this.f["grupoProductoDescripcion"].setValue("");
    this.f["grupoProductoId"].setValue(null);
    this.cargarUnidadesMedida();
  }

  limpiarFiltroGrupoProducto(): void {
    this.f["unidadMedidaDescripcion"].setValue("");
    this.f["unidadMedidaId"].setValue(null);
    this.cargarGruposProductos();
  }

  archivoSeleccionado(event) {
    if (event.target.files && event.target.files[0]) {
      const archivo = new ImagenesProductos();
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          archivo.base64 = event.target.result;
        };
        reader.readAsDataURL(event.target.files[i]);
        archivo.nombre = event.target.files[i].name;
        const extencion = event.target.files[i].type.split("/");
        archivo.extension = "." + extencion[1];
        this.modelProductoImagenes.push(archivo);
      }
    }
    console.dir(this.modelProductoImagenes);
  }

  archivoEliminar(indice: number) {
    this.modelProductoImagenes.splice(indice, 1);
  }
  /*
  async cargarProductosEnAlmacen(): Promise<void> {
    let resultado = await this.ctx.asignacionAlmacenProducto.obtenerProductoAlmacen(this.idSeleccionado).toPromise();
    this.almacenesProducto = resultado;
  }
*/
  async cargarAlmacenes(): Promise<void> {
    const resultado = await this.ctx.Almacen.obtenerTodos().toPromise();
    this.almacenes = resultado.map((a) => {
      const almacen = this.almacenesProducto.find((e) => e.almacenId == a.id);
      if (!almacen) {
        a.seleccionado = false;
      } else {
        a.seleccionado = true;
      }
      return a;
    });

    console.dir(resultado);
  }
  opcion(seleccion: boolean, almacen: Almacen) {
    const i = this.almacenSeleccionado.indexOf(almacen,0);
    if (seleccion) {
      if(i !== -1 ){
        this.almacenSeleccionado.splice(i,1);
        almacen.activo=true;
        this.almacenSeleccionado.push(almacen);
      }else{
        almacen.activo=true;
        this.almacenSeleccionado.push(almacen);
      }
    } else {
      if ( i !== -1 ) {
        this.almacenSeleccionado.splice(i,1);
        almacen.activo=false;
        this.almacenSeleccionado.push(almacen);
      }
    }
    console.log(this.almacenSeleccionado)
  }
}

import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Router } from '@angular/router';
import { Pedido } from 'src/app/shared/models/pedido';
import { ComprobantePedidoComponent } from '../../comprobante-pedido/catalogo.component';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { TranslateService } from '@ngx-translate/core';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { Subscription } from 'rxjs';
import { Credencial } from 'src/app/shared/models/credencial';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Traspaso } from 'src/app/shared/models/traspaso';
import { ComprobanteTraspasoComponent } from '../../comprobante-traspaso/catalogo.component';

@Component({
  selector: 'catalogo-control-inventario-traspasos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoControlTraspasosComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  sub: Subscription;
  fuenteDatos: MatTableDataSource<Traspaso> = new MatTableDataSource([]);
  columnasMostradas = [
    'folio',
    'almacenOrigenDescripcion',
    'almacenDestinoDescripcion',
    'referencia',
    'fechaDocumento',
    'opciones',
  ];

  constructor(
    public ventana: MatDialog,
    private db: Contexto,
    private alerta: ServicioAlerta,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {}

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarTabla();
      }
    );
  }

  cargarTabla() {
    this.db.traspasos
      .obtenerTraspasosAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((traspaso) => {
        this.fuenteDatos = new MatTableDataSource(traspaso);
        this.fuenteDatos.sort = this.ordenador;
        this.fuenteDatos.paginator = this.paginador;
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }



  abrirFormaNuevoTraspaso(id?: string): void {
    const dato = id ? id : 0;
    this.router.navigate(['/RegistroTraspasos/', dato]);
  }

  verComprobante(traspaso: Traspaso): void {
    this.db.traspasos
      .obtenerComprobanteTraspaso(traspaso.id)
      .toPromise()
      .then((p) => {
        this.dialog.open(ComprobanteTraspasoComponent, {
          data: p,
          panelClass: 'comprobante',
          width: '1000px',
        });
      });
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

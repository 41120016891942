import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RangoFechaSincronizacion } from '../models/rango-fecha-sincro';

export class RepositorioRangoFechaSincronizacion extends Repositorio<RangoFechaSincronizacion> {
  constructor(http: HttpClient) {
    super(http, 'RangoFechaSincronizacion');
  }


  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  NgModel,
} from '@angular/forms';
import { ArticuloAlternativo } from 'src/app/shared/models/articulos-alternativos';
import { Productos } from 'src/app//shared/models/Productos';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Observable, Subscription } from 'rxjs';
import { MatTable } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TranslateService } from '@ngx-translate/core';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';

@Component({
  templateUrl: './formaLista.component.html',
  styleUrls: ['./formaLista.component.scss'],
})
export class FormaListaComponent implements OnInit, OnDestroy {
  constructor(
    private ctx: Contexto,
    private formBuilder: FormBuilder,
    private alerta: ServicioAlerta,
    private translate: TranslateService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {}

  forma: FormGroup;
  listaAlternativos: ArticuloAlternativo[] = [];
  nombreColumnas: string[] = [
    'codigo',
    'articuloPrincipal',
    'articuloAlternativo',
    'factorCoincidencia',
    'activo',
    'opcion',
  ];

  articuloPrincipalFiltrados: Observable<Productos[]>;
  articuloPrincipalSinFiltrar: Productos[];
  articuloPrincipalfiltro = new FormControl();
  articuloAlternativoFiltrados: Observable<Productos[]>;
  articuloAlternativoSinFiltrar: Productos[];
  articuloAlternativofiltro = new FormControl();
  @ViewChild(MatTable, null) table: MatTable<any>;
  porcentajeTotal = 0;
  editar = false;
  sub: Subscription;
  get f() {
    return this.forma.controls;
  }
  page = '';
  ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0],
      codigo: [''],
      articuloPrincipal: ['', Validators.required],
      productoPrincipalId: ['', Validators.required],
      articuloAlternativo: ['', Validators.required],
      productoAlternativoId: ['', Validators.required],
      factorCoincidencia: ['', Validators.required],
      activo: [true],
    });
    this.cargarArticulosAlternativos();
    this.cargarArticuloPrincipal();

    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarArticulosAlternativos();
        this.cargarArticuloPrincipal();
      }
    );


    this.translate.get('title.assignPrd').subscribe((res: string) => {
      this.page = res;
    });
  }

  cargarFolio(): void {
    let r = this.ctx.articuloAlternativo
      .generarFolio()
      .toPromise()
      .then((resultado) => {
        this.forma.get('codigo').setValue('ARA-' + resultado);
      })
      .catch((e) => {
        this.translate
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
        this.translate
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  AgregarALista() {
    if (this.forma.valid) {
      const model = this.forma.value as ArticuloAlternativo;
      if (this.porcentajeTotal + Number(model.factorCoincidencia) <= 100) {
        this.ctx.articuloAlternativo
          .guardar(model)
          .toPromise()
          .then((resultado) => {
            console.log(resultado);
            this.porcentajeTotal =
              this.porcentajeTotal + Number(model.factorCoincidencia);
            this.translate
              .get('messages.guardar', { value: this.page })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.listaAlternativos.push(resultado);
            this.table.renderRows();
          })
          .catch((e) => {
            console.log(e);
            if (e === 'OK') {
              this.translate
                .get('messages.guardarExiste', { value: this.page })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.translate
                .get('messages.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      } else {
        this.translate
          .get('messages.datosInvalido')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      }
    }
  }

  delete(row: ArticuloAlternativo): void {
    const index = this.listaAlternativos.indexOf(row, 0);
    if (index > -1) {
      if (row.id != null) {
        this.ctx.articuloAlternativo.desactivar(row.id).subscribe();
      }
    }
    this.table.renderRows();
  }

  cargarArticuloPrincipal() {
    this.ctx.Productos.obtenerProductosAlmacenPrincipales(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.articuloPrincipalSinFiltrar = [];
        this.articuloPrincipalSinFiltrar = resultado;
        this.articuloPrincipalFiltrados = this.articuloPrincipalfiltro.valueChanges.pipe(
          startWith<string | Productos>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarProducto(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarProducto(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.articuloPrincipalSinFiltrar.filter(
      (t) =>
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  productoSeleccionado(modelo: Productos) {
    this.f['articuloPrincipal'].setValue(modelo.descripcion);
    this.f['productoPrincipalId'].setValue(modelo.id);
    this.porcentajeTotal = 0;
    this.productosAlternativos(modelo.id);
  }

  limpiarFiltroArticuloPrincipal(): void {
    this.f['articuloPrincipal'].setValue('');
    this.f['productoPrincipalId'].setValue(null);
    this.cargarArticuloPrincipal();
  }

  cargarArticulosAlternativos() {
    this.ctx.Productos.obtenerProductosAlmacenAlternativos(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.articuloAlternativoSinFiltrar = [];
        this.articuloAlternativoSinFiltrar = resultado;
        this.articuloAlternativoFiltrados = this.articuloAlternativofiltro.valueChanges.pipe(
          startWith<string | Productos>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarProductoAlternativo(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarProductoAlternativo(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.articuloAlternativoSinFiltrar.filter(
      (t) =>
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  articuloAlternativoSeleccionado(modelo: Productos) {
    this.f['articuloAlternativo'].setValue(modelo.descripcion);
    this.f['productoAlternativoId'].setValue(modelo.id);
  }

  limpiarFiltroArticuloAlternativo(): void {
    this.f['articuloAlternativo'].setValue('');
    this.f['productoAlternativoId'].setValue(null);
    this.cargarArticulosAlternativos();
  }

  productosAlternativos(id: number) {
    this.ctx.articuloAlternativo
      .obtenerAlternativos(id)
      .subscribe((resultado) => {
        this.listaAlternativos = resultado;
        this.listaAlternativos.forEach((element) => {
          if (element.activo) {
            this.porcentajeTotal =
              this.porcentajeTotal + element.factorCoincidencia;
          }
        });
      });
  }

  cambiarEstado(activo: boolean, row: ArticuloAlternativo): void {
    if (activo) {
      this.activar(row.id);
      this.porcentajeTotal = this.porcentajeTotal + row.factorCoincidencia;
    } else {
      this.desactivar(row.id);
      this.porcentajeTotal = this.porcentajeTotal - row.factorCoincidencia;
    }
  }

  seleccion(row: ArticuloAlternativo) {
    this.forma.get('factorCoincidencia').setValue(row.factorCoincidencia);
    this.forma.get('articuloAlternativo').setValue(row.articuloAlternativo);
    this.forma.get('productoAlternativoId').setValue(row.productoAlternativoId);
    this.forma.get('codigo').setValue(row.codigo);
    this.forma.get('id').setValue(row.id);
    console.log(row.id);
    this.editar = true;
  }

  Editar() {
    const model = this.forma.value as ArticuloAlternativo;
    const index = this.listaAlternativos.indexOf(model, 5);
    this.ctx.articuloAlternativo.actualizar(model.id, model).subscribe(() => {
      //recarga la busqueda
      this.productosAlternativos(model.productoPrincipalId);
      //Limpia los datos
      this.forma.get('factorCoincidencia').setValue('');
      this.forma.get('articuloAlternativo').setValue('');
      this.forma.get('productoAlternativoId').setValue('');
      this.forma.get('codigo').setValue('');
      this.forma.get('id').setValue(0);
      //muestra la mensaje
      this.translate
        .get('messages.actualizar', { value: this.page })
        .subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
      this.editar = false;
    });
    console.log(model);
  }

  activar(id: number): void {
    this.ctx.articuloAlternativo
      .activar(id)
      .toPromise()
      .then(() => {
        this.translate.get('messages.activar').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
        //this.tabla();
      })
      .catch((e) => {
        console.log(e);
        this.translate.get('messages.activarError').subscribe((res: string) => {
          this.alerta.mostrarError(res);
        });
      });
  }

  desactivar(id: number): void {
    this.ctx.articuloAlternativo
      .desactivar(id)
      .toPromise()
      .then(() => {
        this.translate.get('messages.desactivar').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
        // this.tabla();
      })
      .catch((e) => {
        console.log(e);
        this.translate
          .get('messages.desactivarError')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }
  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-configuracion-principal',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoConfiguracionPrincipalComponent implements OnInit {

  verOpcionSchema: boolean = false;

  constructor(private autenticacion: AutenticacionService) {


  }
  ngOnInit() {

    if (this.autenticacion.credencial.usuarioId === 1) {
      this.verOpcionSchema = true;
    }

  }




}
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource,
  Sort,
} from '@angular/material';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TipoMenu } from 'src/app/shared/models/tipo-menu';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pedido } from 'src/app/shared/models/pedido';
import { Almacen } from 'src/app/shared/models/Almacen';
import { map, startWith } from 'rxjs/operators';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';
import { Productos } from 'src/app/shared/models/Productos';
import { DatePipe } from '@angular/common';
import { groupBy2 } from 'src/app/utilerias/groupBy';
import * as XLSX from 'xlsx';
import { ExcelMaxMinCriticos } from 'src/app/shared/models/excel-max-min-criticos';
import { ExcelDetalleRequisicion } from 'src/app/shared/models/excel-detalle-requisicion.';
import { LoadingService } from 'src/app/loading/loading.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { Traspaso } from '../../models/traspaso';
import { DetalleTraspaso } from '../../models/detalle-traspaso';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@AutoUnsubscribe()
@Component({
  templateUrl: './doc-traspaso.component.html',
  styleUrls: ['./doc-traspaso.component.scss'],
})
export class FormaDocTraspasoComponent implements OnInit {
  forma: FormGroup;
  traspasoId: number = 0;
  traspaso: Traspaso = new Traspaso();
  detalleTraspaso: DetalleTraspaso[] = [];
  detalleTraspasosCompletados: DetalleTraspaso[] = [];
  arrayBuffer: any;
  file: File;
  detalle: DetalleTraspaso;
  get f() {
    return this.forma.controls;
  }

  almacenes: Almacen[] = [];
  almacenesFiltrados: Observable<Almacen[]>;
  almacenesSinFiltrar: Almacen[];
  filtroAlmacenes = new FormControl();

  productos: Productos[] = [];

  textoBuscar: string = '';

  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<DetalleTraspaso> = new MatTableDataSource([]);

  columnasMostradas = [
    'linea',
    'producto',
    'unidadMedidaCodigo',
    'existencia',
    'cantidad',
    'opciones',
  ];

  @ViewChild(MatPaginator, { static: false }) paginadorCompletado: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenadorCompletado: MatSort;
  @ViewChild('filtroCompletado', { static: false })
  filtroCompletado: ElementRef;
  sub: Subscription;

  fuenteDatosCompletado: MatTableDataSource<DetalleTraspaso> = new MatTableDataSource(
    []
  );
  columnasMostradasCompletado = [
    'cantidad',
    'existencia',
    'producto',
    'unidadMedidaCodigo',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private translate: TranslateService,
    private activateRouter: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    private cargando: LoadingService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {
    this.activateRouter.params.subscribe((params) => {
      this.traspasoId = +params['id'];
    });
  }

  async ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.nullValidator],
      folio: ['', Validators.required],
      fechaDocumento: [new Date(), Validators.required],
      referencia: ['', Validators.nullValidator],
      comentarios: ['', Validators.nullValidator],
      esBorrador: [false, Validators.required],
      cantidadTotalPedido: [0, Validators.nullValidator],
      totalLineas: [0, Validators.nullValidator],
      almacenId: [0, Validators.nullValidator],
      almacenDescripcion: ['', Validators.required],
      almacenDestinoId: [0, Validators.required],
      almacenDestinoDescripcion: ['', Validators.required],
      cantidad: [0, Validators.nullValidator],
    });

    if (this.traspasoId > 0) {
      this.traspaso = await this.ctx.traspasos
        .obtenerTraspasoDetalle(this.traspasoId)
        .toPromise();
      Object.assign(this.forma.value, this.traspaso);
      this.forma.reset(this.forma.value);
      this.cargarProductos(this.traspaso.almacenId);
      this.cargarDetalleTraspasos();
    } else {
      this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
        (credencial) => {
          this.forma.get('almacenId').setValue(this.autenticacion.credencial.almacenId);
          this.forma.get('almacenDescripcion').setValue(this.autenticacion.credencial.almacenDescripcion);
          this.generarFolio(this.autenticacion.credencial.almacenId);
          this.cargarProductos(this.autenticacion.credencial.almacenId);
          this.detalleTraspaso = [];
          this.traspaso.detalles = [];
          this.fuenteDatos = new MatTableDataSource(this.detalleTraspaso);
          this.cargarDetalleTraspasos();
          this.cargarAlmacenes();
        }
      );
    }
    // await this.cargarAlmacenes();
    // await this.cargarProductos();

    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
  }

  async generarFolio(almacenId: number) {
    const resultado = await this.ctx.traspasos.generarFolio(almacenId).toPromise();
    this.forma.get("folio").setValue(resultado.objeto);
  }

  cargarDetalleTraspasos() {
    this.detalleTraspaso = this.traspaso.detalles;
    if (this.detalleTraspaso.length == 0) {
      this.detalle = new DetalleTraspaso();
      this.detalle.id = 0;
      this.detalle.linea = 1;
      this.detalle.cantidad = 0;
      this.detalle.inventarioCantidad = 0;
      this.detalle.traspasoId = this.traspasoId;
      this.detalle.productoCodigo = '';
      this.detalle.orden = '';
      this.detalle.productoDescripcion = '';
      this.detalle.productoId = 0;
      this.detalle.unidadMedidaCodigo = '';
      this.detalle.existencia = 0;
      this.detalle.bloqueado = false;
      this.detalleTraspaso.push(this.detalle);
      this.fuenteDatos = new MatTableDataSource<DetalleTraspaso>(this.detalleTraspaso);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.conteoLineasCantidad();
    } else {
      this.fuenteDatos = new MatTableDataSource<DetalleTraspaso>(this.detalleTraspaso);
      this.fuenteDatos.paginator = this.paginador;
      // this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    }

    this.detalleTraspasosCompletados = this.traspaso.detalleCompleto;
    this.fuenteDatosCompletado = new MatTableDataSource(
      this.detalleTraspasosCompletados
    );
    this.fuenteDatosCompletado.paginator = this.paginadorCompletado;
    this.fuenteDatosCompletado.sort = this.ordenadorCompletado;
  }

  limpiar(): void { }

  guardar(tipo: boolean): void {
    //GUARDO EL PEDIDO COMO DOCUMENTO BORRADOR

    const traspaso = this.forma.value as Traspaso;

    if (this.forma.get('almacenDestinoId').value == 0) {
      this.alerta.mostrarError('Seleccione Almacén de destino')
    } else {

      traspaso.esBorrador = tipo;
      var productoEnCero = this.detalleTraspaso
        .filter((e) => e.cantidad == 0)
        .map((e) => (e.remarcar = true));
      if (productoEnCero.length > 0) {
        this.alerta.mostrarAdvertencia(
          'Aún hay productos con cantidad en cero, en el detalle'
        );
        return;
      }


      let lista = this.detalleTraspaso.filter(
        (e) => e.existencia < e.cantidad
      );
      if (lista.length > 0) {
        lista.map((e) => (e.remarcar = true));
        this.alerta.mostrarAdvertencia(
          'Hay producto con cantidad menor al traspaso!'
        );
        return;
      }
      this.cargando.show("Espera un momento... Guardando el traspaso " + traspaso.folio);

      var helper = {};
      var detalleTraspasoAgrupado = this.detalleTraspaso.reduce(function (r, o) {
        var key = o.productoId;
        if (!helper[key]) {
          helper[key] = Object.assign({}, o);
          r.push(helper[key]);
        } else {
          helper[key].existencia = o.existencia;
          helper[key].cantidad += o.cantidad;
        }

        return r;

      }, []);

      let lista2 = detalleTraspasoAgrupado.filter(
        (e) => e.existencia < e.cantidad
      );
      if (lista2.length > 0) {
        this.cargando.hide();
        lista.map((e) => (e.remarcar = true));
        this.alerta.mostrarAdvertencia(
          'Hay producto con cantidad menor al traspaso!'
        );
        return;
      } else {
        let detTraspaso = detalleTraspasoAgrupado as DetalleTraspaso[];
        traspaso.detalles = [];
        traspaso.detalles.length = 0;
        traspaso.detalles = detTraspaso;
        console.table(this.traspaso.detalles);
        const accion =

          this.ctx.traspasos.generarTraspaso(traspaso);

        accion
          .toPromise()
          .then(() => {
            this.cargando.hide();

            this.alerta.mostrarExito('Documento guardado con éxito!');
            this.router.navigate(['/TraspasoAlmacen']);
          })
          .catch(() => {
            this.cargando.hide();
            this.alerta.mostrarError('Error al guardar!');
          });
          
      }

    }


  }

  cerrar(): void { }

  buscar(event) {
    this.textoBuscar = event.target.value;
  }

  actualizaLinea(
    linea: number,
    cantidad?: number,
    existencia?: number,
    producto?: Productos
  ) {
    const detallePedido = this.fuenteDatos.data.find((e) => e.linea == linea);
    detallePedido.id = detallePedido.id;
    detallePedido.cantidad = +cantidad;
    detallePedido.existencia = +existencia;
    detallePedido.traspasoId = this.traspasoId;
    if (producto.id > 0) {
      detallePedido.productoCodigo = producto.codigo;
      detallePedido.productoDescripcion = producto.codigo + ' - ' + producto.descripcion;
      detallePedido.productoId = producto.id;
      detallePedido.unidadMedidaCodigo = producto.unidadMedidaClave;
      detallePedido.existencia = +producto.existencia;
      detallePedido.bloqueado = false;
      detallePedido.codigoProductoReemplazo = producto.codigoProductoReemplazo;
    }
    detallePedido.remarcar = false;
    // this.verificaExistenciaProducto(detallePedido.productoCodigo, detallePedido.existencia);
    this.cantidadVsEntrega();
    this.conteoLineasCantidad();
  }

  verificaExistenciaProducto(codigoProducto: string, existencia: number) {
    let cantidadProducto = this.detalleTraspaso
      .filter((e) => e.productoCodigo == codigoProducto)
      .reduce((suma, detalle) => suma + +detalle.cantidad, 0);
    if (existencia < cantidadProducto) {
      this.alerta.mostrarAdvertencia(
        'La cantidad del producto ' +
        codigoProducto +
        ' es mayor a la existencia!'
      );
      this.detalleTraspaso
        .filter((e) => e.productoCodigo == codigoProducto)
        .map((e) => (e.remarcar = true));
      return;
    }
  }

  cantidadVsEntrega() {
    let lista = this.detalleTraspaso.filter(
      (e) => e.existencia < e.cantidad
    );
    if (lista.length > 0) {
      lista.map((e) => (e.remarcar = true));
      this.alerta.mostrarAdvertencia(
        'Hay producto con cantidad menor al traspaso!'
      );
      return;
    }
  }

  agregarLinea() {
    const detalle = new DetalleTraspaso();
    detalle.id = 0;
    detalle.linea = this.detalleTraspaso.length + 1;
    detalle.cantidad = 0;
    detalle.existencia = 0;
    detalle.fechaSolicitud = new Date();
    detalle.orden = '';
    detalle.traspasoId = this.traspasoId;
    detalle.productoCodigo = '';
    detalle.productoDescripcion = '';
    detalle.productoId = 0;
    detalle.unidadMedidaCodigo = '';
    detalle.existencia = 0;
    detalle.bloqueado = false;
    detalle.codigoProductoReemplazo = '';
    this.detalleTraspaso.push(detalle);
    this.fuenteDatos = new MatTableDataSource(this.detalleTraspaso);
    this.fuenteDatos.paginator = this.paginador;
    this.fuenteDatos.sort = this.ordenador;
    // this.ordenadoPorLineaDesc();
    this.conteoLineasCantidad();
    this.textoBuscar = '';
  }

  ordenadoPorLineaDesc() {
    const sortState: Sort = { active: 'linea', direction: 'desc' };
    this.ordenador.active = sortState.active;
    this.ordenador.direction = sortState.direction;
    this.ordenador.sortChange.emit(sortState);
  }

  bloqueaLinea() {
    this.detalleTraspaso.map((e) => (e.bloqueado = true));
  }

  conteoLineasCantidad() {
    this.f['cantidadTotalPedido'].setValue(0);
    this.f['totalLineas'].setValue(0);
    this.f['cantidadTotalPedido'].setValue(
      this.fuenteDatos.data.reduce(
        (suma, detalle) => suma + +detalle.existencia,
        0
      )
    );
    this.f['totalLineas'].setValue(this.fuenteDatos.data.length);
  }

  removerLinea(linea: number) {
    if (this.detalleTraspaso.length > 1) {
      this.detalleTraspaso.splice(linea - 1, 1);
      let contador: number = 1;
      for (let index = 0; index < this.detalleTraspaso.length; index++) {
        const element = this.detalleTraspaso[index];
        element.linea = contador;
        contador++;
      }
      this.fuenteDatos = new MatTableDataSource(this.detalleTraspaso);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      // this.ordenadoPorLineaDesc();
      this.conteoLineasCantidad();
    }
  }

  //#region  Productos
  async cargarProductos(almacenId: number) {
    this.productos = await this.ctx.Productos.obtenerPorAlmacen(
      almacenId
    ).toPromise();
  }

  async limpiarProductos(linea: number) {
    const detalleTraspaso = this.fuenteDatos.data.find((e) => e.linea == linea);
    if (!detalleTraspaso.bloqueado) {
      detalleTraspaso.cantidad = 0;
      detalleTraspaso.existencia = 0;
      detalleTraspaso.traspasoId = this.traspasoId;
      detalleTraspaso.productoCodigo = '';
      detalleTraspaso.productoDescripcion = '';
      detalleTraspaso.productoId = 0;
      detalleTraspaso.unidadMedidaCodigo = '';
      detalleTraspaso.existencia = 0;
      detalleTraspaso.bloqueado = false;
      detalleTraspaso.remarcar = false;
      detalleTraspaso.codigoProductoReemplazo = '';
      detalleTraspaso.id = 0;
      this.textoBuscar = '';
    }
  }

  //#endregion

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  filtrar(filtro: string) {
    this.fuenteDatos.filter = filtro;
  }

  limpiarFiltroCompletado(): void {
    this.filtroCompletado.nativeElement.value = '';
    this.fuenteDatosCompletado.filter = '';
  }

  filtrarCompletado(filtro: string) {
    this.fuenteDatosCompletado.filter = filtro;
  }

  // cargarExcelDetalle(event) {
  //   this.detallePedidos = [];
  //   try {
  //     if (event.target.files && event.target.files[0]) {
  //       var filesAmount = event.target.files.length;
  //       for (let i = 0; i < filesAmount; i++) {
  //         this.file = event.target.files[i];
  //         let fileReader = new FileReader();
  //         fileReader.onload = (e) => {
  //           this.arrayBuffer = fileReader.result;
  //           var data = new Uint8Array(this.arrayBuffer);
  //           var arr = new Array();
  //           for (var i = 0; i != data.length; ++i)
  //             arr[i] = String.fromCharCode(data[i]);
  //           var bstr = arr.join('');
  //           var workbook = XLSX.read(bstr, { type: 'binary' });
  //           var first_sheet_name = workbook.SheetNames[0];
  //           var worksheet = workbook.Sheets[first_sheet_name];
  //           let Json = XLSX.utils.sheet_to_json(worksheet, {
  //             raw: true,
  //           }) as ExcelDetalleRequisicion[];
  //           if (Json) {
  //             this.detallePedidos = Json.map((s) => {
  //               const pro = this.productos.find(e => e.codigo === s.articulo);

  //               const r = {} as DetallePedido;
  //               r.orden = s.orden;
  //               r.productoCodigo = s.articulo;
  //               r.productoDescripcion = pro != null? pro.codigo + ' - ' + pro.descripcion: "";
  //               r.codigoProductoReemplazo = pro != null? pro.codigoProductoReemplazo: "";
  //               r.productoId = pro != null? pro.id: undefined;
  //               r.fechaSolicitud = s.fecha;
  //               r.cantidadEntregada = 0;
  //               r.cantidad = +s.cantidad
  //               r.remarcar = pro != null? false: true;
  //               r.bloqueado = false;
  //               r.unidadMedidaCodigo = pro != null? pro.unidadMedidaClave: "";
  //               r.linea = +s.linea;
  //               r.fechaSolicitud = new Date(s.fecha);
  //               r.cantidadFaltante = +s.cantidad
  //               return r;
  //             })

  //             this.fuenteDatos = new MatTableDataSource<DetallePedido>(this.detallePedidos);
  //             this.fuenteDatos.paginator = this.paginador;
  //             this.conteoLineasCantidad();
  //           } else {
  //             this.translate
  //               .get('estructuraincorrecta')
  //               .toPromise()
  //               .then((e) => {
  //                 this.alerta.mostrarError(e);
  //               });
  //           }
  //         };
  //         fileReader.readAsArrayBuffer(this.file);
  //       }
  //     }
  //   } catch (error) {
  //     this.translate
  //       .get('errorleerdocumento')
  //       .toPromise()
  //       .then((e) => {
  //         this.alerta.mostrarError(e);
  //       });
  //     this.alerta.mostrarError('');
  //   }
  // }

  //#region CargaAlmacenes
  cargarAlmacenes() {
    this.ctx.Almacen.obtenerPorAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.almacenesSinFiltrar = resultado;
        this.almacenesFiltrados = this.filtroAlmacenes.valueChanges.pipe(
          startWith<string | Almacen>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarAlmacenes(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarAlmacenes(nombre: string): Almacen[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.almacenesSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  almacenSeleccionado(almacen: Almacen) {
    console.log(almacen.id);
    this.forma
      .get('almacenDestinoDescripcion')
      .setValue(almacen.descripcion);
    this.forma.get('almacenDestinoId').setValue(almacen.id);
  }

  limpiarAlmacenes(): void {
    this.forma.get('almacenDestinoDescripcion').setValue('');
    this.forma.get('almacenDestinoId').setValue(0);
    this.cargarAlmacenes();
  }
  //#endregion


  descargarGuiaLayout() {
    let archivo = window.open("assets/guias-excel/Layout_DetallePedido.xlsx", '_blank');
    setTimeout(function () {
      archivo.close();
    }, 100);
    return false;
  }

  checkError(controlName: string, errorName: string) {

    return this.forma.controls[controlName].hasError(errorName);
  }
}

import { Detalle } from "./detalle";
import { Documento } from "./documento";

export class DetalleTraspaso extends Detalle {
    traspasoId: number;
    cantidad: number;
    productoId: number;
    inventarioCantidad: number;
    bloqueado: boolean = false;
    existencia: number;
    remarcar: boolean = false;
    inventarioCantidadDestino: number;

}
export class ConteoProducto{
    conteo: string;
    productoDescripcion: string;
    codigo: number;
    almacenDescripcion: string;
    almacenId: number;
    diaConteo: string;
    prioridad: string;
    fechaDocumento: string;
    ubicacionDescripcion: string;
}
import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'cus-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CusCard implements OnInit {
  @Input() size: 'sm' | 'md' | 'lg';
  @HostBinding('style.flex') flex = '1 1 auto';
  @HostBinding('style.max-width.px') maxWidth = '';

  constructor() {}

  ngOnInit() {
    switch (this.size) {
      case 'sm':
        this.maxWidth = '399.99';
        break;
      case 'md':
        this.maxWidth = '599.99';
        break;
      case 'lg':
        this.maxWidth = '1024';
        break;
      default:
        this.maxWidth = '';
        break;
    }
  }
}

export class Almacen {
    id: number;
    almacenId:number;
    codigo: string;
    descripcion: string;
    activo: boolean;
    minaUbicacionesId: number;
    descripcionMinaUbicaciones: string;
    descripcionMina: string;
    seleccionado: boolean;
    esReabastecimiento:boolean;
    descripcionAlmacen:string;
    listasPreciosId:number;
    decripcionListaIdEstatus:boolean;
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dashboard } from '../models/dashboard';
import { DashboardVentasGenerales } from '../models/dashboard-ventas-generales';
import { Grafica } from '../models/grafica';
import { FiabilidadInventario } from '../models/fiabilidad-inventario';
import { Reporteador } from '../models/reporteador';
import { EntradaAbierta } from '../models/entradas-abiertas';

export class RepositorioDashboard extends Repositorio<Dashboard> {
  constructor(http: HttpClient) {
    super(http, 'Dashboard');
  }

  ventasGenerales(almacenId: number): Observable<DashboardVentasGenerales> {
    const ruta = `${this.ruta}/ObtenerVentasGenerales/${almacenId}`;
    return this.http.get<DashboardVentasGenerales>(ruta);
  }

  obtenerControlRecepciones(almacenId: number): Observable<Grafica[]> {
    const ruta = `${this.ruta}/ObtenerControlRecepciones/${almacenId}`;
    return this.http.get<Grafica[]>(ruta);
  }

  obtenerEficienciasAnioActual(almacenId: number): Observable<Grafica> {
    const ruta = `${this.ruta}/ObtenerEficienciasAnioActual/${almacenId}`;
    return this.http.get<Grafica>(ruta);
  }

  obtenerEficienciasAnioAnterior(almacenId: number): Observable<Grafica> {
    const ruta = `${this.ruta}/ObtenerEficienciasAnioAnterior/${almacenId}`;
    return this.http.get<Grafica>(ruta);
  }

  obtenerProductosConMenosMovimientos(almacenId: number): Observable<Grafica[]> {
    const ruta = `${this.ruta}/ObtenerProductosConMenosMovimientos/${almacenId}`;
    return this.http.get<Grafica[]>(ruta);
  }

  obtenerKardexProducto(productoCodigo: string, almacenId: number): Observable<Reporteador[]> {
    const ruta = `${this.ruta}/ObtenerKardexProducto/${productoCodigo}/${almacenId}`;
    return this.http.get<Reporteador[]>(ruta);
  }

  actualizarProductosConMenosMovimientos(almacenId: number): Observable<Grafica[]> {
    const ruta = `${this.ruta}/ActualizarProductosConMenosMovimientos/${almacenId}`;
    return this.http.get<Grafica[]>(ruta);
  }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pedido } from '../models/pedido';
import { DetallePedido } from '../models/detalle-pedido';
import { ExcelDetalleRequisicion } from '../models/excel-detalle-requisicion.';
import { Respuesta } from '../models/respuesta';

export class RepositorioPedido extends Repositorio<Pedido> {
  constructor(http: HttpClient) {
    super(http, 'Pedidos');
  }

  obtenerExcel(id: number): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, id, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerPedidosAlmacen(almacenId: number): Observable<Pedido[]> {
    const ruta = `${this.ruta}/ObtenerPedidosAlmacen/${almacenId}`;
    return this.http.get<Pedido[]>(ruta);
  }

  // Regresa las requisiciones que aun están pendientes
  obtenerAbiertos(): Observable<Pedido[]> {
    const ruta = `${this.ruta}/Abiertos`;
    return this.http.get<Pedido[]>(ruta);
  }

  ObtenerDetalles(id: number): Observable<DetallePedido[]> {
    const ruta = `${this.ruta}/ObtenerDetalleRequisicion/${id}`;
    return this.http.get<DetallePedido[]>(ruta);
  }

  obtenerPedidoDetalle(id: number): Observable<Pedido> {
    const ruta = `${this.ruta}/ObtenerPedidoDetalle/${id}`;
    return this.http.get<Pedido>(ruta);
  }

  obtenerComprobantePedido(id: number): Observable<Pedido> {
    const ruta = `${this.ruta}/ObtenerComprobantePedido/${id}`;
    return this.http.get<Pedido>(ruta);
  }

  cerrarComprobantePedido(id: number): Observable<Pedido> {
    const ruta = `${this.ruta}/CerrarComprobantePedido/${id}`;
    return this.http.get<Pedido>(ruta);
  }

  cancelarComprobantePedido(id: number): Observable<Pedido> {
    const ruta = `${this.ruta}/CancelarComprobantePedido/${id}`;
    return this.http.get<Pedido>(ruta);
  }

  cargarDetalleRequisicionExcel(items: ExcelDetalleRequisicion[]): Observable<ExcelDetalleRequisicion[]> {

    let ruta = `${this.ruta}/CargarDetalleRequisicionExcel`;
    return this.http.post(ruta, items).pipe(
      map((articulos: ExcelDetalleRequisicion[]) => {
        return articulos;
      })
    );
  }

  generarFolio(almacenId: number): Observable<Respuesta<string>> {
    const ruta = `${this.ruta}/GenerarFolio/${almacenId}`;
    return this.http.get<Respuesta<string>>(ruta);
  }

  generarRequisicion(pedido: Pedido): Observable<Respuesta<Pedido>> {
    const ruta = `${this.ruta}/GenerarRequisicion`;
    return this.http.post<Respuesta<Pedido>>(ruta, pedido);
  }

  actualizarRequisicion(pedido: Pedido): Observable<Respuesta<Pedido>> {
    const ruta = `${this.ruta}/ActualizarRequisicion`;
    return this.http.post<Respuesta<Pedido>>(ruta, pedido);
  }
}

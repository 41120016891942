import {Component,OnInit,ViewChild} from '@angular/core';
import {MatTable} from '@angular/material';
import {Contexto} from 'src/app/shared/api/contexto.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {FormControl,FormGroup,FormBuilder} from '@angular/forms'
import { DetalleListaPrecio } from 'src/app/shared/models/detalle-lista-precio';
import { ListasPrecios } from 'src/app/shared/models/listas-precios';
import {TranslateService} from '@ngx-translate/core';
import {ServicioAlerta} from 'src/app/utilerias/alerta.service';
import {Productos} from 'src/app/shared/models/Productos';
import { async } from '@angular/core/testing';

@Component({
  templateUrl:'./crearLista.component.html',
  styleUrls:['./crearLista.component.scss']
})

export class CrearListaComponent implements OnInit{

  constructor(private ctx:Contexto,private formBuilder:FormBuilder,private translate:TranslateService,private alerta:ServicioAlerta){

  }
  forma:FormGroup;
  DescripcionListaPreciosFiltrados: Observable<ListasPrecios[]>;
  DescripcionListaPreciosSinFiltrar: ListasPrecios[];
  DescripcionListaPreciosfiltro = new FormControl();

  articuloPrincipalFiltrados: Observable<Productos[]>;
  articuloPrincipalSinFiltrar: Productos[];
  articuloPrincipalfiltro = new FormControl();

  ListaPreciosFiltrados: Observable<DetalleListaPrecio[]>;
  ListasPrecioSinFiltrar: DetalleListaPrecio[];
  ListaPreciofiltro = new FormControl();
  get f(){return this.forma.controls}

  listaPrecios:DetalleListaPrecio[]=[];
  lista:DetalleListaPrecio;
  editar=false;
  @ViewChild(MatTable,null) table: MatTable<any>;

  nombreColumnas=['codigoBarra','descripcion','precioPesos','precioDollar','activo','opcion']

  incrementoGeneral=0;

  crearForma(){
    this.forma=this.formBuilder.group({
      id:[0],
      codigo:[''],
      activo:[true],
      descripcionLista:[''],
      listaPreciosId:[0],
      productoDescripcion:[''],
      codigoBarra:[''],
      ProductosId:[0],
      precioPesos:[0],
      precioDollares:[0],
      incremento:[this.incrementoGeneral]
    });

  }

  ngOnInit(){
    this.crearForma();
    this.cargarDescripcionLista();
    this.cargarArticuloPrincipal();
  }

  cargarDescripcionLista() {
    this.ctx.ListaDePrecio.obtenerTodos().toPromise().then(resultado => {
      this.DescripcionListaPreciosSinFiltrar = resultado;
      this.DescripcionListaPreciosFiltrados = this.DescripcionListaPreciosfiltro.valueChanges
        .pipe(
          startWith<string | ListasPrecios>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarListas(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  incrementoTodos(i:number){
      this.f["incremento"].setValue(i);
      this.incrementoGeneral=i;
      if(this.f["productoDescripcion"].value!=''){
        this.agregarPorcentaje(i);
      }
  }

  private filtrarListas(modelo: string): ListasPrecios[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = this.DescripcionListaPreciosSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  ListasSeleccionado(modelo: ListasPrecios) {
    this.f['descripcionLista'].setValue(modelo.descripcion);
    this.f['listaPreciosId'].setValue(modelo.id);
    this.listaPrecios.length=0;
    this.ctx.ListaDePrecio.GetPrd(modelo.id).toPromise().then(res=>{
      res.forEach(e=>{
        this.listaPrecios.push(e);
      })
      this.table.renderRows();
    })
  }

  limpiarFiltroListas(): void {
    this.f['descripcionLista'].setValue('');
    this.f['listaPreciosId'].setValue(0);
    this.cargarDescripcionLista();
  }

  //Parte de los productos
  cargarArticuloPrincipal() {
    this.ctx.Productos.obtenerProductos().toPromise().then(resultado => {
      this.articuloPrincipalSinFiltrar = resultado;
      this.articuloPrincipalFiltrados = this.articuloPrincipalfiltro.valueChanges
        .pipe(
          startWith<string | Productos>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarProducto(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarProducto(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = this.articuloPrincipalSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  productoSeleccionado(modelo: Productos) {
    this.f['productoDescripcion'].setValue(modelo.descripcion);
    this.f['ProductosId'].setValue(modelo.id);

    this.ctx.Productos.obtener(modelo.id).subscribe((res)=>{
      this.f["precioPesos"].setValue(res.precio);
      this.f["precioDollares"].setValue((res.precioUSD));
      this.f["codigoBarra"].setValue(res.codigoBarra);
      if(this.incrementoGeneral>0){
        this.agregarPorcentaje(this.incrementoGeneral);
      }
    });
  }

  limpiarFiltroArticuloPrincipal(): void {
    this.f['productoDescripcion'].setValue('');
    this.f['ProductosId'].setValue(null);
    this.forma.get("precioPesos").setValue('');
    this.forma.get("precioDollares").setValue('');
    this.forma.get("incremento").setValue(this.incrementoGeneral);
    this.cargarArticuloPrincipal();
  }


  seleccion(row:DetalleListaPrecio){
    this.forma.get("ProductosId").setValue(row.productosId);
    this.forma.get("productoDescripcion").setValue(row.productoDescripcion);
    this.forma.get("precioPesos").setValue(row.precioPesos);
    this.forma.get("precioDollares").setValue(row.precioDollares);
    this.forma.get("incremento").setValue(row.incremento);
    this.forma.get("id").setValue(row.id);
    this.forma.get("codigo").setValue(row.codigo);
    this.forma.get("activo").setValue(row.activo);
    this.editar=true;
  }

  AgregarALista(){
    if(this.forma.valid){
      const model=this.forma.value as DetalleListaPrecio;
        this.ctx.ListaDePrecio.guardarLista(model).toPromise().then((resultado) => {
          this.translate.get("messages.guardar",{value:"Producto"}).subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
          })
          this.listaPrecios.push(resultado);
          this.table.renderRows();
          this.crearForma();
          this.f["descripcionLista"].setValue(model.descripcionLista);
          this.f["listaPreciosId"].setValue(model.listaPreciosId);
          this.forma.get("incremento").setValue(this.incrementoGeneral);
        }).catch(e => {
          console.log(e);
          if (e === 'OK') {
            this.translate.get("messages.guardarExiste",{value:"producto"}).subscribe((res:string)=>{
              this.alerta.mostrarAdvertencia(res);
            })
          }
          else {
            this.translate.get("messages.guardarError").subscribe((res:string)=>{
              this.alerta.mostrarError(res);
            })
          }
        });
      }
  }

  EditarLista(){
    let b=parseInt(this.f["incremento"].value);
    this.ctx.ListaDePrecio.EditarTodaLista(this.f["listaPreciosId"].value,b).toPromise().then(resultado=>{
      this.listaPrecios.length=0;
      resultado.forEach(e=>{
        this.listaPrecios.push(e);
        this.table.renderRows();
      })
    }).catch(EX=>{
      console.log(EX)
    })
  }

  Editar(){
    const model=this.forma.value as DetalleListaPrecio;
    this.ctx.ListaDePrecio.actualizarLista(model.id,model).subscribe((productos)=>{
      //Limpia los datos
      this.forma.get("ProductosId").setValue('');
    this.forma.get("productoDescripcion").setValue('');
    this.forma.get("precioPesos").setValue('');
    this.forma.get("precioDollares").setValue('');
    this.forma.get("incremento").setValue('');
    this.forma.get("id").setValue('');
    this.forma.get("codigo").setValue('');
    this.forma.get("activo").setValue('')

      //muestra la mensaje
      let id=parseInt(this.f['listaPreciosId'].value);
      this.translate.get("messages.actualizar",{value:"Productos"}).subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.listaPrecios.length=0;
      console.log(productos)
       productos.forEach(e=>{
          this.listaPrecios.push(e);});
        this.table.renderRows();
      this.editar=false;
      this.f["incremento"].setValue(this.incrementoGeneral);
    })
  }

  cambiarEstado(activo: boolean, row:DetalleListaPrecio): void {
    this.ctx.ListaDePrecio.activarLista(row.id).toPromise().then(() => {
      this.translate.get("messages.activar").subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      //this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("messages.activarError").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }

  agregarPorcentaje(porcentaje:number){
    let agregarTotal="1."+porcentaje;
    this.ctx.Productos.obtener(this.f["ProductosId"].value).subscribe((res)=>{
      this.f["precioPesos"].setValue(res.precio);
      this.f["precioDollares"].setValue((res.precioUSD));
      if(porcentaje>0){
        this.f["precioPesos"].setValue((this.f["precioPesos"].value*parseFloat(agregarTotal)).toFixed(2));
        this.f["precioDollares"].setValue((this.f["precioDollares"].value*parseFloat(agregarTotal)).toFixed(2));
      }
    });


  }

}


import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Contexto } from 'src/app/shared/api/contexto.service';
import {
  FormBuilder,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { DocExcelRevisado } from 'src/app/shared/models/revision-docs';
import { ExcelEntradas } from 'src/app/shared/models/excel-entradas';

@AutoUnsubscribe()
@Component({
  selector: 'entrada-excel',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoEntradaExcelComponent implements OnInit {
  arrayBuffer: any;
  file: File;
  docsBien: DocExcelRevisado[] = [];
  docsMal: DocExcelRevisado[] = [];
  doc: DocExcelRevisado = new DocExcelRevisado();
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private translate:TranslateService
  ) { }

  ngOnInit() { }

  archivos(event) {
    try {
      if (event.target.files && event.target.files[0]) {

        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          this.file = event.target.files[i];


          let fileReader = new FileReader();
          fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            let Json = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as ExcelEntradas[];
            if (Json) {
              console.dir(Json);
              this.ctx.documentoExcel.entradas(Json).toPromise().then(resultado => {
                this.docsBien.push(this.archivoRevisado(this.file.name));
              }).catch(e => {
                this.docsMal.push(this.archivoRevisado(this.file.name));
              });
            } else {
              this.translate.get("estructuraincorrecta").toPromise().then(e=>{
                this.alerta.mostrarError(e);
              })
            }
          }
          fileReader.readAsArrayBuffer(this.file);
        }
      }
    } catch (error) {
      this.translate.get("errorleerdocumento").toPromise().then(e=>{
        this.alerta.mostrarError(e);
      })
      this.alerta.mostrarError('');
    }
  }

  archivoRevisado(nombre: string): DocExcelRevisado {
    this.doc.nombreArchivo = nombre;
    return this.doc;
  }
}

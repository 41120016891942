import { OnInit,Component } from "@angular/core";


@Component({
  templateUrl:'./catalogo.component.html',
  styleUrls:['./catalogo.component.scss']
})

export class CatalogoIndicadores implements OnInit{

  ngOnInit(){

  }
  opcion="";

}


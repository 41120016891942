import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Almacen } from 'src/app/shared/models/Almacen';
import { MinaUbicaciones } from 'src/app/shared/models/mina-ubicacion';
import {TranslateService} from '@ngx-translate/core';
import { PeriodosSemanales } from 'src/app/shared/models/periodosSemanales';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';


@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss',],
})
export class FormaPeriodosSemanales implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  modelo: PeriodosSemanales;
  page="";


  Filtrados: Observable<MinaUbicaciones[]>;
  SinFiltrar: MinaUbicaciones[];
  filtro = new FormControl();


  sinFiltarAlmacen:Almacen[];
  FiltradoAlmacen:Observable<Almacen[]>;
  formReabastecimiento=new FormControl();

  mostrarReabastecimiento=false;

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaPeriodosSemanales>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService, public autenticacion: AutenticacionService) {

    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.PeriodosSemanas.obtener(id).toPromise().then(resultado => {
        this.modelo = resultado;
        Object.assign(this.forma.value, this.modelo);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new PeriodosSemanales();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        numSemana: ['', Validators.required],
        periodoId: ['', Validators.required],
        fechaInicio: ['', Validators.required],
        fechaFinal: ['', Validators.required],
        fechaConteo: ['', Validators.required],
        almacenId: this.autenticacion.credencial.almacenId
      },
    );

    this.translate.get("title.week").subscribe((res:string)=>{
      this.page=res;
    });

  }


  limpiar(): void {
    this.forma.reset();
  }



  minaUbicacionSeleccionado(mina: MinaUbicaciones) {
    this.f['descripcionMinaUbicaciones'].setValue(mina.descripcion);
    this.f['minaUbicacionesId'].setValue(mina.id);
  }


  guardar(): void {
    if (this.forma.valid) {

      const model = this.forma.value as PeriodosSemanales;
      console.log(this.idSeleccionado)
      console.log(model)

      if (this.idSeleccionado === 0) {
        this.ctx.PeriodosSemanas.guardar(model).toPromise().then(() => {
          this.translate.get('messages.guardar', { value: this.page }).subscribe((res: string) => {
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          if (e === 'Unprocessable Entity') {
            this.translate.get('messages.guardarExiste', { value: this.page }).subscribe((res: string) => {
              this.alerta.mostrarAdvertencia(res);
            })
          } else {
            this.translate.get('messages.guardarError').subscribe((res: string) => {
              this.alerta.mostrarError(res);
            })
          }
        });

      } else {
        const model = this.forma.value as PeriodosSemanales;
        console.log(this.idSeleccionado)

        this.ctx.PeriodosSemanas.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.translate.get('Periodo semanal actualizado con exito', { value: this.page }).subscribe((res: string) => {
            this.alerta.mostrarExito(res);
          })
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e === 'Unprocessable Entity') {
            this.translate.get('Error al actualizar periodo semanal', { value: this.page }).subscribe((res: string) => {
              this.alerta.mostrarError(res);
            })
          } else {
            this.translate.get('Error al actualizar periodo semanal').subscribe((res: string) => {
              this.alerta.mostrarError(res);
            })
          }
        });
      }
    }
  }


  cerrar(): void {
    this.ventana.close();
  }


}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticuloAlternativo } from '../models/articulos-alternativos';
import { AlternativeServiceOptions } from 'http2';

export class RepositorioArticuloAlternativo extends Repositorio<
  ArticuloAlternativo
> {
  constructor(http: HttpClient) {
    super(http, 'ArticuloAlternativo');
  }

  generarFolio(): Observable<string> {
    let ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  confirmarEnvio(
    model: ArticuloAlternativo[]
  ): Observable<ArticuloAlternativo> {
    let ruta = `${this.ruta}/ConfirmarEnvio`;
    return this.http.post<ArticuloAlternativo>(ruta, model);
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerAlternativos(id: number): Observable<ArticuloAlternativo[]> {
    const ruta = `${this.ruta}/Referencias/${id}`;
    return this.http.get<ArticuloAlternativo[]>(ruta);
  }

  obtenerAlternativosPorAlmacen(almacenId: number): Observable<ArticuloAlternativo[]> {
    const ruta = `${this.ruta}/ObtenerAlternativosPorAlmacen/${almacenId}`;
    return this.http.get<ArticuloAlternativo[]>(ruta);
  }
}

import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate
} from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';

@Injectable({
    providedIn: 'root'
})
export class GuardiaMenuCatalogos implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;

    constructor(
        private autenticacion: AutenticacionService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const url: string = state.url;
        let autorizado = false;

        const descripcion = url.replace('/', '');
        const catalogos = this.autenticacion.credencial.pantallasCatalogos.filter(e => e.clave === descripcion);
        if (catalogos.length > 0) {
            autorizado = true;
        } else {
            autorizado = false;
            this.router.navigate(['AccesoRestringido']);
        }
        return autorizado;
    }
}

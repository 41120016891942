import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { flatMap, startWith, map } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AsignacionRolPantalla } from 'src/app/shared/models/asignacion-rol-pantalla';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Almacen } from 'src/app/shared/models/Almacen';
import { Usuario } from 'src/app/shared/models/usuario';
import { AsignacionAlmacenUsuario } from 'src/app/shared/models/asignacion-almacen-usuario';

@AutoUnsubscribe()
@Component({
  selector: 'asignacion-rol-pantalla',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaAsignacionUsuariosPantallasComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  forma: FormGroup;
  get f() {
    return this.forma.controls;
  }

  almacenes: Almacen[] = [];
  almacenesFiltrados: Observable<Almacen[]>;
  almacenesSinFiltrar: Almacen[];
  filtroAlmacenes = new FormControl();

  usuarios: Usuario[] = [];

  almacenIdSeleccionado: number;
  fuenteDatos: MatTableDataSource<Usuario> = new MatTableDataSource([]);
  columnasMostradas = [
    'tipoUsuarioDescripcion',
    'nombre',
    'apellidoPaterno',
    'apellidoMaterno',
    'email',
    'asignacion',
  ];

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  tabla: Usuario[];
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.forma = this.formBuilder.group({
      almacenId: [null, Validators.nullValidator],
      almacenDescripcion: ['', Validators.required],
    });
    this.cargarAlmacenes();
  }

  cargarAlmacenes() {
    this.ctx.Almacen.obtenerTodos()
      .toPromise()
      .then((resultado) => {
        this.almacenesSinFiltrar = resultado;
        this.almacenesFiltrados = this.filtroAlmacenes.valueChanges.pipe(
          startWith<string | Almacen>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarAlmacenes(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarAlmacenes(nombre: string): Almacen[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.almacenesSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  almacenSeleccionado(almacen: Almacen) {
    console.log(almacen.id);
    this.f['almacenDescripcion'].setValue(almacen.descripcion);
    this.f['almacenId'].setValue(almacen.id);
    this.cargarTabla(almacen.id);
  }

  public cargarTabla(almacenId: number): void {
    try {
      this.almacenIdSeleccionado = almacenId;
      this.ctx.Almacen.obtenerAlmacenesUsuarios(almacenId)
        .pipe(
          flatMap((direcciones) => {
            this.tabla = direcciones;
            return this.ctx.usuario.obtenerUsuarioActivos();
          })
        )
        .subscribe((direcciones) => {
          this.fuenteDatos = new MatTableDataSource(direcciones);
          this.usuarios = direcciones;
          console.dir(this.fuenteDatos);
        });
    } catch (error) {
      console.log(error);
    }
  }

  cambiarEstado(seleccionado: boolean, idDireccion: number): void {
    if (seleccionado) {
      this.asignar(idDireccion);
    } else {
      this.desasignar(idDireccion);
    }
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  asignar(id: number): void {
    const asignacion = new AsignacionAlmacenUsuario();
    asignacion.almacenId = this.almacenIdSeleccionado;
    asignacion.usuarioId = id;

    this.ctx.Almacen.asignar(asignacion).subscribe(
      () => {
        this.translate.get('messages.asignacion').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
      },
      (error) => {
        this.translate
          .get('messages.asignacionError')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      }
    );
  }

  desasignar(id: number): void {
    const asignacion = new AsignacionAlmacenUsuario();
    asignacion.almacenId = this.almacenIdSeleccionado;
    asignacion.usuarioId = id;

    this.ctx.Almacen.desasignar(asignacion).subscribe(
      () => {
        this.translate
          .get('messages.asignacionEliminada')
          .subscribe((res: string) => {
            this.alerta.mostrarExito(res);
          });
      },
      (error) => {
        this.translate
          .get('messages.asignacionEliminadaError')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      }
    );
  }

  modoAsignada(id: number): boolean {
    const direccion = this.tabla.find((d) => d.id === id);

    if (direccion) {
      return true;
    } else {
      return false;
    }
  }

  descargarExcel(): void {
    let fechaActual = new Date();
    let nombreArchivo = '';
    this.translate
      .get('messages.descargarExcel', { value: fechaActual.toLocaleString() })
      .subscribe((res: string) => {
        nombreArchivo = res;
      });
    this.ctx.asignacionAlmacenUsuario
      .obtenerExcel()
      .toPromise()
      .then((respuesta) => {
        this.llamarExcel(respuesta, nombreArchivo);
      })
      .catch((e) => {});
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  limpiarAlmacenes(): void {
    this.f['almacenDescripcion'].setValue('');
    this.f['almacenId'].setValue(null);
    this.cargarAlmacenes();
  }
}

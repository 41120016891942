export class ArticuloAlternativo {
    id: number;
    codigo: string;
    productoPrincipalId: number;
    productoAlternativoId: number;
    factorCoincidencia: number;
    articuloAlternativo: string;
    articuloPrincipal: string;
    activo: boolean;
}

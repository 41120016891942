import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Usuario } from 'src/app/shared/models/usuario';
import { TipoUsuario } from 'src/app/shared/models/tipo-usuario';
import { Roles } from 'src/app/shared/models/rol';
import { startWith, map } from 'rxjs/operators';
import { UsuarioRoles } from 'src/app/shared/models/usuario-rol';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaRolesUsuarioComponent implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }



  model: UsuarioRoles;
  roles: Roles[] = [];
  rolesFiltrados: Observable<Roles[]>;
  rolesSinFiltrar: Roles[];
  filtroRoles = new FormControl();

  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<UsuarioRoles> = new MatTableDataSource([]);
  columnasMostradas = ['descripcionRol', 'opciones'];

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaRolesUsuarioComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id !== null) {
      this.idSeleccionado = id; 
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        rolId: [0, Validators.required],
        usuarioId: [0, Validators.required],
        descripcionRol: ['', Validators.nullValidator],
      },
    );
    this.cargarRoles();
    this.tabla();
  }

  tabla(): void {
    this.ctx.roles.obtenerRolesPorUsuario(this.idSeleccionado).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al cargar los roles del usuario');
      });
  }


  limpiar(): void {
    this.forma.reset();
    this.cargarRoles();
  }

  cargarRoles() {
    this.ctx.roles.obtenerTodos().toPromise().then(resultado => {
      this.rolesSinFiltrar = resultado;
      this.rolesFiltrados = this.filtroRoles.valueChanges
        .pipe(
          startWith<string | Roles>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarRol(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarRol(nombre: string): Roles[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.rolesSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  rolSeleccionado(rol: Roles) {
    console.log(rol.id);
    this.f['descripcionRol'].setValue(rol.descripcion);
    this.f['rolId'].setValue(rol.id);
    this.f['usuarioId'].setValue(this.idSeleccionado);

  }

  guardar(): void {
    if (this.forma.valid) {
      this.model = this.forma.value as UsuarioRoles;
      this.ctx.roles.guardarUsuarioRol(this.model).toPromise().then(() => {
        this.alerta.mostrarExito("Rol asignado al usuario con éxito");
        this.guardado.emit();
        this.tabla();
        this.limpiar();
      }).catch(e => {
        console.log(e);
        if (e.error === 'Existe') {
          this.alerta.mostrarAdvertencia('¡Ya existe ese rol asignado al usuario! ');
        } else if (e.error === 'ErrorGuardar') {
          this.alerta.mostrarAdvertencia('¡Error al asignar el rol al usuario! ');
        }
        else {
          this.alerta.mostrarError('¡Error interno al guardar! ');
        }
      });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  eliminar(model: UsuarioRoles) {
    this.ctx.roles.eliminarUsuarioRol(model).toPromise().then(() => {
      this.alerta.mostrarExito("Rol de usuario eliminado con éxito");
      this.tabla();
      this.limpiar();
    }).catch(e => {
      console.log(e);
      if (e.error === 'ErrorEliminar') {
        this.alerta.mostrarAdvertencia('¡Error al eliminar el rol del usuario! ');
      }
      else {
        this.alerta.mostrarError('¡Error interno al eliminar! ');
      }
    });
  }


  cerrar(): void {
    this.ventana.close();
  }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Pantallas } from 'src/app/shared/models/pantallas';
import { TipoMenu } from 'src/app/shared/models/tipo-menu';
import { startWith, map } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaPantallaComponent implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
  model: Pantallas;
  actualizar: boolean;
  chkActualiza: boolean;

  tiposMenu: TipoMenu[] = [];
  tiposMenuFiltrados: Observable<TipoMenu[]>;
  tiposMenuSinFiltrar: TipoMenu[];
  filtroTipoMenu = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaPantallaComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.pantallas.obtener(id).toPromise().then(resultado => {
        this.model = resultado;
        Object.assign(this.forma.value, this.model);
        this.forma.reset(this.forma.value);
     
      }).catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al obtener información');
      });
      this.actualizar = false;
      this.chkActualiza = true;
    }
    else {
      this.idSeleccionado = 0;
      this.model = new Pantallas();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        clave: ['', Validators.required],
        descripcion: ['', Validators.required],
        icono: ['', Validators.required],
        activo: [true, Validators.nullValidator],
        tipoMenuDescripcion: ['', Validators.required],
        tipoMenuId: [null, Validators.required],
        
      },
    );
    this.cargarTiposMenu();
  }

  limpiar(): void {
    this.forma.reset();
  }


  cargarTiposMenu() {
    this.ctx.tiposMenu.obtenerTodos().toPromise().then(resultado => {
      this.tiposMenuSinFiltrar = resultado;
      this.tiposMenuFiltrados = this.filtroTipoMenu.valueChanges
        .pipe(
          startWith<string | TipoMenu>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarTipoMenu(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarTipoMenu(nombre: string): TipoMenu[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.tiposMenuSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  tipoMenuSeleccionado(tipo: TipoMenu) {
    console.log(tipo.id);
    this.f['tipoMenuDescripcion'].setValue(tipo.descripcion);
    this.f['tipoMenuId'].setValue(tipo.id);
  }

 

  guardar(): void {
   
    if (this.forma.valid) {

      const model = this.forma.value as Pantallas;

      if (this.idSeleccionado === 0) {
        this.ctx.pantallas.guardar(model).toPromise().then(() => {
          this.alerta.mostrarExito("Tipo de usuario guardado con éxito");
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.alerta.mostrarAdvertencia('¡Ya existe una pantalla con esa descripción! ');
          } else if (e.error === 'ErrorGuardar') {
            this.alerta.mostrarAdvertencia('¡Error al guardar la pantalla! ');
          }
          else {
            this.alerta.mostrarError('¡Error interno al guardar! ');
          }
        });

      } else {
        this.ctx.pantallas.actualizar(this.idSeleccionado, model).toPromise().then(() => {
          this.alerta.mostrarExito("Pantalla actualizada con éxito");
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          
          if (e.error === 'Existe') {
            this.alerta.mostrarAdvertencia('¡Ya existe una pantalla con esa descripción! ');
          } else {
            this.alerta.mostrarError('¡Error interno al guardar! ');
          }
        });
      }
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  Iconos(): void{
    window.open("https://material.io/resources/icons/");
  }

  cerrar(): void {
    this.ventana.close();
  }
}

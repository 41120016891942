import { Detalle } from './detalle';
import { Productos } from './Productos';

export class DetallePedido extends Detalle {
  pedidoId: number;
  cantidadFaltante: number;
  cantidadEntregada: number;
  bloqueado: boolean = false;
  remarcar: boolean = false;
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsignacionAlmacenProducto } from '../models/asignacion-almacen-producto';
import { ExcelAsignacionAlamacenProducto } from '../models/excel-asignacion-almacen-producto';

export class RepositorioAsignacionAlmacenProducto extends Repositorio<
  AsignacionAlmacenProducto
> {
  constructor(http: HttpClient) {
    super(http, 'AsignacionAlmacenProducto');
  }

  generarFolio(): Observable<string> {
    let ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerProductoAlmacen(
    productoId: number
  ): Observable<AsignacionAlmacenProducto[]> {
    const ruta = `${this.ruta}/ObtenerProductoAlmacen/${productoId}`;
    return this.http.get<AsignacionAlmacenProducto[]>(ruta);
  }

  obtenerAlmacenProductos(almacenId: number): Observable<AsignacionAlmacenProducto[]> {
    const ruta = `${this.ruta}/ObtenerAlmacenProductos/${almacenId}`;
    return this.http.get<AsignacionAlmacenProducto[]>(ruta);
  }

  guardarRangoExcel(asignaciones: AsignacionAlmacenProducto[]): Observable<string[]>{
    const ruta = `${this.ruta}/GuardarPorRango`;
    return this.http.post<string[]>(ruta, asignaciones);
  }
}

import {
  AbstractControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Pedido } from 'src/app/shared/models/pedido';
import { groupBy } from 'src/app/utilerias/groupBy';
import { FormaListaDetallePedido } from '../lista-productos/forma-lista-detalle-pedido';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';

export class FormaPedido extends FormGroup {
  constructor(public pedido: Pedido) {
    super(
      new FormBuilder().group({
        folio: ['', Validators.required],
        fechaDocumento: [new Date(), Validators.required],
        referencia: ['', Validators.nullValidator],
        comentarios: ['', Validators.nullValidator],
        esBorrador: [false, Validators.required],
        detalles: new FormaListaDetallePedido(
          pedido ? pedido.detalles : [],
          existenciaInuficiente
        ),
        cantidadTotalPedido: [0, Validators.nullValidator],
        totalLineas: [0, Validators.nullValidator],
        almacenId:  [0, Validators.nullValidator],
        almacenDescripcion: ['', Validators.nullValidator],
        almacenReabastecimientoId:  [0, Validators.nullValidator],
        almacenReabastecimientoDescripcion: ['', Validators.nullValidator]
      }).controls
    );
    this.patchValue(pedido);


    this.valueChanges.subscribe((f) => {
      Object.assign(pedido, this.getRawValue());
    });

    this.get('detalles').valueChanges.subscribe((f) => {
      let detalle = this.get('detalles').value as DetallePedido[];
      let cantidadTotalPedido = detalle.reduce((suma, detalle) => suma + detalle.cantidad, 0);
      let totalLineas = detalle.length;

      this.get('cantidadTotalPedido').setValue(cantidadTotalPedido);
      this.get('totalLineas').setValue(totalLineas);
    });
  }
}




export function existenciaInuficiente(
  control: AbstractControl
): { [key: string]: any } | null {
  const controles = (control as FormaListaDetallePedido).controls.map(
    (f) => f.value
  ) as object[];
  if (controles.length === 0) {
    return null;
  }
  const lista = groupBy('productoId', controles); // Agrupo la lista de detalles por producto
  // Filtro por los productos que no tienen suficiente inventario
  const insuficiente = lista.filter((dato: unknown[]) => {
    const existencia = dato[0]['existencia'] as number;
    const cantidad = dato
      .map((f) => f['cantidad'] as number)
      .reduce((a, b) => a + b, 0); // Sumo las cantidades de todas las líneas que tengan el mismo producto
    if (dato[0]['productoId'] === '') {
      return false; // Si no se ha seleccionado un producto, regreso falso pra evitar falso positivo
    }
    return existencia < cantidad; // Verfico si hay cantidad suficiente
  });
  // Regreso el error en caso de que no haya inventario
  return insuficiente.length > 0
    ? {
        IventarioInsuficiente: {
          mensaje: 'Inventario insuficiente',
        },
      }
    : null;
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reporteador } from '../models/reporteador';
import { Filtro } from '../models/Filtro';
import { isNullOrUndefined } from 'util';

export class RepositorioReporteador extends Repositorio<Reporteador> {
  constructor(http: HttpClient) {
    super(http, 'Reporteador');
  }

  obtenerReporte(buscador: Filtro): Observable<Reporteador[]> {
    const ruta = `${this.ruta}/ObtenerReporte`;
    return this.http.post<Reporteador[]>(ruta, buscador);
  }

  obtenerExcel(buscador: Filtro): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, buscador, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }
}

import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Router } from '@angular/router';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { TranslateService } from '@ngx-translate/core';
import { FormaMensaje } from '../../control-pedidos/opcion-cerrar/forma.component';
import { PedidoSalida } from 'src/app/shared/models/pedido-salida';
import { ComprobantePedidoSalidaComponent } from '../../comprobante-pedido-salida/catalogo.component';
import { Subscription } from 'rxjs';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-control-inventario-pedidos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoControlPedidosSalidasComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  sub: Subscription;
  fuenteDatos: MatTableDataSource<PedidoSalida> = new MatTableDataSource([]);
  columnasMostradas = [
    'folio',
    'codigoAlmacen',
    'codigoReabastecimiento',
    'referencia',
    'esBorrador',
    'fechaDocumento',
    'estatusDocumento',
    'opciones',
  ];

  constructor(
    public ventana: MatDialog,
    private db: Contexto,
    private alerta: ServicioAlerta,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) { }

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarTabla();
      }
    );

  }

  cargarTabla() {

    this.db.pedidosSalida
      .obtenerPedidosSalidasAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((pedidos) => {
        this.fuenteDatos = new MatTableDataSource(pedidos);
        this.fuenteDatos.sort = this.ordenador;
        this.fuenteDatos.paginator = this.paginador;
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  abrirForma(id?: string): void {
    const dato = id ? id : 0;
    this.router.navigate(['/Registro-Pedidos-Salida/', dato]);
  }


  verComprobante(pedido: PedidoSalida): void {
    this.db.pedidosSalida
      .obtenerComprobantePedido(pedido.id)
      .toPromise()
      .then((p) => {
        this.dialog.open(ComprobantePedidoSalidaComponent, {
          data: p,
          panelClass: 'comprobante',
          width: '1000px'
        });
      });
  }


  async cerrarRequisicion(requisicion: PedidoSalida): Promise<void> {
    const modelo = {
      requisicionId: requisicion.id,
      requisicionCodigo: requisicion.folio,
      estatus: "CerrarDocSalida"
    }
    const forma = this.ventana.open(FormaMensaje, {
      data: modelo,
      panelClass: 'form-container',
    });
    let r = await forma.afterClosed().toPromise();
    if (r === "Cerrado") {
      this.cargarTabla();
      this.translate
        .get('messages.documentoCerrado')
        .subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
    }
  }

  async cancelarRequisicion(requisicion: PedidoSalida): Promise<void> {
    const modelo = {
      requisicionId: requisicion.id,
      requisicionCodigo: requisicion.folio,
      estatus: "CancelarDocSalida"
    }
    const forma = this.ventana.open(FormaMensaje, {
      data: modelo,
      panelClass: 'form-container',
    });
    let r = await forma.afterClosed().toPromise();
    if (r === "Cancelado") {
      this.cargarTabla();
      this.translate
        .get('messages.documentoCerrado')
        .subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
    }
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

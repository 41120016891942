import { CierreInventario } from './../../models/cierre-inventario';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MAT_DATE_FORMATS,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from '@angular/material';
import { DATE_FORMATS } from 'src/app/utilerias/date-formats';
import { Contexto } from '../../api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TranslateService } from '@ngx-translate/core';
import { timeStamp } from 'console';

@Component({
  selector: 'app-cierre-inventario',
  templateUrl: './cierre-inventario.component.html',
  styleUrls: ['./cierre-inventario.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class CierreInventarioComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  page = '';
  fecha: Date = new Date();
  reporteAnterior:number;
  mostrarAviso=false;
  columnas = [
    'productoCodigo',
    'productoDescripcion',
    'almacenCodigo',
    'cantidadInventario',
  ];
  fuenteDatos = new MatTableDataSource<CierreInventario>([]);
  constructor(
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private translate: TranslateService
  ) {
    this.translate.get('title.invclose').subscribe((res: string) => {
      this.page = res;
    });
  }
  ngOnInit() {
    this.mostrarAvisoMensual();
  }

  mostrarAvisoMensual(){
    const fechaActual=new Date();
    this.ctx.cierresInventario.obtenerMesAnterior(fechaActual.getFullYear(),fechaActual.getMonth()).toPromise().then(e=>{
      if(e==0){
        this.mostrarAviso=true;
      }
    })
  }
  cargarCierre() {
    this.mostrarAvisoMensual();
    const enviarFecha=new Date(this.fecha)
    this.ctx.cierresInventario
      .obtener(enviarFecha.getUTCFullYear(),enviarFecha.getMonth()+1)
      .toPromise()
      .then((detalleCierre) => {
        if(detalleCierre.length>0){
          this.fuenteDatos = new MatTableDataSource(detalleCierre);
          this.fuenteDatos.paginator = this.paginador;
          this.fuenteDatos.sort=this.ordenador;
        }else{
          this.ctx.cierresInventario.reporteMensual(enviarFecha.getUTCFullYear(),enviarFecha.getMonth()+1).toPromise()
          .then(reporteMensual=>{
            console.log(reporteMensual)
            this.fuenteDatos = new MatTableDataSource(reporteMensual);
            this.fuenteDatos.paginator = this.paginador;
            this.fuenteDatos.sort=this.ordenador;
          })
        }



      })
      .catch((err) => {
        console.log(err);
        this.translate.get('errors.errorleerdocumento').toPromise().then();
      });
  }

  guardarCierre() {
    this.ctx.cierresInventario
      .guardar()
      .toPromise()
      .then(() => {
        this.translate
          .get('messages.guardar', { value: this.page })
          .subscribe((res: string) => {
            this.alerta.mostrarExito(res);
          });
      })
      .catch((err) => {
        console.log(err);
        this.translate
          .get('messages.errorInformacion')
          .toPromise()
          .then((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  limpiarFiltro() {
    this.fecha = null;
  }

  generarReporte(){
    this.ctx.cierresInventario.guardar().toPromise().then(e=>{
      this.cargarCierre();
    }).catch(ex=>{

    })
  }

  descargarExcel() {}
}

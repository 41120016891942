import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
//import { FormaAsignacionAlmacenProducto } from '../forma/forma.component';
import { AsignacionAlmacenProducto } from 'src/app/shared/models/asignacion-almacen-producto';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ExcelAsignacionAlamacenProducto } from 'src/app/shared/models/excel-asignacion-almacen-producto';
import { FormaDocumentoExcelProdouctoCriticoComponent } from '../documentos-excel/forma.component';
import { AsignacionProductoCritico } from 'src/app/shared/models/asignacion-producto-critico';


@Component({
  selector: 'asignacion-almacen',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoAsignacionProductoCritico implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<
    AsignacionProductoCritico
  > = new MatTableDataSource([]);
  sub: Subscription;

  columnasMostradas = [
    'mina',
    'almacen',
    'codigo',
    'codigoProducto',
    'producto',
    'activo',
    'fechaAsignacion'
  ];

  constructor(
    private traslate: TranslateService,
    public ventana: MatDialog,
    private ctx: Contexto,
    private spinner: LoadingService,
    private alerta: ServicioAlerta,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {}
  ngOnInit() {

    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.tabla();
      }
    );
  }

  tabla(): void {
    this.ctx.asignacionProductoCritico
      .obtenerAlmacenProductos(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        console.log(resultado);
        this.fuenteDatos = new MatTableDataSource(resultado);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
      })
      .catch((e) => {
        this.traslate
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  // abrirForma(id?: string): void {
  //   let dato = id ? id : null;
  //   let forma = this.ventana.open(FormaAsignacionAlmacenProducto, {
  //     data: dato,
  //     panelClass: 'form-container',
  //   });
  //   forma.componentInstance.guardado.subscribe(() => {
  //     this.tabla();
  //   });
  // }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {
    this.ctx.asignacionProductoCritico
      .activar(id)
      .toPromise()
      .then(() => {
        this.traslate.get('messages.activar').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
        this.tabla();
      })
      .catch((e) => {
        console.log(e);
        this.traslate.get('messages.activarError').subscribe((res: string) => {
          this.alerta.mostrarError(res);
        });
      });
  }

  desactivar(id: number): void {
    this.ctx.asignacionProductoCritico
      .desactivar(id)
      .toPromise()
      .then(() => {
        this.traslate.get('messages.desactivar').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
        this.tabla();
      })
      .catch((e) => {
        console.log(e);
        this.traslate
          .get('messages.desactivarError')
          .subscribe((res: string) => {
            this.alerta.mostrarExito(res);
          });
      });
  }

  eliminar(id: number): void {
    this.ctx.asignacionProductoCritico
      .eliminar(id)
      .toPromise()
      .then(() => {
        this.traslate.get('messages.eliminar').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
        this.tabla();
      })
      .catch((e) => {
        console.log(e);
        this.traslate.get('messages.eliminarError').subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        });
      });
  }

  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  descargarExcel(): void {
    let fechaActual = new Date();
    let nombreArchivo = '';
    this.traslate
      .get('messages.descargarExcel', { value: fechaActual.toLocaleString() })
      .subscribe((res: string) => {
        nombreArchivo = res;
      });
    this.ctx.asignacionProductoCritico
      .obtenerExcel()
      .toPromise()
      .then((respuesta) => {
        this.llamarExcel(respuesta, nombreArchivo);
      })
      .catch((e) => {});
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async cargarExcel() {
    let forma = this.ventana.open(FormaDocumentoExcelProdouctoCriticoComponent, { data: {}, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => {
      this.tabla();
    });

  }

  descargarGuiaLayout() {
    let archivo = window.open("assets/guias-excel/Layout_asignacion_producto_critico.xlsx", '_blank');
    setTimeout(function () {
      archivo.close();
    }, 100);
    return false;
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

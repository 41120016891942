import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Router } from '@angular/router';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { TranslateService } from '@ngx-translate/core';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { Subscription } from 'rxjs';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ConteosProductos } from '../../models/conteos-productos';
import { ComprobanteConteosProductosComponent } from '../comprobante-conteos-productos/catalogo.component';


@Component({
  selector: 'catalogo-control-inventario-pedidos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoControlConteosPrductosComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  sub: Subscription;
  fuenteDatos: MatTableDataSource<ConteosProductos> = new MatTableDataSource([]);
  columnasMostradas = [
    'folio',
    'usuarioNombreCompleto',
    'fechaRegistro',
    'horaInicio',
    'horaFin',
    'fechaInicial',
    'fechaFinal',
    'esBorrador',
    'diferencias',
    'enviadoPorCorreo',
    'opciones',
  ];

  constructor(
    public ventana: MatDialog,
    private db: Contexto,
    private alerta: ServicioAlerta,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {}

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarTabla();
      }
    );
  }

  cargarTabla() {
    this.db.ConteosProductos
      .ObtenerConteosAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((cierres) => {
        this.fuenteDatos = new MatTableDataSource(cierres);
        this.fuenteDatos.sort = this.ordenador;
        this.fuenteDatos.paginator = this.paginador;
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }



  verComprobante(conteo: ConteosProductos): void {
    this.db.ConteosProductos
      .ObtenerComprobanteConteo(conteo.id)
      .toPromise()
      .then((p) => {
        this.dialog.open(ComprobanteConteosProductosComponent, {
          data: p,
          panelClass: 'comprobante',
          width: '1000px',
        });
      });
  }


  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

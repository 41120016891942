import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormaPedido } from './forma-pedido';
import { Pedido } from 'src/app/shared/models/pedido';
import { FormControl, Validators } from '@angular/forms';
import { Productos } from 'src/app/shared/models/Productos';
import { Detalle } from 'src/app/shared/models/detalle';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';
import { FiltroDetallePedidos } from 'src/app/services/pedidos/filtro-detalle-pedidos.service';
import { Almacen } from 'src/app/shared/models/Almacen';
import { Observable } from 'rxjs';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { startWith, map } from 'rxjs/operators';
import { ProductosAlmacen } from 'src/app/services/productos/productos-almacen.service';

@Component({
  selector: 'app-encabezado-pedido',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.scss'],
})
export class EncabezadoPedidoComponent implements OnInit {
  @Input() forma: FormaPedido;
  @Input() productos: Productos[] = [];
  @ViewChild('filtro', { static: false }) buscador: ElementRef;
  

  almacenes: Almacen[] = [];
  almacenesFiltrados: Observable<Almacen[]>;
  almacenesSinFiltrar: Almacen[];
  filtroAlmacenes = new FormControl();

  formaEmpleado = new FormControl(null, Validators.required);
  get pedido(): Pedido {
    return this.forma.pedido;
  }
  constructor(public filtro: FiltroDetallePedidos,  private ctx: Contexto) { }

  ngOnInit() {this.cargarAlmacenes()}
  guardar() { }


  cargarAlmacenes() {
    this.ctx.Almacen.obtenerReabastecimiento()
      .toPromise()
      .then((resultado) => {
        this.almacenesSinFiltrar = resultado;
        this.almacenesFiltrados = this.filtroAlmacenes.valueChanges.pipe(
          startWith<string | Almacen>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarAlmacenes(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarAlmacenes(nombre: string): Almacen[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.almacenesSinFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  almacenSeleccionado(almacen: Almacen) {
    console.log(almacen.id);
    this.forma.get("almacenReabastecimientoDescripcion").setValue(almacen.descripcion);
    this.forma.get("almacenReabastecimientoId").setValue(almacen.id);
    
  }

  filtrar(codigoPro: string) {
    if (codigoPro != "") {
      const productos = this.pedido.detalles.filter(e =>
        e.productoCodigo === codigoPro
        || e.orden.includes(codigoPro)
        || e.cantidad.toString() === codigoPro
        || e.unidadMedidaCodigo == codigoPro);
      this.filtro.productoFiltrado.next(productos);
    } else {
      this.filtro.productoFiltrado.next([]);
    }
  }

  limpiarAlmacenes(): void {
    this.forma.get("almacenReabastecimientoDescripcion").setValue("");
    this.forma.get("almacenReabastecimientoId").setValue(0);
    this.cargarAlmacenes();
  }

  limpiarFiltro(){
    this.buscador.nativeElement.value = "";
    this.filtro.productoFiltrado.next([]);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cus-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.css']
})
export class CardContent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

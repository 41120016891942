export function groupBy(key: string, array: unknown[]) {
  const group = array.reduce((g: any, obj: unknown) => {
    g[obj[key]] = g[obj[key]] || []; // Check the value exists, if not assign a new array
    g[obj[key]].push(obj); // Push the new value to the array
    return g; // You need to return the value of g or it will become undefined on the next pass
  }, {});
  return Object.keys(group).map((p) => group[p]);
}



export function groupBy2(list: any, keyGetter: any) {
  const map = new Map();
  list.forEach((item: any) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

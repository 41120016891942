import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { DetallePaleta } from 'src/app/shared/models/detalle-paleta';
import { DocumentosExternosExcel } from 'src/app/shared/models/documentos-externos';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'documentos-externos-requisiciones',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class DocumentosExternosRequiciciones implements OnInit {
  @ViewChild('inputArchivos', { read: ElementRef, static: false }) inputArchivos: ElementRef;
  fuenteDatos: MatTableDataSource<DetallePaleta> = new MatTableDataSource([]);
  columnasMostradas = ['partNumber', 'description', 'quantity', 'etdDate', 'eastTime', 'consignee'];

  columnasMostradasAlmacenes = ['almacen'];
  columnasMostradasProductos = ['producto'];

  detallePaleta: DetallePaleta[] = [];
  almacenes: number = 0;
  productos: number = 0;
  


  constructor(public ventana: MatDialog,
    private ctx: Contexto,
    private spinner: LoadingService,
    private alerta: ServicioAlerta,
    private translate: TranslateService,
    private datePipe: DatePipe) { }
  documentos: DocumentosExternosExcel = new DocumentosExternosExcel();
  ngOnInit() {
   
    // let fecha = this.datePipe.transform(new Date(),"dd/MM/yyyy");
    // console.log(fecha);
    



  }
  async archivos() {
    try {
      if (this.detallePaleta.length === 0) {
        this.spinner.show('Verificando requisiciones, espere un momento...');
        let datos = new FormData();
        let xml = (<HTMLInputElement>this.inputArchivos.nativeElement).files[0];
        datos.append("xml", xml);

        let detalles = await this.ctx.documentosExternos.leerXML(datos).toPromise();
        if (detalles) {
          this.detallePaleta = detalles;
          this.fuenteDatos = new MatTableDataSource(detalles);
          await this.verificarPedidos();
          this.spinner.hide();
        } else {
          this.alerta.mostrarAdvertencia('No fue posible leer el XML intente nuevamente.');
          this.spinner.hide();
        }
      } else {
        this.alerta.mostrarAdvertencia('Hay una lista de productos pendiente por guardar.');
      }
    } catch (error) {
      console.log(error);
      this.alerta.mostrarError('Error interno, contacte con el administrador.');
    }
  }

  limpiar() {
    this.detallePaleta.length = 0;
    this.fuenteDatos = new MatTableDataSource(null);
    this.productos = 0;
    this.almacenes = 0;
    this.documentos.almacenes.length = 0;
    this.documentos.productos.length = 0;
    this.documentos.almacenes = [];
    this.documentos.productos = [];
    this.documentos = new DocumentosExternosExcel();
  }

  // async guardarEntrada() {
  //   try {
  //     if (this.detallePaleta.length > 0) {
  //       let guardaEntrada = await this.ctx.documentosExternos.guardarEntradaDetalle(this.detallePaleta).toPromise();
  //       if (guardaEntrada) {
  //         this.alerta.mostrarExito('Documento entrada guardado con éxito.');
  //         this.limpiar();
  //       } else {
  //         this.alerta.mostrarAdvertencia('Error al guardar el documento.');
  //       }
  //     } else {
  //       this.alerta.mostrarAdvertencia('No hay detalles por guardar.');
  //     }
  //   } catch (error) {
  //     this.alerta.mostrarError('Error interno, contacte con el administrador');
  //   }
  // }

  async guardarPedidos() {
    try {
      await this.verificarPedidos();
      if (this.documentos.productos.length <= 0 && this.documentos.almacenes.length <= 0) {
        if (this.detallePaleta.length > 0) {
          let guardadPedido = await this.ctx.documentosExternos.guardarPedidoDetalle(this.detallePaleta).toPromise();
          if (guardadPedido) {
            this.alerta.mostrarExito('Requisiciones guardadas con éxito.');
            this.limpiar();
          } else {
            this.alerta.mostrarAdvertencia('Error al guardar el documento.');
          }
        } else {
          this.alerta.mostrarAdvertencia('No hay detalles por guardar.');
        }
      } else {
        this.alerta.mostrarAdvertencia('Existen registros no identificados, verifique nuevamente.');
      }
    } catch (error) {
      this.alerta.mostrarError('Error interno, contacte con el administrador');
    }
  }

  async verificarPedidos() {

    try {
      if (this.detallePaleta.length > 0) {
        let verificado = await this.ctx.documentosExternos.verificarPedidoDetalle(this.detallePaleta).toPromise();
        this.documentos = verificado;
        this.almacenes = verificado.almacenes.length;
        this.productos = verificado.productos.length;
        if (verificado.almacenes.length > 0 || verificado.productos.length > 0) {
          this.alerta.mostrarAdvertencia('Existen registros no identificados, verifique nuevamente.');
        } else {
          this.alerta.mostrarExito('Requisiciones verificadas, proceda a guardar el documento');
        }
      } else {
        this.alerta.mostrarAdvertencia('No hay detalles por guardar.');
      }
    } catch (error) {
      this.alerta.mostrarError('Error interno, contacte con el administrador');
    }
  }
}
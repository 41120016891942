import { DetalleLogSincronizacion } from "./detalle-log-sincronizacion";

export class LogSincronizacion {
    id: number;
    usuarioId: number;
    nombreCompleto: string;
    almacenId: number;
    fechaInicio: Date | string;
    fechaFin: Date | string;
    folio: string;
    cantidadMov?: number;
    movSincronizados?: number;
    ubicacionDescripcion: string;
    almacenDescripcion: string;
    detalle: DetalleLogSincronizacion[] = [];
  }
  
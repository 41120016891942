import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Router } from '@angular/router';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-no-acceso',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoNoAccesoComponent implements OnInit {
  constructor( private router: Router,) { }
  ngOnInit() {}

  atras(): void {
    this.router.navigate(['/']);
  }
}
import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExcelDetalleRequisicion } from '../models/excel-detalle-requisicion.';
import { Respuesta } from '../models/respuesta';
import { Traspaso } from '../models/traspaso';
import { DetalleTraspaso } from '../models/detalle-traspaso';

export class RepositorioTraspaso extends Repositorio<Traspaso> {
  constructor(http: HttpClient) {
    super(http, 'Traspaso');
  }

//   obtenerExcel(id: number): Observable<Blob> {
//     const ruta = `${this.ruta}/DescargarExcel`;
//     return this.http
//       .post(ruta, id, {
//         responseType: 'blob' as 'blob',
//       })
//       .pipe(
//         map((res: Blob) => {
//           return res;
//         })
//       );
//   }

  obtenerTraspasosAlmacen(almacenId: number): Observable<Traspaso[]> {
    const ruta = `${this.ruta}/ObtenerTraspasosAlmacen/${almacenId}`;
    return this.http.get<Traspaso[]>(ruta);
  }


  ObtenerDetalles(id: number): Observable<DetalleTraspaso[]> {
    const ruta = `${this.ruta}/ObtenerDetalleTraspasos/${id}`;
    return this.http.get<DetalleTraspaso[]>(ruta);
  }

  obtenerTraspasoDetalle(id: number): Observable<Traspaso> {
    const ruta = `${this.ruta}/ObtenerTraspasoDetalle/${id}`;
    return this.http.get<Traspaso>(ruta);
  }

  obtenerComprobanteTraspaso(id: number): Observable<Traspaso> {
    const ruta = `${this.ruta}/ObtenerComprobanteTraspaso/${id}`;
    return this.http.get<Traspaso>(ruta);
  }

  generarFolio(almacenId: number): Observable<Respuesta<string>> {
    const ruta = `${this.ruta}/GenerarFolio/${almacenId}`;
    return this.http.get<Respuesta<string>>(ruta);
  }

  generarTraspaso(traspaso: Traspaso): Observable<Respuesta<Traspaso>> {
    const ruta = `${this.ruta}/GenerarTraspaso`;
    return this.http.post<Respuesta<Traspaso>>(ruta, traspaso);
  }

}

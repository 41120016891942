import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AutenticacionService } from './autenticacion.service';



@Injectable()
export class AccesoTokenInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AutenticacionService) { }

    intercept(solicitud: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let tokenBearer = `Bearer ${this.authenticationService.obtenerToken()}`; // me lo traigo de local storage
        let encabezados = new HttpHeaders({
            "Authorization": tokenBearer,
        });

        let solicitudToken = solicitud.clone({ headers: encabezados });
        return next.handle(solicitudToken).pipe(catchError(err => {
            if ([401, 403].indexOf(err.status) !== -1) {
                this.authenticationService.salir();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
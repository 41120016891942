import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReporteAntiguedadStock } from '../models/reporte-antiguedad-stock';
import { ReporteadorFiltro } from '../models/reporteador-filtro';

export class RepositorioReporteAntiguedadStock extends Repositorio<ReporteAntiguedadStock> {
  constructor(http: HttpClient) {
    super(http, 'ReporteAntiguedadStock');
  }



  obtenerReporteAntiguedadStock(model: ReporteadorFiltro): Observable<ReporteAntiguedadStock[]> {
    const ruta = `${this.ruta}/ObtenerReporteAntiguedadStock`;
    return this.http.post<ReporteAntiguedadStock[]>(ruta, model);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }
}

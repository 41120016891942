import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExcelAsignacionAlamacenProducto } from '../models/excel-asignacion-almacen-producto';
import { AsignacionProductoCritico } from '../models/asignacion-producto-critico';

export class RepositorioAsignacionProductoCritico extends Repositorio<
  AsignacionProductoCritico
> {
  constructor(http: HttpClient) {
    super(http, 'AsignacionProductoCritico');
  }

  generarFolio(): Observable<string> {
    let ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  obtenerProductoCritico(
    productoId: number
  ): Observable<AsignacionProductoCritico[]> {
    const ruta = `${this.ruta}/ObtenerProductoCritico/${productoId}`;
    return this.http.get<AsignacionProductoCritico[]>(ruta);
  }

  obtenerAlmacenProductos(almacenId: number): Observable<AsignacionProductoCritico[]> {
    const ruta = `${this.ruta}/ObtenerProductoCriticoAlmacen/${almacenId}`;
    return this.http.get<AsignacionProductoCritico[]>(ruta);
  }

  guardarRangoExcel(almacenId:number, asignaciones: AsignacionProductoCritico[]): Observable<string[]>{
    const ruta = `${this.ruta}/GuardarPorRango/${almacenId}`;
    return this.http.post<string[]>(ruta, asignaciones);
  }



} 

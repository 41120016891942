import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { TurnosConteos } from '../models/turnos-conteos';
import { DiaInhabil } from '../models/Dias-Inhabiles';

export class RepositorioDiasInhabiles extends Repositorio<DiaInhabil> {
  constructor(http: HttpClient) {
    super(http, 'DiaInhabil');
  }

  ObtenerDiasInhabilesAlmacen(almacenId: number): Observable<DiaInhabil[]> {
    const ruta = `${this.ruta}/ObtenerDiasInhabilesAlmacen/${almacenId}`;
    return this.http.get<DiaInhabil[]>(ruta);
  }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProcesoEstatus } from '../models/ProcesoEstatus';
import { map } from 'rxjs/operators';

export class RepositorioProcesoEstatus extends Repositorio<ProcesoEstatus> {
  constructor(http: HttpClient) {
    super(http, 'ProcesoEstatus');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerProcesoEstatus(id: number): Observable<ProcesoEstatus[]> {
    const ruta = `${this.ruta}/ObtenerPantallasRoles/${id}`;
    return this.http.get<ProcesoEstatus[]>(ruta);
  }

  obtenerProcesosActivos(): Observable<ProcesoEstatus[]> {
    const ruta = `${this.ruta}/ObtenerPantallasActivos`;
    return this.http.get<ProcesoEstatus[]>(ruta);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  generarFolio(): Observable<string> {
    let ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }
}

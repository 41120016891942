import { Component, OnInit, HostBinding } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'cus-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class CusPage implements OnInit {
  @HostBinding('style.padding') padding: string;

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe(Object.values(Breakpoints)).subscribe(result => {
      console.log(result);
      const xs = result.breakpoints[Breakpoints.XSmall];
      if (xs) {
        this.padding = '0';
      } else {
        this.padding = '1rem';
      }
    });
  }

  ngOnInit() {}
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdministradorCorreos } from '../models/administrador-correos';

export class RepositorioAdministradorCorreos extends Repositorio<
  AdministradorCorreos
> {
  constructor(http: HttpClient) {
    super(http, 'AdministradorCorreos');
  }

  generarFolio(): Observable<string> {
    let ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  activarSSL(id: number): Observable<void> {
    const ruta = `${this.ruta}/ActivarSSL/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivarSSL(id: number): Observable<void> {
    const ruta = `${this.ruta}/DesactivarSSL/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }
}

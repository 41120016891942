import { Injectable } from "@angular/core";
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class ImportacionExcelService {

  arrayBuffer: any;
  public async LeerArchivo(archivo: File, data: any): Promise<any> {
    let Json: any;


    var arr = new Array();
    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");
    var workbook = XLSX.read(bstr, { type: "binary" });
    var first_sheet_name = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[first_sheet_name];
    Json = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    return Json;
  }
}

import { Injectable, Inject } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';
import { DetalleTraspaso } from 'src/app/shared/models/detalle-traspaso';



@Injectable({
    providedIn: 'root',
})
export class FiltroDetalleTraspasos {

    public productoFiltrado = new BehaviorSubject<DetalleTraspaso[]>([]);
    constructor() {
    }


}

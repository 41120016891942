import { Component } from '@angular/core';
import { LoadingService } from './loading.service';



@Component({
  selector: 'loading',
  template: `
    <ngx-spinner>
        {{ spinner.mensaje }}
    </ngx-spinner>
  `,
  styleUrls: ['./loading.component.scss']
})
export class LoadComponent {
  constructor(public spinner: LoadingService) { }
}

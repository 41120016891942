import { Component, OnInit, Input } from '@angular/core';
import { FormaListaDetalleTraspaso } from './forma-lista-detalle-traspaso';
import { Productos } from 'src/app/shared/models/Productos';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lista-detalle-traspaso',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.scss'],
})
export class ListaDetallesTraspasoComponent implements OnInit {
  @Input() formaLista: FormaListaDetalleTraspaso;
  @Input() almacenId: number;
  subNuevoAlmacen = new Subscription();
  @Input() productos: Productos[] = [];
  constructor(private db: Contexto) { }

  ngOnInit() {}
}

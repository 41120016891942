import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';

@Injectable({
  providedIn: 'root'
})
export class GuardiaMenuSeguridad implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private autenticacion: AutenticacionService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    let autorizado = false;

    const descripcion = url.replace('/', '');
    const seguridad = this.autenticacion.credencial.pantallasSeguridad.filter(e => e.clave === descripcion);

    if (seguridad.length > 0) {
        autorizado = true;
    } else {
        autorizado = false;
        this.router.navigate(['AccesoRestringido']);
    }
    return autorizado;
  }
}

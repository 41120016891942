import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-periodo-inventario',
  templateUrl: './periodo-inventario.component.html',
  styleUrls: ['./periodo-inventario.component.scss'],
})
export class PeriodoInventarioComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { DetalleConteosProductos } from "./detalle-conteos-productos";

export class ConteosProductos{
    id: number;
    fechaRegistro: Date | string;
    folio: string;
    fechaInicio: Date | string;
    fechaFinal: Date | string;
    fechaInicioConteo: Date | string;
    fechaFinalConteo: Date| string;
    cantidadDiferencias: number;
    esBorrador: boolean;
    enviadoPorCorreo: boolean;
    usuarioId: number;
    almacenId: number;
    usuarioNombreCompleto: string;
    detallesConteo: DetalleConteosProductos[] = [];
}
export class AdministradorCorreos {
    id: number;
    codigo: string;
    direccion: string;
    contrasena: string;
    servidor: string;
    puerto: string;
    usaSSL: boolean;
    activo: boolean;
}

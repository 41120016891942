import { Component, OnDestroy, OnInit } from '@angular/core';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Grafica } from 'src/app/shared/models/grafica';
import { LoadingService } from 'src/app/loading/loading.service';
import { Subscription } from 'rxjs';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';

@Component({
  selector: 'db-eficiencia',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class DdEficiencia implements OnInit, OnDestroy{

  sub: Subscription;

  grafica: Grafica = new Grafica();
  graficaAnterior: Grafica = new Grafica();
  constructor(private ctx: Contexto, private spinner: LoadingService, public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) { }
  ngOnInit() {

    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarGrafica();
        this.cargarGraficaAnterior();
      }
    );
  }

  async cargarGrafica() {
    let eficiencia = await this.ctx.dashbord.obtenerEficienciasAnioActual(this.autenticacion.credencial.almacenId).toPromise();
    if(eficiencia)
    {
      this.grafica = eficiencia;

      console.log(eficiencia);
    }
  }

  async cargarGraficaAnterior() {
    let eficiencia = await this.ctx.dashbord.obtenerEficienciasAnioAnterior(this.autenticacion.credencial.almacenId).toPromise();
    if(eficiencia)
    {
      this.graficaAnterior = eficiencia;

      console.log(eficiencia);
    }
  }
  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}





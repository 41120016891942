import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
//import { FormaAsignacionAlmacenProducto } from '../forma/forma.component';
import { AsignacionAlmacenProducto } from 'src/app/shared/models/asignacion-almacen-producto';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ExcelAsignacionAlamacenProducto } from 'src/app/shared/models/excel-asignacion-almacen-producto';
import { AsignacionProductoCritico } from 'src/app/shared/models/asignacion-producto-critico';
import { Conteos } from 'src/app/shared/models/conteos';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConteoProducto } from 'src/app/shared/models/conteoProducto';
import { ComprobanteConteoComponent } from '../../comprobante-conteo/catalogo.component';
import { ComprobanteCalcularConteoComponent } from '../../comprobante-calcular-conteo/catalogo.component';
import { ComprobanteConteoSemanalComponent } from '../../comprobante-conteo-semanal/catalogo.component';


@Component({
  selector: 'asignacion-almacen',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoCalcularConteo implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  dias: 0;

  forma: FormGroup;

  fuenteDatos: MatTableDataSource<
    Conteos
  > = new MatTableDataSource([]);
  sub: Subscription;

  columnasMostradas = [
    'id',
    'almacen',
    'descripcion',
    'opciones',
  ];


  constructor(
    private traslate: TranslateService,
    public ventana: MatDialog,
    private ctx: Contexto,
    private spinner: LoadingService,
    private alerta: ServicioAlerta,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) { }
  ngOnInit() {

    this.forma = this.formBuilder.group({
      dias: [0],
      almacen: [''],
      almacenId: [0],
    });

    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.tabla();
      }
    );
  }

  tabla(): void {
    this.ctx.ControlConteos
      .ObtenerCatalogoConteos(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        console.log(resultado);
        this.fuenteDatos = new MatTableDataSource(resultado);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
      })
      .catch((e) => {
        this.traslate
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  //Calcular conteos diario y mensual
  async CalcularConteo(almacenId = this.autenticacion.credencial.almacenId) {
    const periodos = await this.ctx.ControlConteos.PeriodoActivo(almacenId = this.autenticacion.credencial.almacenId).toPromise();
    const calculado = await this.ctx.ControlConteos.EstaCalculado(almacenId = this.autenticacion.credencial.almacenId).toPromise();
    const productoCritico = await this.ctx.ControlConteos.ExisteListaCritica(almacenId = this.autenticacion.credencial.almacenId).toPromise();
    const restriccionConteoMensual = await this.ctx.ControlConteos.ExisteRestriccionFechaMensual(almacenId = this.autenticacion.credencial.almacenId).toPromise();

    if (periodos.length > 0) {
      if (calculado.length == 0) {
        if (productoCritico.length > 0) {
          if (restriccionConteoMensual.length > 0) {



            this.ctx.ControlConteos
              .ObtenerComprobanteCalculoConteo(this.autenticacion.credencial.almacenId)
              .toPromise()
              .then((p) => {
                const dialogRef = this.dialog.open(ComprobanteCalcularConteoComponent, {
                  data: p,
                  panelClass: 'comprobante',
                  width: '1000px',
                });

                dialogRef
                  .afterClosed()
                  .subscribe((resultado: { acepto: boolean }) => {
                    if (resultado.acepto) {
                      this.spinner.show('Espera un momento, estamos calculando: Conteo Diario / Mensual.');
                      this.ctx.ControlConteos
                        .calcularConteo(almacenId)
                        .toPromise()
                        .then((resultado) => {
                          console.log(resultado);
                          this.spinner.hide();
                          this.alerta.mostrarExito("Conteos calculados con exito.");
                        })
                        .catch((e) => {
                          this.spinner.hide();
                          this.traslate
                            .get('messages.errorInformacion')
                            .subscribe((res: string) => {
                              this.alerta.mostrarError(res);
                            });
                        });
                    }
                  });

              });

          } else {
            this.alerta.mostrarAdvertencia("No se ha asignado fecha para conteo mensual.");
          }
        } else {
          this.alerta.mostrarAdvertencia("No se ha asignado el producto critico.");
        }
      } else {
        this.alerta.mostrarAdvertencia("Ya se realizo el calculo para este periodo.");
      }
    } else {
      this.alerta.mostrarAdvertencia("No existe periodo activo.");
    }

  }

  //Ver comprobante Diario/Mensual
  verComprobante(id: number, almacenId = this.autenticacion.credencial.almacenId) {
    this.ctx.ControlConteos
      .ObtenerComprobanteConteo(id, almacenId)
      .toPromise()
      .then((p) => {
        this.dialog.open(ComprobanteConteoComponent, {
          data: p,
          panelClass: 'comprobante',
          width: '1000px',
        });
      });
  }

  verComprobanteSemanal(id: number, almacenId = this.autenticacion.credencial.almacenId) {
    this.ctx.ControlConteos
      .ObtenerComprobanteConteo(id, almacenId)
      .toPromise()
      .then((p) => {
        this.dialog.open(ComprobanteConteoSemanalComponent, {
          data: p,
          panelClass: 'comprobante',
          width: '1000px',
        });
      });
  }



  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  limpiarFiltro(): void {
    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }


  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

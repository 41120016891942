import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DetallePedido } from '../models/detalle-pedido';
import { ExcelDetalleRequisicion } from '../models/excel-detalle-requisicion.';
import { Respuesta } from '../models/respuesta';
import { PedidoSalida } from '../models/pedido-salida';

export class RepositorioPedidoSalida extends Repositorio<PedidoSalida> {
  constructor(http: HttpClient) {
    super(http, 'PedidosSalidas');
  }

  generarFolio(almacenId: number): Observable<Respuesta<string>> {
    const ruta = `${this.ruta}/GenerarFolio/${almacenId}`;
    return this.http.get<Respuesta<string>>(ruta);
  }

  obtenerExcel(id: number): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, id, {
        responseType: 'blob' as 'blob',
      })
      .pipe(
        map((res: Blob) => {
          return res;
        })
      );
  }

  generarRequisicion(pedido: PedidoSalida): Observable<Respuesta<PedidoSalida>> {
    const ruta = `${this.ruta}/GenerarRequisicion`;
    return this.http.post<Respuesta<PedidoSalida>>(ruta, pedido);
  }

  actualizarRequisicion(pedido: PedidoSalida): Observable<Respuesta<PedidoSalida>> {
    const ruta = `${this.ruta}/ActualizarRequisicion`;
    return this.http.post<Respuesta<PedidoSalida>>(ruta, pedido);
  }


  obtenerPedidosSalidasAlmacen(almacenId: number): Observable<PedidoSalida[]> {
    const ruta = `${this.ruta}/ObtenerPedidosSalidasAlmacen/${almacenId}`;
    return this.http.get<PedidoSalida[]>(ruta);
  }


  // Regresa las requisiciones que aun están pendientes
  obtenerAbiertos(): Observable<PedidoSalida[]> {
    const ruta = `${this.ruta}/Abiertos`;
    return this.http.get<PedidoSalida[]>(ruta);
  }

  ObtenerDetalles(id: number): Observable<DetallePedido[]> {
    const ruta = `${this.ruta}/ObtenerDetalleRequisicion/${id}`;
    return this.http.get<DetallePedido[]>(ruta);
  }

  obtenerPedidoDetalle(id: number): Observable<Respuesta<PedidoSalida>> {
    const ruta = `${this.ruta}/ObtenerPedidoDetalle/${id}`;
    return this.http.get<Respuesta<PedidoSalida>>(ruta);
  }

  obtenerComprobantePedido(id: number): Observable<PedidoSalida> {
    const ruta = `${this.ruta}/ObtenerComprobantePedido/${id}`;
    return this.http.get<PedidoSalida>(ruta);
  }

  cerrarComprobantePedido(id: number): Observable<PedidoSalida> {
    const ruta = `${this.ruta}/CerrarComprobantePedido/${id}`;
    return this.http.get<PedidoSalida>(ruta);
  }

  cancelarComprobantePedido(id: number): Observable<PedidoSalida> {
    const ruta = `${this.ruta}/CancelarComprobantePedido/${id}`;
    return this.http.get<PedidoSalida>(ruta);
  }

  cargarDetalleRequisicionExcel(items: ExcelDetalleRequisicion[]): Observable<ExcelDetalleRequisicion[]> {

    let ruta = `${this.ruta}/CargarDetalleRequisicionExcel`;
    return this.http.post(ruta, items).pipe(
      map((articulos: ExcelDetalleRequisicion[]) => {
        return articulos;
      })
    );
  }
}

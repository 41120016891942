import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormaDetallePedido } from './forma-detalle-pedido';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Productos } from 'src/app/shared/models/Productos';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';
import { FiltroDetallePedidos } from 'src/app/services/pedidos/filtro-detalle-pedidos.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ValidadorFormas } from 'src/app/utilerias/validadorFormas';
import { ProductosAlmacen } from 'src/app/services/productos/productos-almacen.service';
import { MAT_DATE_FORMATS } from '@angular/material';
import { DATE_FORMATS_COMPLETE } from 'src/app/utilerias/date-formats';
@Component({
  selector: 'app-detalle-pedido',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS_COMPLETE }],
})
export class DetallePedidoComponent implements OnInit {

  @Input() forma: FormaDetallePedido;
  @Input() almacenId: number;
  @Input() productos: Productos[] = [];
  productosFiltrados: Observable<Productos[]>;
  lineaBloqueada: boolean = false;
  formaProducto = new FormControl(null, Validators.required);
  subFiltro: Subscription;
  subNuevosProductos = new Subscription();
  colorear: boolean = false;
  get producto(): Productos {
    return this.formaProducto.value;
  }
  get unidadMedida(): string {
    return this.producto ? this.producto.unidadMedidaDescripcion : '';
  }

  constructor(public filtro: FiltroDetallePedidos, public nuevosProductos: ProductosAlmacen) { }

  ngOnInit() {

    if (this.forma.detalle.cantidadFaltante == 0) {
      this.forma.disable();
      this.formaProducto.disable();

    }
    this.forma.get('cantidad').valueChanges.subscribe((e) => {
      this.forma.get('cantidadFaltante').setValue(e);
    });

    // this.forma.get('fechaSolicitud').valueChanges.subscribe((e) => {
    //   let fecha = new Date();
    //   let r = this.diaConHoraActual(fecha);
    //   console.log(r);

    // });

    this.subFiltro = this.filtro.productoFiltrado.subscribe((productos) => {
      this.colorear = false;
      let productoDet = this.forma.get("productoCodigo").value;
      if (productos && productos.length > 0) {
        for (const pro of productos) {
          if (productoDet === pro.productoCodigo) {
            this.colorear = true;
          }
        }
      } else {
        this.colorear = false;
      }
    });
    this.cargarProductos();
  }

  public diaConHoraActual(fechaDocumento: Date): Date {
    let fechaSeleccionada: Date = fechaDocumento;
    let fechaActual: Date = new Date();
    fechaSeleccionada.setHours(fechaActual.getHours());
    fechaSeleccionada.setMinutes(fechaActual.getMinutes());
    fechaSeleccionada.setSeconds(fechaActual.getSeconds());
    let fechaDoc = fechaSeleccionada;
    return fechaDoc;
  }

  

  cargarProductos() {
    const productoId = this.forma.get('productoId').value;
    if (productoId > 0) {
      const producto = this.productos.find((p) => p.id === productoId);
      this.formaProducto.setValue(producto);
      this.forma.get('existencia').setValue(producto.existencia);
    } else {
      this.formaProducto.setValue(null);
      this.forma.get('existencia').setValue(0);
    }
    this.productosFiltrados = this.formaProducto.valueChanges.pipe(
      startWith<string | Productos>(''),
      map((producto) =>
        typeof producto === 'string'
          ? producto
          : producto == null
            ? ''
            : producto.codigo
      ),
      map((dato) => this.filtrar(dato))
    );
  }

  filtrar(dato: string): Productos[] {
    const valorFiltro = dato ? dato.toLowerCase() : '';
    const filtro = this.productos.filter(
      t =>
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  campoMostrar(producto: Productos) {
    let mostrar = '';
    if (producto) {
      mostrar = `${producto.codigo} - ${producto.descripcion}`;
    }
    return mostrar;
  }

  seleccionarProducto(producto: Productos) {
    this.forma.get('productoId').setValue(producto.id);
    this.forma.get('productoCodigo').setValue(producto.codigo);
    this.forma.get('productoDescripcion').setValue(producto.productoDescripcion);
    this.forma.get('unidadMedidaCodigo').setValue(producto.unidadMedidaClave);
    this.forma.get('existencia').setValue(producto.existencia != null ? producto.existencia : 0);
  }




  
  // ngOnDestroy(): void {
  //   if (this.subFiltro != undefined) {
  //     this.subFiltro.unsubscribe();
  //   }
  //   // if (this.subNuevosProductos != undefined) {
  //   //   this.subNuevosProductos.unsubscribe();
  //   // }
  // }
}

 
import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Pedido } from '../../models/pedido';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { ConteoProducto } from '../../models/conteoProducto';
import { element } from 'protractor';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { DatePipe } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-pedido',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteConteoComponent implements OnInit {
  columnas = [
    'Conteo',
    'ProductoDescripcion',
    'Codigo',
    'Dia',
    'Prioridad',
  ];
  conteoProducto: ConteoProducto = new ConteoProducto();
  cantidadTotal: number = 0;
  cantidadLineas: number = 0;
  fechaDocumento: Date;
  fecha: string;
  ubicacionDescripcion: string;
  almacenDescripcion: string;
  conteo: string = "";
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
      jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ComprobanteConteoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public conProd: ConteoProducto,
    private exportAsService: ExportAsService,
    public autenticacion: AutenticacionService,
    private datePipe: DatePipe
  ) {


    this.conteoProducto = conProd;
    this.almacenDescripcion = this.autenticacion.credencial.almacenDescripcion;
    this.fechaDocumento = new Date(Date.now());
    this.fecha = this.datePipe.transform(new Date(Date.now()), "dd-MM-yyyy");
    this.cantidadLineas = Object.keys(conProd).length
  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Conteo ' + this.fecha).subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Conteo ' + this.fecha).subscribe();
  }
  ngOnInit() { }

  regresar() {
    this.dialogRef.close();
  }
}

import { HttpClient } from '@angular/common/http';
import { Maquinarias } from "../models/maquinarias";
import { Repositorio } from "./repositorio";
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GrupoProductos } from '../models/Grupo-Producto';

export class RepositorioMaquinarias extends Repositorio<Maquinarias> {
    constructor(http: HttpClient) {
      super(http, 'Maquinarias');
    }
  
  
    activar(id: number): Observable<void> {
      const ruta = `${this.ruta}/Activar/${id}`;
      return this.http.put<void>(ruta, null);
    }
  
    desactivar(id: number): Observable<void> {
      const ruta = `${this.ruta}/Desactivar/${id}`;
      return this.http.put<void>(ruta, null);
    }
  
  
    obtenerMaquinariasPorAlmacen(almacenId: number): Observable<Maquinarias[]> {
      const ruta = `${this.ruta}/ObtenerMaquinariasPorAlmacen/${almacenId}`;
      return this.http.get<Maquinarias[]>(ruta);
    }
  
    obtenerExcel(): Observable<Blob> {
      const ruta = `${this.ruta}/DescargarExcel`;
      return this.http
        .post(ruta, null, {
          responseType: 'blob' as 'blob',
        })
        .pipe(
          map((res: Blob) => {
            return res;
          })
        );
    }
  }
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Label, Color } from 'ng2-charts';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map, first } from 'rxjs/operators';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';

@Component({
  selector: 'app-inventarioKPI',
  templateUrl: 'catalogo.component.html',
  styleUrls: ['catalogo.component.scss'],
})
export class InventorikpiComponent implements OnInit, OnDestroy {
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'A',
          type: 'linear',
          position: 'left',
          ticks: {
            min: 80,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  sub: Subscription;
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    {
      data: [0],
      type: 'line',
      label: '2020',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    {
      data: [0],
      type: 'line',
      label: '2021',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    {
      data: [0],
      type: 'line',
      label: 'Goal',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0],
      label: 'Inventory Reliability',
      backgroundColor: [
        '#BEBFC1',
        '#BEBFC1',
        '#BEBFC1',
        '#BEBFC1',
        '#2E86C1',
        '#2E86C1',
        '#2E86C1',
        '#2E86C1',
        '#2E86C1',
      ],
      yAxisID: 'A',
    },
  ];
  myControl = new FormControl();
  options: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  mseseIngles: string[] = [
    'January',
    'Febreary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'Octuber',
    'November',
    'Decemeber',
  ];
  filteredOptions: Observable<string[]>;

  public barChartPlugins = [{
    beforeInit: function(chart, options) {
      chart.legend.afterFit = function() {
        this.height += 20;
      };
    }
  }];
  constructor(private ctx: Contexto,   public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) {}

  ngOnInit() {
    this.obtnermeses();
    /*this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );*/
      this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
        (credencial) => {
          this.generarBusqueda();
        }
      );


  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  mesSeleccionado() {
    const mes = new Date().getMonth();
    console.log(mes);
    this.barChartLabels = [];
    var mostrarMeses =
      window.localStorage.getItem('language') == 'es'
        ? this.options
        : this.mseseIngles;
    this.barChartLabels = [
      mostrarMeses[mes - 4],
      mostrarMeses[mes - 3],
      mostrarMeses[mes - 2],
      mostrarMeses[mes - 1],
      'WK1',
      'WK2',
      'WK3',
      'WK4',
      'WK5',
    ];

    //var mesesAMostrar=[mostrarMeses[mes-4],mostrarMeses[mes-3],mostrarMeses[mes-2],mostrarMeses[mes-1]];
    //var barchart=mesesAMostrar.concat(semanasElegidas);
    //var semanasElegidas=window.localStorage.getItem("language")=='es'?["Sem1","Sem2","Sem3","Sem4","Sem5"]:["WK1","WK2","WK3","WK4","WK5"]
    //this.barChartLabels=barchart;
  }
  generarBusqueda() {
    this.mesSeleccionado();
    this.ctx.KPIS.InventarioKPI(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((e) => {
        const anio = Array.from(Array(9), (x) => e.anioAnterior);
        const meta = Array.from(Array(9), (x) => e.meta);
        const aniocurso = Array.from(Array(9), (x) => e.anioCurso);
        let promedios: Array<number> = e.promedioMensual.concat(
          e.promedioSemanal
        );
        this.barChartData[3].data = promedios;
        this.barChartData[3].label = 'Inventory';
        this.barChartData[0].data = anio;
        this.barChartData[1].data = aniocurso;
        this.barChartData[2].data = meta;
      });
  }
  obtnermeses() {
    const date = new Date();
    const dateMonth = date.getMonth;
    console.log(dateMonth);
    const dateYear = date.getFullYear;
    console.log(dateYear);
  }
  // events
  /* public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }*/

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

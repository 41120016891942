import {Component,OnInit,Inject,EventEmitter } from '@angular/core';
import {FormControl,FormGroup,FormBuilder, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {Contexto} from 'src/app/shared/api/contexto.service';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {Almacen} from 'src/app/shared/models/Almacen';
import {ListasPrecios} from 'src/app/shared/models/listas-precios';
import {ServicioAlerta} from 'src/app/utilerias/alerta.service';
import { CrearListaComponent } from '../../ListaDePrecios/CrearLista/crearLista.Component';
import {TranslateService} from '@ngx-translate/core';
@Component({
  templateUrl:'./agregarLista.component.html',
  styleUrls:['./agregarLista.component.scss']
})

export class agregarListaComponent implements OnInit{
  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<CrearListaComponent>,private ctx:Contexto,
  private formBuilder:FormBuilder,private alerta:ServicioAlerta,private translate:TranslateService){
    console.log(id)
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.Almacen.obtener(id).toPromise().then(resultado => {
        console.log(resultado)
        this.modelo = resultado;
      }).catch(e => {
        console.log(e);
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }
  }

  forma:FormGroup;
  idSeleccionado: number;
  modelo: Almacen;

  get f(){return this.forma.controls}
  listasFiltrados: Observable<ListasPrecios[]>;
  listasSinFiltrar: ListasPrecios[];
  listasfiltro = new FormControl();
  guardado: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(){
    this.forma=this.formBuilder.group({
      descripcionLista:['',Validators.required],
      decripcionListaId:[0,Validators.required]
    });
    this.cargarListas();
  }

  guardar(): void {
    if (this.forma.valid) {

      const model = this.forma.value as Almacen;
      this.ctx.Almacen.listaAlmacenesPrecios(this.idSeleccionado,this.f['decripcionListaId'].value).toPromise().then(()=>{
        this.translate.get("messages.guardar",{value:""}).subscribe(re=>{
          this.alerta.mostrarExito(re);
        })
        this.cerrar();
      })

    }
    else {
      this.translate.get('messages.datosInvalidod').subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    }
  }


  cargarListas() {
    this.ctx.ListaDePrecio.obtenerTodos().toPromise().then(resultado => {
      this.listasSinFiltrar = resultado;
      this.listasFiltrados = this.listasfiltro.valueChanges
        .pipe(
          startWith<string | ListasPrecios>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarLista(t))

        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarLista(modelo: string): ListasPrecios[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = this.listasSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }
  limpiarFiltroListas(): void {
    this.f['descripcionLista'].setValue('');
    this.f['decripcionListaId'].setValue(null);
    this.cargarListas();
  }

  listaSeleccionado(modelo: ListasPrecios) {
    this.f['descripcionLista'].setValue(modelo.descripcion);
    this.f['decripcionListaId'].setValue(modelo.id);
  }

  cerrar(): void {
    this.ventana.close();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cus-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.css']
})
export class CusCardHeader implements OnInit {
  constructor() {}

  ngOnInit() {}
}



export class AsignacionAlmacenProducto{
    Id:number;
    codigo:string;
    codigoProducto: string;
    productoId:number;
    almacenId:number;
    producto:string;
    almacen:string;
    mina: string;
    activo:boolean;
    sku: string;
    material: string;
    otro: string;
    ideal: string;
    maximo: string;
}

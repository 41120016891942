import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Router } from '@angular/router';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { ThemeService } from 'src/app/services/theming/theme.service';
import { Login } from 'src/app/shared/models/acceso';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './pagina.component.html',
  styleUrls: ['./pagina.component.scss'],
})
export class PaginaLoginComponent implements OnInit {
  forma: FormGroup;
  get f() {
    return this.forma.controls;
  }
  constructor(
    public servicio: TranslateService,
    private formBuilder: FormBuilder,
    public themeService: ThemeService,
    private alerta: ServicioAlerta,
    private ctx: Contexto,
    private autenticacion: AutenticacionService,
    private router: Router,
    private spinner: LoadingService
  ) {
    console.log("contructor")
    this.cambiar("es");

  }
  ngOnInit() {
    this.forma = this.formBuilder.group({
      usuario: ['', [Validators.required, Validators.email]],
      contrasena: ['', Validators.required],
    });
  }

  login(): void {

    if (this.forma.valid) {
      this.spinner.show('');
      const acceso = this.forma.value as Login;
      acceso.lang=window.localStorage.getItem('language');
      this.ctx.acceso
        .obtenerCredencial(acceso)
        .toPromise()
        .then((credencial) => {
          this.autenticacion.asignarCredencial(credencial);
          this.spinner.hide();
          this.router.navigate(['/']);
        })
        .catch((e) => {
          this.spinner.hide();
          console.log(e);
          this.alerta.mostrarError('Usuario y/o contraseña incorrectas');
        });
    }
  }

  cambiar(lang: string) {
    window.localStorage.setItem('language', lang);
    this.servicio.use(window.localStorage.getItem('language'));
  }

  verContrasena(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  irADocumentosRequiciones() {
    this.router.navigate(['DocumentosExternosRequisiciones']);
  }

  irADocumentosEntradas() {
    this.router.navigate(['DocumentosExternosEntradas']);
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ReporteadorFiltro } from 'src/app/shared/models/reporteador-filtro';
import { LogSincronizacion } from 'src/app/shared/models/LogSincronizacion';
import { Observable, Subscription } from 'rxjs';
import { Almacen } from 'src/app/shared/models/Almacen';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ComprobanteDetalleSincronizacionComponent } from '../../comprobante-detalle-sincronizacion/catalogo.component';

@AutoUnsubscribe()
@Component({
  selector: 'grupo-productos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoLogSincronizacion implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  forma: FormGroup;
  get f() {
    return this.forma.controls;
  }

  almacenFiltrados: Observable<Almacen[]>;
  almacenSinFiltrar: Almacen[];
  almacenfiltro = new FormControl();
  sub: Subscription;


  fuenteDatos: MatTableDataSource<LogSincronizacion> = new MatTableDataSource([]);
  columnasMostradas = [
    'nombreCompleto',
    'fechaInicio',
    'fechaFin',
    'folio',
    'cantidadMov',
    'movSincronizados',
    'status',
    'opciones'
  ];

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService,
    private db: Contexto,
    private dialog: MatDialog,
  ) { }
  async ngOnInit() {

    this.forma = this.formBuilder.group({
      diasAntiguedad: [0],
      almacen: [''],
      almacenId: [0],
    });
    // this.cargarAlmacenes();
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.f['almacen'].setValue(this.autenticacion.credencial.almacenDescripcion);
        this.f['almacenId'].setValue(this.autenticacion.credencial.almacenId);
        this.busqueda();
      }
    );
  }


  async busqueda() {
    const model = this.forma.value as ReporteadorFiltro;
    let reporte = await this.ctx.reporteLogSincro.obtenerReporteLogSincronizacion(model).toPromise();
    if (reporte) {
      this.fuenteDatos = new MatTableDataSource(reporte);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    }
  }


  descargarExcel(): void {
    let fechaActual = new Date();
    let nombreArchivo = '';
    this.translate
      .get('messages.descargarExcel', { value: fechaActual.toLocaleString() })
      .subscribe((res: string) => {
        nombreArchivo = res;
      });
    this.ctx.reporteAntiguedadStock.obtenerExcel()
      .toPromise()
      .then((respuesta) => {
        this.llamarExcel(respuesta, nombreArchivo);
      })
      .catch((e) => { });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  limpiarFiltro() {

    this.filtro.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  verComprobante(log: LogSincronizacion): void {
    this.db.reporteLogSincro
      .ObtenerComprobanteLogDetalle(log.id)
      .toPromise()
      .then((p) => {
        this.dialog.open(ComprobanteDetalleSincronizacionComponent, {
          data: p,
          panelClass: 'comprobante',
          width: '1000px',
        });
      });
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }

}

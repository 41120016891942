

export class MinaUbicaciones{
    id:number;
    codigo:string;
    descripcion:string;
    referencia:string;
    activo:boolean;
    minaId: number;
    descripcionMina: string;
}

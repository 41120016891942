import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RepositorioAcceso } from './respositorio-acceso';
import { RepositorioUsuario } from './repositorio-usuario';
import { RepositorioTipoUsuario } from './repositorio-tipo-usuario';
import { RepositorioRol } from './repositorio-rol';
import { RepositorioTipoMenu } from './repositorio-tipo-menu';
import { RepositorioPantallas } from './repositorio-pantallas';
import { RepositorioContactoSoporte } from './repositorio-contacto-soporte';
import { HostService } from 'src/app/services/infoHost/host-service.service';
import { RepositorioGrupoProducto } from './repositorio-grupo-producto';
import { RepositorioUnidadMedida } from './RepositorioUnidadesMedida';
import { RepositorioEmpresaMinera } from './repositorio-empresaMinera';
import { RepositorioMinas } from './repositorio-Minas';
import { RepositorioAlmacen } from './repositorio-Almacen';
import { RepositorioProductos } from './repositorio-Productos';
import { RepositorioEmpleados } from './repositorio-Empleados';
import { RepositorioMinaUbicaciones } from './repositorio-mina-ubicaciones';
import { RepositorioAsignacionAlmacenUsuario } from './repositorio-almacen-usuario';
import { RepositorioAsignacionAlmacenProducto } from './repositorio-almacen-producto';
import { RepositorioAsignacionMinaEmpleado } from './repositorio-mina-empleado';
import { RepositorioArticuloAlternativo } from './repositorio-articulo-alternativo';
import { RepositorioReporteador } from './repositorio-reporteador';
import { RepositorioIndicadores } from './repositorio-Indicadores';
import { RepositorioProcesoEstatus } from './repositorio-ProcesoEstatus';
import { RepositorioControlAjuste } from './repositorio-control-ajustes';
import { RepositorioAnalisisPfep } from './repositorio-analisis-pfep';
import { RespositorioListaDePrecio } from './repositorio-ListaDePrecios';
import { RepositorioVentas } from './repositorio-ventas';
import { RepositorioParametrosPFEP } from './repositorio-parametros-pfep';
import { RepositorioPedido } from './repositorio-pedido';
import { RepositorioDocumentosExcel } from './repositorio-docs-excel';
import { RepositorioEsquemaDesktop } from './repositorio-esquema-desktop';
import { RepositorioAsignacionMinaProducto } from './repositorio-mina-producto';
import { RepositorioDashboard } from './repositorio-dashboard';
import { RepositorioDocumentosExternos } from './repositorio-documentos-externos';
import { RepositorioKpis } from './repositorio-kpis';
import { RepositorioAdministradorCorreos } from './repositorio-administrador-correos';
import { TemporizadorNotificaciones } from '../models/temporizador-notificaciones';
import { RepositorioTemporizadorNotificaciones } from './repositorio-temporizador-notificaciones';
import { RepositorioCierreInventario } from './repositorio-cierre-inventario';
import { ReporteAntiguedadStock } from '../models/reporte-antiguedad-stock';
import { RepositorioReporteAntiguedadStock } from './reporte-antiguedad-stock';
import { RepositorioRangoFechaSincronizacion } from './repositorio-rango-fecha-sincro';
import { RepositorioPedidoSalida } from './repositorio-pedidos-salidas';
import { RepositorioControlCierreKardex } from './repo-cierrediario';
import { RepositorioLogSincronizacion } from './repositorio-LogSincronizacion';
import { RepositorioCierre } from './repositorio-cierre';
import { RepositorioAsignacionProductoCritico } from './repositorio-producto-critico';
import { RepositorioControlPeriodos } from './repositorio-control-periodos';
import { RepositorioControlCalcularConteos } from './repositorio-control-calcular-conteo';
import { RepositorioTraspaso } from './repositorio-traspaso';
import { RepositorioFechaOperacion } from './repositorio-fecha-operacion';
import { RepositorioMaquinarias } from './repositorio-maquinarias';
import { RepositorioPeriodosSemanas } from './repositorio-periodos-semanas';
import { RepositorioTurnosConteos } from './repositorio-turnos-conteos';
import { RepositorioDiasInhabiles } from './repositorio-control-dias-inhabiles';
import { RepositorioControlPlataformas } from './repositorio-control-plataformas';
import { RepositorioConteosProductos } from './repositorio-conteos-productos';



// import { RepositorioDocumentosExternos } from './repositorio-documentos-externos';

@Injectable({
  providedIn: 'root',
})
export class Contexto {
  private repoAcceso: RepositorioAcceso;
  public get acceso(): RepositorioAcceso {
    return this.repoAcceso;
  }

  private repoUsuario: RepositorioUsuario;
  public get usuario(): RepositorioUsuario {
    return this.repoUsuario;
  }

  private repoTipoUsuario: RepositorioTipoUsuario;
  public get tipoUsuario(): RepositorioTipoUsuario {
    return this.repoTipoUsuario;
  }

  private repoRol: RepositorioRol;
  public get roles(): RepositorioRol {
    return this.repoRol;
  }

  private repoPantallas: RepositorioPantallas;
  public get pantallas(): RepositorioPantallas {
    return this.repoPantallas;
  }

  private repoTipoMenu: RepositorioTipoMenu;
  public get tiposMenu(): RepositorioTipoMenu {
    return this.repoTipoMenu;
  }

  private repoContactoSoporte: RepositorioContactoSoporte;
  public get contactoSoporte(): RepositorioContactoSoporte {
    return this.repoContactoSoporte;
  }

  private repoGrupoProductos: RepositorioGrupoProducto;
  public get grupoProducto(): RepositorioGrupoProducto {
    return this.repoGrupoProductos;
  }

  private repoMaquinarias: RepositorioMaquinarias;
  public get Maquinarias(): RepositorioMaquinarias {
    return this.repoMaquinarias;
  }

  private repoUnidadesMedida: RepositorioUnidadMedida;
  public get UnidadesMedida(): RepositorioUnidadMedida {
    return this.repoUnidadesMedida;
  }

  private repoEmpresaMinera: RepositorioEmpresaMinera;
  public get EmpresaMinera(): RepositorioEmpresaMinera {
    return this.repoEmpresaMinera;
  }

  private repoFechaOperacion: RepositorioFechaOperacion;
  public get FechaOperacion(): RepositorioFechaOperacion {
    return this.repoFechaOperacion;
  }

  private repoPeriodosSemanas: RepositorioPeriodosSemanas;
  public get PeriodosSemanas(): RepositorioPeriodosSemanas {
    return this.repoPeriodosSemanas;
  }

  private repoTurnosConteos: RepositorioTurnosConteos;
  public get TurnosConteos(): RepositorioTurnosConteos {
    return this.repoTurnosConteos;
  }

  private repoMinas: RepositorioMinas;
  public get Minas(): RepositorioMinas {
    return this.repoMinas;
  }

  private repoAlmacen: RepositorioAlmacen;
  public get Almacen(): RepositorioAlmacen {
    return this.repoAlmacen;
  }

  private repoProductos: RepositorioProductos;
  public get Productos(): RepositorioProductos {
    return this.repoProductos;
  }

  private repoEmpleados: RepositorioEmpleados;
  public get Empleados(): RepositorioEmpleados {
    return this.repoEmpleados;
  }

  private repoMinaUbicaciones: RepositorioMinaUbicaciones;
  public get MinaUbicaciones(): RepositorioMinaUbicaciones {
    return this.repoMinaUbicaciones;
  }

  private repoAsignacionAlmacenUsuario: RepositorioAsignacionAlmacenUsuario;
  public get asignacionAlmacenUsuario(): RepositorioAsignacionAlmacenUsuario {
    return this.repoAsignacionAlmacenUsuario;
  }

  private repoAsignacionAlmacenProducto: RepositorioAsignacionAlmacenProducto;
  public get asignacionAlmacenProducto(): RepositorioAsignacionAlmacenProducto {
    return this.repoAsignacionAlmacenProducto;
  }

  private repoAsignacionProductoCritico: RepositorioAsignacionProductoCritico;
  public get asignacionProductoCritico(): RepositorioAsignacionProductoCritico {
    return this.repoAsignacionProductoCritico;
  }

  private repoControlPeriodos: RepositorioControlPeriodos;
  public get ControlPeriodos(): RepositorioControlPeriodos {
    return this.repoControlPeriodos;
  }

  private repoControlPlataformas: RepositorioControlPlataformas;
  public get ControlPlataformas(): RepositorioControlPlataformas {
    return this.repoControlPlataformas;
  }

  private repoControDiasInhabiles: RepositorioDiasInhabiles;
  public get ControlDiasInhabiles(): RepositorioDiasInhabiles {
    return this.repoControDiasInhabiles;
  }

  private repoControlCalcularConteo: RepositorioControlCalcularConteos;
  public get ControlConteos(): RepositorioControlCalcularConteos {
    return this.repoControlCalcularConteo;
  }

  private repoAsignacionMinaEmpleado: RepositorioAsignacionMinaEmpleado;
  public get asignacionMinaEmpleado(): RepositorioAsignacionMinaEmpleado {
    return this.repoAsignacionMinaEmpleado;
  }

  private repoArticuloAlternativo: RepositorioArticuloAlternativo;
  public get articuloAlternativo(): RepositorioArticuloAlternativo {
    return this.repoArticuloAlternativo;
  }

  private repoReporteador: RepositorioReporteador;
  public get reporte(): RepositorioReporteador {
    return this.repoReporteador;
  }

  private repoIndicadores: RepositorioIndicadores;
  public get Inidicadores(): RepositorioIndicadores {
    return this.repoIndicadores;
  }

  private repoProcesoEstatus: RepositorioProcesoEstatus;
  public get ProcesoEstatus(): RepositorioProcesoEstatus {
    return this.repoProcesoEstatus;
  }

  private repoControlAjustes: RepositorioControlAjuste;
  public get controlAjustes(): RepositorioControlAjuste {
    return this.repoControlAjustes;
  }

  private repoListaDePrecios: RespositorioListaDePrecio;
  public get ListaDePrecio(): RespositorioListaDePrecio {
    return this.repoListaDePrecios;
  }

  private repoAnalisisPfep: RepositorioAnalisisPfep;
  public get analisisPfep(): RepositorioAnalisisPfep {
    return this.repoAnalisisPfep;
  }

  private repoVentas: RepositorioVentas;
  public get Ventas(): RepositorioVentas {
    return this.repoVentas;
  }

  private repoParametrosPFEP: RepositorioParametrosPFEP;
  public get parametrosPEFP(): RepositorioParametrosPFEP {
    return this.repoParametrosPFEP;
  }

  private repoPedidos: RepositorioPedido;
  public get pedidos(): RepositorioPedido {
    return this.repoPedidos;
  }

  private repoTraspasos: RepositorioTraspaso;
  public get traspasos(): RepositorioTraspaso {
    return this.repoTraspasos;
  }

  private repoCierres: RepositorioCierre;
  public get cierres(): RepositorioCierre {
    return this.repoCierres;
  }

  private repoConteosProductos: RepositorioConteosProductos;
  public get ConteosProductos(): RepositorioConteosProductos {
    return this.repoConteosProductos;
  }

  private repoDocExcel: RepositorioDocumentosExcel;
  public get documentoExcel(): RepositorioDocumentosExcel {
    return this.repoDocExcel;
  }

  private repoEsquemaDesktop: RepositorioEsquemaDesktop;
  public get esquemaDesktop(): RepositorioEsquemaDesktop {
    return this.repoEsquemaDesktop;
  }

  private repoAsignacionEmpresaProducto: RepositorioAsignacionMinaProducto;
  public get asignacionEmpresaProducto(): RepositorioAsignacionMinaProducto {
    return this.repoAsignacionEmpresaProducto;
  }

  private repoDashboard: RepositorioDashboard;
  public get dashbord(): RepositorioDashboard {
    return this.repoDashboard;
  }

  private repoDocumentosExternos: RepositorioDocumentosExternos;
  public get documentosExternos(): RepositorioDocumentosExternos {
    return this.repoDocumentosExternos;
  }

  private repoKPIS: RepositorioKpis;
  public get KPIS(): RepositorioKpis {
    return this.repoKPIS;
  }

  private adminCorreos: RepositorioAdministradorCorreos;
  public get administradorCorreos(): RepositorioAdministradorCorreos {
    return this.adminCorreos;
  }

  private tempo: RepositorioTemporizadorNotificaciones;
  public get temporizadorNotificaciones(): RepositorioTemporizadorNotificaciones {
    return this.tempo;
  }

  private cierreIventario: RepositorioCierreInventario;
  public get cierresInventario(): RepositorioCierreInventario {
    return this.cierreIventario;
  }

  private repoAntiguedadStock: RepositorioReporteAntiguedadStock;
  public get reporteAntiguedadStock(): RepositorioReporteAntiguedadStock {
    return this.repoAntiguedadStock;
  }

  private repoLogSincronizacion: RepositorioLogSincronizacion;
  public get reporteLogSincro(): RepositorioLogSincronizacion {
    return this.repoLogSincronizacion;
  }

  private repoRangoFechaSincro: RepositorioRangoFechaSincronizacion;
  public get repoRangoFechaSincronizacion(): RepositorioRangoFechaSincronizacion {
    return this.repoRangoFechaSincro;
  }

  private pedidoSalida: RepositorioPedidoSalida;
  public get pedidosSalida(): RepositorioPedidoSalida {
    return this.pedidoSalida;
  }

  private controlCierre: RepositorioControlCierreKardex;
  public get cierreManual(): RepositorioControlCierreKardex {
    return this.controlCierre;
  }


  constructor(cliente: HttpClient, url: HostService) {
    this.repoAcceso = new RepositorioAcceso(cliente);
    this.repoUsuario = new RepositorioUsuario(cliente);
    this.repoTipoUsuario = new RepositorioTipoUsuario(cliente);
    this.repoRol = new RepositorioRol(cliente);
    this.repoTipoMenu = new RepositorioTipoMenu(cliente, url);
    this.repoPantallas = new RepositorioPantallas(cliente);
    this.repoContactoSoporte = new RepositorioContactoSoporte(cliente);
    this.repoGrupoProductos = new RepositorioGrupoProducto(cliente);
    this.repoUnidadesMedida = new RepositorioUnidadMedida(cliente);
    this.repoEmpresaMinera = new RepositorioEmpresaMinera(cliente);
    this.repoMinas = new RepositorioMinas(cliente);
    this.repoAlmacen = new RepositorioAlmacen(cliente);
    this.repoProductos = new RepositorioProductos(cliente);
    this.repoEmpleados = new RepositorioEmpleados(cliente);
    this.repoMinaUbicaciones = new RepositorioMinaUbicaciones(cliente);
    this.repoAsignacionAlmacenUsuario = new RepositorioAsignacionAlmacenUsuario(
      cliente
    );
    this.repoAsignacionAlmacenProducto = new RepositorioAsignacionAlmacenProducto(
      cliente
    );
    this.repoAsignacionMinaEmpleado = new RepositorioAsignacionMinaEmpleado(
      cliente
    );
    this.repoArticuloAlternativo = new RepositorioArticuloAlternativo(cliente);
    this.repoReporteador = new RepositorioReporteador(cliente);
    this.repoIndicadores = new RepositorioIndicadores(cliente);
    this.repoProcesoEstatus = new RepositorioProcesoEstatus(cliente);
    this.repoControlAjustes = new RepositorioControlAjuste(cliente);
    this.repoListaDePrecios = new RespositorioListaDePrecio(cliente);
    this.repoAnalisisPfep = new RepositorioAnalisisPfep(cliente);
    this.repoListaDePrecios = new RespositorioListaDePrecio(cliente);
    this.repoVentas = new RepositorioVentas(cliente);
    this.repoParametrosPFEP = new RepositorioParametrosPFEP(cliente);
    this.repoPedidos = new RepositorioPedido(cliente);
    this.repoDocExcel = new RepositorioDocumentosExcel(cliente);
    this.repoEsquemaDesktop = new RepositorioEsquemaDesktop(cliente);
    this.repoAsignacionEmpresaProducto = new RepositorioAsignacionMinaProducto(
      cliente
    );
    this.repoDashboard = new RepositorioDashboard(cliente);
    this.repoDocumentosExternos = new RepositorioDocumentosExternos(cliente);
    this.repoKPIS = new RepositorioKpis(cliente);
    this.adminCorreos = new RepositorioAdministradorCorreos(cliente);
    this.tempo = new RepositorioTemporizadorNotificaciones(cliente);
    this.cierreIventario = new RepositorioCierreInventario(cliente);
    this.repoAntiguedadStock = new RepositorioReporteAntiguedadStock(cliente);
    this.repoRangoFechaSincro = new RepositorioRangoFechaSincronizacion(cliente);
    this.pedidoSalida = new RepositorioPedidoSalida(cliente);
    this.controlCierre = new RepositorioControlCierreKardex(cliente);
    this.repoLogSincronizacion = new RepositorioLogSincronizacion(cliente);
    this.repoCierres = new RepositorioCierre(cliente);
    this.repoAsignacionProductoCritico = new RepositorioAsignacionProductoCritico(cliente);
    this.repoControlPeriodos = new RepositorioControlPeriodos(cliente);
    this.repoControlCalcularConteo = new RepositorioControlCalcularConteos(cliente);
    this.repoTraspasos = new RepositorioTraspaso(cliente);
    this.repoFechaOperacion = new RepositorioFechaOperacion(cliente);
    this.repoMaquinarias = new RepositorioMaquinarias(cliente);
    this.repoPeriodosSemanas = new RepositorioPeriodosSemanas(cliente);
    this.repoTurnosConteos = new RepositorioTurnosConteos(cliente);
    this.repoControDiasInhabiles = new RepositorioDiasInhabiles(cliente);
    this.repoControlPlataformas = new RepositorioControlPlataformas(cliente);
    this.repoConteosProductos = new RepositorioConteosProductos(cliente);
  }
}

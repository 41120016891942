import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CierreInventario } from 'src/app/shared/models/cierre-inventario';
import { Repo } from './repo';

export class RepositorioCierreInventario extends Repo {
  constructor(http: HttpClient) {
    super(http, 'CierreInventario');
  }

  obtener(year:number,month:number): Observable<CierreInventario[]> {
    const ruta = this.ruta + `/${year},${month}`;
    return this.http.get<CierreInventario[]>(ruta);
  }

  obtenerMesAnterior(year:number,month:number): Observable<number> {
    const ruta = `${this.ruta}/MesAnterior/${year},${month}`;
    return this.http.get<number>(ruta);
  }

  reporteMensual(year:number,month:number): Observable<CierreInventario[]> {
    const ruta = `${this.ruta}/ReportePrevio/${year},${month}`;
    return this.http.get<CierreInventario[]>(ruta);
  }

  guardar(): Observable<void> {
    return this.http.post<void>(this.ruta, null);
  }
}

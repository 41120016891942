import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Pedido } from '../../models/pedido';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { ConteoProducto } from '../../models/conteoProducto';
import { element } from 'protractor';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { DatePipe } from '@angular/common';
import { ComprobanteCalculoConteo } from '../../models/comprobante-calculo-conteo';
import { DetalleSemana } from '../../models/detalle-semana-periodo';
import { DiaInhabil } from '../../models/Dias-Inhabiles';
import { CatalogoCalcularConteo } from '../control-calcular-conteo/catalogo/catalogo.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-pedido',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteCalcularConteoComponent implements OnInit {
  columnasGeneral = [
    'ProductoCritico',
    'PeriodosSemanas',
    'DiasInhabiles',
    'DiaMensual',
  ];

  columnasSemanal = [
    'NumSemana',
    'FechaInicial',
    'FechaFinal',
    'FechaConteo',
  ];

  columnasDias = [
    'Periodo',
    'DiaInhabil',
  ];

  calcularconteoProducto: ComprobanteCalculoConteo = new ComprobanteCalculoConteo();
  detallesSemana: DetalleSemana[];
  diasInhabiles: DiaInhabil[];
  fechaDocumento: Date;
  fecha: string;
  ubicacionDescripcion: string;
  almacenDescripcion: string;
  conteo: string = "";
  


  constructor(
    public dialogRef: MatDialogRef<ComprobanteCalcularConteoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public calCon: ComprobanteCalculoConteo,
    public autenticacion: AutenticacionService,
    private datePipe: DatePipe
  ) {


    this.calcularconteoProducto = calCon;
    this.detallesSemana = this.calcularconteoProducto[0].detalleSemana;
    this.diasInhabiles = this.calcularconteoProducto[0].detalleDiasInhabiles;

  //   this.calcularconteoProducto[0].forEach(object =>{
  //     this.detallesSemana.push(object);
  // });
   
    //this.calcularconteoProducto.detalleSemana = calCon.detalleSemana;
    this.almacenDescripcion = this.autenticacion.credencial.almacenDescripcion;
    this.fechaDocumento = new Date(Date.now());
    this.fecha = this.datePipe.transform(new Date(Date.now()), "dd-MM-yyyy");
    
  }

  ngOnInit() { }

  aceptar() {
    this.dialogRef.close({ acepto: true });
  }

  regresar() {
    this.dialogRef.close({ acepto: false });
  }


}

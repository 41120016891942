import { FormArray, ValidatorFn } from '@angular/forms';
import { DetalleTraspaso } from 'src/app/shared/models/detalle-traspaso';
import { FormaDetalleTraspaso } from '../producto/forma-detalle-traspaso';

export class FormaListaDetalleTraspaso extends FormArray {
  constructor(
    public detalles: DetalleTraspaso[],
    validatorOrOpts?: ValidatorFn | ValidatorFn[]
  ) {
    super(
      detalles.map((d) => new FormaDetalleTraspaso(d)),
      [] // validatorOrOpts
    );
    if (detalles.length === 0) {
      this.agregarLinea(new DetalleTraspaso());
    }
  }

  nuevaLinea() {
    this.agregarLinea(new DetalleTraspaso());
  }

  agregarLinea(linea: DetalleTraspaso) {
    this.push(new FormaDetalleTraspaso(linea));
  }

  editarLinea(indice: number, linea: DetalleTraspaso) {
    this.controls.splice(indice, 1, new FormaDetalleTraspaso(linea));
  }

  eliminarLinea(indice: number) {
    this.controls.splice(indice, 1);
    this.updateValueAndValidity();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Ventas } from 'src/app/shared/models/Ventas';
import { Salida } from 'src/app/shared/models/Salida';
import { MatDialog } from '@angular/material';
import { SeleccionesComponent } from '../selecciones/selecciones.component';
import { DetalleSalida } from 'src/app/shared/models/detalleSalidas';
import { MatTableDataSource, MatTable } from '@angular/material';
import { Router } from '@angular/router';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { DetalleSalidaExcluidos } from 'src/app/shared/models/detalle-salidas-excluidos';
import { LoadingService } from 'src/app/loading/loading.service';
import { ComprobanteComponent } from '../Comprobante/comprobante.component';


@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})

export class FormaVentaComponent implements OnInit {


  constructor(private ctx: Contexto,  private spinner: LoadingService, private alerta: ServicioAlerta, private ventana: MatDialog, private fromBuilder: FormBuilder,
    public route: Router, private autenticacion: AutenticacionService, private translate: TranslateService) { }
  seleccionados: Salida[] = [];
  detalles: DetalleSalida[] = [];
  recibirdatos: Salida[] = [];

  salida: Ventas;
  forma: FormGroup;
  totalDetallePesos: string = "";
  totalDetalleUSD: string = "";
  detallesExcluidos: DetalleSalidaExcluidos[] = [];
  detalleExcluido: DetalleSalidaExcluidos;

  get f() { return this.forma.controls; }
  fuenteDatos: MatTableDataSource<DetalleSalida> = new MatTableDataSource([]);
  columnasMostradas = ['solicitoEmpleado', 'folio', 'descipcionProductoPrincipal', 'descripcion', 'cantidad', 'precio', 'precioUSD', 'TotalMxn', 'TotalUSD', 'opciones'];
  @ViewChild(MatTable, null) table: MatTable<any>;


  ngOnInit() {
    this.forma = this.fromBuilder.group({
      folio: ["", Validators.required],
      almacenDescripcion: ["", Validators.required],
      almacenId: [0],
      referencia: [''],
      usuario: [this.autenticacion.credencial.nombreCompleto, Validators.required],
      usuarioId: [this.autenticacion.credencial.usuarioId, Validators.required],
      fechaRegistro: ["", Validators.required],
      ubicacionDescripcion: ["", Validators.required],
      comentarios: [''],
      productoPrincipalId: [0],
      totalUSD: [0],
      total: []
    });
    this.ObtenerFolio();
  }



  ObtenerFolio() {
    this.ctx.Ventas.generarFolio().toPromise().then((res) => {
      this.f["folio"].setValue("REM-COM-WEB1-" + res);
    }).catch(e => {
      console.log(e)
    })
  }

  VerDetalle(id: number) {
    this.ventana.open(ComprobanteComponent, { data: id, panelClass: "form-container" })
  }

  async eliminarSalida(salida: Salida) {

    var quitar = this.seleccionados.indexOf(salida, 0);
    if (quitar > -1) {

      let detallesSalidas = this.detalles.filter(e => e.salidaId === salida.id);
      if (detallesSalidas.length > 0) {
        for (const detalle of detallesSalidas) {
          this.eliminarArticuloLinea(detalle.id, salida.id, detalle.cantidad, salida.folio);
        }
      }
      this.seleccionados.splice(quitar, 1);
    }
    this.fuenteDatos = null;
    this.detalles.length = 0;
    for (const e of this.seleccionados) {
      await this.ctx.Ventas.ObtenerDetalles(e.id).toPromise().then((res) => {
        for (const u of res) {
          this.detalles.push(u);
        }
      });
    }
    this.totalDetallePesos = this.detalles.reduce((suma, detalle) => suma + detalle.totalMxn, 0).toFixed(2).toString();
    this.totalDetalleUSD = this.detalles.reduce((suma, detalle) => suma + detalle.totalUSD, 0).toFixed(2).toString();
    this.fuenteDatos = new MatTableDataSource(this.detalles);
  }

  eliminarTodasLasAsignaciones() {
  }

  eliminarTodos() {
    this.seleccionados.length = 0;
    this.detalles.length = 0;
    this.detallesExcluidos.length = 0;
    this.totalDetallePesos = "0";
    this.totalDetalleUSD = "0";
    this.table.renderRows();
  }


  verComprobante(pedidoId: number): void {

  }

  guardar() {
    if (this.forma.valid) {
      const modelo = this.forma.value as Ventas
      console.log(modelo);

      if (this.detalles && this.detalles.length > 0) {

        modelo.detallesSalida = this.detalles;
        modelo.total = +this.totalDetallePesos;
        modelo.totalUSD = +this.totalDetalleUSD;
        modelo.detallesSalidaExcluidos = this.detallesExcluidos;

        this.ctx.Ventas.guardar(modelo).toPromise().then((respuesta) => {
          if (respuesta) {

            this.translate.get("messages.guardar", { value: "" }).subscribe((res: string) => {
              this.alerta.mostrarExito(res);
            })
            this.route.navigate(["/ListaVentas/"]);
          }
        }).catch((e) => {
          this.translate.get("messages.guardarError", { value: "" }).subscribe((res: string) => {
            this.alerta.mostrarExito(res);
          });
        });
      } else {
        this.translate.get("messages.guardarDetalleError", { value: "" }).subscribe((res: string) => {
          this.alerta.mostrarAdvertencia(res);
        });
        return;
      }
    }
  }

  async seleccionarPedidos() {
    await this.ventana.open(SeleccionesComponent, { data: this.recibirdatos, panelClass: 'form-container' })
    .afterClosed().toPromise().then(async (salida: Salida[]) => {
      if (salida) {
        this.spinner.show('Espera un momento, me encuentro cargando los siguientes ' + salida.length + ' documentos');
          this.recibirdatos = salida;
          this.seleccionados = salida;
          this.detalles.length = 0;

          this.f["almacenDescripcion"].setValue(salida[0].almacenDescripcion);
          this.f["ubicacionDescripcion"].setValue(salida[0].ubicacionDescripcion);
          this.f["almacenId"].setValue(salida[0].almacenId)

          for (const e of salida) {
            await this.ctx.Ventas.ObtenerDetalles(e.id).toPromise().then((res) => {
              for (const u of res) {
                console.log(u);
                this.detalles.push(u);
              }
            });
          }
        }
        this.spinner.hide();
      });

    this.totalDetallePesos = this.detalles.reduce((suma, detalle) => suma + detalle.totalMxn, 0).toFixed(2).toString();
    this.totalDetalleUSD = this.detalles.reduce((suma, detalle) => suma + detalle.totalUSD, 0).toFixed(2).toString();
    this.fuenteDatos = new MatTableDataSource(this.detalles);
  }

  eliminarArticuloLinea(id: any, salidaId: any, cantidad: any, folio: any) {
    const index = this.detalles.indexOf(id);

    let cantidadSalida = this.detalles.filter(e => e.folio == folio).length;
    if (cantidadSalida === 1) {
      var quitar = this.seleccionados.indexOf(salidaId, 0);
      this.seleccionados.splice(quitar, 1);
    }


    this.detalleExcluido = new DetalleSalidaExcluidos();
    this.detalleExcluido.salidaId = salidaId,
    this.detalleExcluido.detalleSalidaId = id,
    this.detalleExcluido.cantidadFaltante = cantidad;
    this.detallesExcluidos.push(this.detalleExcluido);
    this.detalles.splice(index, 1);

    console.log(this.detallesExcluidos);

    this.totalDetallePesos = this.detalles.reduce((suma, detalle) => suma + detalle.totalMxn, 0).toFixed(2).toString();
    this.totalDetalleUSD = this.detalles.reduce((suma, detalle) => suma + detalle.totalUSD, 0).toFixed(2).toString();
    this.fuenteDatos = new MatTableDataSource(this.detalles);
  }


  cancelar(){
    this.route.navigate(["/ListaVentas/"]);
  }
}

import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';

@Injectable({
  providedIn: 'root'
})
export class GuardiaAcceso implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private autenticacion: AutenticacionService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;

    const autorizado = this.autenticacion.ingreso;
    if (!autorizado) {
      this.autenticacion.rutaRedireccion = url;
      this.router.navigate(['Login']);
      return false;
    }
    return true;
  }
}



// let descripcion = url.replace("/", "");
// let seguridad = this.autenticacion.credencial.pantallasSeguridad.filter(e => e.clave == descripcion);
// let catalogos = this.autenticacion.credencial.pantallasCatalogos.filter(e => e.clave == descripcion);
// let configuracion = this.autenticacion.credencial.pantallasConfiguracion.filter(e => e.clave == descripcion);
// let operacion = this.autenticacion.credencial.pantallasOperacion.filter(e => e.clave == descripcion);

// if (seguridad.length === 0) {
//   this.autenticacion.rutaRedireccion = url;
//   this.router.navigate(['Login']);
//   autorizoAccesoPantalla = false;
//   return autorizoAccesoPantalla;
// } else if (catalogos.length === 0) {
//   this.autenticacion.rutaRedireccion = url;
//   this.router.navigate(['Login']);
//   autorizoAccesoPantalla = false;
//   return autorizoAccesoPantalla;
// }
// else if (configuracion.length === 0) {
//   this.autenticacion.rutaRedireccion = url;
//   this.router.navigate(['Login']);
//   autorizoAccesoPantalla = false;
//   return autorizoAccesoPantalla;
// }
// else if (operacion.length === 0) {
//   this.autenticacion.rutaRedireccion = url;
//   this.router.navigate(['Login']);
//   autorizoAccesoPantalla = false;
//   return autorizoAccesoPantalla;
// }

import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { flatMap, startWith, map } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Pantallas } from 'src/app/shared/models/pantallas';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AsignacionRolPantalla } from 'src/app/shared/models/asignacion-rol-pantalla';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TipoUsuario } from 'src/app/shared/models/tipo-usuario';
import {TranslateService} from '@ngx-translate/core';
import { Credencial } from 'src/app/shared/models/credencial';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { LoadingService } from 'src/app/loading/loading.service';


@AutoUnsubscribe()
@Component({
  selector: 'asignacion-rol-pantalla',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAsignacionRolPantallaComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  forma: FormGroup;
  get f() { return this.forma.controls; }
  sub: Subscription;

  roles: TipoUsuario[] = [];
  rolesFiltrados: Observable<TipoUsuario[]>;
  rolesSinFiltrar: TipoUsuario[];
  filtroRoles = new FormControl();

  pantallas : Pantallas[] =[];

  proveedorSeleccionado: number;
  fuenteDatos: MatTableDataSource<Pantallas> = new MatTableDataSource([]);
  columnasMostradas = [
    'clave',
    'descripcion',
    'asignacion'
  ];

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  tabla: Pantallas[];
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private formBuilder: FormBuilder,
    private translate:TranslateService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService,
    private spinner: LoadingService
  ) { }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        tipoUsuarioId: [0, Validators.nullValidator],
        tipoUsuarioDescripcion: ['', Validators.required],
      },
    );
    this.cargarRoles()
  }

  recargarMenu(){
    this.spinner.show("Recargando menú...")
    let crede = this.autenticacion.credencial;
    crede.lang = window.localStorage.getItem('language');
    this.ctx.acceso
    .obtenerPantallasPerfil(crede)
    .toPromise()
    .then((credencial) => {
      this.autenticacion.asignarCredencial(credencial);
      this.servicioUsuarioAlmacen.actualizarCredencial.next(this.autenticacion.credencial);
      this.spinner.hide();

    })
    .catch((e) => {
      this.spinner.hide();
      console.log(e);
    });
  }

  cargarRoles() {
    this.ctx.tipoUsuario.obtenerTodos().toPromise().then(resultado => {
      this.rolesSinFiltrar = resultado;
      this.rolesFiltrados = this.filtroRoles.valueChanges
        .pipe(
          startWith<string | TipoUsuario>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarRol(t))
        );
    }).catch(e => {
      console.log(e);
    });
  }

  private filtrarRol(nombre: string): TipoUsuario[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.rolesSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }

  rolSeleccionado(rol: TipoUsuario) {
    console.log(rol.id);
    this.f['tipoUsuarioDescripcion'].setValue(rol.descripcion);
    this.f['tipoUsuarioId'].setValue(rol.id);
    this.cargarTabla(rol.id);
  }


  public cargarTabla(proveedor: number): void {
    this.proveedorSeleccionado = proveedor;

    this.ctx.pantallas
      .obtenerPantallasRoles(this.proveedorSeleccionado)
      .pipe(
        flatMap(direcciones => {
          this.tabla = direcciones;
          return this.ctx.pantallas.obtenerPantallasActivos();
        })
      )
      .subscribe(direcciones => {
        this.fuenteDatos = new MatTableDataSource(direcciones);
        this.pantallas = direcciones;
      });
  }

  cambiarEstado(seleccionado: boolean, idDireccion: number): void {
    if (seleccionado) {
      this.asignar(idDireccion);
    } else {
      this.desasignar(idDireccion);
    }

  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  asignar(id: number): void {
    const asignacion = new AsignacionRolPantalla();
    asignacion.tipoUsuarioId = this.proveedorSeleccionado;
    asignacion.pantallaId = id;

    this.ctx.pantallas
      .asignar(asignacion)
      .subscribe(
        () => {
          this.translate.get("messages.asignacion").subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
            this.recargarMenu();
          })
        },
        error => {
          this.translate.get("messages.asignacionError").subscribe((res:string)=>{
            this.alerta.mostrarError(res);
          })
        }
      );
  }

  desasignar(id: number): void {
    const asignacion = new AsignacionRolPantalla();
    asignacion.tipoUsuarioId = this.proveedorSeleccionado;
    asignacion.pantallaId = id;

    this.ctx.pantallas
      .desasignar(asignacion)
      .subscribe(
        () => {
          this.translate.get("messages.asignacionEliminada").subscribe((res:string)=>{
            this.alerta.mostrarExito(res);
            this.recargarMenu();
          })
        },
        error => {
          this.translate.get("messages.asignacionEliminadaError").subscribe((res:string)=>{
            this.alerta.mostrarError(res);
          })
        }
      );
  }

  modoAsignada(id: number): boolean {
    const direccion = this.tabla.find(d => d.id === id);

    if (direccion) {
      return true;
    } else {
      return false;
    }
  }

  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "";
    this.translate.get("messages.descargarExcel",{value: fechaActual.toLocaleString()}).subscribe((res:string)=>{
      nombreArchivo=res;
    })
    this.ctx.pantallas.obtenerExcel().toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  limpiarFiltro(): void{
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }
  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, ThemePalette } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';


@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})

export class FormaMensajeComponent implements OnInit {

  guardado: EventEmitter<void> = new EventEmitter<void>();
  mensajePersonalizado: string;
  activarCargando: boolean = false;
  activarOk: boolean = false;
  tipoMensaje: any;
  color: ThemePalette = 'primary';


  constructor(@Inject(MAT_DIALOG_DATA) public dato: any, private ventana: MatDialogRef<FormaMensajeComponent>) {

    this.tipoMensaje = this.dato;
    if (this.dato.mensaje != "") {
      this.mensajePersonalizado = this.dato.mensaje;
    } else {
      this.mensajePersonalizado = "";
    }

    if (dato.mostrarCargando) {
      this.activarCargando = true;
    }
  }

  ngOnInit() {

  }

  accion(respuesta: number): void {
    this.ventana.close(respuesta);
  }


}

import {Component,OnInit,Inject,EventEmitter} from '@angular/core';
import {Contexto} from 'src/app/shared/api/contexto.service';
import {ServicioAlerta} from 'src/app/utilerias/alerta.service'
import {Almacen } from 'src/app/shared/models/Almacen';
import {ListasPrecios} from 'src/app/shared/models/listas-precios';
import {MatTableDataSource, MatTable,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import { Observable, from } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {FormGroup,FormBuilder,FormControl, Validators, FormArray} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';


@Component({
  templateUrl:'./preciosAlmacenes.component.html',
  styleUrls:['./preciosAlmacenes.component.scss']
})

export class preciosAlmacenesComponent implements OnInit{
  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<preciosAlmacenesComponent>,
  private ctx:Contexto,private alerta:ServicioAlerta,private translate:TranslateService, private fromBuilder:FormBuilder){
    console.log(id)
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.ListaDePrecio.obtener(id).toPromise().then(resultado => {
        this.modelo = resultado;
        this.titulo=resultado.descripcion;
        this.ctx.Almacen.listaAlmacenes(id).toPromise().then((res)=>{
          this.dataSource.data.forEach(e=>{
            res.forEach(m=>{
              if(e.id==m.id){
                this.seleccion.select(e);
              }
            })
          })
        }).catch(err=>{
          this.translate.get("messages.errorInformacion").subscribe(res=>{
            alerta.mostrarError(res);
          })
        })
      }).catch(e => {
        console.log(e);
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
    }


  }
  forma:FormGroup;
  mostrarColumnas:string[]=["mina","ubicacionMina","almacen","select"];
  seleccion=new SelectionModel<Almacen>(true,[]);
  dataSource: MatTableDataSource<Almacen>=new MatTableDataSource([]);
  almacenes:Almacen[];
  guardado: EventEmitter<void> = new EventEmitter<void>();
  titulo="";

  sinFiltarAlmacen:Almacen[];
  FiltradoAlmacen:Observable<Almacen[]>;
  formReabastecimiento=new FormControl();
  get f(){return this.forma.controls}
  modelo:ListasPrecios;
  idSeleccionado:number;
  ngOnInit(){
    this.forma=this.fromBuilder.group({

    })
    this.almacenesLocales();
  }

  almacenesLocales() {
    this.ctx.Almacen.obtenerLocales().toPromise().then(resultado => {
      this.dataSource=new MatTableDataSource(resultado);
      //this.dataSource.data.forEach(row => this.seleccion.select(row));
    }).catch(e => {
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      } )
      console.log(e);
    });
  }

  opcion(selector:boolean,almacen:Almacen){
    if(this.forma.valid){
      console.log(selector)
          almacen.decripcionListaIdEstatus=selector;
          almacen.listasPreciosId=this.modelo.id;

          this.ctx.Almacen.agregarLista(this.modelo.id,almacen).subscribe((res)=>{
            this.translate.get("messages.guardar",{value:''}).subscribe((res2:string)=>{
              this.alerta.mostrarExito(res2);
            });
          },(err:any)=>{
            this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
              this.alerta.mostrarError(res)
            })
          })
    }
    else{
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res)
      })
      this.almacenesLocales();
    }
  }

  almacenSeleccionado(almacen:Almacen){
    this.f["AlmacenReabastecimientoId"].setValue(almacen.id);
    this.f["descripcionAlmacen"].setValue(almacen.descripcion);
    let listado:Almacen[];
    this.ctx.Almacen.reabastecimientoLocal(almacen.id).subscribe((res)=>{
      this.dataSource.data.forEach(element => {
        res.forEach(data=>{
          if(data.almacenId==element.id){
            this.seleccion.select(element);
          }
        })
      });
    },(err:any)=>{
      this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
        this.alerta.mostrarError(res);
      })
    });
  }


}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


@AutoUnsubscribe()
@Component({
  selector: 'alta-documentos-excel',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAltaDocumentosExcelComponent implements OnInit {


  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    console.log("estas en luagar correcto")
  }

  descargarGuiaLayout() {
    return window.open("assets/guias-excel/Layout_Entradas.xlsx", '_blank');
  }
}

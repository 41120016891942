import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Pedido } from '../../models/pedido';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { Traspaso } from '../../models/traspaso';

@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-pedido',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobanteTraspasoComponent implements OnInit {
  columnas = [
    'Codigo',
    'ProductoDescripcion',
    'Cantidad',
    'InventarioCantidad',
    'InventarioCantidadDestino'

  ];
  traspaso: Traspaso = new Traspaso();
  fechaLocal: Date = new Date();
  cantidadTotal: number = 0;
  cantidadLineas: number = 0;
  folio: string = "";
  fechaDocumento: string = "";
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ComprobanteTraspasoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public tra: Traspaso,
    private exportAsService: ExportAsService,
  ) {


    this.traspaso = tra;

    this.cantidadTotal = this.traspaso.detalles.reduce((suma, detalle) => suma + detalle.cantidad, 0);
    this.cantidadLineas = this.traspaso.detalles.length;
  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Traspaso ' + this.traspaso.folio).subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Traspaso ' + this.traspaso.folio).subscribe();
  }
  ngOnInit() {}

  regresar() {
    this.dialogRef.close();
  }
}

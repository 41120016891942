import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mina } from '../models/Mina';
import { MinaUbicaciones } from '../models/mina-ubicacion';

export class RepositorioMinaUbicaciones extends Repositorio<MinaUbicaciones> {
  constructor(http: HttpClient) {
    super(http, 'MinaUbicacion');
  }

  generarFolio(): Observable<string> {
    const ruta = `${this.ruta}/GenerarFolio`;
    return this.http.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.http.put<void>(ruta, null);
  }

  obtenerPorMina(minaId: number): Observable<MinaUbicaciones[]> {
    const ruta = `${this.ruta}/ObtenerPorMina/${minaId}`;
    return this.http.get<MinaUbicaciones[]>(ruta);
  }

  obtenerExcel(): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.http
      .post(ruta, null, {
        responseType: 'blob' as 'blob',
      })
      .pipe(map((res: Blob) => res));
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Router } from '@angular/router';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { ThemeService } from 'src/app/services/theming/theme.service';
import { RecaptchaComponent } from 'ng-recaptcha';


@Component({
  selector: 'app-registro-login',
  templateUrl: './pagina.component.html',
  styleUrls: ['./pagina.component.scss']
})
export class PaginaRegistroClienteComponent implements OnInit {
  forma: FormGroup;

  get f() {
    return this.forma.controls;
  }
  constructor(
    private formBuilder: FormBuilder,
    public themeService: ThemeService,
    private alerta: ServicioAlerta,
    private ctx: Contexto,
    private autenticacion: AutenticacionService,
    private router: Router
  ) { }
  ngOnInit() {
    this.forma = this.formBuilder.group({
      nombreUsuario: ['', Validators.required],
      nombreComercial: ['', Validators.required],
      rfc: ['', Validators.required],
      email: ['', Validators.required],
      movil: ['', Validators.required],
      contrasena: ['', Validators.required],
      confirmarContrasena: ['', Validators.required],
      captcha: [null, Validators.required],
    });
  }

  registro(): void {
    if (this.forma.valid) {
    
       
    } else {
      this.alerta.mostrarError('Por favor verifique que los campos no se encuentren vacios');
    }
  }

  verContrasena(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
  volver(): void {
    this.router.navigate(['Login']);
  }

  limpiar(): void {
    this.forma.reset();
    this.router.navigate(['Login']);
  }


  captchaResuelto(respuestaCaptcha: string): void {
    console.log(`Resolved captcha with response ${respuestaCaptcha}:`);
  }
}

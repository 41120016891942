import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ParametrosPFEP } from 'src/app/shared/models/parametros-pfep';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-parametrosPFEP',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoParametrosPFEPComponent implements OnInit {
  forma: FormGroup;
  modelo: ParametrosPFEP;
  page = "";
  get f() { return this.forma.controls; }
  constructor(private formBuilder: FormBuilder, private ctx: Contexto, private alerta: ServicioAlerta, private translate: TranslateService) {

    this.ctx.parametrosPEFP.obtener(1).toPromise().then(resultado => {
      this.modelo = resultado;
      Object.assign(this.forma.value, this.modelo);
      this.forma.reset(this.forma.value);
    }).catch(e => {
      console.log(e);
      this.translate.get("messages.errorInformacion").subscribe((res: string) => {
        this.alerta.mostrarError(res);
      })
    });
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        ideal: ['', Validators.required],
        maximo: ['', Validators.required],
      },
    );
  }

  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as ParametrosPFEP;
      console.log(model);

      this.ctx.parametrosPEFP.actualizar(1, model).toPromise().then(() => {
        this.translate.get("messages.guardar", { value: this.page }).subscribe((res: string) => {
          this.alerta.mostrarExito(res);
        })
      }).catch(e => {
        console.log(e);
        this.translate.get("messages.guardarError").subscribe((res: string) => {
          this.alerta.mostrarError(res);
        })
      });
    }
    else {
      this.translate.get("messages.datosInvalido").subscribe((res: string) => {
        this.alerta.mostrarError(res);
      })
    }
  }

  limpiar(): void {

    this.f['ideal'].setValue("");
    this.f['maximo'].setValue("");
  }
}
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MinaUbicaciones } from '../../models/mina-ubicacion';
import {
  MatTableDataSource,
  MAT_DATE_FORMATS,
  MatPaginator,
  MatSort,
} from '@angular/material';
import { AnalisisPfep, MesPfep } from '../../models/analisis-pfep';
import { Contexto } from '../../api/contexto.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { Almacen } from '../../models/Almacen';
import { Mina } from '../../models/Mina';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { FiltroPfep } from '../../models/filtro-pfep';
import { LoadingService } from 'src/app/loading/loading.service';
import * as XLSX from 'xlsx';
import { DATE_FORMATS } from 'src/app/utilerias/date-formats';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-analisis-pfep',
  templateUrl: './analisis-pfep.component.html',
  styleUrls: ['./analisis-pfep.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class AnalisisPfepComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtroTabla', { static: false }) filtroTabla: ElementRef;
  @ViewChild('reporte', { static: true }) reporte: ElementRef;

  columnasOriginales = [
    'productoClave',
    'acumulado',
    'frecuencia',
    'uso',
    'promedio',
    'pm',
    'ideal',
    'maximo',
    'existencia',
    'solicitado',
    'efectivo',
    'status',
  ];

  columnasEncabezadoOriginales = ['encabezado-producto', 'encabezado-vacio'];

  columnas: string[] = [];
  columnasEncabezado: string[] = [];
  minas: Mina[] = [];
  ubicaciones: MinaUbicaciones[] = [];
  almacenes: Almacen[] = [];
  fuenteDatos = new MatTableDataSource<AnalisisPfep>();
  meses: MesPfep[] = [];
  grupoMeses: { anio: string; noMeses: number }[] = [];
  filtro: FormGroup;

  // Devualve la lista de columnas por añoque se deben mostrar
  get anios(): string[] {
    return this.grupoMeses.map((g) => g.anio);
  }

  constructor(
    public ctx: Contexto,
    private fb: FormBuilder,
    private alerta: ServicioAlerta,
    private cargando: LoadingService
  ) {}
  ngOnInit() {
    this.cargarMinas();
    const hoy = new Date();
    this.filtro = this.fb.group(
      {
        desde: [moment(), Validators.required],
        hasta: [moment(), Validators.required],
        almacenId: [undefined, Validators.required],
      },
      { validators: this.validadorFechas }
    );
  }

  validadorFechas(control: AbstractControl): { [key: string]: any } | null {
    const filtro = control.value as FiltroPfep;
    const desde = moment(filtro.desde);
    const hasta = moment(filtro.hasta);
    return desde > hasta ? { invalidDates: true } : null;
  }

  cargarMinas() {
    this.ctx.Minas.obtenerTodos()
      .toPromise()
      .then((minas) => (this.minas = minas));
  }

  buscarUbicaciones(minaId: number) {
    this.ctx.MinaUbicaciones.obtenerPorMina(minaId)
      .toPromise()
      .then((ubicaciones) => (this.ubicaciones = ubicaciones));
  }

  buscarAlmacenes(ubicacionId: Moment) {
    this.ctx.Almacen.obtenerPorUbicacion(ubicacionId)
      .toPromise()
      .then((almacenes) => (this.almacenes = almacenes));
  }

  desdeSeleccionado(desde: Moment) {
    console.log(desde);

    this.filtro.get('desde').setValue(desde);
  }

  hastaSeleccionado(hasta: Date) {
    this.filtro.get('hasta').setValue(hasta);
  }

  solicitarAnalisis() {
    if (this.filtro.invalid) {
      this.alerta.mostrarAdvertencia('Datos inválidos');
      return;
    }
    this.cargando.show('');
    const filtro = this.filtro.value as FiltroPfep;
    this.ctx.analisisPfep
      .buscarAnalisisReporte(filtro)
      .toPromise()
      .then((reporte) => {
        // Preparo las columnas a mostrar
        const columnasMeses = reporte.meses.map((m) => `${m.mes}-${m.anio}`);
        this.columnas = [...this.columnasOriginales];
        // Agrego las columnas que corresponden a los meses que se seleccionaron en el filtro
        this.columnas.splice(1, 0, ...columnasMeses);
        this.meses = reporte.meses;

        // Se agrupan los meses por año para mostrar los encabezados
        const grupoMeses = reporte.meses.reduce((r, a) => {
          r[a.anio] = [...(r[a.anio] || []), a];
          return r;
        }, {});

        // Limpiamos la lista de grupo de meses en caso de que se genere otra busqueda
        this.grupoMeses = [];
        // Se arma la lista de los encabezados por Año
        for (const anio of Object.keys(grupoMeses)) {
          this.grupoMeses.push({
            anio: anio,
            noMeses: grupoMeses[anio].length,
          });
        }
        // Se arma la lista de los nombre encabezados por Año
        this.columnasEncabezado = [...this.columnasEncabezadoOriginales];
        // Agrego las columnas que corresponden a los años que se seleccionaron en el filtro
        this.columnasEncabezado.splice(1, 0, ...Object.keys(grupoMeses));

        console.table(reporte.analisis);
        this.fuenteDatos = new MatTableDataSource(reporte.analisis);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
      })
      .finally(() => this.cargando.hide());
  }

  nombreColumnaMes(mes: string, anio: number): string {
    return `${mes.substring(0, 3)} ${anio.toString().substring(2, 4)}`;
  }

  acumuladoPorMes(linea: AnalisisPfep, mes: MesPfep): number {
    const dato = linea.meses.find(
      (m) => m.anio === mes.anio && m.mes === mes.mes
    );
    return dato ? dato.acumulado : 0;
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  limpiarFiltro(): void {
    this.filtroTabla.nativeElement.value = '';
    this.fuenteDatos.filter = '';
  }

  exportarAExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.reporte.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Movimientos');

    /* save to file */
    const filtro = this.filtro.value as FiltroPfep;
    const desde = moment(filtro.desde);
    const hasta = moment(filtro.hasta);
    XLSX.writeFile(
      wb,
      `PFEP_${desde.month()}-${desde.year()}_${hasta.month()}-${hasta.year()}.xlsx`
    );
    // XLSX.writeFile(wb, `reporte_${filtro.desde}_${filtro.hasta}.xlsx`);
  }
}

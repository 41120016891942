import { Injectable, Inject } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DetallePedido } from 'src/app/shared/models/detalle-pedido';



@Injectable({
    providedIn: 'root',
})
export class FiltroDetallePedidos {

    public productoFiltrado = new BehaviorSubject<DetallePedido[]>([]);
    constructor() {
    }


}

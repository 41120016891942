import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { TranslateService } from '@ngx-translate/core';
import { UnidadesMedida } from 'src/app/shared/models/UnidadesMedida';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaUnidadesMedida implements OnInit {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }
  modelo: UnidadesMedida;

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaUnidadesMedida>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private translate: TranslateService
  ) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.UnidadesMedida.obtener(id)
        .toPromise()
        .then((resultado) => {
          this.modelo = resultado;
          Object.assign(this.forma.value, this.modelo);
          this.forma.reset(this.forma.value);
        })
        .catch((e) => {
          console.log(e);
          this.translate
            .get('messages.errorInformacion')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
    } else {
      this.idSeleccionado = 0;
      this.modelo = new UnidadesMedida();
      this.cargarFolio();
    }
  }
  page = '';
  ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.nullValidator],
      codigo: ['', Validators.required],
      descripcion: ['', Validators.required],
      abreviatura: ['', Validators.required],
      activo: [true, Validators.nullValidator],
    });
    this.translate.get('title.measunits').subscribe((res: string) => {
      this.page = res;
    });
  }

  limpiar(): void {
    this.forma.reset();
  }

  cargarFolio(): void {
    var r = this.ctx.UnidadesMedida.generarFolio()
      .toPromise()
      .then((resultado) => {
        this.forma.get('codigo').setValue('UM-' + resultado);
      })
      .catch((e) => {
        this.translate
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as UnidadesMedida;
      console.log(model);
      if (this.idSeleccionado === 0) {
        this.ctx.UnidadesMedida.guardar(model)
          .toPromise()
          .then(() => {
            this.translate
              .get('messages.guardar', { value: this.page })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            console.log(e);
            if (e === 'Unprocessable Entity') {
              this.translate
                .get('messages.guardarExiste', { value: this.page })
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            } else if (e.error === 'ErrorGuardar') {
              this.translate
                .get('messages.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            } else {
              this.translate
                .get('messages.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      } else {
        this.ctx.UnidadesMedida.actualizar(this.idSeleccionado, model)
          .toPromise()
          .then(() => {
            this.translate
              .get('messages.actualizar', { value: this.page })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            console.log(e);
            if (e === 'Unprocessable Entity') {
              this.translate
                .get('messages.guardarExiste', { value: this.page })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.translate
                .get('messages.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      }
    } else {
      this.translate.get('messages.datosInvalido').subscribe((res: string) => {
        this.alerta.mostrarError(res);
      });
    }
  }

  cerrar(): void {
    this.ventana.close();
  }
}

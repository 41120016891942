import { DetalleSemana } from "./detalle-semana-periodo";

export class ComprobanteCalculoConteo{
    almacenDescripcion: string;
    almacenId: number;
    fechaDocumento: string;
    productoCritico: number;
    periodosSemanas: number;
    diasInhabiles: number;
    diaMensual: number;

    //detalleSemana: DetalleSemana[] = [];
    //detalleInhabil: [];


    
}

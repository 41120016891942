import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TipoMenu } from 'src/app/shared/models/tipo-menu';
import * as XLSX from 'xlsx';
import { LoadingService } from 'src/app/loading/loading.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactoSoporte } from 'src/app/shared/models/contacto-soporte';
import {TranslateService} from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-tipo-menu',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoContactoSoporteComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  fuenteDatos: MatTableDataSource<ContactoSoporte> = new MatTableDataSource([]);
  columnasMostradas = ['tema', 'problema', 'opciones'];
  file: File;
  arrayBuffer: any;
  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }
  constructor(public ventana: MatDialog, private spinner: LoadingService, private ctx: Contexto, private alerta: ServicioAlerta,private translate:TranslateService) { }

  ngOnInit() {
    this.tabla();
  }

  tabla(): void {

    this.spinner.show('Loading...');
    this.ctx.contactoSoporte.obtenerTodos().toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.spinner.hide();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("errorInformacion").toPromise().then(e=>{
          this.alerta.mostrarError(e);
        })
      });
  }


  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {

    this.ctx.tiposMenu.activar(id).toPromise().then(() => {
      this.translate.get("activado").toPromise().then(e=>{
        this.alerta.mostrarExito(e);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("activadoerror").toPromise().then(e=>{
          this.alerta.mostrarExito(e);
        })
        this.alerta.mostrarError('Error al activar');
      });

  }

  desactivar(id: number): void {
    this.ctx.tiposMenu.desactivar(id).toPromise().then(() => {
      this.translate.get("inactivado").toPromise().then(e=>{
        this.alerta.mostrarExito(e);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("inactivadoerror").toPromise().then(e=>{
          this.alerta.mostrarError(e);
        })
      });
  }


  eliminar(id: number): void {
    this.ctx.tiposMenu.eliminar(id).toPromise().then(() => {
      this.translate.get("eliminado").toPromise().then(e=>{
        this.alerta.mostrarExito(e);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get("eliminadoerror").toPromise().then(e=>{
          this.alerta.mostrarError(e);
        })
      });
  }


  limpiarFiltro(): void {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }



  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "Reporte hasta el día: " + fechaActual.toLocaleString();
    this.ctx.tiposMenu.obtenerExcel().toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  importarExcel($event) {
    this.file = $event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let JsonProductos = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as TipoMenu[];
      if (JsonProductos) {
        this.ctx.tiposMenu.guardarExcel(JsonProductos).toPromise().then(() => {
          this.alerta.mostrarExito("Tipo de menú guardado con éxito");
          this.tabla();
        }).catch(e => {
          console.log(e);
          this.alerta.mostrarError('¡Error interno al guardar! ');
        });
      } else {
        this.alerta.mostrarError('No fue posible dar lectura al archivo');
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }

}

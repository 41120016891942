import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Reporteador } from 'src/app/shared/models/reporteador';
import { EntradaAbierta } from 'src/app/shared/models/entradas-abiertas';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import * as _ from 'underscore';
import { Productos } from 'src/app/shared/models/Productos';

@AutoUnsubscribe()
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dashboard',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})

export class CatalogoDashBoardComponent implements OnInit {
  objectKeys = Object.keys;
  Grupos: any = {};
  Totales: any[][] = [];
  obj: any;

  evaluo: any ={};


  constructor(public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    public autenticacion: AutenticacionService) {
  }

  async ngOnInit() {
    if (this.autenticacion.credencial.app === 'Motion') {
      this.ctx.Productos.obtenerPorAlmacen(this.autenticacion.credencial.almacenId).subscribe(response => {
        const datos = _.filter(response, function (e: Productos) { return e.existencia > 0; });
        this.Grupos = _.groupBy(datos, 'grupoProductoDescripcion');
      });
      this.ctx.Productos.obtenerPorGrupo(this.autenticacion.credencial.almacenId).subscribe(response => {
        const datos = _.filter(response, function (e: Productos) { return e.existencia > 0; });
        this.Totales = _.groupBy(datos, 'grupoProductoDescripcion');
      });
    }
  }
  
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReporteAntiguedadStock } from '../models/reporte-antiguedad-stock';

export class RepositorioControlCierreKardex extends Repositorio<ReporteAntiguedadStock> {
  constructor(http: HttpClient) {
    super(http, 'ControlCierreKardex');
  }

  cierreDiarioDeAlmacenes(): Observable<boolean> {
    const ruta = `${this.ruta}/CierreDiarioDeAlmacenes`;
    return this.http.get<boolean>(ruta);
  }

  cierreMasivoDeAlmacenes(): Observable<boolean> {
    const ruta = `${this.ruta}/CierreMasivoDeAlmacenes`;
    return this.http.get<boolean>(ruta);
  }


  generacionKardex(): Observable<boolean> {
    const ruta = `${this.ruta}/GeneracionKardex`;
    return this.http.get<boolean>(ruta);
  }
}

import { Injectable } from '@angular/core';
import { Pedido } from 'src/app/shared/models/pedido';
import { Resolve } from '@angular/router';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Contexto } from 'src/app/shared/api/contexto.service';

@Injectable({
  providedIn: 'root',
})
export class PedidoResolver implements Resolve<Pedido> {
  constructor(
    private db: Contexto,
    private autenticacion: AutenticacionService
  ) {}

  resolve(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ): Pedido | import('rxjs').Observable<Pedido> | Promise<Pedido> {
    const id = route.paramMap.get('id');
    const ruta = route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/');
    if (+id > 0) {
      return this.db.pedidos.obtener(+id);
    } else {
      // return this.db.pedidos.CrearPedido();
    }
  }
}

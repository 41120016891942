import { Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';


@Component({
  selector: 'app-forma',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.css']
})
export class FormaMensaje implements OnInit {

  requisicionId: number = 0;
  requisicionCodigo: string = "";
  estatus: string = "";
  page = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public valor: any,
    private ventana: MatDialogRef<FormaMensaje>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private activateRouter: ActivatedRoute,
    private translate: TranslateService,
    private router: Router) {
      console.log(valor);
    if (valor != null) {
      this.requisicionId = valor.requisicionId;
      this.requisicionCodigo = valor.requisicionCodigo;
      this.estatus = valor.estatus;

    }
  }
  async ngOnInit() {
    this.translate.get('title.measunits').subscribe((res: string) => {
      this.page = res;
    });
  }

  cerrar(): void {
    this.ventana.close();
  }

  cerrarRequisicion() {
    if (this.valor != null) {
      if(this.estatus == "CerrarDoc")
      {
        this.ctx.pedidos.cerrarComprobantePedido(this.requisicionId)
        .toPromise()
        .then(() => {
          this.ventana.close("Cerrado");
        })
        .catch((e) => {
          this.translate
            .get('messages.guardarError')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
      }else if(this.estatus == "CancelarDoc"){

        this.ctx.pedidos.cancelarComprobantePedido(this.requisicionId)
        .toPromise()
        .then(() => {
          this.ventana.close("Cancelado");
        })
        .catch((e) => {
          this.translate
            .get('messages.guardarError')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });

      }else if(this.estatus == "CerrarDocSalida")
      {
        this.ctx.pedidosSalida.cerrarComprobantePedido(this.requisicionId)
        .toPromise()
        .then(() => {
          this.ventana.close("Cerrado");
        })
        .catch((e) => {
          this.translate
            .get('messages.guardarError')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
      }else if(this.estatus == "CancelarDocSalida"){

        this.ctx.pedidosSalida.cancelarComprobantePedido(this.requisicionId)
        .toPromise()
        .then(() => {
          this.ventana.close("Cancelado");
        })
        .catch((e) => {
          this.translate
            .get('messages.guardarError')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });

      }
    }
  }
}

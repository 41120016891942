import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ChartType,
  ChartOptions,
  ChartDataSets,
  RadialChartOptions,
} from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import { Grafica } from 'src/app/shared/models/grafica';
import { PortalHostDirective } from '@angular/cdk/portal';
import { EntradaAbierta } from 'src/app/shared/models/entradas-abiertas';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { FormaProductoKardex } from '../forma-producto-kardex/forma.component';
import { preserveWhitespacesDefault } from '@angular/compiler';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'db-producto-menos-movimientos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class DdProductoConMenosMovimientos implements OnInit, OnDestroy {
  @ViewChild('myCanvas', { static: false }) canvas: ElementRef;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  repo: Grafica[] = [];
  producto: string = '';

  barChartOptions: ChartOptions = {
    tooltips: {
      // bodyAlign: 'left'
    },
    legend: {
      display: false,
    },
    responsive: true,

    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          ticks: {
            min: 0,
            max: 50,
          },
        },
      ],
      yAxes: [{ ticks: { beginAtZero: true } }],
    },
    plugins: {
      datalabels: {
        color: 'white',
        anchor: 'end',
        align: 'start',
        font: {
          size: 10,
        },
        clamp: true,
        rotation: 0,
        formatter: (value, context) => {
          const xLabel = context.chart.config.data.labels[context.dataIndex];
          const datasetLabel = context.dataset.label;
          return (
            '                                           ' +
            datasetLabel +
            ' - ' +
            ' (' +
            Math.round(value) +
            ')'
          );
        },
      },
    },
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'horizontalBar';
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];
  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  sub: Subscription;

  public barChartData: ChartDataSets[] = [{ data: [], type: 'horizontalBar' }];
  constructor(private ctx: Contexto, public ventana: MatDialog, public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) { }
  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarGrafica();
      }
    );
  }

  ngAfterViewInit() {
    this.barChartData.forEach((d, i) => {
      d.backgroundColor = this.generateGradient();
    });
    this.chart.chart.update();
  }

  async cargarGrafica() {
    this.repo = await this.ctx.dashbord
      .obtenerProductosConMenosMovimientos(this.autenticacion.credencial.almacenId)
      .toPromise();
    if (this.repo) {
      this.barChartLabels = this.repo.map(
        (p) => p.producto + ' ' + p.productoDescripcion
      );
      this.barChartData[0].data = this.repo.map((p) => p.diasTranscurridos);
      this.barChartData[0].label = 'Dias transcurridos';
    }
  }

  seleccionaProducto(valor: any) {
    this.producto = valor.active[0]._chart.tooltip._model.dataPoints[0].yLabel;

    let forma = this.ventana.open(FormaProductoKardex, {
      data: this.producto,
      panelClass: 'form-container',
    });
  }

  async refrescar() {
    this.repo = [];

    this.repo = await this.ctx.dashbord
      .actualizarProductosConMenosMovimientos(this.autenticacion.credencial.almacenId)
      .toPromise();
    if (this.repo) {
      this.barChartData[0].data = [];
      this.barChartLabels = this.repo.map(
        (p) => p.producto + ' ' + p.productoDescripcion
      );
      this.barChartData[0].data = this.repo.map((p) => p.diasTranscurridos);
      this.barChartData[0].label = 'Dias transcurridos';
    }
  }

  onResize(event?) {
    this.barChartData.forEach((d, i) => {
      d.backgroundColor = this.generateGradient();
    });
    this.chart.chart.update();
  }

  generateGradient() {
    let eleWidth = this.canvas.nativeElement.offsetWidth;
    const gradient = this.canvas.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 1, eleWidth, 0);
    gradient.addColorStop(0.4, 'green');
    gradient.addColorStop(0.5, 'yellow');
    gradient.addColorStop(0.7, 'red');
    return gradient;
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

import {Component, OnInit,ViewChild,ElementRef,EventEmitter} from '@angular/core';
import { Observable } from 'rxjs';
import {FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {Contexto} from 'src/app/shared/api/contexto.service';
import {MatTable,MatTableDataSource,MatPaginator,MatSort, MatDialog} from '@angular/material';
import {Almacen} from 'src/app/shared/models/Almacen';
import {TranslateService} from '@ngx-translate/core';
import {ServicioAlerta} from 'src/app/utilerias/alerta.service';
import { FormaListaDePrecios } from '../forma/forma.component';
import { ListasPrecios } from 'src/app/shared/models/listas-precios';
import { preciosAlmacenesComponent } from '../PreciosAlmacenes/preciosAlmacenes.component';

@Component({
  templateUrl:'./catalogo.component.html',
  styleUrls:['./catalogo.component.scss']
})

export class ComponentListaDePrecios implements OnInit{

  constructor(private ctx:Contexto,private formBuilder:FormBuilder,private translate:TranslateService,private ventana:MatDialog,
              private alerta:ServicioAlerta){}

  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;

  guardado: EventEmitter<void> = new EventEmitter<void>();
  fuenteDatos: MatTableDataSource<ListasPrecios> = new MatTableDataSource([]);
  columnasMostradas = ['codigo', 'descripcion','activo', 'opciones'];



  ngOnInit(){
    this.tabla();
  }


  tabla(): void {
    this.ctx.ListaDePrecio.obtenerTodos().toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
    })
      .catch(e => {
        this.translate.get('messages.errorInformacion').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }

  abrirForma(id?: string): void {
    let dato = id ? id : null;
    console.log("funciono");
    let forma = this.ventana.open(FormaListaDePrecios, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

  abrirFormaDetalle(id?: string): void {
    let dato = id ? id : null;
    console.log(dato)
    // let forma = this.ventana.open(DetallesListaComponent, { data: dato, panelClass: 'form-container' });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {

    this.ctx.ListaDePrecio.activar(id).toPromise().then(() => {
      this.translate.get('messages.activar').subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get('messages.activarError').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });

  }

  desactivar(id: number): void {
    this.ctx.ListaDePrecio.desactivar(id).toPromise().then(() => {
      this.translate.get('messages.desactivar').subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get('messages.desactivarError').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }


  eliminar(id: number): void {
    this.ctx.Almacen.eliminar(id).toPromise().then(() => {
      this.translate.get('messages.eliminar').subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get('messages.eliminarError').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }


  limpiarFiltro(): void {
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }

  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "";
    this.translate.get("messages.descargarExcel",{value: fechaActual.toLocaleString()}).subscribe((res:string)=>{
      nombreArchivo=res;
    })
    this.ctx.ListaDePrecio.obtenerExcel().toPromise().then(respuesta => {
      this.llamarExcel(respuesta, nombreArchivo)
    }).catch(e => {
    });
  }

  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  abrirFormaLista(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(preciosAlmacenesComponent, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

}

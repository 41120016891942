import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Pedido } from '../../models/pedido';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { Periodos } from '../../models/periodos';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { DatePipe } from '@angular/common';


@AutoUnsubscribe()
@Component({
  selector: 'app-comprobante-pedido',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class ComprobantePeriodoSemanalComponent implements OnInit {
  columnas = [
    'NumPeriodo',
    'Descripcion',
    'NumSemana',
    'FechaInicial',
    'FechaFinal',
    'FechaConteo',
  ];

  periodo: Periodos = new Periodos();
  fechaLocal: Date = new Date();
  cantidadTotal: number = 0;
  cantidadLineas: number = 0;
  folio: string = "";
  fecha: string;
  almacenDescripcion: string;
  fechaDocumento: Date;
  exportPDF: ExportAsConfig = {
    type: 'pdf',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  exportExcel: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'element',
    options: {
     jsPDF: {
        orientation: 'portrait',
        margins: {
          top: '20',
          left: '20',
          right: '20'
        }
      },
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ComprobantePeriodoSemanalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public per: Periodos,
    private exportAsService: ExportAsService,
    public autenticacion: AutenticacionService,
    private datePipe: DatePipe,


  ) {


    this.periodo = per;

    // this.cantidadTotal = this.periodo.detalles.reduce((suma, detalle) => suma + detalle.cantidad, 0);
    //this.cantidadLineas = this.periodo.length;
    this.fechaDocumento = new Date(Date.now());
    this.almacenDescripcion = this.autenticacion.credencial.almacenDescripcion;
    this.fecha = this.datePipe.transform(new Date(Date.now()),"dd-MM-yyyy");

  }

  descargarDocumento(): void {
    this.exportAsService.save(this.exportPDF, 'Periodo_semanal ' + this.fecha).subscribe();
  }

  descargarDocumentoExcel(): void {
    this.exportAsService.save(this.exportExcel, 'Periodo_semanal ' + this.fecha).subscribe();
  }
  ngOnInit() {}

  regresar() {
    this.dialogRef.close();
  }
}

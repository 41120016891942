import { Component, OnInit, ViewChild, ElementRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { TurnosConteos } from 'src/app/shared/models/turnos-conteos';
import { DiaInhabil } from 'src/app/shared/models/Dias-Inhabiles';
import { FormaDiaInhabil } from '../forma/forma.component';
//import { FormaTurnosConteos } from '../forma/forma.component';



@Component({
  selector: 'DiasInhabiles',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoControlDiasInhabilesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  @Output() public periodoSeleccionado = new EventEmitter<any>();

  fuenteDatos: MatTableDataSource<DiaInhabil> = new MatTableDataSource([]);
  sub: Subscription;

  
  columnasMostradas = [
    'periodoId',
    'almacenDescripcion',
    'dia',
    'opciones'
  ];



  constructor(
    private traslate: TranslateService,
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService,
    private dialog: MatDialog,
    private translate:TranslateService
    

  ) {}
  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.tabla();
      }
    );
  }

  tabla(): void {
    this.ctx.ControlDiasInhabiles
      .ObtenerDiasInhabilesAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        console.log(resultado);
        this.fuenteDatos = new MatTableDataSource(resultado);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
      })
      .catch((e) => {
        this.traslate
          .get('messages.errorInformacion')
          .subscribe((res: string) => {
            this.alerta.mostrarError(res);
          });
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  
  eliminar(id: number): void {
    this.ctx.ControlDiasInhabiles.eliminar(id).toPromise().then(() => {
      this.translate.get('messages.eliminar').subscribe((res:string)=>{
        this.alerta.mostrarExito(res);
      })
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.translate.get('messages.eliminarError').subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      });
  }


  abrirForma(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaDiaInhabil, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExcelPedidos } from '../models/excel-pedidos';
import { ExcelEntradas } from '../models/excel-entradas';
import { ExcelProductosAlmacen } from '../models/excel-productos-almacen';

export class RepositorioDocumentosExcel extends Repositorio<ExcelEntradas> {
  constructor(http: HttpClient) {
    super(http, 'DocumentosExcel');
  }

  entradas(articulos: ExcelEntradas[]): Observable<ExcelEntradas[]> {
    let ruta = `${this.ruta}/EntradasExcel`;
    return this.http.post(ruta, articulos).pipe(
      map((articulos: ExcelEntradas[]) => {
        return articulos;
      })
    );
  }

  productosPorAlmacen(articulos: ExcelProductosAlmacen[]): Observable<ExcelProductosAlmacen[]> {
    let ruta = `${this.ruta}/ProductosAlmacenExcel`;
    return this.http.post(ruta, articulos).pipe(
      map((articulos: ExcelProductosAlmacen[]) => {
        return articulos;
      })
    );
  }
}

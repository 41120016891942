import { TableroInventario } from './../models/tableroInventario';
import { KPISModels } from './../models/kpis';
import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ParetoVM } from '../models/ParetoVM';

export class RepositorioKpis extends Repositorio<KPISModels> {
  constructor(http: HttpClient) {
    super(http, 'KPIs');
  }

  InventarioKPI(almacenId: number): Observable<KPISModels> {
    const ruta = `${this.ruta}/Inventario/${almacenId}`;
    return this.http.get<KPISModels>(ruta);
  }

  Pareto(almacenId: number): Observable<ParetoVM[]> {
    const ruta = `${this.ruta}/Pareto/${almacenId}`;
    return this.http.get<ParetoVM[]>(ruta);
  }

  tableroInventario(almacenId: number): Observable<TableroInventario> {
    const ruta = `${this.ruta}/TableroKpi/${almacenId}`;
    return this.http.get<TableroInventario>(ruta);
  }
}

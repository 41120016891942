import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { TranslateService } from '@ngx-translate/core';
import { UnidadesMedida } from 'src/app/shared/models/UnidadesMedida';
import { Reporteador } from 'src/app/shared/models/reporteador';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})

export class FormaProductoKardex implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: false }) ordenador: MatSort;
  @ViewChild('filtro', { static: false }) filtro: ElementRef;
  sub: Subscription;

  fuenteDatos: MatTableDataSource<Reporteador> = new MatTableDataSource([]);
  columnasMostradas = ['documento', 'almacen', 'fechaDocumento', 'folio', 'cantidad', 'stockBalance'];
  producto: string = "";

  public get diametro(): number {
    if (this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public productoCodigo: string, private ventana: MatDialogRef<FormaProductoKardex>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta, private translate: TranslateService, public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService) {
    if (productoCodigo !== null) {
      this.producto= this.productoCodigo.split(" ")[0];

      this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
        (credencial) => {
          this.tabla();
        }
      );

    }

  }

  ngOnInit() {

  }


  tabla(): void {

    this.ctx.dashbord.obtenerKardexProducto(this.producto, this.autenticacion.credencial.almacenId).toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;

    })
      .catch(e => {
        this.translate.get("messages.errorInformacion").subscribe((res:string)=>{
          this.alerta.mostrarError(res);
        })
      })
  }


  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

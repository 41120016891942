import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CusPage } from './components/page/page.component';

@NgModule({
  declarations: [CusPage],
  imports: [CommonModule],
  exports: [CusPage]
})
export class CusPageModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CusCard } from './components/card/card.component';
import {
  MatCardModule,
  MatFormFieldModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatIconModule,
  MatMenuModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CusCardHeader } from './components/card-header/card-header.component';
import { CusCardActions } from './components/card-actions/card-actions.component';
import { CardContent } from './components/card-content/card-content.component';
 
 

@NgModule({
  declarations: [CusCard, CusCardHeader, CusCardActions, CardContent],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatIconModule,
    FlexLayoutModule,
    MatMenuModule,
  ],
  exports: [CusCard, CusCardHeader, CusCardActions, CardContent]
})
export class CusCardModule {}
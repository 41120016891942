import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Productos } from 'src/app/shared/models/Productos';
import { startWith, map } from 'rxjs/operators';
import { ArticuloAlternativo } from 'src/app/shared/models/articulos-alternativos';
import { TranslateService } from '@ngx-translate/core';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss'],
})
export class FormaArticuloAlternativo implements OnInit, OnDestroy {
  forma: FormGroup;
  idSeleccionado: number;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }
  modelo: ArticuloAlternativo;

  articuloPrincipalFiltrados: Observable<Productos[]>;
  articuloPrincipalSinFiltrar: Productos[];
  articuloPrincipalfiltro = new FormControl();

  articuloAlternativoFiltrados: Observable<Productos[]>;
  articuloAlternativoSinFiltrar: Productos[];
  articuloAlternativofiltro = new FormControl();
  sub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<Productos>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private translate: TranslateService,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {
    if (id !== null) {
      this.idSeleccionado = id;
      this.ctx.articuloAlternativo
        .obtener(id)
        .toPromise()
        .then((resultado) => {
          this.modelo = resultado;
          Object.assign(this.forma.value, this.modelo);
          this.forma.reset(this.forma.value);
        })
        .catch((e) => {
          console.log(e);
          this.translate
            .get('message.errorInformacion')
            .subscribe((res: string) => {
              this.alerta.mostrarError(res);
            });
        });
    } else {
      this.idSeleccionado = 0;
      this.modelo = new ArticuloAlternativo();
      this.cargarFolio();
      this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
        (credencial) => {
          this.cargarArticulosAlternativos();
          this.cargarArticuloPrincipal();
        }
      );

    }





  }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.required],
      codigo: ['', Validators.required],
      productoPrincipalId: [0, Validators.required],
      productoAlternativoId: [0, Validators.required],
      articuloAlternativo: ['', Validators.required],
      articuloPrincipal: ['', Validators.required],
      factorCoincidencia: [0, [Validators.required, Validators.min(0)]],
      activo: [true, Validators.required],
    });
  }

  cargarFolio(): void {
    const r = this.ctx.articuloAlternativo
      .generarFolio()
      .toPromise()
      .then((resultado) => {
        this.forma.get('codigo').setValue('ARA-' + resultado);
      })
      .catch((e) => {
        this.translate.get('errorInformacion').subscribe((res: string) => {
          this.alerta.mostrarError(res);
        });
      });
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      const model = this.forma.value as ArticuloAlternativo;
      if (this.idSeleccionado === 0) {
        this.ctx.articuloAlternativo
          .guardar(model)
          .toPromise()
          .then(() => {
            this.translate
              .get('guardar', { value: 'Artículo' })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            if (e === 'Unprocessable Entity') {
              this.translate
                .get('guardarExiste', { value: 'artículo' })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.translate
                .get('message.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      } else {
        this.ctx.articuloAlternativo
          .actualizar(this.idSeleccionado, model)
          .toPromise()
          .then(() => {
            this.translate
              .get('message.actualizar', { value: 'El artículo' })
              .subscribe((res: string) => {
                this.alerta.mostrarExito(res);
              });
            this.guardado.emit();
            this.cerrar();
          })
          .catch((e) => {
            if (e === 'Unprocessable Entity') {
              this.translate
                .get('message.guardarExiste', { value: 'artículo' })
                .subscribe((res: string) => {
                  this.alerta.mostrarAdvertencia(res);
                });
            } else {
              this.translate
                .get('message.guardarError')
                .subscribe((res: string) => {
                  this.alerta.mostrarError(res);
                });
            }
          });
      }
    } else {
      this.translate.get('message.datosInvalido').subscribe((res: string) => {
        this.alerta.mostrarAdvertencia(res);
      });
    }
  }

  cargarArticulosAlternativos() {
    this.ctx.Productos.obtenerPorAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.articuloAlternativoSinFiltrar = resultado;
        this.articuloAlternativoFiltrados = this.articuloAlternativofiltro.valueChanges.pipe(
          startWith<string | Productos>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarProductoAlternativo(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarProductoAlternativo(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.articuloAlternativoSinFiltrar.filter(
      (t) =>
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0 &&
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  articuloAlternativoSeleccionado(modelo: Productos) {
    this.f['articuloAlternativo'].setValue(modelo.descripcion);
    this.f['productoAlternativoId'].setValue(modelo.id);
  }

  cargarArticuloPrincipal() {
    this.ctx.Productos.obtenerPorAlmacen(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((resultado) => {
        this.articuloPrincipalSinFiltrar = resultado;
        this.articuloPrincipalFiltrados = this.articuloPrincipalfiltro.valueChanges.pipe(
          startWith<string | Productos>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.filtrarProducto(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  private filtrarProducto(modelo: string): Productos[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.articuloPrincipalSinFiltrar.filter(
      (t) =>
        t.descripcion.toLowerCase().indexOf(valorFiltro) === 0 ||
        t.codigo.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  productoSeleccionado(modelo: Productos) {
    this.f['articuloPrincipal'].setValue(modelo.descripcion);
    this.f['productoPrincipalId'].setValue(modelo.id);
  }

  limpiarFiltroArticuloPrincipal(): void {
    this.f['articuloPrincipal'].setValue('');
    this.f['productoPrincipalId'].setValue(null);
    this.cargarArticuloPrincipal();
  }

  limpiarFiltroArticuloAlternativo(): void {
    this.f['articuloAlternativo'].setValue('');
    this.f['productoAlternativoId'].setValue(null);
    this.cargarArticulosAlternativos();
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}

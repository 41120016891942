import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cierre } from '../models/cierres';
import { DetalleCierres } from '../models/detalle-cierres';
import { ExcelDetalleCierre } from '../models/excel-detalle-cierre';
import { ConteosProductos } from '../models/conteos-productos';
import { ConteoProducto } from '../models/conteoProducto';

export class RepositorioConteosProductos extends Repositorio<ConteosProductos> {
  constructor(http: HttpClient) {
    super(http, 'Conteo');
  }

//   obtenerExcel(id: number): Observable<Blob> {
//     const ruta = `${this.ruta}/DescargarExcel`;
//     return this.http
//       .post(ruta, id, {
//         responseType: 'blob' as 'blob',
//       })
//       .pipe(
//         map((res: Blob) => {
//           return res;
//         })
//       );
//   }

  ObtenerConteosAlmacen(almacenId: number): Observable<ConteosProductos[]> {
    const ruta = `${this.ruta}/ObtenerConteosAlmacen/${almacenId}`;
    return this.http.get<ConteosProductos[]>(ruta);
  }


//   ObtenerDetalles(id: number): Observable<DetalleCierres[]> {
//     const ruta = `${this.ruta}/ObtenerDetalleCierres/${id}`;
//     return this.http.get<DetalleCierres[]>(ruta);
//   }

//   obtenerCierreDetalle(id: number): Observable<Cierre> {
//     const ruta = `${this.ruta}/ObtenerCierreDetalle/${id}`;
//     return this.http.get<Cierre>(ruta);
//   }

  ObtenerComprobanteConteo(id: number): Observable<ConteosProductos> {
    const ruta = `${this.ruta}/ObtenerComprobanteConteo/${id}`;
    return this.http.get<ConteosProductos>(ruta);
  }


//   cargarDetalleCierresExcel(items: ExcelDetalleCierre[]): Observable<ExcelDetalleCierre[]> {

//     let ruta = `${this.ruta}/CargarDetalleCierreExcel`;
//     return this.http.post(ruta, items).pipe(
//       map((articulos: ExcelDetalleCierre[]) => {
//         return articulos;
//       })
//     );
//   }

}

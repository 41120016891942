import { ImagenesProductos } from './imagenes-productos';
import { Almacen } from './Almacen';

export class Productos {
  id: number;
  codigo: string;
  codigoBarra: string;
  descripcion: string;
  grupoProductoId: number;
  grupoProductoDescripcion: string;
  unidadMedidaId: number;
  unidadMedidaDescripcion: string;
  costo: number;
  activo: boolean;
  stockMinimo: number;
  stockMaximo: number;
  reorden: number;
  precio: number;
  precioUSD: number;
  material: string;
  sku: string;
  PGC: number;
  GAC: number;
  productoId: number;
  productoDescripcion: string;
  productoCodigoDescripcion: string;
  unidadMedidaCodigoPiezaDesc: string;
  unidadMedidaClave: string;
  esAlternativo: string;
  almacenes: Almacen[];
  imagenesProductos: ImagenesProductos[];
  existencia: number;
  otro: string;
  idealCritico: number;
  maximoCritico: number;
  codigoProductoReemplazo: string;
}

import { Repositorio } from './repositorio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AnalisisPfep, ReportePfep } from '../models/analisis-pfep';
import { FiltroPfep } from '../models/filtro-pfep';
import { ReservaPfep, InfoReservaPfep } from '../models/reservas-pfep';
import { RequisicionPfep } from '../models/requisicion-pfep';
import { Pedido } from '../models/pedido';
import { ExcelMaxMinCriticos } from '../models/excel-max-min-criticos';
import { map } from 'rxjs/operators';

export class RepositorioAnalisisPfep extends Repositorio<AnalisisPfep> {
  constructor(http: HttpClient) {
    super(http, 'Pfep');
  }
  // comentario
  buscarAnalisisReporte(filtro: FiltroPfep): Observable<ReportePfep> {
    const ruta = `${this.ruta}/ObtenerAnalisis`;
    return this.http.post<ReportePfep>(ruta, filtro);
  }

  buscarAnalisisReservas(filtro: FiltroPfep): Observable<InfoReservaPfep> {
    const ruta = `${this.ruta}/ObtenerAnalisisReserva`;
    return this.http.post<InfoReservaPfep>(ruta, filtro);
  }

  crearRequisiciones(datos: RequisicionPfep): Observable<void> {
    const ruta = `${this.ruta}/CrearRequsiciones`;
    return this.http.post<void>(ruta, datos);
  }

  crearRequisicionSinDetalle(almacenId: number): Observable<Pedido> {
    const ruta = `${this.ruta}/CrearRequisicionSinDetalle/${almacenId}`;
    return this.http.get<Pedido>(ruta);
  }

  cargarMaxMinCriticos(items: ExcelMaxMinCriticos[], minaId: number): Observable<ExcelMaxMinCriticos[]> {

    let ruta = `${this.ruta}/CargarMaxMinCriticos/${minaId}`;
    return this.http.post(ruta, items).pipe(
      map((articulos: ExcelMaxMinCriticos[]) => {
        return articulos;
      })
    );
  }
}

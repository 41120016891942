import { Component, OnInit, Input } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { ValidadorFormas } from 'src/app/utilerias/validadorFormas';
import { Contexto } from 'src/app/shared/api/contexto.service';
import { Productos } from 'src/app/shared/models/Productos';
import {TranslateService} from '@ngx-translate/core';
import { groupBy2 } from 'src/app/utilerias/groupBy';
import { ProductosAlmacen } from 'src/app/services/productos/productos-almacen.service';
import { Subscription } from 'rxjs';
import { FormaTraspaso } from '../encabezado/forma-traspaso';
import { Traspaso } from 'src/app/shared/models/traspaso';
import { FiltroDetalleTraspasos } from 'src/app/services/traspasos/filtro-detalle-traspasos.service';
import { DetalleTraspaso } from 'src/app/shared/models/detalle-traspaso';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-registro-pedidos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class FormaRegistroTraspasosComponent implements OnInit {


  @Input() productos: Productos[] = [];
  id: number;
  forma: FormaTraspaso = new FormaTraspaso(new Traspaso());
  traspaso: Traspaso;
  get f() {
    return this.forma.controls;
  }

  sePuedeGuardar(): boolean {
    return this.forma.traspaso.id === 0 || this.forma.traspaso.esBorrador;
  }

  constructor(
    private activateRouter: ActivatedRoute,
    private router: Router,
    private alerta: ServicioAlerta,
    private db: Contexto,
    private validadorForma: ValidadorFormas,
    private transalte:TranslateService,
    public filtro: FiltroDetalleTraspasos,
    public nuevoAlmacen: ProductosAlmacen,

  ) {
    this.activateRouter.params.subscribe((params) => {
      this.id = +params['id'];
    });
    this.traspaso = this.activateRouter.snapshot.data['traspaso'];
  }

  async ngOnInit() {
    await this.cargarProductos(this.traspaso.almacenId);
    this.forma = new FormaTraspaso(this.traspaso);
  }

  async cargarProductos(almacenId: number) {
    let productos = await this.db.Productos.obtenerPorAlmacen(almacenId).toPromise();
    this.productos = [];
    this.productos = productos;
 }

  guardar(esBorrador: boolean): void {
    this.forma.get('esBorrador').setValue(esBorrador);

    if (this.forma.invalid && !esBorrador) {
      this.validadorForma.marcarComoTocado(this.forma);
      this.transalte.get("messages.datosInvalido").toPromise().then(e=>{
        this.alerta.mostrarAdvertencia(e);

      })
      return;
    }
    this.forma.disable();


    // let detalleAgrupado = groupBy2(this.forma.pedido.detalles, (pro: DetallePedido) => pro.productoCodigo) as Map<number, DetallePedido[]>;
    // const detalles = Array.from(detalleAgrupado.values()).map(ds =>
    // {
    //     const detalle = {...ds[0],
    //       cantidadFaltante: ds.map(d => d.cantidad - (d.cantidadEntregada != null? d.cantidadEntregada: 0)).reduce((a,b) => a+b,0),
    //       cantidad: ds.map(d => d.cantidad).reduce((a,b) => a+b,0),
    //       cantidadEntregada: ds.map(d => d.cantidadEntregada).reduce((a,b) => a+b,0),
    //       orden:  ds.map(d => d.orden).join(", "),
    //     } as DetallePedido;

    //     return detalle;
    // });

    // this.forma.pedido.detalles = [];
    // this.forma.pedido.detalles.length = 0;
    // this.forma.pedido.detalles = detalles;

    var helper = {};
    var detalleTraspasoAgrupado = this.forma.traspaso.detalles.reduce(function (r, o) {
      var key = o.productoId + '-' + o.orden;
      if (!helper[key]) {
        helper[key] = Object.assign({}, o);
        r.push(helper[key]);
      } else {
        helper[key].cantidadFaltante += o.existencia;
        helper[key].cantidad += o.cantidad;
      }
      return r;
    }, []);

    let detTraspaso = detalleTraspasoAgrupado as DetalleTraspaso[];
     this.forma.traspaso.detalles = [];
    this.forma.traspaso.detalles.length = 0;
    this.forma.traspaso.detalles = detTraspaso;

    const accion =


        this.db.traspasos.generarTraspaso(this.forma.traspaso);

    accion
      .toPromise()
      .then(() => {
        this.transalte.get("messages.documentoguardado").toPromise().then(e=>{
          this.alerta.mostrarExito(e);
          this.router.navigate(['TraspasoAlmacen']);

        })
      })
      .catch(() => {
        this.transalte.get("messages.errorguardar").toPromise().then(e=>{
          this.alerta.mostrarError(e);

        })
        this.forma.enable();
      });
  }

  limpiar() { }

  listaRequisiciones() {
    this.filtro.productoFiltrado.next([]);
    this.router.navigate(['/TraspasoAlmacen']);
  }
}

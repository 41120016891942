import { Contexto } from 'src/app/shared/api/contexto.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AutenticacionService } from 'src/app/core/services/autenticacion.service';
import { ServicioUsuarioAlmacen } from 'src/app/services/almacen/usuario-almacen.service';

@Component({
  selector: 'app-pareto',
  templateUrl: 'pareto.component.html',
  styleUrls: ['pareto.component.scss'],
})
export class ParetoComponent implements OnInit, OnDestroy {
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'B',
          type: 'linear',
          position: 'left',
          ticks: {
            min: 0,
          },
        },
        {
          id: 'A',
          type: 'linear',
          position: 'right',
          ticks: {
            max: 100,
            min: 0,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    {
      data: [],
      type: 'line',
      label: '%',
      backgroundColor: 'rgba(0,0,0,0)',
      yAxisID: 'A',
    },
    { data: [], label: 'Pareto', yAxisID: 'B' },
  ];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  sub: Subscription;

  constructor(
    private ctx: Contexto,
    public servicioUsuarioAlmacen: ServicioUsuarioAlmacen,
    public autenticacion: AutenticacionService
  ) {}

  ngOnInit() {
    this.sub = this.servicioUsuarioAlmacen.actualizarCredencial.subscribe(
      (credencial) => {
        this.cargarPareto();
      }
    );

  }

  cargarPareto() {
    this.ctx.KPIS.Pareto(this.autenticacion.credencial.almacenId)
      .toPromise()
      .then((e) => {
        console.log(e);

        this.barChartLabels = e.map((p) => p.numeroParte);
        this.barChartData[1].data = e.map((p) => p.discrepanciaPrdTotal);
        this.barChartData[0].data = e.map((p) => p.acomulado);
        this.barChartData[1].backgroundColor = e.map((p) => p.color);
      });
  }

  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
  }
}
